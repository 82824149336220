import React from 'react';

function CardType(props) {
    const cardType = props?.card_type;
    const last4 = props?.last_4_digits;
    return (
        <span className={`mb-0 text-capitalize ${props?.isBold ? 'text-bold' : ''}`}>
            {cardType && last4 ? (cardType + ' - ' + last4) :
                cardType ? cardType :
                    last4 ? last4 : '-'
            }
        </span>
    );
}

export default CardType;