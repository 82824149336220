import React from 'react';
import { getTextColorByBG } from '../../../utils/utils';

function SmallCampaignTiles(props) {
    return (
        <div
            style={{
                backgroundColor: 
                props?.completedCampaigns ? 'unset' :
                (props?.pageSettings?.secondary_color ? props?.pageSettings?.secondary_color : '#daeffffa')
            }}
            className={`d-sm-block d-block d-md-none campaign-main-section pb-3 pt-2 ${props?.featuredItems?.length > 0 ? 'pt-3' : ''}`}>
            <div className='container'>
                <div className={`row justify-content-center ${(props?.featuredItems?.length > 0 && !props?.completedCampaigns) ? ' mt-4' : ''}`}>
                    {
                        props?.storeItems?.map((item, index) => {
                            return <div

                                key={`icon-tiles` + index} className={`col-xxl-2 col-sm-4 col-6 mt-4 text-center`}>
                                <a href={
                                    !props?.completedCampaigns ? 
                                    (item?.type === 'product' ?
                                        `
                                                        ${props?.organizationInfo?.organization_short_name ?
                                            props?.organizationInfo?.organization_short_name : 'masjid'
                                        }/product/${item.donation_page_link}` : `${props?.organizationInfo?.organization_short_name ?
                                            props?.organizationInfo?.organization_short_name : 'masjid'
                                        }/campaign/${item.donation_page_link}`) : null
                                }>
                                    <div className='p-2' style={{
                                        border: "1px solid lightgrey",
                                        borderRadius: '10px',
                                        minHeight: '187px',
                                        backgroundColor: props?.pageSettings?.primary_color ? props?.pageSettings?.primary_color : '#004475'
                                    }}>
                                        <img className='image-icon store-img' src={item?.campaign_icon ? (props?.campaignIconUrl + item.campaign_icon) : '/img/masjid/mosque.png'} />
                                        <h6 style={{
                                            minHeight: '50px', maxHeight: '50px', overflow: 'hidden',
                                            color: props?.pageSettings?.primary_color ? getTextColorByBG(props?.pageSettings?.primary_color) : 'white'
                                        }}
                                            className='mt-2'>{item?.campaign_title}
                                        </h6>
                                        {
                                            !props?.completedCampaigns ?
                                                <div
                                                    style={{
                                                        marginTop: '1rem',
                                                        minHeight: '45px',
                                                        maxHeight: '45px'
                                                    }}
                                                    className={`d-flex align-items-center 
                                     ${item?.type === 'product' ? 'justify-content-between' : 'justify-content-end'}`}>
                                                    {
                                                        item?.type === 'product' ?
                                                            <div className='d-flex flex-column align-items-start'>
                                                                <span
                                                                    style={{
                                                                        color: props?.pageSettings?.primary_color ? getTextColorByBG(props?.pageSettings?.primary_color) : 'white',
                                                                        fontWeight: '900'
                                                                    }}
                                                                    className='f-18'>${item?.price_per_unit / 100}</span>
                                                                {
                                                                    item?.remainingQuantity && item?.goal_amount ?
                                                                        <span
                                                                            style={{
                                                                                color: props?.pageSettings?.primary_color ? getTextColorByBG(props?.pageSettings?.primary_color) : 'white'
                                                                            }}
                                                                            className='f-12-small'>{item?.remainingQuantity} Available</span> : ''
                                                                }
                                                            </div> : ''
                                                    }
                                                    <div>
                                                        <a
                                                            href={
                                                                item?.type === 'product' ?
                                                                    `
                                                        ${props?.organizationInfo?.organization_short_name ?
                                                                        props?.organizationInfo?.organization_short_name : 'masjid'
                                                                    }/product/${item.donation_page_link}` : `${props?.organizationInfo?.organization_short_name ?
                                                                        props?.organizationInfo?.organization_short_name : 'masjid'
                                                                    }/campaign/${item.donation_page_link}`
                                                            }
                                                        ><i style={{
                                                            display: 'inline-block',
                                                            borderRadius: '60px',
                                                            padding: '0.5em 0.6em',
                                                            boxShadow: '0 0 2px #888',
                                                            backgroundColor: props?.pageSettings?.button_color ? props?.pageSettings?.button_color : '#004475',
                                                            color: getTextColorByBG(props?.pageSettings?.button_color ? props?.pageSettings?.button_color : '#004475')
                                                        }} className="fa-solid fa-arrow-right"></i></a>
                                                    </div>
                                                </div> : <div style={{
                                                    color: props?.pageSettings?.primary_color ? getTextColorByBG(props?.pageSettings?.primary_color) : 'white'
                                                }} className='f-18 mt-5 completed-campaign text-center pt-2 mb-0'>
                                                    Completed
                                                </div>
                                        }
                                    </div>
                                </a>

                            </div>
                        })
                    }

                </div>
            </div>

            {
                props?.completedItems?.length === 0 && props?.storeItems?.length > 0 ?
                    props?.footer() : ''
            }
        </div>
    );
}

export default SmallCampaignTiles;