import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./upload-image-dialog.scss";
import useUploadFile from '../../hooks/upload-file.hook';
import FieldError from '../fields-errors/field-error';
import { ENVIRONMENT_CONSTANT } from '../../constants/env.constant';

const UploadImageDialog = React.forwardRef((props, ref) => {

    const {file, fileError, fileProperties,checkIsValidImage, imageBase64, setImageBase64, clearProps} = useUploadFile();
    const imageBaseUrl = ENVIRONMENT_CONSTANT.LOGO_BASE_URL;
    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow,
            handleClose: handleClose,
            setImageSrcByFile: setImageSrcByFile,
            setImageSrcByField: setImageSrcByField,
            file: file,
            fileProperties: fileProperties
        };
    });

    const saveFile = () => {
        if (file) {
            props?.setFileName(file?.name);
            props?.setCurrentFile(file);
            handleClose();
        }
    }

    const handleClose = () => {
        clearProps();
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    }

    const setImageSrcByFile = (file) => {
        checkIsValidImage(file);
        handleShow();
    }

    const setImageSrcByField = (value) => {
        setImageBase64(imageBaseUrl + value?.image_location);
        handleShow();
    }

    return (
        <>
            <Modal
                keyboard={false}
                backdrop="static" show={show} onHide={handleClose} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='upload-image-container'>
                        {
                            imageBase64 ?
                                <div>
                                    <img style={{
                                        maxHeight: '350px',
                                        maxWidth: '100%'
                                    }} src={imageBase64} className='image-responsive' />
                                    <span className='f-16 text-ma-primary mt-2'>{
                                        fileProperties?.image_alt_name ? fileProperties?.image_alt_name : props?.currentValue?.image_alt_name}</span>
                                    <button onClick={() => {
                                        document.getElementById('uploadImage')?.click()
                                    }} type='button' className='btn btn-primary mt-2'>Change Image</button>
                                </div>
                                : <div>
                                    <i className="text-primary fa-solid fa-images f-25"></i>
                                    <button
                                        onClick={() => {
                                            document.getElementById('uploadImage')?.click()
                                        }}
                                        type='button' className='mt-2 w-50 btn btn-primary mb-0'>Upload</button>
                                    <span className='mt-2 f-12-small'>Supports JPEG, JPG, PNG</span>
                                    <span className='mt-2 f-12-small text-warning'>Maximum 2mb is allowed to upload.</span>
                                </div>
                        }
                        {
                            fileError ?
                                <p className='text-center'><FieldError message={fileError} /></p> : ''
                        }
                        <input onChange={checkIsValidImage} id='uploadImage' type='file' className='d-none' accept='.png, .jpeg, .jpg' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='button' variant="default" onClick={handleClose}>
                        Close
                    </Button>
                    <Button onClick={saveFile} type='button' variant="primary">
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
});

export default UploadImageDialog;