import React from 'react';
import NoDataComponent from '../no-data/no-data';

function TableWidget(props) {
    const columns = props?.columns;
    const data = props?.data;
    return (
        <div className="table-responsive">
            <table className="table align-items-center ">
                <thead className='text-center prayers-table-header white'>
                    <tr>
                        {
                            columns.map((item, index) => {
                                return <th key={index}>{item}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody className='prayer-table-body text-center'>
                    {
                        data?.map((item, key) => {
                            return <tr key={key}>
                                <td>{item?.currency}</td>
                                <td>${item?.total_amount}</td>
                            </tr>
                        })
                    }

                </tbody>

            </table>

            {/* no data message */}
            {
                data?.length === 0 ?
                    <NoDataComponent message={'No Record Available'} /> : ''
            }

        </div>
    );
}

export default TableWidget;