import React, { useEffect, useRef, useState } from 'react';
import NoDataComponent from '../../../shared/no-data/no-data';
import Loader from '../../../shared/loader/loader';
import { Form } from 'react-bootstrap';
import getFormattedDate, { dateTimeFormat } from '../../../utils/date-format';
import { Link } from 'react-router-dom';
import { receiptStatusList } from '../../../data-list/data-list';
import axios from 'axios';
import { CAMPAIGNS_NAMES, GENERATE_PDF, GET_DONORS_RECEIPTS, GET_YEARLY_RECEIPT, SEND_YEARLY_RECEIPT } from '../../../constants/endpoints';
import { useForm } from 'react-hook-form';
import PreviewEmailDialog from './dialogs/preview-email.dialog';

function YearlyReceipt(props) {
    //prop start
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dataLoader, setDataLoader] = useState(false);
    const columns = ['Name', 'Email','Total', 'Receipt sent at', 'Actions'];
    const [donorList, setDonorList] = useState([]);
    const [campaignsList, setCampaignList] = useState([]);
    const {register, getValues} = useForm();
    const [receiptSettings, setReceiptSettings] = useState({});
    const previewEmailDialogRef = useRef(null);

    //methods start
    const getDonors = () => {
        setDataLoader(true);
        let formData = getValues();
        const fiscalDate = fiscalDateMapper();
        formData.start_date = dateTimeFormat(fiscalDate?.startDate);
        formData.end_date = dateTimeFormat(fiscalDate?.endDate);
        axios.post(GET_DONORS_RECEIPTS, formData)
        .then((res) => {
            setDataLoader(false);
            if(res?.data?.status){
                setDonorList(res?.data?.response);
            }
        }).catch((error) => {
            setDataLoader(false);
        })
    }

    const getCampaignsName = () => {
        axios.get(CAMPAIGNS_NAMES + 'both')
          .then((res) => {
            if (res?.data?.status) {
                setCampaignList(res?.data?.response);
            }
          }).catch((error) => {
    
          });
    };

    const getYearlyReceiptSettings = () =>{
        setDataLoader(true);
        axios.get(GET_YEARLY_RECEIPT)
        .then((res) => {
            if(res?.data?.status){
                setReceiptSettings(res?.data?.response);
            }else{
                setReceiptSettings({
                    fiscal_year_period: '0-01'
                });
            }
        }).catch((error) => {
            setReceiptSettings({
                fiscal_year_period: '0-01'
            });
        });
    }

    const generatePdf = (currentDonor, isLoader) => {
        if(isLoader){
            setDataLoader(true);
        }
        let formValues = currentDonor;
        formValues.fiscal_year_start = startDate;
        formValues.fiscal_year_end = endDate;
        formValues.pdf_template = receiptSettings?.pdf_thank_you_message;
        axios.post(GENERATE_PDF, formValues, {
            responseType: 'blob'
        })
            .then((res) => {
                setDataLoader(false);
                if (res?.data) {
                    if(res?.data?.type === 'application/json'){
                        alert("Failed to download yearly receipt.")
                    }else{
                        const blobUrl = window.URL.createObjectURL(
                            new Blob([res?.data], { type: "application/pdf" })
                          );
      
                          // Create a link element
                          const link = document.createElement("a");
                          link.href = blobUrl;
      
                          // Set the download attribute to specify the file name
                          link.setAttribute("download", `${currentDonor?.first_name} ${currentDonor?.last_name}.pdf`);
      
                          // Append the link to the body
                          document.body.appendChild(link);
      
                          // Programmatically click the link to trigger the download
                          link.click();
      
                          // Remove the link from the DOM
                          document.body.removeChild(link);
      
                          // Release the object URL
                          window.URL.revokeObjectURL(blobUrl);
                          // window.open(url, '_black');
                          // const link = document.createElement("a");
                          // link.href = url
                          // const fileName = `${currentDonor?.first_name} ${currentDonor?.last_name}_yearly_receipt.pdf`;
                          // link.setAttribute("download", fileName);
                          // document.body.appendChild(link);
                          // link.click();
                          // document.body.removeChild(link);
                    }
                }
            }).catch((error) => {
                setDataLoader(false);
            })
    }

    const sendEmail = (currentDonor) => {
        setDataLoader(true);
        let formValues = currentDonor;
        formValues.fiscal_year_start = startDate;
        formValues.fiscal_year_end = endDate;
        formValues.pdf_template = receiptSettings?.pdf_thank_you_message;
        formValues.email_template = {
            email_subject: receiptSettings?.email_subject ? receiptSettings?.email_subject : "",
            email_body: receiptSettings?.email_body ? receiptSettings?.email_body : ""
        }
        axios.post(SEND_YEARLY_RECEIPT, formValues)
            .then((res) => {
                setDataLoader(false);
                if (res?.data?.status) {
                    alert("Email sent successfully.");
                    getDonors();
                }else{
                    alert(res?.data?.response?.message);
                }
            }).catch((error) => {
                alert("Failed to send email.");
                setDataLoader(false);
            })
    }

    useEffect(() => {
        getYearlyReceiptSettings();
        getCampaignsName();
    }, []);

    useEffect(() => {
        if(receiptSettings?.fiscal_year_period){
            getDonors();
        }
    }, [receiptSettings]);

    const fiscalDateMapper = () => {
        const currentDate = new Date();
        let startDate;
        let endDate;
        if(receiptSettings?.fiscal_year_period === '0-01'){
            startDate = new Date((currentDate.getFullYear() - 1),0,1,0,0,0,0);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 12, 0, 23,59,59,59);
        }else if(receiptSettings?.fiscal_year_period === '3-01'){
            startDate = new Date((currentDate.getFullYear() - 1),3,1,0,0,0,0);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 12, 0, 23,59,59,59);
        }else if(receiptSettings?.fiscal_year_period === '6-01'){
            startDate = new Date((currentDate.getFullYear() - 1),6,1,0,0,0,0);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 12, 0, 23,59,59,59);
        }else if(receiptSettings?.fiscal_year_period === '7-01'){
            startDate = new Date((currentDate.getFullYear() - 1),7,1,0,0,0,0);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 12, 0, 23,59,59,59);
        }else if(receiptSettings?.fiscal_year_period === '9-01'){
            startDate = new Date((currentDate.getFullYear() - 1),9,1,0,0,0,0);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 12, 0, 23,59,59,59);
        }else{
            startDate = new Date((currentDate.getFullYear() - 1),0,1,0,0,0,0);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 12, 0, 23,59,59,59);
            
        }

        setStartDate(getFormattedDate(startDate));
        setEndDate(getFormattedDate(endDate));
        return {startDate, endDate};
    }

    return (
        <>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2'>
                            <h5 className='font-weight-semibold text-black'>Yearly Receipts</h5>
                        </div>

                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 text-right f-14'>
                            <Link to={'/yearly-receipts-settings'}
                                className="mx-3 add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3">
                                <span>
                                    <i className="fa-solid fa-gears mr-5"></i>
                                </span>
                                <span className='ms-2 text-white'>Settings</span>
                            </Link>
                        </div>
                        <div className='col-12'>
                            <p className='text-black'>Current fiscal year: {`${startDate} - ${endDate}`}</p>
                        </div>
                    </div>
                </div>

                {/* search start */}
                <div className='row'>

                    <div className="col-md-2 mt-2">
                        <div className="form-group">
                            <Form.Select {...register('status',{
                                onChange:e => {
                                    getDonors();
                                }
                            })}>
                                {
                                    receiptStatusList.map((item, index) => {
                                        return <option
                                            key={'receipt_status_' + index}
                                            value={item.value}>{item.label}</option>
                                    })
                                }
                            </Form.Select>
                        </div>
                    </div>

                    <div className="col-md-2 mt-2">
                        <div className="form-group">
                            <Form.Select {...register('campaign_id',{
                                onChange: e => {
                                    getDonors();
                                }
                            })}>
                                <option value={""}>All Campaigns</option>
                                {
                                    campaignsList.map((item, index) => {
                                        return <option
                                            key={'campaigns ' + index}
                                            value={item.id}>{item.campaign_title}</option>
                                    })
                                }
                            </Form.Select>
                        </div>
                    </div>
                    {/* text filter */}
                    <div className="col-md-4 mt-2">
                        <div className="form-group">
                            <div className="input-group">
                                <input
                                    {...register('search_value')}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            getDonors()
                                        }
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by email"
                                />
                                <button onClick={(e) => {
                                    getDonors();
                                }} type="button" className="btn btn-outline-secondary mb-0">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* search end */}

                {/* email receipts listing start */}
                <div className='row mt-3 position-relative'>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className="table-responsive">
                                {
                                    !dataLoader ? 
                                    <table className="table align-items-center ">
                                            <thead className='text-center prayers-table-header white'>
                                                <tr>
                                                    {
                                                        columns.map((item, index) => {
                                                            return <th key={index}>{item}</th>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody className='prayer-table-body text-center'>
                                                {
                                                    donorList.map((item, index) => {
                                                        return <tr key={'receipt_' + index}>
                                                            <td>{item?.first_name + ' ' + item?.last_name}</td>
                                                            <td>{item?.email}</td>
                                                            <td>${item?.total_amount ? parseFloat(item?.total_amount)?.toFixed(2) : 0}</td>
                                                            <td>{item?.receipts[0]?.receipt_sent_at ? getFormattedDate(item?.receipts[0]?.receipt_sent_at) : '-'}</td>
                                                            <td>
                                                            <i 
                                                            onClick={(e) => {
                                                                previewEmailDialogRef?.current?.handleShow(receiptSettings, item)
                                                            }}
                                                            title='View Receipt' className="fa-regular fa-eye me-2 cursor-pointer"></i>
                                                            <i onClick={(e) => generatePdf(item, true)} title='Download Receipt' className="fa-solid fa-download cursor-pointer me-2"></i>
                                                            <i onClick={(e) => sendEmail(item)} title={
                                                                item?.receipts?.length > 0 ?
                                                                'Resend Email' : 'Send Email'
                                                            } className="fa-solid fa-envelope-circle-check cursor-pointer"></i>
                                                            </td>
                                                        </tr>
                                                    })
                                                }

                                            </tbody>

                                        </table> : ''
                                }
                                {/* no data message */}
                                {

                                    !dataLoader && donorList?.length === 0 ?
                                        <NoDataComponent message={'No Data Found'} /> : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        dataLoader ?
                            <Loader /> : ''
                    }
                </div>
                {/* email receipts listing end */}
            </div>

        {/* preview email dialog start */}
        <PreviewEmailDialog ref={previewEmailDialogRef} generatePdf={generatePdf} />
        {/* preview email dialog end */}
        </>
    );
}

export default YearlyReceipt;