const getFormattedDate = (date, isDatePicker) => {
  var currentDate = new Date(date);

  var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  var day = currentDate.getDate().toString().padStart(2, "0");
  var year = currentDate.getFullYear().toString();

  var formattedDateTime = isDatePicker ? (`${year}-${month}-${day}`) : (month + "/" + day + "/" + year);
  return formattedDateTime;
};

export const dateTimeFormat = (date, isHyphen) => {
  const dateTime = new Date(date);
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const hour = String(dateTime.getHours()).padStart(2, '0');
  const minute = String(dateTime.getMinutes()).padStart(2, '0');
  const second = String(dateTime.getSeconds()).padStart(2, '0');
  
  const formattedDateTime = 
  isHyphen ? `${year}-${month}-${day} ${hour}:${minute}:${second}` :
  `${month}/${day}/${year} ${hour}:${minute}:${second}`;
  return formattedDateTime;
};

export default getFormattedDate;
