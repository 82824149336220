import React, { useEffect, useState } from 'react';
import {
    PaymentRequestButtonElement,
    Elements, PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import PaymentForm from '../../pages/components/donation-widget/payment-form';
import axios from 'axios';
import { INITIATE_QUICK_PAYMENT } from '../../constants/endpoints';
import { useFormContext } from 'react-hook-form';
import calculateCCAfterFee, { calculateFeePercentage } from '../../utils/stripe-payment-fee.util';

const PaymentRequestForm = (props) => {

    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const {register, watch, setValue, getValues} = useFormContext();
    const fieldsWatcher = watch();

    const returnSelectedAmount = () => {
        if(props?.campaignDetail?.type === 'product'){
            return parseFloat(props?.amount);
        }else{
            return parseFloat(
                (!getValues()?.selected_amount || getValues()?.selected_amount === 'custom_amount') ? getValues()?.custom_amount :
                    getValues()?.selected_amount);
        }
        
    }

    const [stripeAmount, setStripeAmount] = useState(0);
    useEffect(() => {
        setPaymentRequest(null);
    }, [stripe, props?.campaignDetail, props?.selectedDonationType]);

    useEffect(() => {
        if(!paymentRequest){
            props?.setStripeButtonElementLoader(true);
            if (stripe && props?.campaignDetail && props?.selectedDonationType) {
                let amount = returnSelectedAmount();
                const finalAmount =
                ((props?.campaignDetail?.donation_amount?.ask_donors_cover_fee_default
                    ? Math.round(parseFloat(calculateCCAfterFee((amount)) * 100)) : (Math.round(amount * 100)) ))
                
                  if(!isNaN(finalAmount)){
                    setStripeAmount(finalAmount)
                  };
                const paymentRequestObject = stripe.paymentRequest({
                    country: props?.campaignDetail?.country?.toLowerCase() === 'usa' ? 'US' : props?.campaignDetail?.country,
                    currency: props?.campaignDetail?.currency,
                    total: {
                        label: 'Total',
                        amount: !isNaN(finalAmount) ? finalAmount : 0
                    },
                    requestPayerName: true,
                    requestPayerEmail: true
                });
    
                const donationType = props.selectedDonationType;
                console.log(donationType);
                //checking if apple pay or google pay is available or not 
                paymentRequestObject.canMakePayment().then((response) => {
                    props?.setStripeButtonElementLoader(false);
                    if (response) {
                        // alert("Apple pay is active: "+ JSON.stringify(response));
                        props?.setPaymentFlow(true);
                        setPaymentRequest(paymentRequestObject);
    
                        paymentRequestObject.on('paymentmethod', async (ev) => {
                            //constructing personal info object from google and apple pay integration
                            let userInfo = {
                                first_name: ev.payerName.split(' ')[0],
                                last_name: ev.payerName.split(' ')[1],
                                email: ev.payerEmail,
                                address:ev.paymentMethod.billing_details.address.line1,
                                address_2: ev.paymentMethod.billing_details.address.line2,
                                city:ev.paymentMethod.billing_details.address.city,
                                state_province:ev.paymentMethod.billing_details.address.state,
                                postal_code:ev.paymentMethod.billing_details.address.postal_code,
                                country:ev.paymentMethod.billing_details.address.country,
                                payment_method_id: ev.paymentMethod.id,
                                donation_type: donationType
                            }
                            
                            const donationPayload = props?.returnDonationPayload(userInfo);
    
                            try {
                                const res = await axios.post(INITIATE_QUICK_PAYMENT, donationPayload);
                                const clientSecret = res?.data?.response?.client_secret;
                                if(clientSecret){
                                    // Confirm the PaymentIntent without handling potential next actions (yet).
                                const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
                                    clientSecret,
                                  {payment_method: ev.paymentMethod.id},
                                  {handleActions: false}
                                );
        
                                if (confirmError) {
                                    alert(JSON.stringify(confirmError));
                                  // Report to the browser that the payment failed, prompting it to
                                  // re-show the payment interface, or show an error message and close
                                  // the payment interface.
                                  ev.complete('fail');
                                } else {
                                  // Report to the browser that the confirmation was successful, prompting
                                  // it to close the browser payment method collection interface.
                                  ev.complete('success');
                                  props?.onPaymentSuccess("Payment successful thank you for your kind donations.");
                                  // Check if the PaymentIntent requires any actions and if so let Stripe.js
                                  // handle the flow. If using an API version older than "2019-02-11"
                                  // instead check for: `paymentIntent.status === "requires_source_action"`.
                                  if (paymentIntent.status === "requires_action") {
                                    // Let Stripe.js handle the rest of the payment flow.
                                    const {error} = await stripe.confirmCardPayment( clientSecret );
                                    if (error) {
                                        alert(JSON.stringify(error));
                                      // The payment failed -- ask your customer for a new payment method.
                                    } else {
                                        props?.onPaymentSuccess("Payment successful thank you for your kind donations.");
                                      // The payment has succeeded.
                                    }
                                  } else {
                                    props?.onPaymentSuccess("Payment successful thank you for your kind donations.");
                                    // The payment has succeeded.
                                  }
                                }
                            }else{
                                alert(res?.data?.response);
                            }
                            } catch (error) {
                                alert('Unable to complete your request, try again later.');
                            }
                          });
                    }else{
                        // props?.setPaymentFlow('info');
                        props?.setPaymentFlow(false);
                        // alert("Apple pay is not active");
                    }
                }).catch((error) => {
                    alert(error?.toString());
                    props?.setStripeButtonElementLoader(false);
                });
            }
    
            // if(props?.campaignDetail?.donation_amount?.ask_donors_cover_fee_default){
            //     console.log('Is default processing fee true')
            //     setValue('cover_processing_fee',true);
            // }else{
            //     console.log('Is default processing fee false')
            //     setValue('cover_processing_fee',false);
            // }
        }
    },[stripe, paymentRequest, props?.campaignDetail, props?.selectedDonationType])

    useEffect(() => {
        let amount = returnSelectedAmount();
        if (paymentRequest && amount >= 0) {
            const finalAmount =
                ((props?.campaignDetail?.donation_amount?.ask_donors_cover_fee_default
                    ? Math.round(parseFloat(calculateCCAfterFee((amount)) * 100)) : (Math.round(amount * 100))))
            paymentRequest.update({
                country: props?.campaignDetail?.country?.toLowerCase() === 'usa' ? 'US' : props?.campaignDetail?.country,
                currency: props?.campaignDetail?.currency,
                total: {
                    label: 'Total',
                    amount: !isNaN(finalAmount) ? finalAmount : 0
                },
            });
        }
    },[getValues()?.selected_amount, getValues()?.custom_amount, props?.amount]);

    const updatePaymentRequest = (event) => {
        const finalAmount = ((event?.target?.checked
            ? Math.round(parseFloat(calculateCCAfterFee((props?.amount)) * 100)) : (Math.round(props?.amount * 100)) ))
        if(paymentRequest){
            setStripeAmount(finalAmount);
            paymentRequest.update({
                country: props?.campaignDetail?.country?.toLowerCase() === 'usa' ? 'US' : props?.campaignDetail?.country,
                currency: props?.campaignDetail?.currency,
                total: {
                    label: 'Total',
                    amount: finalAmount
                },
            });
        }
    }

    // useEffect(() => {
    //     if(props?.amount && paymentRequest){
    //         const finalAmount =
    //         ((props?.campaignDetail?.donation_amount?.ask_donors_cover_fee_default
    //             ? Math.round(parseFloat(calculateCCAfterFee((props?.amount)) * 100)) : (Math.round(props?.amount * 100)) ))
    //             paymentRequest.update({
    //                 country:'US',
    //                 currency: 'usd',
    //                 total: {
    //                     label: 'Total',
    //                     amount: finalAmount
    //                 },
    //             });

    //             setStripeAmount(finalAmount);
    //     }
    // }, [props?.amount])

    useEffect(() => {
        if(props?.formStep === 'request_button'){
            updatePaymentRequest({target:{checked:props?.campaignDetail?.donation_amount?.ask_donors_cover_fee_default }});
        }
    }, [props?.formStep])

    return (
        <>
            {
                paymentRequest ?
                    <div className='text-center'>
                        <p className='f-18 mb-3'>Choose a payment option:</p>
                    {
                        (props?.campaignDetail?.donation_amount?.ask_donors_cover_fee &&
                            props?.formStep === 'request_button'
                            ) ?
                            <div className='col-lg-12 mt-2 mb-2'>
                                <div className='d-flex align-items-center justify-content-start'>
                                    <input 
                                    defaultChecked={props?.campaignDetail?.donation_amount?.ask_donors_cover_fee_default ? true : false} 
                                    {...register('cover_processing_fee', {
                                        
                                        onChange: ((e) => {
                                            if(props?.calculateStripeFee){
                                                props?.calculateStripeFee(e)
                                            }
                                            updatePaymentRequest(e);
                                        })
                                    })} className="me-1" type="checkbox" />
                                    <label className='f-14'>
                                        Optionally add ${calculateFeePercentage(props?.amount)} to cover processing fees
                                    </label>
                                </div>
                            </div> : ''
                    }
                        <PaymentRequestButtonElement options={{ paymentRequest }} /> 
                        <button 
                        onClick={(() => props?.setPaymentFlow('info'))}
                            style={{
                                backgroundColor: props?.campaignDetail?.primary_color ? (props?.campaignDetail?.primary_color) : '#005B97',
                                borderBottom: props?.campaignDetail?.primary_color ? ('2px solid ' + props?.campaignDetail?.primary_color) : ''
                            }}
                            type="button" className={`btn btn-md w-100 text-white btn-step-one mt-4`}>
                            <span>
                                <span className='f-18 mx-4'><i className="fa-regular fa-credit-card f-18"></i>  Credit / Debit Card {props?.campaignDetail?.enable_ach ? ' / ACH' : ''}</span>
                            </span>
                        </button>

                        {/* <div className='mt-2'>
                        <img src="../../img/masjid/payment-option.png" className='w-80' alt="Stripe Logo" />
                        </div> */}
                    </div>
                    : ''
                    // :
                    // <>
                    //     {options?.clientSecret && (
                    //         <div className="mt-4">
                    //             <Elements options={options} stripe={props?.stripePromise}>
                    //                 {/* adding payment options for card and google pay apple pay */}
                    //                 <PaymentForm campaignDetail={props?.campaignDetail} clientSecret={options?.clientSecret} onPaymentSuccessful={props?.onPaymentSuccessful} />
                    //             </Elements>
                    //         </div>

                    //     )}
                    // </>
                    
                    
            }
        </>
    );
}

export default PaymentRequestForm;