import React, { useImperativeHandle, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { activeTilesBasedOnDate } from '../../../../../utils/utils';
import AddEditTiles from '../../tiles/add-tiles.dialog';
import { ENVIRONMENT_CONSTANT } from '../../../../../constants/env.constant';

const AttachTilesDialog = React.forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const addTileDialogRef = useRef(null);

    const { register, getValues, watch, reset } = useForm({
        defaultValues: {
            selected_tiles: []
        }
    });

    const watchField = watch();

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow
        }
    });

    const handleShow = (assignedTiles) => {
        if (assignedTiles?.length > 0) {
            const assignedTileIDs = props?.tiles.filter(it => {
                const index = assignedTiles.findIndex(a => a.id === it.id);
                if (index >= 0) {
                    return true
                } else {
                    return false
                }
            }).map(it => it.id);

            reset({
                selected_tiles: assignedTileIDs
            });
        } else {
            reset({
                selected_tiles: []
            });
        }
        setShow(true);
    }

    const handleClose = () => {
        reset({
            selected_tiles: []
        })
        setShow(false);
    };

    return (
        <>
        <Modal
            size='lg'
            keyboard={false}
            backdrop="static" show={show} onHide={handleClose} animation={true}>
            <Modal.Header closeButton>
                <Modal.Title>Attach Tiles</Modal.Title>
            </Modal.Header>
            <Modal.Body className='dialog-height'>
                <div className='row'>
                    {
                        props?.tiles?.map((item, index) => {
                            return <div key={'tile_' + index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-2">
                                <label className={`card cursor-pointer
                            ${watchField.selected_tiles.includes(item.id) === true ? 'selected_tiles' : ''}`}>

                                    <div className="card-header p-3 d-flex justify-content-center position-relative">

                                        <div className='select-tile'>
                                            <input
                                                value={item.id}
                                                name={item.id}
                                                {...register('selected_tiles')}
                                                className='form-control' type='checkbox' />
                                        </div>
                                        <div>
                                            <img className="image-icon small"
                                                src={`${ENVIRONMENT_CONSTANT.CAMPAIGN_ICON_URL}${item.icon_image}`} />
                                        </div>
                                    </div>
                                    <div className="card-body pt-0 p-3 text-center">
                                        <p className="cursor-pointer text-center mb-0 f-16 text-bold text-black">{item.tile_name}</p>
                                        <span className={`badge badge-sm mx-3
                                    ${(item?.tile_status === 'active' && activeTilesBasedOnDate(item) === true) ? 'bg-gradient-success' : 'bg-gradient-danger'
                                            }`}>
                                            {
                                                (item?.tile_status === 'active' && activeTilesBasedOnDate(item) === true) ? 'Active' : 'In-Active'
                                            }
                                        </span>
                                    </div>
                                </label>
                            </div>
                        })
                    }

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={handleClose}>
                    Cancel
                </Button>
                <button type='button' onClick={() => {
                    addTileDialogRef.current.handleShow()
                    setShow(false)
                }} className='btn btn-primary'>Create Tile</button>
                <Button onClick={() => {
                    console.log(getValues());
                    props?.addTiles(getValues()?.selected_tiles);
                    handleClose();
                }} type='button' variant="primary">
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>

        <AddEditTiles
        openPrevious={() => setShow(true)}
        getTiles={props?.getUnAssignedTiles}
        ref={addTileDialogRef} />
        </>
    );
});

export default AttachTilesDialog;