import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../shared/fields-errors/field-error';

function DonorUserInfo(props) {

    const { register, clearErrors } = useFormContext();

    useEffect(() => {
        clearErrors();
    },[])
    return (
        <div className='col-12'>
            <div className='row'>
                <div className='col-lg-6 mt-4'>
                    <div className="form-group"><label className="form-control-label">
                    <span className='text-danger'>*</span> First Name</label>
                        <input onInput={(() => clearErrors('first_name'))} placeholder="First Name"
                            {...register("first_name", {
                                required: true
                            })}
                            type="text"
                            className="form-control-alternative form-control" /></div>
                    {props?.errors?.first_name && props?.errors?.first_name?.type === 'required' ? <FieldError message={'First name is required'} /> : ''}
                </div>
                <div className='col-lg-6 mt-4'>
                    <div className="form-group"><label className="form-control-label">
                    <span className='text-danger'>*</span> Last Name</label>
                        <input onInput={(() => clearErrors('last_name'))}
                            {...register("last_name", {
                                required: true
                            })}
                            placeholder="Last Name" type="text"
                            className="form-control-alternative form-control" /></div>
                    {props?.errors?.last_name && props?.errors?.last_name?.type === 'required' ? <FieldError message={'Last name is required'} /> : ''}
                </div>
                {
                    props?.campaignDetail?.enable_anonymous ?
                    <div className="form-group mt-2">
                    <label className="form-control-label d-flex align-items-center">
                        <input {...register('make_anonymous_donation',{
                            value: false
                        })} className="me-1" type="checkbox" />
                        Make donation anonymous
                    </label>
                </div> : ''
                }
                <div className='col-lg-12 mt-4'>
                    <div className="form-group"><label className="form-control-label">
                    <span className='text-danger'>*</span> Email</label>
                        <input onInput={(() => clearErrors('email'))}
                            {...register("email", {
                                required: true,
                                pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message: "Invalid email."
                                }
                            })} placeholder="Email" type="text"
                            className="form-control-alternative form-control" /></div>
                    {props?.errors?.email ? <FieldError message={
                        props?.errors?.email?.type === 'required' ?
                            'Email is required' :
                            props?.errors?.email?.type === 'pattern' ?
                                props?.errors?.email?.message : ''
                    } /> : ''}
                </div>

                {
                    (props?.campaignDetail?.collect_phone || props?.pledgeID) ?
                        <div className='col-lg-12 mt-4'>
                            <div className="form-group">
                            {
                                        (props?.fieldValidations?.phone || props?.pledgeID) ?
                                        <span className='text-danger'>*</span> : ""
                                    }
                                <label className="form-control-label">Phone</label>
                                <input placeholder="Phone"
                                    {...register('phone', {
                                        validate:{
                                            required :(value) => {
                                                if(!value && (props?.fieldValidations?.phone || props?.pledgeID)){
                                                    return "The field is required"
                                                }
                                                return true
                                            }
                                        }
                                    })}
                                    type="text" className="form-control-alternative form-control" />
                            </div>
                            {props?.errors?.phone ? <FieldError message={props?.errors?.phone?.message} /> : ''}
                        </div> : ''
                }
                

                {
                    props?.campaignDetail?.collect_address ?
                        <>
                         <div className='col-lg-12 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                    {
                                        props?.fieldValidations?.address_required ?
                                        <span className='text-danger'>*</span> : ""
                                    }
                                    Address</label>
                                    <input placeholder="Address"
                                        {...register('address', {
                                            validate:{
                                                required :(value) => {
                                                    if(!value && props?.fieldValidations?.address_required){
                                                        return "The field is required"
                                                    }
                                                    return true
                                                }
                                            }
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                                {props?.errors?.address ? <FieldError message={props?.errors?.address?.message} /> : ''}
                            </div>
                            <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                {
                                        props?.fieldValidations?.city_required ?
                                        <span className='text-danger'>*</span> : ""
                                }   City</label>
                                    <input placeholder="City"
                                        {...register('city', {
                                            validate:{
                                                required :(value) => {
                                                    if(!value && props?.fieldValidations?.city_required){
                                                        return "The field is required"
                                                    }
                                                    return true
                                                }
                                            }
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                                {props?.errors?.city ? <FieldError message={props?.errors?.city?.message} /> : ''}
                            </div>
                            <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                {
                                        props?.fieldValidations?.state_province_required ?
                                        <span className='text-danger'>*</span> : ""
                                }
                                    State / Province</label>
                                    <input placeholder="State / Province"
                                        {...register('state_province',{
                                            validate:{
                                                required :(value) => {
                                                    if(!value && props?.fieldValidations?.state_province_required){
                                                        return "The field is required"
                                                    }
                                                    return true
                                                }
                                            }
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                            </div>
                            <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                        <span className='text-danger'>*</span>Country</label>
                                    <input disabled placeholder="Country"
                                        {...register('country', {
                                            required: "Country is required",
                                            value:'USA'
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                                {props?.errors?.country ? <FieldError message={props?.errors?.country?.message} /> : ''}
                            </div>
                            <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                {
                                        props?.fieldValidations?.postal_code_required ?
                                        <span className='text-danger'>*</span> : ""
                                }
                                    Postal Code</label>
                                    <input placeholder="Postal Code"
                                        {...register('postal_code',{
                                            validate:{
                                                required :(value) => {
                                                    if(!value && props?.fieldValidations?.postal_code_required){
                                                        return "The field is required"
                                                    }
                                                    return true
                                                }
                                            }
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                            </div>
                           
                           
                            {/* <div className='col-lg-12 mt-4'>
                                <div className="form-group"><label className="form-control-label">Address 2</label>
                                    <input placeholder="Address 2"
                                        {...register('address_2')}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                            </div> */}
                        </> : ''
                }
                
                {
                    props?.campaignDetail.ask_donor_to_subscribe ? 
                    <div className='col-lg-12 mt-4'>
                    <div className='form-group mt-3'>
                    <label className="form-control-label d-flex align-items-start">
                        <input
                            {...register("subscribe_to_mailing_list",{
                                value: props?.campaignDetail.ask_donor_to_subscribe_default ? true : false
                            })}
                            className="me-1 mt-1" type="checkbox" />
                        <p>
                        {props?.campaignDetail?.email_request_label}
                        </p>
                    </label>
                </div>
                </div> : ''
                }
                {/* {
    props?.campaignDetail?.donor_comments ?
        <>
            <div className='col-lg-12 mt-4'>
                <div className='d-flex align-items-center justify-content-start'>
                    <input checked={isShowComments}
                        onChange={handleShowComments} className="me-1" type="checkbox" />
                    <label className='f-14'>
                        Add Comments.
                    </label>
                </div>
            </div>

            {
                isShowComments ? 
                <div className='col-lg-12 mt-4'>
                <div className="form-group"><label className="form-control-label">Comments</label>
                    <textarea placeholder='Comments' cols="30" rows="3" className="form-control-alternative form-control"></textarea>

                </div>
            </div> : ''
            }
            
        </> : ''
} */}


                <div className="col-lg-12 mt-4">
                    <div className="text-center position-relative">
                        {/* <button
        disabled={!options?.clientSecret}
            style={{
                backgroundColor: props?.campaignDetail?.primary_color ? (props?.campaignDetail?.primary_color) : '#005B97 !important'
            }}
            type="submit" className={`btn text-white btn-md w-100 `}>
            Next
        </button> */}
                        <button
                            onClick={props?.donorInfo}
                            //  disabled={!options?.clientSecret}
                            style={{
                                backgroundColor: props?.campaignDetail?.primary_color ? (props?.campaignDetail?.primary_color) : '#005B97',
                                borderBottom: props?.campaignDetail?.primary_color ? ('2px solid ' + props?.campaignDetail?.primary_color) : ''
                            }}
                            type="button" className={`btn btn-md w-100 text-white btn-step-one`}>
                            <span >
                                <span className='f-18 mx-4'>Next</span> <i className="fa-solid fa-arrow-right f-18 "></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DonorUserInfo;