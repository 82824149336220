import React, { useImperativeHandle, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import RecurringOptions from '../../campaigns/campaign-form/shared/recurring-options';
import axios from 'axios';
import { UPDATE_RECURRING_OPTION } from '../../../../constants/endpoints';
import getFormattedDate from '../../../../utils/date-format';

const UpdateRecurringOptionDialog = React.forwardRef((props, ref) => {
    const [loader, setLoader] = useState(false);
    const methods = useForm();
    const { register, handleSubmit, reset, setValue } = methods;
    const [show, setShow] = useState(false);
    const [totalDonations, setTotalDonations] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [currentRecurringOption, setCurrentRecurringOption] = useState("");
    const [planID, setPlanID] = useState("");

    const handleClose = () => {
        setPlanID("");
        setErrorMessage("");
        setCurrentRecurringOption("");
        setTotalDonations(0);
        setShow(false);
    };

    const handleShow = (value,id, totalDonations) => {
        setPlanID(id);
        setCurrentRecurringOption(value);
        setTotalDonations(totalDonations);
        setValue("recurring_option", (
            value?.includes('-') ? 'end_by_date' : (value === 'none' || !value) ? 'none' : 'charge_fixed_number_of_times'
        ));
        setValue('recurring_option_value', value);
        setShow(true);
    }

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow,
            handleClose: handleClose
        };
    });

    const updateRecurringOption = (formValues) => {
        setErrorMessage("");
        if (formValues?.recurring_option === 'charge_fixed_number_of_times' && parseFloat(formValues?.recurring_option_value) <= totalDonations) {
            setErrorMessage("You cannot set value less then or equal to the total donations.");
            return;
        } else if (formValues?.recurring_option === 'end_by_date') {
            const today = new Date(getFormattedDate(new Date(), true) + ' ' + '00:00:00');
            const selectedDate = new Date(`${formValues?.recurring_option_value} 00:00:00`);
            if (selectedDate.getTime() <= today.getTime()) {
                setErrorMessage("Invalid date selected you can not select date from the past.");
                return;
            }
        }

        setLoader(true);
        axios.patch(`${UPDATE_RECURRING_OPTION}${planID}?recurring_option_value=${formValues?.recurring_option_value}`)
        .then((res) => {
            setLoader(false);
            if(res?.data?.status){
                handleClose();
                props?.callback();
            }else{
                setErrorMessage(res?.data?.message ? res?.data?.message : 'Failed to update plan');
            }
        }).catch((error) => {
            setLoader(false);
            setErrorMessage('Failed to update plan');
        })
    }

    return (
        <Modal
            keyboard={false}
            backdrop="static" show={show} onHide={handleClose} animation={true}>
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(updateRecurringOption)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Plan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <RecurringOptions isEditPlan={true} />
                        {
                            errorMessage ? <p className='text-danger mb-0'>{errorMessage}</p> : ''
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button disabled={loader} type='submit' variant="primary"
                            className={`${loader ? 'position-relative button--loading' : ''}`}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    );
});

export default UpdateRecurringOptionDialog;