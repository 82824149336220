import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import "./email-receipt.scss"

//importing quill for react
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { GET_EMAIL_RECEIPT, GET_PLEDGE_EMAIL_TEMPLATE, SAVE_EMAIL_RECEIPT, SAVE_PLEDGE_TEMPLATE } from '../../../constants/endpoints';
import { useNavigate, useParams } from 'react-router-dom';
import FieldError from '../../../shared/fields-errors/field-error';
import SendTestEmail from './send-test-email.component';
import TestEmailDialog from './test-email-dialog.component';
import { emailVariables } from '../../../data-list/data-list';
import { DEFAULT_EMAIL_BODY, DEFAULT_EMAIL_SUBJECT, DEFAULT_PLEDGE_EMAIL_BODY, DEFAULT_PLEDGE_EMAIL_SUBJECT } from '../../../constants/constant';
import { getUserInfo } from '../../../utils/user-info';

function EmailReceiptForm(props) {

    const params = useParams();
    const campaignID = params?.campaign_id;
    const userInfo = getUserInfo();
    const type = window?.location?.pathname?.includes("pledge") ? 'pledge' : 'donation';

    const navigate = useNavigate();

    const methods = useForm();
    const { register, control,setFocus, handleSubmit, reset, setValue, formState: { errors }, getValues, watch } = methods;
    const [currentReceipt, setCurrentReceipt] = useState({});

    const [receiptActionLoader, setReceiptActionLoader] = useState(false);

    const watchFields = watch();

    const onSubmit = (formValues) => {
        console.log(formValues);
        setReceiptActionLoader(true);
        saveReceiptInfo(formValues);
    }

    const saveReceiptInfo = (values) => {
        const formData = Object.assign({}, values);
        formData.campaign_id = campaignID;
        if (currentReceipt?.id) {
            formData.id = currentReceipt?.id;
        }
        axios.post((type === 'pledge' ? SAVE_PLEDGE_TEMPLATE : SAVE_EMAIL_RECEIPT), formData)
            .then((res) => {
                setReceiptActionLoader(false);
                if (res?.data?.status) {
                    navigate(-1);
                } else {
                    alert('Unable to save receipt template');
                }
            }).catch((error) => {
                setReceiptActionLoader(false);
                alert('Unable to save receipt template');
            })
    }

    const getReceipt = () => {
        axios.get((type === 'pledge' ? GET_PLEDGE_EMAIL_TEMPLATE : GET_EMAIL_RECEIPT) + campaignID)
            .then((res) => {
                if (res?.data?.status) {
                    reset({
                        "email_subject": res?.data?.response?.email_subject,
                        "email_body": res?.data?.response?.email_body
                    });
                    setCurrentReceipt(res?.data?.response);
                } else {
                    if (type === 'pledge') {
                        reset({
                            email_subject: DEFAULT_PLEDGE_EMAIL_SUBJECT,
                            email_body: DEFAULT_PLEDGE_EMAIL_BODY
                        });
                    } else {
                        reset({
                            email_subject: DEFAULT_EMAIL_SUBJECT,
                            email_body: DEFAULT_EMAIL_BODY
                        });
                    }
                }
            }).catch((error) => {

            });
    }

    useEffect(() => {
        getReceipt();
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setFocus("email_subject");
        }, 100);
      }, [setFocus]);

    const cancelReceipt = () => {
        navigate(-1);
        // let text = "Changes you have so far will not be saved.";
        // if (window.confirm(text) === true) {
        //     text = "Canceled.";
        //     navigate("/campaigns");
        // }
    }

    const textareaRef = useRef(null);
    const insertTextAtCursor = (text) => {
        const textarea = textareaRef.current;
        const startPosition = textarea.selectionStart;
        const endPosition = textarea.selectionEnd;

        const currentValue = textarea.value;

        // Count the number of characters up to the selection start position
        const characterCount = Array.from(currentValue.slice(0, startPosition)).length;

        const newValue =
            currentValue.slice(0, startPosition) +
            text +
            currentValue.slice(endPosition);

        textarea.value = newValue;
        setValue('variables', newValue)
        // Calculate the new selection start position based on the character count
        const newStart = characterCount + text.length;

        // Calculate the new selection end position
        const newEnd = newStart;

        textarea.selectionStart = newStart;
        textarea.selectionEnd = newEnd;

    };

    const childRef = useRef(null);
  
    const openTestEmailDialog = () => {
      childRef.current.handleShow();
    };

    return (
       <>
        <form
            onSubmit={handleSubmit(onSubmit)}
            className='col-xl-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 left-form-panel p-0'>
            <div className='form-title text-white d-flex justify-content-start align-items-center bg-blue p-3'>
                <a
                    onClick={cancelReceipt}
                    className='text-white cursor-pointer'><i className="fa-solid fa-chevron-left me-2"></i></a>
                <h5 className='text-white mb-0'>Email Receipt</h5>
            </div>

            <div className='campaign-form-body p-4 mb-5'>
                <div className='col-12 card'>
                    <div className='card-body'>
                        <label className="form-control-label text-black text-bold text-capitalize">
                            {type} Receipts
                        </label>
                        <div className="form-group mt-3 ">
                            <label className="form-control-label">
                                Email Subject
                            </label>
                            <div className='email-subject'>
                              
                                <Controller
                                    name="email_subject"
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <input placeholder="Enter Subject"
                                            {...field}
                                            ref={(el) => {
                                                // textareaRef.current = el;
                                                field.ref(el);
                                            }}
                                            type="text" className="form-control-alternative form-control mt-2" />
                                    )}
                                />
                                {errors?.email_subject ? <FieldError message={'This field is required'} /> : ''}
                            </div>

                        </div>
                        <div className="form-group mt-4">
                            <label className="form-control-label">
                                Email Body
                            </label>
                            <Controller
                                name="email_body"
                                control={control}
                                // rules={{ required: 'This field is required' }}
                                render={({ field }) => (
                                    <ReactQuill
                                        value={field.value}
                                        onChange={field.onChange}
                                        modules={{ toolbar: true }}
                                        formats={['bold', 'italic', 'underline']}
                                    />
                                )}
                            />
                            {/* {errors?.email_body ? <FieldError message={'This field is required'} /> : ''} */}
                            {/* <textarea rows={8} cols={50} className="form-control-alternative form-control mt-2" {...register("email_body")}></textarea> */}
                        </div>
                        
                        <div className="form-group mt-4">
                        <label className="form-control-label">
                                Email Variables
                            </label>
                        <Form.Select
                                    {...register("email_variable", {
                                        value: "select_value",
                                        onChange: ((e) => {
                                            if (getValues()?.email_variable !== 'select_value') {
                                                insertTextAtCursor(getValues()?.email_variable)
                                            }
                                        })
                                    })}>
                                    {
                                        emailVariables.map((item, index) => {
                                            return item?.value?.includes('{donation_details}') ? 
                                            type === 'donation' ? <option key={'variable_' + index}
                                            value={item.value}>{item.label}</option> : ''
                                             : item?.value?.includes("{donation_link}") ? 
                                             type === 'pledge' ? <option key={'variable_' + index}
                                             value={item.value}>{item.label}</option> : '' : <option key={'variable_' + index}
                                             value={item.value}>{item.label}</option>
                                        })
                                    }

                                </Form.Select>
                                <p className='f-12-small text-warning mb-0'>Copy and past to include these variables inside email body.</p>
                                <input type='hidden' {...register("masjid_id", {
                                    value: userInfo?.masjid_id
                                })} />
                                <div>
                                <Controller
                                    name="variables"
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <input placeholder="Copy and past to include these variables inside email body or subject"
                                            {...field}
                                            ref={(el) => {
                                                textareaRef.current = el;
                                                field.ref(el);
                                            }}
                                            type="text" className="form-control-alternative form-control mt-2" />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='campaign-form-footer'>
                <button onClick={cancelReceipt} type="button"
                    className="btn btn-outline-secondary mx-3">Cancel</button>
                <div>

                    <button type="button"
                    onClick={openTestEmailDialog}
                        disabled={receiptActionLoader}
                        className={`position-relative btn btn-ma-primary btn-md me-3 ${receiptActionLoader ? 'button--loading' : ''}`}>
                        Test Email
                    </button>
                    <button type="submit"
                        disabled={receiptActionLoader}
                        className={`position-relative btn btn-ma-primary btn-md me-3 ${receiptActionLoader ? 'button--loading' : ''}`}>
                        Save
                    </button>
                </div>

            </div>
        </form>

        {/* attaching test email dialog */}
        <TestEmailDialog 
        heading={'Send Test Email'}
        ref={childRef} 
        campaignID={campaignID}
        watchFields={watchFields}  />
       </>
    );
}

export default EmailReceiptForm;