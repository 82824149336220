import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { STRIPE_ONBOARDING_ENDPOINTS } from '../../constants/stripe-onboarding.endpoints';
import {getUserInfo} from '../../utils/user-info';
import { Link } from 'react-router-dom';

function StripeAccountNotActive(props) {
    const userInfo = getUserInfo();
    const [stripeAccountInfo, setStripeAccountInfo] = useState({});

    const getStripeAccountStatus = () => {
        axios.get(STRIPE_ONBOARDING_ENDPOINTS.GET_ACCOUNT + userInfo?.masjid_id)
        .then((res) => {
            if(res?.data){
                setStripeAccountInfo(res?.data?.response);
            }
        }).catch((error) => {

        });
    }

    useEffect(() => {
        getStripeAccountStatus();
    },[])
    return (
            stripeAccountInfo?.payouts_enabled === false || stripeAccountInfo?.charges_enabled === false || stripeAccountInfo?.details_submitted === false ?
            <div className='col-xl-12 mb-5'>
            <div className='card'>
                <div className='card-body'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <h6><i className="fa-solid fa-circle-exclamation text-danger"></i> You are unable to receive donations. Please finish setting up Stripe to begin.</h6>
                            <p className='f-14 mb-0'>Stripe requires additional documents in order to receive donations and transfer donations to your bank account.</p>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <Link to="/payment-processors" className="btn btn-ma-primary btn-md">
                            Get More Details
                        </Link>
                    </div>
                    <div>
                        <img className='me-1' src='../../../img/masjid/stripe_small.svg' />
                        <a className='text-primary f-14' target="_blank">How to connect Stripe with Masjidal Donation</a>
                    </div>
                </div>
            </div>

        </div> : ''
    );
}

export default StripeAccountNotActive;