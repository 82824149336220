export const DONATION_COLUMNS = [
    'ID & Date',
    'Campaign',
    'Donation Designation',
    // 'Campaign & Designation',
    'Donor',
    'Amount',
    'Type',
    'Card Type',
    'Payment Status'
    // 'Donor Comments',
    // 'Internal Notes'
];
export const DONATION_COLUMNS_ADMIN = [
    'ID & Date',
    'Campaign',
    'Amount',
    'Type',
    'Card Type',
    'Payment Status'
];