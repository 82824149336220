import React, { useEffect } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import FieldError from '../../../../../shared/fields-errors/field-error';

function DonationDesignation(props) {

    const { register,watch } = useFormContext();
    const values = watch();

    useEffect(() => {
        console.log(values);
    },[values])
    return (
        <div className='col-12 card mt-3' >
            <div className='card-body'>
                <label className="form-control-label text-black text-bold">
                    Ask for donation designation (dropdown)
                </label>

                <div className="form-group mt-3">
                    <label
                        className="form-control-label d-flex align-items-center">
                        <input
                            {...register('is_donation_designation',{
                                // onChange:((e) => {
                                //     // props.isDonationDesignation.onChange(e)
                                //     props.toggleDynamicValidation(e, 'designation_label')
                                // })
                            })}
                           className="me-1" type="checkbox" /> Enable donation designations

                           {/* {JSON.stringify(values,null, 2)} */}
                    </label>

                </div>
                <hr></hr>
                {
                    values?.is_donation_designation === true ?
                        <div>
                            <div className="form-group mt-3">
                                <label className="form-control-label">
                                <span className='text-danger text-bold'>*</span> Designation Label
                                    <p className='f-12-small mb-0'>ex: Where should this donation go toward?</p>

                                </label>
                                <input placeholder="Designation"
                                    {...register('designation_label',{required: true})}
                                    type="text" className="form-control-alternative form-control" />
                            </div>
                            {props.errors?.designation_label?.type === 'required' ? <FieldError message={'Please fill this field'} /> : ''}

                            <hr></hr>

                            <div className="form-group mt-3">
                                <label className="form-control-label">
                                    <input
                                        {...props.requireToSelect}
                                        onChange={e => {
                                            props.requireToSelect.onChange(e)
                                            // props.toggleDynamicValidation(e, 'no_designation_label')
                                        }} className="me-1" type="checkbox" /> Require donor to select from the dropdown
                                    <p className='f-12-small mb-0'>The first option won't be selected by default</p>
                                </label>
                                {
                                    props.watchAllFields.require_to_select === true ?
                                    <>
                                    <div className='form-group mt-1'>
                                            <label className="form-control-label d-flex align-items-start" >
                                            <span className='text-danger pe-1 text-bold'>*</span> Label to show when designation is not selected
                                            </label>
                                            <input
                                                {...register('no_designation_label', {required: true})}
                                                placeholder="Choose One"
                                                type="text" className="form-control-alternative form-control" />
                                        </div>

                                        {props.errors?.no_designation_label?.type === 'required' ? <FieldError message={'Please fill this field'} /> : ''}
                                    </>
                                         : ''
                                }
                            </div>

                            <hr></hr>

                            <div className='form-group mt-3'>
                                <label className="form-control-label d-flex align-items-center" >
                                    Designation values (At least one)
                                </label>
                                {
                                    props.fields.map((item, index) => {
                                        return (
                                            <div key={item.id}
                                                className='d-flex align-items-center justify-content-center mt-2'>
                                                <i onClick={(() => props.addRemoveField(index))} className="fa-solid fa-square-minus me-2 f-12"></i>
                                                <input
                                                    {...register(`designation_dropdown_value.${index}.value`)}
                                                    type="text" className="form-control-alternative form-control" />
                                            </div>
                                        )
                                    })
                                }

                                {/* <button type='button' onClick={(() => console.log(getValues()))}>df</button> */}
                                <div className='mt-3'>
                                    <button onClick={props.addRemoveField} type="button" className="form-control btn-default w-50">
                                        <i className="fa-solid fa-square-minus"></i> Add Designation Value
                                    </button>
                                </div>
                            </div>
                        </div> : ''
                }

            </div>
        </div >
    );
}

export default DonationDesignation;