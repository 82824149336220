import React, { useState } from "react";
import { useSquarePayment } from "../hooks/useSquarePayment";
import { PAYMENT_METHOD_TYPE } from "../../../../../constants/constant";

function SquareCard(props) {
  //props
  const { errorMessage, tokenizeCard } = useSquarePayment("sandbox-sq0idb-Z4dtbHOtrZ12wMIHR_ItHw","LK6HBHT3T9XQ5",PAYMENT_METHOD_TYPE.CARD);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  //methods
  const handlePayment = async (event) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);

    const token = await tokenizeCard();

    if (token.status === "ERROR") {
      setError(token.message);
      setIsLoading(false);
    } else {
      // Call backend API to process the payment with the token
      try {
        setIsLoading(false);
        throw new Error("Payment submission failed Error: 422")
        // alert(`Card detail captured your payment token is: ${token}`);
        // const response = await fetch('/process-payment', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ token }),
        // });
        // const data = await response.json();
        // if (data.errors) {
        //   setError('Payment failed');
        // } else {
        //   onPaymentComplete(data);
        // }
      } catch (error) {
        setIsLoading(false);
        setError(error?.message || "Payment processing error");
      }
    }
  };

  return (
    <>
      <div id="square-payment-container"></div>
      <button 
      disabled={isLoading} 
      onClick={handlePayment} 
      className={`btn btn-primary ${isLoading ? 'position-relative button--loading' : ''}`}>Pay $1</button>
      {(errorMessage || error) && <p className="error">{errorMessage || error}</p>}
    </>
  );
}

export default SquareCard;
