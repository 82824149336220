import React, { useEffect, useState } from 'react';
import { GET_CAMPAIGN_ICONS } from '../../constants/endpoints';
import axios from 'axios';
import { ENVIRONMENT_CONSTANT } from '../../constants/env.constant';

function IconView(props) {
    const [campaignIconsList, setCampaignIconsList] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState(props?.selectedIcon || '');
    const icons = [
        {
            "campaign_icon": "books.png",
            "in_app": true
        },
        {
            "campaign_icon": "brisck_sales.png",
            "in_app": true
        },
        {
            "campaign_icon": "food_sales_3.png",
            "in_app": true
        },
        {
            "campaign_icon": "food_sales_4.png",
            "in_app": true
        },
        {
            "campaign_icon": "food_sales_5.png",
            "in_app": true
        },
        {
            "campaign_icon": "quran.png",
            "in_app": true
        },
        {
            "campaign_icon": "school_fees.png",
            "in_app": true
        },
    ]
    const toggleIconState = (source) => {
        setSelectedIcon(source?.campaign_icon);
        props?.setSelectedIcon(source?.campaign_icon);
    }

    const [loader, setLoader] = useState(false);
    const getCampaignIcons = () => {
        setLoader(true);
        axios.get(GET_CAMPAIGN_ICONS)
            .then((res) => {
                setLoader(false);
                if (res?.data?.response?.length > 0) {
                    let list = [...res?.data?.response, ...icons];
                    list = list.sort((a, b) => a.campaign_icon.localeCompare(b.campaign_icon));
                    setCampaignIconsList(list);
                }
            }).catch((error) => {
                setLoader(false);
                // alert('Failed to get campaign details')
            });
    }

    useEffect(() => {
        getCampaignIcons();
    }, [])

    return (
        <div 
        className={`position-relative ${loader ? 'button--loading' : 'row'}`}>
        {
            <div  className='row'
                    style={{
                        // borderBottom: '1px solid',
                        paddingBottom: '1rem',
                        paddingTop: '1rem'
                    }}
                >
                    {/* <p className='mb-1 text-black'>{icon.type}</p> */}
                    {
                        campaignIconsList.map((icon, index) => {
                             return <div key={'icon ' + index}  
                             className='col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6 cursor-pointer text-center'>
                                <div onClick={(() => toggleIconState(icon))} 
                                className={`campaign-icon p-2 mb-1 ${selectedIcon === icon?.campaign_icon ? 'active' : ''}`}>
                                    <img 
                                    className='img-thumbnail'
                                    style={{width:'50px',height:'50px', objectFit:'cover'}} 
                                    src={
                                        icon?.in_app ? `../../../img/masjid/campaign_icons/${icon.campaign_icon}` : 
                                        ENVIRONMENT_CONSTANT.CAMPAIGN_ICON_URL + (icon.campaign_icon?.includes('carpeting') ? icon.campaign_icon.replace('.jpg.jpg','.jpg') : icon.campaign_icon)} 
                                    alt='Campaign Icon' />
                                    <p className='mx-2 mt-1 mb-0 f-xsmal'>{
                                    icon?.campaign_icon?.includes('brisck_sales') ? 'brick sales' :
                                    icon.campaign_icon.replaceAll('_',' ')?.replace(/[0-9]/g, "").replace('.jpg','')?.replace('.png', '')}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
        }
    </div>
    );
}

export default IconView;