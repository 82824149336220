const getFormattedDateTime = (date) => {
    var now = new Date(date) || new Date();
    
    var month = (now.getMonth() + 1).toString().padStart(2, '0');
    var day = now.getDate().toString().padStart(2, '0');
    var year = now.getFullYear().toString();
    
    var hours = now.getHours();
    var minutes = now.getMinutes().toString().padStart(2, '0');
    var amPm = hours >= 12 ? 'PM' : 'AM';
    
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }
    
    var formattedDateTime = month + '/' + day + '/' + year + ' ' + hours + ':' + minutes + ' ' + amPm;
    return formattedDateTime;
}

export default getFormattedDateTime;
