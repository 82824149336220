import React from 'react';
import "./qr-code.scss";
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import { downloadSVGAsImage } from '../../utils/utils';

function LinkQRCode(props) {
    return (
        <>
            {
                props?.qrCode === props?.id ?
                    <div className='link-qr-code mt-1 position-absolute w-100 text-center'>
                        <QRCodeCanvas id='QRSvg'style={{
                            width: '150px',
                            height:'150px'
                        }}
                         
                        value={props?.url} />
                        <div className='text-center mt-3'>
                            <button onClick={() => downloadSVGAsImage('QRSvg',props?.name)} type="button"
                                className={`btn btn-ma-primary btn-md mb-0`}>
                                Download
                            </button>
                        </div>
                    </div> : ''
            }
        </>
    );
}

export default LinkQRCode;