import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../../shared/loader/loader';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CHANGE_PLAN_STATUS, FIND_DONOR_BY_EMAIL, GET_PLAN_DETAIL, GET_PLAN_DONATIONS } from '../../../constants/endpoints';
import getFormattedDate from '../../../utils/date-format';
import NoDataComponent from '../../../shared/no-data/no-data';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';
import CardType from '../../../shared/card-type/card-type';
import UpdateRecurringOptionDialog from './components/update-recurring-option-dialog';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function RecurringPlanDetail(props) {
    // "Month", 
    const columns = ["Payment Date", "Amount", "Status", "Paid At", "Reason", "Attempts"];
    const [invoicesHistory, setInvoicesHistory] = useState([]);
    const [planDetail, setPlanDetail] = useState({});
    const [donorPaymentMethod, setDonorPaymentMethod] = useState({});
    const [loader, setLoader] = useState(false);
    const { id } = useParams();
    const confirmationDialogRef = useRef(null);
    const updateRecurringOptionDialogRef = useRef(null);
    const [changePlanLoader, setChangePlanLoader] = useState(false);

    //methods start
    const getPlanDetail = () => {
        axios.get(GET_PLAN_DETAIL + id)
            .then((res) => {
                if (res?.data?.status) {
                    setPlanDetail(res?.data?.response);
                    getDonorPaymentMethod(res?.data?.response?.email, res?.data?.response?.last_4_digits)
                }
            }).catch((error) => {

            });
    }

    const getPlanDonations = () => {
        setLoader(true);
        axios.get(GET_PLAN_DONATIONS + id)
            .then((res) => {
                setLoader(false);
                if (res?.data?.status) {
                    setInvoicesHistory(res?.data?.response);
                }
            }).catch((error) => {
                setLoader(false);
            });
    }

    const getDonorPaymentMethod = (email, last_4_digits) => {
        axios.post(FIND_DONOR_BY_EMAIL, {
            email: email
        })
            .then((res) => {
                if (res?.data?.response?.is_exists) {

                    const paymentMethod = res?.data?.response?.cards?.find(it => it.last_4_digits === last_4_digits);
                    if (paymentMethod?.id) {
                        setDonorPaymentMethod(paymentMethod);
                    }

                }
            }).catch((error) => {

            });
    }

    const changePlanStatus = (status) => {
        setChangePlanLoader(true);
        axios.post(CHANGE_PLAN_STATUS, {
            id: id,
            status: status,
            interval: planDetail?.donation_interval
        }).then((res) => {
            setChangePlanLoader(false);
            if (res?.data?.status) {
                window.location.reload();
            }
        }).catch((error) => {
            setChangePlanLoader(false);
            alert(`Failed to ${status} plan.`)
        })
    }

    useEffect(() => {
        getPlanDetail();
        getPlanDonations();
    }, []);
    //methods end
    return (
        <div className='row'>
            <div className='col-xl-12'>
                <div>
                    <h5 className='font-weight-semibold text-black'>
                        {planDetail?.first_name} {planDetail?.last_name}: {planDetail?.donation_interval} Plan
                    </h5>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 col-12 mt-3'>
                    <div className='card'>
                        <div className='card-body p-3 text-black'>
                            <h6>Settings</h6>
                            <div>
                                <span>Campaign</span>
                                <p className='mb-2 text-bold'>
                                    {planDetail?.campaign_title ? planDetail?.campaign_title : "-"}
                                </p>
                            </div>
                            <div>
                                <span>Designation</span>
                                <p className='mb-2 text-bold'>
                                    {planDetail?.donation_designation ? planDetail?.donation_designation : '-'}
                                </p>
                            </div>
                            <div>
                                <span>Amount</span>
                                <p className='mb-2 text-bold'>
                                    ${planDetail?.amount ? planDetail?.amount : '-'}
                                </p>
                            </div>
                            <div>
                                <span>Next Donation Date</span>
                                <p className='mb-2 text-bold'>
                                    {
                                        planDetail?.status?.toLowerCase() !== 'paused' ? 
                                        (planDetail?.next_donation_date ? getFormattedDate(planDetail?.next_donation_date) : '-')
                                        : '-'
                                    }
                                    
                                </p>
                            </div>
                            <div>
                                <span>Selected Recurring Option 
                                    {
                                        planDetail?.status?.toLowerCase() !== 'completed' ?
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 100 }}
                                                overlay={<Tooltip>Edit Plan Option</Tooltip>}
                                            >
                                                <i
                                                onClick={() => updateRecurringOptionDialogRef?.current?.handleShow(planDetail?.recurring_option_value, planDetail?.id, invoicesHistory?.length)}
                                                className="mx-2 text-ma-primary cursor-pointer f-16 fa-solid fa-pen-to-square"></i>
                                            </OverlayTrigger> : ''
                                    }
                                     </span>
                                <p className='mb-2 text-bold'>
                                    {
                                        planDetail?.recurring_option_value ? 
                                        planDetail?.recurring_option_value?.includes("-") ? 
                                        `Recurring donation will end on ${getFormattedDate(planDetail?.recurring_option_value)}` :
                                        planDetail?.recurring_option_value?.toLowerCase() === 'none' ? 
                                        'None' : `Recurring will end after ${planDetail?.recurring_option_value} payments`
                                        : 'None'
                                    }
                                </p>
                            </div>
                            <div>
                                <span>Plan Status</span>
                                <p className={`${(planDetail?.status?.toLowerCase() === 'Failed' || planDetail?.status?.toLowerCase() === 'Canceled') ?
                                    'text-danger' : 'text-success'} text-capitalize mb-2 text-bold`}>
                                    {planDetail?.status ? planDetail?.status : '-'}
                                </p>
                            </div>
                            <div className='text-right'>
                                {
                                    planDetail?.status?.toLowerCase() !== 'canceled' ?
                                        <>
                                            <button
                                                disabled={changePlanLoader}
                                                onClick={() => {
                                                    confirmationDialogRef.current.setDialogTitle(`Confirm ${planDetail?.status?.toLowerCase() === 'paused' ? 'Resume' : 'Pause'}`);
                                                    confirmationDialogRef.current.setMessage(`Are you sure you want to ${planDetail?.status?.toLowerCase() === 'paused' ? 'Resume' : 'Pause'} this plan?`);
                                                    confirmationDialogRef.current.setButtonLabel(`${planDetail?.status?.toLowerCase() === 'paused' ? 'Resume' : 'Pause'} Plan`);
                                                    confirmationDialogRef.current.openDialog(planDetail?.status?.toLowerCase() === 'paused' ? 'Active' : 'paused');
                                                }}
                                                className='btn btn-outline-primary me-2' type='button'>{
                                                    planDetail?.status?.toLowerCase() === 'paused' ? 'Resume' : 'Pause'
                                                }</button>
                                            <button
                                                disabled={changePlanLoader}
                                                onClick={() => {
                                                    confirmationDialogRef.current.setDialogTitle('Confirm Cancel');
                                                    confirmationDialogRef.current.setMessage("Are you sure you want to cancel this plan?");
                                                    confirmationDialogRef.current.setButtonLabel("Cancel Plan");
                                                    confirmationDialogRef.current.openDialog("Canceled");
                                                }}
                                                className='btn btn-outline-danger' type='button'>Cancel Plan</button>
                                        </> : ''
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-12 mt-3'>
                    <div className='card'>
                        <div className='card-body p-3 text-black'>
                            <h6>Payment Method</h6>
                            <div>
                                <span>Current Method</span>
                                <p className='mb-2 text-bold'>
                                    {/* {planDetail?.card_type ? planDetail?.card_type : '-'} - {donorPaymentMethod?.last_4_digits ? donorPaymentMethod?.last_4_digits : '-'} */}
                                    <CardType isBold={true} card_type={planDetail?.card_type} last_4_digits={donorPaymentMethod?.last_4_digits} />
                                </p>
                            </div>
                            <div>
                                <span>Expires At</span>
                                <p className='mb-2 text-bold'>
                                    {donorPaymentMethod?.exp_month ? donorPaymentMethod?.exp_month : '-'}
                                    {donorPaymentMethod?.exp_year ? (' / ' + donorPaymentMethod?.exp_year) : '-'}
                                </p>
                            </div>
                            {/* <div>
                                <span>Billing Postal Code</span>
                                <p className='mb-2 text-bold'>*****</p>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* invoices table start */}
                <div className='col-12 mt-3 position-relative'>
                    <div className='card'>
                        <div className='card-body p-3'>
                            <h6 className='mb-2'>Invoices History</h6>
                            {
                                !loader ?
                                    <div className="table-responsive">
                                        <table className="table align-items-center ">
                                            <thead className='text-center prayers-table-header white'>
                                                <tr>
                                                    {
                                                        columns.map((item, index) => {
                                                            return <th key={index}>{item}</th>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody className='prayer-table-body text-center'>
                                                {
                                                    invoicesHistory?.map((item, key) => {
                                                        return <tr key={key}>
                                                            <td>{getFormattedDate(item?.created_at)}</td>
                                                            <td>${(item?.amount / 100)?.toFixed(2)}</td>
                                                            <td
                                                                className={`${item?.donationStatus?.payment_status === 'failed' ? 'text-danger' : 'text-success'} text-capitalize`}
                                                            >
                                                                {item?.donationStatus?.payment_status}
                                                            </td>
                                                            <td>{getFormattedDate(item?.donationStatus?.payment_date)}</td>
                                                            <td title={item?.donationStatus?.payment_log}>{
                                                                item?.donationStatus?.payment_status?.toLowerCase() === 'failed' ?
                                                                    'Payment failed' : 'Payment successfully initiated.'
                                                            }</td>
                                                            <td>{item?.donationStatus?.payment_retry_count}</td>
                                                        </tr>
                                                    })
                                                }

                                            </tbody>

                                        </table>

                                        {/* no data message */}
                                        {
                                            invoicesHistory?.length === 0 ?
                                                <NoDataComponent message={'No Record Available'} /> : ''
                                        }

                                    </div> : ""
                            }
                        </div>
                    </div>
                    {
                        loader ?
                            <Loader /> : ''
                    }
                </div>
                {/* invoices table end */}
            </div>

            {/* confirmation dialog start */}
            <ConfirmationDialog
                ref={confirmationDialogRef}
                executeAction={changePlanStatus}
            />
            {/* confirmation dialog end */}

            {/* update recurring plan dialog */}
            <UpdateRecurringOptionDialog ref={updateRecurringOptionDialogRef} callback={getPlanDetail} />
        </div>
    );
}

export default RecurringPlanDetail;