import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import SendTestEmail from './send-test-email.component';

const TestEmailDialog = React.forwardRef((props, ref) => {

    const childRef = useRef(null);

    useImperativeHandle(ref, () => {
        return {
          handleShow: handleShow,
          handleClose: handleClose
        };
      });

      const handleClose = () => {
        setShow(false);
      };
      const handleShow = () => setShow(true);
      const [show, setShow] = useState(false);

      const sendEmail = () => {
        childRef.current.sendTestEmailToRecipient();
      }

      const [emailLoader, setEmailLoader] = useState(false);
      const toggleLoader = (value) => {
        setEmailLoader(value);
      }

    return (
        <Modal size="lg" backdrop="static" keyboard={false} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props?.heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: 'auto', overflowY: 'auto', overflowX: 'hidden' }}>
                {/* test email component */}
                <SendTestEmail 
                ref={childRef}
                toggleLoader={toggleLoader}
                campaignID={props?.campaignID} 
                emailTemplateProps={props?.watchFields} />
            </Modal.Body>
            <Modal.Footer>
                <Button 
                disabled={emailLoader}
                variant="default" onClick={handleClose}>
                    Close
                </Button>
                <Button 
                disabled={emailLoader}
                className={`position-relative btn btn-ma-primary btn-md ${emailLoader ? 'button--loading' : ''}`}
                 onClick={sendEmail} variant="ma-primary" >
                    {emailLoader ? 'Sending Email...' : 'Send Test Email'}
                </Button>
            </Modal.Footer>
        </Modal>

    );
});

export default TestEmailDialog;