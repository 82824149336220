import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GET_LATEST_ANNOUNCEMENT, MARK_ANNOUNCEMENT_AS_READ } from '../../constants/endpoints';
import AnnouncementBar from './announcement-bar';
import AnnouncementModal from './announcement-modal';

function AnnouncementView(props) {
    
    const [announcement, setAnnouncement] = useState({});

    const getAnnouncement = () => {
        axios.get(GET_LATEST_ANNOUNCEMENT)
            .then((res) => {
                if (res?.data?.status) {
                    setAnnouncement(res?.data?.response);
                }
            }).catch((error) => {

            })
    }

    useEffect(() => {
        getAnnouncement();
    }, []);

    const markAnnouncementAsRead = (announcement_id) => {
        setAnnouncement({});
        axios.patch(MARK_ANNOUNCEMENT_AS_READ + announcement_id)
        .then((res) => {

        })
    }

    return (
        announcement?.id ? 
        announcement?.type === 'bar' ?
        <AnnouncementBar announcement={announcement} markAnnouncementAsRead={markAnnouncementAsRead}/> : 
        <AnnouncementModal announcement={announcement} markAnnouncementAsRead={markAnnouncementAsRead}/> : ''
    );
}

export default AnnouncementView;