import React, { useRef } from 'react';
import "../../campaigns/campaign-form/campaign-form.scss"
import { useState } from 'react';
import SharedFormPreviewComponent from '../../../../shared/shared-form-preview/shared-form-preview.component';
import ProductForm from '../../products/add-edit-products/product-form';
import StoreEditForm from './store-edit-form';

function StoreEditMain(props) {

    const formRef = useRef(null);

    const previewProduct = () =>{
        if(formRef?.current?.previewChanges){
            formRef.current.previewChanges();
        }
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
            <StoreEditForm ref={formRef}/>
            <SharedFormPreviewComponent
            triggerEvent={previewProduct}
            isSubtitle={true}
            image={'../../../img/masjid/masjid_store.png'} />
            </div>
            
        </div>
    );
}

export default StoreEditMain;