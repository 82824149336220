import axios from 'axios';
import React, { useEffect } from 'react';
import { GET_MASJID_INFO } from '../../../constants/endpoints';
import { useNavigate } from 'react-router-dom';

function CallBack(props) {
    
    const navigate = useNavigate();

    const getMasjidInfo = () => {
        axios.get(GET_MASJID_INFO)
        .then((res) => {
            if(res?.data?.status){
                localStorage.setItem('userInfo', JSON.stringify(res?.data?.response));
                navigate('/donations');
            }
        }).catch((error) => {
            localStorage.removeItem('userInfo');
            navigate('/login');
        })
    }

    useEffect(() => {
        getMasjidInfo();
    }, [])

    return (
        <></>
    );
}

export default CallBack;