import React from 'react';

function SelectFormType(toggleView,props) {
    return (
        <div>
            <div className='select-form-type text-center'>

                <div className='mt-3'>
                    <h3>Choose How You Want to Fundraise</h3>
                </div>

                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-xl-3 offset-md-1'>
                            <div>
                                <h5>Donation Form</h5>
                                <p className='type-title'>Collect online donations with a form that can be copy-pasted onto your website or used as a pop-up widget.</p>
                                <button onClick={(() => toggleView.toggleForm('get-started'))} type="button" className="btn btn-ma-primary btn-md w-100">New Donation Form</button>
                            </div>

                        </div>
                        <div className='col-xl-2'>
                            <img className='w-100' src="../../../img/masjid/form-type.png" alt="Fajr Image" />
                        </div>

                        <div className='col-xl-6 form-type-list'>
                            <ul>
                                <li><span className='fa-solid fa-circle-check'></span>
                                    Embed onto Your Website, or Install As Popup Form</li>
                                <li><span className='fa-solid fa-circle-check'></span>
                                    Custom Donation Amounts, Designations, and Intervals</li>
                                <li><span className='fa-solid fa-circle-check'></span>
                                    Multiple Payment Options, Including Apple and Google Pay</li>
                                <li><span className='fa-solid fa-circle-check'></span>
                                    Customizable Color</li>
                                <li><span className='fa-solid fa-circle-check'></span>
                                    Allow Donors to Join Campaign Mailing List</li>
                            </ul>
                        </div>

                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectFormType;  