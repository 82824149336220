import React, { useEffect, useState } from 'react';
import NoDataComponent from '../../../shared/no-data/no-data';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { DOWNLOAD_SUPPORTERS, GET_ALL_SUPPORTERS } from '../../../constants/endpoints';
import { downloadBlob } from '../../../utils/utils';
import { Button, Dropdown, Form } from 'react-bootstrap';
import FieldError from '../../../shared/fields-errors/field-error';
import { Link } from 'react-router-dom';
import Loader from '../../../shared/loader/loader';

function Donors(props) {
    const columns = ['First Name', 'Last Name', 'Email', 'Total', 'No. of Donations', 'Location'];
    const methods = useForm();
    const { register, getValues, setValue, formState: { isValid, errors } } = methods;

    const [subscriberList, setSubscriberList] = useState([]);
    const [fileLoader, setFileLoader] = useState(false);

    const [pageIndex, setPageIndex] = useState(0);
    const [dataLoader, setDataLoader] = useState(false);
    const getAllSupporters = (pageIndex) => {
        setDataLoader(true);
        const formData = { ...getValues(), ...{ page_index: (pageIndex ? pageIndex : 0) } }
        axios.post(GET_ALL_SUPPORTERS, formData)
            .then((res) => {
                setDataLoader(false);
                res?.data?.status ? setSubscriberList(res?.data?.response) : setSubscriberList([]);
            }).catch((error) => {
                setDataLoader(false);
            })
    }

    const changePage = (index) => {
        setPageIndex(index);
        getAllSupporters(index);
    }

    useEffect(() => {
        getAllSupporters();
    }, [])

    const downloadCSV = () => {
        setFileLoader(true);

        axios.post(DOWNLOAD_SUPPORTERS, getValues(), { responseType: 'blob' })
            .then((res) => {
                setFileLoader(false);

                if (res?.data instanceof Blob) {
                    downloadBlob(res?.data, 'supporters_');
                } else {
                    alert('Failed to download file.');
                }
            }).catch((error) => {
                setFileLoader(false);
                alert('Failed to download file.');
            })
    }

    const searchWithCustomFilter = () => {
        console.log(getValues())
        getAllSupporters(0);
        setPageIndex(0);
    }

    const clearCustomSearch = (fields) => {
        for (let index = 0; index < fields.length; index++) {
            setValue(fields[index], undefined)
        }
        console.log(getValues())
        getAllSupporters(0);
        setPageIndex(0);
    }
    return (
        <div className='row'>
            <div className='col-xl-12'>
                <div>
                    <h5 className='font-weight-semibold text-black'>Donors</h5>
                </div>
            </div>

            {/* search start */}
            <div className='row'>

                {/* no of donations start */}
                <div className="col-md-2 mt-2">
                    <div className="dropdown">
                        <button className="shadow-none border-one w-100 border-radius-none btn dropdown-toggle" type="button" id="noOfDonations" data-bs-toggle="dropdown" aria-expanded="false">
                            Number of Donations
                        </button>
                        <ul onClick={(e) => e.stopPropagation()} className="dropdown-menu" aria-labelledby="noOfDonations">
                            <li className='p-3'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <input
                                            {...register('no_donation_min', {
                                                valueAsNumber: true,
                                                validate: (value) => value >= 0,
                                            })}
                                            type='number' className='form-control' placeholder='Min' />
                                    </div>
                                    <div className='col-6'>
                                        <input
                                            {...register('no_donation_max', {
                                                valueAsNumber: true,
                                                validate: (value) => value >= 0,
                                            })}
                                            type='number' className='form-control' placeholder='Max' />
                                    </div>
                                </div>
                                <div className='mt-3 d-flex flex-column'>
                                    <button
                                        onClick={searchWithCustomFilter}
                                        type='button' className='mt-1 btn mb-0 btn-outline-primary'>Apply</button>
                                    <button
                                        onClick={() => clearCustomSearch(['no_donation_min', 'no_donation_max'])}
                                        type='button' className='mt-1 btn mb-0 btn-outline-secondary'>Clear</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* no of donations end */}

                {/* donation amount start */}
                <div className="col-md-2 mt-2">
                    <div className="dropdown">
                        <button className="shadow-none border-one w-100 btn-filter border-radius-none btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Donation Amount
                        </button>
                        <ul onClick={(e) => e.stopPropagation()} className="dropdown-menu">
                            <li className='p-3'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <input
                                            {...register('donation_amount_min', {
                                                valueAsNumber: true,
                                                validate: (value) => value >= 0,
                                            })}
                                            type='number' className='form-control' placeholder='Min' />
                                    </div>
                                    <div className='col-6'>
                                        <input
                                            {...register('donation_amount_max', {
                                                valueAsNumber: true,
                                                validate: (value) => value >= 0,
                                            })}
                                            type='number' className='form-control' placeholder='Max' />
                                    </div>
                                </div>
                                <div className='mt-3 d-flex flex-column'>
                                    <button
                                        onClick={searchWithCustomFilter}
                                        type='button' className='mt-1 btn mb-0 btn-outline-primary'>Apply</button>
                                    <button
                                        onClick={() => clearCustomSearch(['donation_amount_min', 'donation_amount_max'])}
                                        type='button' className='mt-1 btn mb-0 btn-outline-secondary'>Clear</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* donation amount end */}


                {/* location filter start */}
                <div className="col-md-2 mt-2">
                    <div className="dropdown">
                        <button className="shadow-none border-one w-100 btn-filter border-radius-none btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Location
                        </button>
                        <ul onClick={(e) => e.stopPropagation()} className="dropdown-menu">
                            <li className='p-3'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <input
                                            {...register('location')}
                                            type='text'
                                            className='form-control'
                                            placeholder='Search City or Country' />
                                    </div>
                                    {/* <div className='col-12 mt-2'>
                                        All Countries
                                        <ul class="list-group">
                                            <li class="list-group-item">
                                            <div className='mt-1'>
                    <label className="form-control-label d-flex align-items-center">
                    <input {...register("country", {
                        value: false
                    })} className='me-1' type="checkbox" /> United States
                    </label>
                </div>
                                                </li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className='mt-3 d-flex flex-column'>
                                    <button
                                        onClick={searchWithCustomFilter}
                                        type='button' className='mt-1 btn mb-0 btn-outline-primary'>Apply</button>
                                    <button
                                        onClick={() => clearCustomSearch(['location'])}
                                        type='button' className='mt-1 btn mb-0 btn-outline-secondary'>Clear</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* location filter end */}
                {/* text filter */}
                <div className="col-md-4 mt-2">
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                {...register('text_value', {
                                    value: ''
                                })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        getAllSupporters(0);
                                        setPageIndex(0);
                                    }
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Search by name or email"
                            />
                            <button onClick={(e) => {
                                getAllSupporters(0);
                                setPageIndex(0);
                            }} type="button" className="btn btn-outline-secondary mb-0">Search</button>
                            <Dropdown>
                                <Dropdown.Toggle
                                    disabled={fileLoader}
                                    variant="default" id="dropdown-basic"
                                    className={`btn btn-outline-secondary mb-0 border-radius-none 
                                    ${fileLoader ? 'position-relative button--loading' : ''}`}>
                                    Export
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={downloadCSV}>Export All Columns</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            {/* search end */}

            {/* donors listing start */}
            <div className='row mt-3 position-relative'>
                <div className='col-xl-12'>
                    <div className='card'>
                        <div className="table-responsive">
                            {
                                !dataLoader ? 
                                <table className="table align-items-center">
                                <thead className='text-center prayers-table-header white'>
                                    <tr>
                                        {
                                            columns.map((item, index) => {
                                                return <th key={index}>{item}</th>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody className='prayer-table-body text-center'>
                                    {
                                        subscriberList.map((item, index) => {
                                            return <tr key={'supporters_' + index}>
                                                <td>
                                                    <Link to={`/donor-detail/${item?.id}/${item?.first_name + ' ' + item?.last_name}`} className='text-light-blue'>{item?.first_name}</Link></td>
                                                <td>
                                                    <Link to={`/donor-detail/${item?.id}/${item?.first_name + ' ' + item?.last_name}`} className='text-light-blue'>{item?.last_name}</Link>
                                                    </td>
                                                <td>{item?.email}</td>
                                                <td>${(item?.total_amount / 100)?.toFixed(2)}</td>
                                                <td>{item?.total_donations}</td>
                                                <td>{
                                                    item?.city && item?.country ?
                                                        (item?.city + ' ' + item?.country) : '-'}</td>
                                            </tr>
                                        })
                                    }

                                </tbody>

                            </table> : ''
                            }

                            {/* no data message */}
                            
                            {
                                !dataLoader && subscriberList?.length === 0 ?
                                <NoDataComponent message={'No Donors Found'} /> : ''
                            }
                        </div>
                    </div>

                    

                    <div className='mt-2 text-right'>
                        {
                            pageIndex ?
                                <Button
                                    onClick={() => {
                                        const index = pageIndex - 1;
                                        setPageIndex(index)
                                        changePage(index)
                                    }}
                                    className='me-2' type='button'
                                    variant="primary">
                                    Previous
                                </Button> : ''
                        }

                        <Button disabled={subscriberList?.length > 0 ? false : true} onClick={() => {
                            const index = pageIndex + 1;
                            setPageIndex(index)
                            changePage(index)
                        }} type='button'
                            variant="primary">
                            Next
                        </Button>
                    </div>
                </div>
                {
                                dataLoader ? 
                                <Loader /> : ''
                }
            </div>
            {/* subscribers listing end */}
        </div>
    );
}

export default Donors;