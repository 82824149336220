export const NameColorMapper = {
        a:"#008000",
        b:"#0000FF",
        c:"#800080",
        d:"#800080",
        e:"#FF00FF",
        f:"#008080",
        g:"#FFFF00",
        h:"#808080",
        i:"#00FFFF",
        j:"#000080",
        k:"#800000",
        l:"#FF3939",
        m:"#7F7F00",
        n:"#C0C0C0",
        o:"#FF6347",
        p:"#FFE4B5",
        q:"#330233",
        r:"#B8860B",
        s:"#C04000",
        t:"#6B8E23",
        u:"#CD853F",
        v:"#C0C000",
        w:"#228B22",
        x:"#D2691E",
        y:"#808000",
        z:"#20B2AA"
        // a:"F4A460",
        // a:"00C000",
        // a:"8FBC8B",
        // a:"B22222",
        // a:"843A05",
        // a:"C00000"
}