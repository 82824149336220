import { useEffect, useState } from 'react';
import { PAYMENT_METHOD_TYPE } from '../../../../../constants/constant';

export const useSquarePayment = (applicationId, locationId, paymentMethod) => {
    const [card, setCard] = useState(null);

    //google pay props
    const [googlePay, setGooglePay] = useState(null);
    const [applePay, setApplePay] = useState(null);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [ach, setAch] = useState(null);
    const [squareInstance, setSquareInstance] = useState(null);

    useEffect(() => {
        initializeSquare();
    }, [applicationId, locationId]);

    useEffect(() => {
        if (squareInstance && paymentMethod) {
            // now checking what payment method should render based on paymentMethod param
            switch (paymentMethod) {
                case PAYMENT_METHOD_TYPE.CARD:
                    initializeCardForm()
                    break;
                case PAYMENT_METHOD_TYPE.GOOGLE_PAY:
                    initializeGooglePay();
                    // initializeApplePay();
                    break;
                default:
                    break;
            }
        }
    }, [squareInstance, paymentMethod])

    const tokenizeCard = async () => {
        if (!card) return { status: 'ERROR', message: 'Payment form is failed to initialized' };

        try {
            const result = await card.tokenize();
            if (result.status === 'OK') {
                return result.token;
            } else {
                return { status: 'ERROR', message: processError(result?.errors) || 'Tokenization failed' };
            }
        } catch (error) {
            return { status: 'ERROR', message: error.message };
        }
    };

    const processError = (errors) => {
        if(Array.isArray(errors)){
            return errors[0]?.message ? errors[0]?.message : 'Tokenization failed'
        }else if(typeof errors === 'string'){
            return errors
        }else{
            return JSON.stringify(errors)
        }
    }

    //initializing square sdk
    const initializeSquare = () => {
        if (!window.Square) {
            setErrorMessage('Square Web SDK not loaded');
            return;
        }

        const square = window.Square.payments(applicationId, locationId);
        setSquareInstance(square);
    }

    //initialize card form
    const initializeCardForm = async () => {
        try {
            const cardForm = await squareInstance.card();
            await cardForm.attach('#square-payment-container');
            setCard(cardForm)
        } catch (error) {
            setErrorMessage('Failed to initialize Square Payment Form');
        }
    }

    const initializeGooglePay = async () => {
        try {
            const paymentRequest = squareInstance.paymentRequest({
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    amount: '1',
                    label: 'Total',
                },
            });

            //creating google pay button
            const googlePay = await squareInstance.googlePay(paymentRequest);
            await googlePay.attach('#google-pay-button');
            setGooglePay(googlePay);
            setPaymentRequest(paymentRequest);
        } catch (error) {
            setErrorMessage('Failed to initialize Square Google Pay');
        }
    }

    const initializeApplePay = async () => {
        try {
            const paymentRequest = squareInstance.paymentRequest({
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    amount: '1',
                    label: 'Total',
                },
            });

            //creating google pay button
            const applePay = await squareInstance.applePay(paymentRequest);
            setApplePay(applePay);
        } catch (error) {
            setErrorMessage('Failed to initialize Square Apple Pay');
        }
    }

    return { tokenizeCard, errorMessage, ach, googlePay };
};
