import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import DonorPersonalInfo from '../offline-donations/sub-components/donor-personal-info';
import DonorAddressInfo from '../offline-donations/sub-components/donor-address-info';
import TableWidget from '../../../shared/table-widget/table-widget';
import { Tab, Tabs } from 'react-bootstrap';
import DonationTable from '../../../shared/donation-info/donation-table';
import { DONATION_COLUMNS } from '../../../constants/table-columns.constant';
import axios from 'axios';
import { FIND_DONOR_BY_ID, GET_ALL_DONATIONS, GET_DONATION_STATS, UPDATE_DONOR } from '../../../constants/endpoints';
import PledgeTable from '../../../shared/pledge-table/pledge-table';
import { getSetOrgInfo } from '../../../utils/user-info';

function DonorDetail(props) {
    const params = useParams();
    const navigate = useNavigate();
    const [updateDonorLoader, setUpdateDonor] = useState(false);
    const columns = ["Currency", "Total Donations"];
    const [totalDonation, setTotalDonations] = useState([])
    const methods = useForm();
    const orgInfo = getSetOrgInfo();
    const [donorDetail, setDonorDetail] = useState({});
    const { reset, getValues, setValue, formState: { isValid, errors } } = methods;

    const [donationsList, setDonationsList] = useState([])


    const findDonorByID = () => {
        axios.get(FIND_DONOR_BY_ID + params?.id)
        .then((res) => {
            if(res?.data?.status){
                reset({
                    donor_info: res?.data?.response
                });
                setDonorDetail(res?.data?.response);
            }
        }).catch((error) => {

        })
    }

    useEffect(() => {
        findDonorByID()
    }, []);

    const updateDonor = (formValues) =>{
        setUpdateDonor(true);
        formValues.current_email = donorDetail?.email;
        axios.post((UPDATE_DONOR + params?.id), formValues)
        .then((res) => {
            setUpdateDonor(false);
            if(res?.data?.status){
                navigate(`/donor-detail/${params.id}/${formValues?.donor_info?.first_name} ${formValues?.donor_info?.last_name}`)
            }else{
                alert(res?.data?.message);
            }
            
        }).catch((error) => {
            alert("Failed to update donor detail.");
            setUpdateDonor(false);
        })
    }

    const getDonationsByDonor = (pageNumber) => {
        axios.post(GET_ALL_DONATIONS, {donor_id: params?.id, page_index: pageNumber ? pageNumber : 0, payment_status: 'all'})
        .then((res) => {
            if(res?.data?.status){
                setDonationsList(res?.data?.response)
            }
        }).catch((error) => {

        });
    }

    useEffect(() => {
        getDonationsByDonor();
        getTotalDonations();
    }, []);

    const [pageIndex, setPageIndex] = useState(0);
    const changePage = (pageNumber) => {
        setPageIndex(pageNumber);
        getDonationsByDonor(pageNumber)
    }

    const getTotalDonations = () => {
        axios.post(GET_DONATION_STATS, {donor_id: params?.id, payment_status: 'all'})
        .then((res) => {
            if(res?.data?.status){
                setTotalDonations([{ currency: orgInfo?.currency?.toUpperCase(), total_amount: res?.data?.response?.gross_donations}])
            }
        }).catch((error) => {

        });
    }

    return (
        <div className='row'>
            <div>
                <h5 className='font-weight-semibold text-black'>Donor: {params?.name}</h5>
            </div>

            <div className='row'>
                <FormProvider {...methods}>
                    
                    <div className='col-xxl-8'>
                    <form  onSubmit={methods.handleSubmit(updateDonor)} >
                        <div className='card'>
                            <div className='card-body'>
                                <div className='col-12 border-bottom mb-3'>
                                    <div className='d-flex justify-content-between'>
                                        <h6 className='text-bold'>Donor Info</h6>
                                    </div>
                                </div>

                                {/* injecting donor personal info */}
                                <DonorPersonalInfo />

                                {/* injecting donor address info */}
                                <DonorAddressInfo />
                            </div>
                            <div className='text-right p-2'>
                            <button
                            disabled={updateDonorLoader}
                             type='submit' 
                            className={`btn btn-primary position-relative ${updateDonorLoader ? 'button--loading' : ''}`}>Update</button>
                            </div>
                        </div>
                        </form>
                    </div>
                    
                  
                </FormProvider>
                <div className='col-xxl-4'>
                    <div className='card'>
                        <div className='card-body p-2'>
                            <TableWidget 
                            columns={columns} 
                            data={totalDonation} />
                        </div>
                    </div>
                </div>

                <div className='col-xxl-12 mt-3'>
                    <div className='card'>
                        <div className='card-body'>
                            <Tabs
                                defaultActiveKey="donations"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="donations" title="Donations">
                                    <DonationTable
                                    changePage={changePage}
                                    pageIndex={pageIndex}
                                        data={donationsList}
                                        columns={DONATION_COLUMNS}
                                    />
                                </Tab>
                                <Tab eventKey="pledges" title="Pledges">
                                    <PledgeTable 
                                     pledgeStatusModel={[]}
                                     campaignSearchModel={""}
                                     searchModel={""}
                                    donor_id={params?.id} />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default DonorDetail;