import React, { useImperativeHandle, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import FieldError from '../fields-errors/field-error';
import { useForm } from 'react-hook-form';

const DatePickerDialog = React.forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const { register, reset, getValues, formState: { errors } } = useForm();
    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow,
            getValues: getValues,
            reset: reset
        }
    });

    const handleClose = () => {
        // props?.setTimeSearchModel("");
        // reset({
        //     from: "",
        //     to: ""
        // });
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    }

    return (
        <Modal
            keyboard={false}
            size='sm'
            backdrop="static" show={show} onHide={handleClose} animation={true}>
            <Modal.Header closeButton>
                <Modal.Title className='text-capitalize'>
                    Select Date
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label className='d-flex justify-content-between align-items-center'>
                        From
                    </Form.Label>
                    <Form.Control
                        {...register('from', {
                            validate: {
                                required: value => {
                                    if (!value) return 'Field is required.';
                                    return true;
                                }
                            }
                        })}
                        type="date" />
                </Form.Group>
                <Form.Group className='mt-3'>
                    <Form.Label className='d-flex justify-content-between align-items-center'>
                        To
                    </Form.Label>
                    <Form.Control
                        {...register('to', {
                            validate: {
                                required: value => {
                                    if (!value) return 'Field is required.';
                                    return true;
                                }
                            }
                        })}
                        type="date" />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={handleClose}>
                    Close
                </Button>
                <Button onClick={(e) => {
                    props?.setTimeSearchModel(JSON.stringify(getValues()));
                    setShow(false);
                }} type='button' variant="primary">
                    Apply
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default DatePickerDialog;