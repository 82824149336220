import React, { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import FieldError from '../../../shared/fields-errors/field-error';
import { Form } from 'react-bootstrap';
import { insertTextAtCursor } from '../../../utils/utils';
import { emailVariablesYearlyReceipt, fiscalYearList } from '../../../data-list/data-list';
import PreviewEmailDialog from './dialogs/preview-email.dialog';
import axios from 'axios';
import { GET_YEARLY_RECEIPT, SAVE_YEARLY_RECEIPT_SETTINGS } from '../../../constants/endpoints';

function YearlyReceiptSettings(props) {
    //props section
    const methods = useForm();
    const { register, reset, setValue, control, handleSubmit, getValues, formState: { errors } } = methods;
    const textareaRef = useRef(null);
    const emailPreviewDialogRef = useRef(null);
    const [requestLoader, setRequestLoader] = useState(false);
    const navigate = useNavigate();

    //methods section
    useEffect(() => {
        reset({
            email_subject: "Year-end Giving Receipt from {{organization.organization_name}}",
            email_body: `
            <p>Dear {{first_name}},</p>
            <p><br></p>
            <p>Thank you for your gracious contributions to {{organization.organization_name}} last year.</p>
            <p>Please find your Year-end Giving Receipt attached to this email.</p>
            <p><br></p>
            <p>Best,</p>
            <p>{{organization.organization_name}}</p>
            `,
            fiscal_year_period: "0-01",
            pdf_thank_you_message: `
            <p>Sincerely,</p>
            <p>{{organization.organization_name}}</p>
            <p>Thanks so much for your generosity. Please keep this document for tax purposes. No goods or services were provided in return for these contributions.</p>
            <p><br></p>
            <p>Thank you!</p>
            `
        })
    }, []);

    const getYearlyReceiptSettings = () =>{
        axios.get(GET_YEARLY_RECEIPT)
        .then((res) => {
            if(res?.data?.status){
                reset(res?.data?.response);
            }
        }).catch((error) => {

        });
    }

    const saveYearlyReceiptSettings = (formValues) => {
        console.log(formValues)
        setRequestLoader(true);
        axios.post(SAVE_YEARLY_RECEIPT_SETTINGS, formValues)
        .then((res) => {
            setRequestLoader(false);
            if(res?.data?.status){
                navigate('/yearly-receipts')
            }else{
                alert(res?.data?.message);
            }
        }).catch((error) => {
            setRequestLoader(false);
            alert("Failed save receipts Settings");
        });
    }

    //getting receipt settings
    useEffect(() => {
        getYearlyReceiptSettings();
    },[])

    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-xl-12'>
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                <Link to={'/yearly-receipts'} className='text-ma-primary mb-3' >
                                    <i className="fa-solid fa-arrow-left me-2"></i>
                                    Return to Managing Receipts</Link>
                                </div>
                                <div className='col-12'>
                                    <h5 className='font-weight-semibold text-black'>Fiscal Year-end Receipt Settings</h5>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <p className='text-black f-14'>You can use these settings to customize the yearly receipts e-mail notifications and the PDF attachment contents.To update the Non-profit Info on the PDF header, <a className='text-ma-primary' href='/organization' target='_blank'>click here</a>.</p>
                                </div>
                            </div>
                        </div>

                        {/* email settings section start */}
                        <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(saveYearlyReceiptSettings)}>
                        <div className='col-12'>
                            <hr className='me-0 mx-0' />
                            <h6 className='text-black'><i className="f-16 me-2 fa-solid fa-envelope-open-text"></i>Email Settings</h6>

                            <div className="form-group mt-3">
                                <label className="form-control-label">
                                    Email Subject Line
                                </label>
                                <div className='row'>
                                    <div className='col-lg-10 col-md-12 pe-md-0'>
                                        <Controller
                                            name="email_subject"
                                            control={control}
                                            defaultValue={''}
                                            rules={{
                                                required: true
                                            }}
                                            render={({ field }) => (
                                                <input placeholder="Enter Subject"
                                                    {...field}
                                                    ref={(el) => {
                                                        textareaRef.current = el;
                                                        field.ref(el);
                                                    }}
                                                    type="text" className="form-control-alternative form-control" />
                                            )}
                                        />
                                        {errors?.email_subject ? <FieldError message={'This field is required'} /> : ''}
                                    </div>
                                    <div className='col-lg-2 col-md-12 px-md-0'>
                                        <Form.Select
                                            {...register("email_variable", {
                                                value: "select_value",
                                                onChange: ((e) => {
                                                    if (getValues()?.email_variable !== 'select_value') {
                                                        const newValue = insertTextAtCursor(getValues()?.email_variable, textareaRef);
                                                        setValue('email_subject', newValue);
                                                    }
                                                })
                                            })}>
                                            {
                                                emailVariablesYearlyReceipt.map((item, index) => {
                                                    return <option key={'variable_' + index}
                                                        value={item.value}>{item.label}</option>
                                                })
                                            }

                                        </Form.Select>
                                    </div>
                                </div>

                            </div>

                            <div className="form-group mt-4">
                                <label className="form-control-label">
                                    Email Content
                                </label>
                                <Controller
                                    name="email_body"
                                    control={control}
                                    rules={{ required: 'This field is required' }}
                                    render={({ field }) => (
                                        <ReactQuill
                                            value={field.value}
                                            onChange={field.onChange}
                                            modules={{ toolbar: true }}
                                        />
                                    )}
                                />
                                {errors?.email_body ? <FieldError message={errors?.email_body?.message} /> : ''}
                                {/* <textarea rows={8} cols={50} className="form-control-alternative form-control mt-2" {...register("email_body")}></textarea> */}
                            </div>

                            <div className='text-right mt-3'>
                                <button onClick={() => {
                                    emailPreviewDialogRef?.current?.handleShow(getValues())
                                }} type='button' className='btn btn-outline-primary'>
                                    Preview Email <i className="fa-regular fa-eye"></i>
                                </button>
                            </div>
                        </div>
                        {/* email settings section end */}

                        {/* pdf settings start */}
                        <div className='col-12'>
                            <hr className='me-0 mx-0 mt-4' />
                            <h6 className='text-black'><i className="f-16 me-2 fa-solid fa-file-pdf"></i>PDF Settings</h6>

                            <div className="form-group mt-3">
                                <label className="form-control-label">
                                    Fiscal Year Period
                                </label>
                                <div>
                                    <Form.Select
                                        {...register("fiscal_year_period", )}>
                                        {
                                            fiscalYearList.map((item, index) => {
                                                return <option key={'year_' + index}
                                                    value={item.value}>{item.label}</option>
                                            })
                                        }

                                    </Form.Select>
                                </div>

                            </div>

                            <div className="form-group mt-4">
                                <label className="form-control-label">
                                    Thank You Message
                                </label>
                                <Controller
                                    name="pdf_thank_you_message"
                                    control={control}
                                    rules={{ required: 'This field is required' }}
                                    render={({ field }) => (
                                        <ReactQuill
                                            value={field.value}
                                            onChange={field.onChange}
                                            modules={{ toolbar: true }}
                                        />
                                    )}
                                />
                                {errors?.pdf_thank_you_message ? <FieldError message={errors?.pdf_thank_you_message?.message} /> : ''}
                            </div>

                            <div className='text-right mt-3'>
                                <a 
                                href='email-receipt-example/yearly_receipt_example.pdf'
                                target='_blank' className='btn btn-outline-primary'>
                                    Preview PDF <i className="fa-regular fa-eye"></i>
                                </a>
                            </div>
                        </div>
                        {/* pdf settings end */}

                        <div className='col-12 text-right'>
                        <hr className='me-0 mx-0 mt-4' />
                            <button
                            disabled={requestLoader} 
                            onClick={(e) => navigate('/yearly-receipts')} className='btn btn-default mb-0 me-3' type='button'>
                                Cancel
                            </button>
                            <button 
                            disabled={requestLoader}
                            className={`btn btn-primary mb-0 ${requestLoader ? 'button--loading position-relative' : ''}`} 
                            type='submit'>
                                Save Changes
                            </button>
                        </div>
                        </form>
                        </FormProvider>
                    </div>
                </div>

            </div>

            {/* email preview dialog */}
            <PreviewEmailDialog ref={emailPreviewDialogRef} />
        </>
    );
}

export default YearlyReceiptSettings;