import { ENVIRONMENT_CONSTANT } from "../constants/env.constant";
import { dateTimeFormat } from "./date-format";

export const getTimeFilterValue = (value, customDate) => {
    let startDate = new Date();
    let endDate = new Date();
    switch (value) {
        case 'today':
          startDate = new Date();
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          endDate.setHours(23, 59, 59, 999);
          break;
        case 'yesterday':
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 1);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          endDate.setDate(endDate.getDate() - 1);
          endDate.setHours(23, 59, 59, 999);
          break;
        case 'last_7_days':
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 6);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          endDate.setHours(23, 59, 59, 999);
          break;
        case 'last_30_days':
          startDate = new Date();
          startDate.setDate(startDate.getDate() - 29);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          endDate.setHours(23, 59, 59, 999);
          break;
        case 'this_month':
          startDate = new Date();
          startDate.setDate(1);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          endDate.setMonth(endDate.getMonth() + 1, 0);
          endDate.setHours(23, 59, 59, 999);
          break;
        case 'last_month':
          startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 1, 1);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          endDate.setDate(0);
          endDate.setHours(23, 59, 59, 999);
          break;
        case 'this_year':
          startDate = new Date();
          startDate.setMonth(0, 1);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          endDate.setMonth(11, 31);
          endDate.setHours(23, 59, 59, 999);
          break;
        case 'last_year':
          startDate = new Date();
          startDate.setFullYear(startDate.getFullYear() - 1, 0, 1);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date();
          endDate.setFullYear(endDate.getFullYear() - 1, 11, 31);
          endDate.setHours(23, 59, 59, 999);
          break;
        default:
          startDate = new Date(customDate?.from);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date(customDate?.to);
          endDate.setHours(23, 59, 59, 999);
          // throw new Error('Invalid date range');
      }
      
      return {
        startDate: dateTimeFormat(startDate, true),
        endDate: dateTimeFormat(endDate, true)
       }
}

export const downloadBlob = (blob, name) => {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([blob]));
    const fileName = `${name ? name : 'donation_records_'}${dateTimeFormat(new Date(), true)?.replaceAll(':', '-')}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

}

export const getImageUrl = (imageName) => {
  const imageUrl = ENVIRONMENT_CONSTANT.CAMPAIGN_ICON_URL+(imageName?.includes('carpeting') ? imageName.replace('.jpg.jpg','.jpg') : imageName);
  return imageUrl;
}

export const getTextColorByBG = (backgroundColor) => {
  if(backgroundColor){
    var color = (backgroundColor?.charAt(0) === '#') ? backgroundColor.substring(1, 7) : backgroundColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
        'black' : 'white';
  }else{
    return "";
  }

}

export const activeTilesBasedOnDate = (tile) => {
  const today = new Date();
  const start = new Date(tile.active_from);
  const end = new Date(tile.active_to);

  return today.getTime() >= start.getTime() && today.getTime() <= end.getTime();
}

//download a svg as image 
export const downloadSVGAsImage = (id, name) => {
  const canvas = document.getElementById(id);

  // Create an image element
  const img = new Image();
  img.src = canvas.toDataURL("image/png");

  // Create a download link
  const a = document.createElement("a");
  a.href = img.src;
  a.download = `${name}.png`;
  a.click();
};

//inserting text at cursor
export const insertTextAtCursor = (text, textareaRef) => {
  const textarea = textareaRef.current;
  const startPosition = textarea.selectionStart;
  const endPosition = textarea.selectionEnd;

  const currentValue = textarea.value;

  // Count the number of characters up to the selection start position
  const characterCount = Array.from(currentValue.slice(0, startPosition)).length;

  const newValue =
      currentValue.slice(0, startPosition) +
      text +
      currentValue.slice(endPosition);

  textarea.value = newValue;
  // setValue('email_subject', newValue);
  // Calculate the new selection start position based on the character count
  const newStart = characterCount + text.length;

  // Calculate the new selection end position
  const newEnd = newStart;

  textarea.selectionStart = newStart;
  textarea.selectionEnd = newEnd;
  return newValue;
};