import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../../shared/loader/loader';
import NoDataComponent from '../../../shared/no-data/no-data';
import AnnouncementDialog from './dialogs/announcement.dialog';
import axios from 'axios';
import { DELETE_ANNOUNCEMENT, GET_ALL_ANNOUNCEMENTS } from '../../../constants/endpoints';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';

function Announcements(props) {

    //props
    const columns = ['Title', "Organization Viewed", "Type", "Action"];
    const [requestLoader, setRequestLoader] = useState(false);
    const [searchModel, setSearchModel] = useState("");
    const [announcementList, setAnnouncementList] = useState([]);
    const announcementDialogRef = useRef(null);
    const confirmationDialogRef = useRef(null);

    const getAllAnnouncements = () => {
        setRequestLoader(true);
        axios.get(GET_ALL_ANNOUNCEMENTS + `?search_text=${searchModel}`)
            .then((res) => {
                setRequestLoader(false);
                if (res?.data?.status) {
                    setAnnouncementList(res?.data?.response);
                } else {
                    setAnnouncementList([])
                }

            }).catch((error) => {
                setRequestLoader(false);
            })
    }

    const deleteAnnouncement = (id) => {
        axios.delete(DELETE_ANNOUNCEMENT + id)
            .then((res) => {
                if (res?.data?.status) {
                    getAllAnnouncements();
                } else {
                    alert(res?.data?.message);
                }
            }).catch((error) => {
                alert("Unable to delete announcement");
            })
    }

    useEffect(() => {
        getAllAnnouncements();
    }, [searchModel])

    return (
        <>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2'>
                            <h5 className='font-weight-semibold text-black'>Announcements</h5>
                        </div>

                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 text-right f-14'>
                            <a onClick={() => {
                                announcementDialogRef?.current?.handleShow()
                            }}
                                className={`add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3`}>
                                <span>
                                    <i className="fa-solid fa-bullhorn mr-5"></i>
                                </span>
                                <span className='ms-2 text-white'>New Announcement</span>
                            </a>
                        </div>
                    </div>
                </div>

                {/* search start */}
                <div className='row'>
                    {/* <div className="col-md-2 mt-2">
                        <div className="form-group">
                            <Form.Select onChange={(e) => {
                                setCampaignModel(e.target.value);
                            }}>
                                <option value={""}>All Campaigns</option>
                                {
                                    campaignList.map((item, index) => {
                                        return <option
                                            key={'campaigns ' + index}
                                            value={item.id}>{item.campaign_title}</option>
                                    })
                                }
                            </Form.Select>
                        </div>
                    </div> */}
                    {/* text filter */}
                    <div className="col-md-4 mt-2">
                        <div className="form-group">
                            <div className="input-group">
                                <input
                                id='txtAnnouncementSearch'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setSearchModel(e.target.value)
                                        }
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by title"
                                />
                                <button onClick={(e) => {
                                    const value = document.getElementById('txtAnnouncementSearch')?.value;
                                    setSearchModel(value)
                                }} type="button" className="btn btn-outline-secondary mb-0">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* search end */}

                <div className='row mt-3 position-relative'>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className="table-responsive">
                                {
                                    !requestLoader ?
                                        <table className="table align-items-center ">
                                            <thead className='text-center prayers-table-header white'>
                                                <tr>
                                                    {
                                                        columns.map((item, index) => {
                                                            return <th key={index}>{item}</th>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody className='prayer-table-body text-center'>
                                                {
                                                    announcementList.map((item, index) => {
                                                        return (
                                                            <tr key={'announcement_' + index}>
                                                                <td>{item?.title}</td>
                                                                <td>{item?.announcementStatuses?.length}</td>
                                                                <td className='text-capitalize'>{item?.type}</td>
                                                                <td >
                                                                    <i title='Delete' onClick={() => {
                                                                        confirmationDialogRef.current.setDialogTitle('Confirm Deletion');
                                                                        confirmationDialogRef.current.setMessage(`Are you sure you want to delete "${item?.title}" announcement?.`);
                                                                        confirmationDialogRef.current.setButtonLabel("Delete Announcement");
                                                                        confirmationDialogRef.current.openDialog(item?.id);
                                                                    }}
                                                                        className="cursor-pointer fa-solid fa-trash me-2"></i>
                                                                    <i title='Edit' onClick={() => {
                                                                        announcementDialogRef?.current?.handleShow(item)
                                                                    }}
                                                                        className="cursor-pointer fa-solid fa-pen-to-square"></i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>

                                        </table> : ''
                                }
                                {/* no data message */}
                                {

                                    !requestLoader && announcementList?.length === 0 ?
                                        <NoDataComponent message={'No Announcements Found'} /> : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        requestLoader ?
                            <Loader /> : ''
                    }
                </div>
            </div>

            {/* announcement dialog */}
            <AnnouncementDialog ref={announcementDialogRef} getAllAnnouncements={getAllAnnouncements} />

            {/* confirmation dialog start */}
            <ConfirmationDialog
                ref={confirmationDialogRef}
                executeAction={deleteAnnouncement}
            />
        </>
    );
}

export default Announcements;