import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import DraggableItem from '../../pages/components/kiosks/update-device/draggable-item';

const OrderContainer = ({campaignList, setCampaignList}) => {

    const TYPE = 'card';

    const [{ isOver }, dropRef] = useDrop({
        accept: TYPE,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    const handleDrop = (originalIndex, newIndex) => {
        if (originalIndex !== newIndex) {
            console.log('originalIndex ' + originalIndex);
            console.log('newIndex ' + newIndex);
            moveItem(originalIndex, newIndex);
        }
    };

    const moveItem = (originalIndex, newIndex) => {
            const updatedItems = [...campaignList];
            const [newItem] = updatedItems.splice(originalIndex, 1);
            updatedItems.splice(newIndex, 0, newItem);
            updatedItems[newIndex].campaign_order = newIndex;
            updatedItems[originalIndex].campaign_order = originalIndex;
            setCampaignList(updatedItems);
    };

    return (
        <div ref={dropRef}>
            {campaignList.map((item, index) => (
                <DraggableItem
                componentType={'campaign'}
                moveItem={handleDrop}
                containerID={'device'}
                id={item.id}
                item={item}
                index={index}
                key={item.id} />
        ))}

        {campaignList?.length === 0 ? <p className='mb-0 text-center text-black'>No Campaigns Available</p> : ''}
    </div>
    );
}

export default OrderContainer;