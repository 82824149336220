import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { paymentTypeList, pledgeStatusList } from '../../../../data-list/data-list';
import FieldError from '../../../../shared/fields-errors/field-error';
import { ADD_DONATION_FOR_PLEDGE, UPDATE_PLEDGE } from '../../../../constants/endpoints';
import axios from 'axios';

const AddOfflineDonationDialog = React.forwardRef(({pledgeDetail}, ref) => {

    //props
    const [show, setShow] = useState(false);
    const [requestLoader, setRequestLoader] = useState(false);
    const form = useForm();
    const { register, handleSubmit, unregister, reset, formState: { errors }, watch } = form
    const fields = watch();
    const [errorMessage, setErrorMessage] = useState("");

    //methods
    const handleShow = () => {
      reset({
        payment_type: "check",
        created_at: "",
        amount: "",
        campaign_id: pledgeDetail?.campaign?.id,
        platform_used: "Offline",
        email: pledgeDetail?.donor?.email,
        donation_type: pledgeDetail?.donation_type,
        stripe_processing_fee: 0,
        pledge_amount: pledgeDetail?.amount
      })
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    };

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow,
            handleClose: handleClose
        };
    });

    const addDonationForPledge = (formValues) => {
        setRequestLoader(true);
        // if(!formValues?.follow_up_date){
        //     formValues.follow_up_date = null;
        // }
        if(formValues?.created_at){
            formValues.created_at = formValues?.created_at + ' 00:00:00';
        }
        axios.post(ADD_DONATION_FOR_PLEDGE + pledgeDetail?.id, formValues)
        .then((res) => {
            setRequestLoader(false);
            if(res?.data?.status){
                // alertDialogRef?.current?.openDialog("Pledge detail updated successfully.");
                // navigate("/pledges");
                window.location.reload();
            }else{
                setErrorMessage(
                    res?.data?.message ? res?.data?.message : 'Failed to add donation.'
                );
            }
        }).catch((error) => {
            setRequestLoader(false);
            setErrorMessage('Failed to add donation.');
        });
    }

    // useEffect(() => {
    //     if(pledgeDetail?.id){
    //         reset({
    //             pledge_status:pledgeDetail?.pledge_status ? pledgeDetail?.pledge_status : "Un_Paid",
    //             follow_up_date: pledgeDetail?.follow_up_date ? pledgeDetail?.follow_up_date : '',
    //             comments: pledgeDetail?.comments ? pledgeDetail?.comments : ''
    //         })
    //     }
    // }, [pledgeDetail])

    // useEffect(() => {
    //     if(fields?.pledge_status !== 'Partial_Paid' && fields?.pledge_status !== 'Paid'){
    //         unregister("donation_info")
    //     }
    // }, [fields?.pledge_status])

    return (
        <>
      <Modal
        keyboard={false}
        size="md"
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={true}
      >
        <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(addDonationForPledge)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Donation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {/* <div className="col-md-6 col-12">
                <Form.Group className="mb-3">
                  <span className="text-danger">*</span>
                  <Form.Label>Status</Form.Label>
                  <select
                    {...register("pledge_status")}
                    className="form-control"
                  >
                    {pledgeStatusList.map((item, index) => {
                      return item?.value ? (
                        <option key={"pledge_" + index} value={item?.value}>
                          {item?.label}
                        </option>
                      ) : (
                        ""
                      );
                    })}
                  </select>
                  {errors?.pledge_status ? (
                    <FieldError message={errors?.pledge_status?.message} />
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              <div className="col-md-6 col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Next Follow Up Date</Form.Label>
                  <input
                    {...register("follow_up_date")}
                    type="date"
                    className="form-control"
                  />
                  {errors?.follow_up_date ? (
                    <FieldError message={errors?.follow_up_date?.message} />
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <textarea
                    {...register("comments")}
                    placeholder="Enter your comments"
                    rows="5"
                    className="form-control"
                  ></textarea>
                  {errors?.comments ? (
                    <FieldError message={errors?.comments?.message} />
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div> */}
             
              <div className="col-12">
                {/* <div className="col-12 border-bottom mb-3">
                  <h6 className="text-bold">Donation Information</h6>
                </div> */}

                <div className="row">
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label className="form-control-label text-black">
                        <span className="text-danger">*</span> Payment Type
                      </label>
                      <Form.Select
                        {...register("payment_type", {
                          required: {
                            value: true,
                            message: "Field is required.",
                          },
                        })}
                      >
                        {paymentTypeList.map((item, index) => {
                          return (
                            <option
                              key={`payment_type` + index}
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="col-12">
                    <Form.Group className="mb-3">
                      <span className="text-danger">*</span>{" "}
                      <Form.Label>Donation Date</Form.Label>
                      <Form.Control
                        {...register("created_at", {
                          validate: {
                            required: (value) => {
                              if (!value) return "Field is required.";
                              return true;
                            },
                          },
                        })}
                        type="date"
                      />
                      {errors?.created_at ? (
                        <FieldError
                          message={errors?.created_at?.message}
                        />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group className="mb-3">
                      <span className="text-danger">*</span>{" "}
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        {...register("amount", {
                          validate: {
                            required: (value) => {
                              if (!value) return "Field is required.";
                              return true;
                            },
                          },
                        })}
                        type="number"
                        placeholder="Enter amount"
                      />
                      {errors?.amount ? (
                        <FieldError
                          message={errors?.amount?.message}
                        />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                </div>
                <input
              type="hidden"
              {...register("campaign_id", {
                value: pledgeDetail?.campaign?.id,
              })}
            />
            <input
              type="hidden"
              {...register("platform_used", {
                value: "Offline",
              })}
            />
            <input
              type="hidden"
              {...register("email", {
                value: pledgeDetail?.donor?.email,
              })}
            />
            <input
              type="hidden"
              {...register("donation_type", {
                value: pledgeDetail?.donation_type,
              })}
            />
            <input
              type="hidden"
              {...register("stripe_processing_fee", {
                value: 0,
              })}
            />
            <input
              type="hidden"
              {...register("pledge_amount",{
                value: pledgeDetail?.amount
              })}
            />
              </div> 
            </div>
            {errorMessage ? <p className='mb-0 text-danger mt-2'>{errorMessage}</p> : ''}
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="default" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={requestLoader}
              type="submit"
              variant="primary"
              className={`${
                requestLoader ? "position-relative button--loading" : ""
              }`}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
        </FormProvider>
      </Modal>
      </>
    );
});

export default AddOfflineDonationDialog;