import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrgLogo, getSetOrgInfo, getUserInfo } from '../../../../utils/user-info';
import { LOGOUT_USER } from '../../../../constants/endpoints';
import { NameColorMapper } from '../../../../constants/color-mapper.contant';
import { ENVIRONMENT_CONSTANT } from '../../../../constants/env.constant';

function MainNavbar(props) {
    const navigate = useNavigate();
    const logoBaseUrl = ENVIRONMENT_CONSTANT.LOGO_BASE_URL;
    const userInfo = getUserInfo();
    // const logo = getOrgLogo();
    const orgInfo = getSetOrgInfo();

    //methods start
    const logout = () => {
        localStorage.clear();
        window.location.href = LOGOUT_USER+`?time=${new Date().getTime()}`;
        // axios.get(LOGOUT_USER)
        // .then((res) => {
        //     console.log(res.data.response)
        // }).catch((error) => {
        //     console.log(error)
        // }).finally(() => {
        //     // localStorage.clear();
        //     // navigate('/login');    
        // })
        
    }
    
    return (
        <nav className="navbar navbar-main navbar-expand-lg px-0 mx-2 shadow-none border-radius-xl " id="navbarBlur" data-scroll="false">
            <div className="container-fluid py-1 px-3 ">

                <div className='d-flex justify-content-between align-items-center mt-2'>

                    <div className='custom-navbar border-0 d-flex justify-content-start align-items-center position-relative p-0'>
                        {/* <div className='position-absolute'>
                    <i className='fa-solid fa-star'></i>
                </div> */}

                        <div>
                            <img id='main-logo' className='main-page-logo' src={
                                userInfo?.role === 1 ? 
                                userInfo?.logo_url : 
                                logoBaseUrl + orgInfo?.logo
                            } alt={userInfo?.name} />
                        </div>

                        {/* <span className='f-28'>{userInfo?.name}</span> */}
                    </div>

                    {/* <div className='d-flex justify-content-center'>
                <div className='custom-navbar d-flex justify-content-center align-items-center me-3 position-relative'>
                    <div className='position-absolute'>
                        <i className='fa-solid fa-star'></i>
                    </div>
                    <span className='font-weight-bold'>Submit a review</span>
                </div>

                <div className='custom-navbar d-flex justify-content-center align-items-center me-3 position-relative'>
                    <div className='position-absolute'>
                        <i className='fa-solid fa-circle-arrow-up fa-fw'></i>
                    </div>
                    <span className='font-weight-bold'>Upgrade</span>
                </div>

                <div className='custom-navbar d-flex justify-content-center align-items-center position-relative'>
                    <div className='position-absolute'>
                        <i className='fa-solid fa-mobile-screen-button fa-fw'></i>
                    </div>
                    <span className='font-weight-bold'>Athan + Phone App</span>
                </div>
            </div> */}
                </div>

                <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 justify-content-end" id="navbar">
                    {/* <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                    <div className="input-group">
                        <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                        <input type="text" className="form-control" placeholder="Type here..." />
                    </div>
                </div> */}
                    <ul className="navbar-nav  justify-content-end">
                        {/* <li className="nav-item d-flex align-items-center">
                        <a  className="nav-link font-weight-bold px-0">
                        <i className="fa-solid fa-list-ul"></i>
                            <span className="d-sm-inline d-none">Sign In</span>
                        </a>
                    </li>
                    <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                        <a  className="nav-link p-0" id="iconNavbarSidenav">
                            <div className="sidenav-toggler-inner">
                                <i className="sidenav-toggler-line bg-white"></i>
                                <i className="sidenav-toggler-line bg-white"></i>
                                <i className="sidenav-toggler-line bg-white"></i>
                            </div>
                        </a>
                    </li>
                    <li className="nav-item px-3 d-flex align-items-center">
                        <a  className="nav-link p-0">
                            <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer" aria-hidden="true"></i>
                        </a>
                    </li> */}
                        <li className="nav-item dropdown pe-2 d-flex align-items-center">
                            <a className="p-0 cursor-pointer" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <div className='d-flex align-items-center'>
                                    <div style={{
                                            background:NameColorMapper[userInfo?.name?.substr(0,1)?.toLowerCase()]
                                        }} className='text-white short-menu text-center me-3 d-flex justify-content-center align-items-center'>
                                        {/* <img src={logo} alt={userInfo?.name} className='mt-1 w-80' /> */}
                                        <span
                                        
                                         className='f-12'>{userInfo?.name?.substr(0,1)}</span>
                                    </div>

                                    <span className='f-16 me-4'>{userInfo?.name}</span>
                                    <i className="fa-solid fa-caret-down"></i>
                                </div>
                            </a>
                            {/* py-3 me-sm-n4 */}
                            <ul className="dropdown-menu dropdown-menu-end px-2" aria-labelledby="dropdownMenuButton">
                                {/* <li className="mb-2">
                                <a className="dropdown-item border-radius-md" >
                                    <div className="d-flex py-1">
                                        <div className="my-auto">
                                            <img src="../assets/img/team-2.jpg" className="avatar avatar-sm  me-3 " />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="text-sm font-weight-normal mb-1">
                                                <span className="font-weight-bold">New message</span> from Laur
                                            </h6>
                                            <p className="text-xs text-secondary mb-0">
                                                <i className="fa fa-clock me-1" aria-hidden="true"></i>
                                                13 minutes ago
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </li> */}
                                <li className="mb-2" onClick={logout}>
                                    <a className="dropdown-item border-radius-md" >
                                        <div className="d-flex py-1">

                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-sm font-weight-normal mb-1">
                                                    <span className="font-weight-bold">Logout</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>

                        {/* <li className="d-xl-none ps-3 d-flex align-items-center">
                                <a className="nav-link text-white p-0" id="iconNavbarSidenav">
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line bg-white"></i>
                                        <i className="sidenav-toggler-line bg-white"></i>
                                        <i className="sidenav-toggler-line bg-white"></i>
                                    </div>
                                </a>
                            </li> */}
                    </ul>
                </div>

            </div>


        </nav>
    );
}

export default MainNavbar;