import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import FieldError from '../../../shared/fields-errors/field-error';
import axios from 'axios';
import { SEND_EMAIL } from '../../../constants/endpoints';

const SendTestEmail = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
            sendTestEmailToRecipient: sendTestEmailToRecipient
        };
      });

    const methods = useForm({
        defaultValues:{
            emails: [{ email: '' }, { email: '' }, { email: '' }]
        }
    });
    const { register, handleSubmit, trigger, getValues, watch, control, formState: { errors, isValid } } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'emails',
    });

    const watchFields = watch();

    const sendTestEmailToRecipient = () => {
        trigger();
        if (isValid) {
            props?.toggleLoader(true);
            const formValues = getValues();
            const recipientsList = formValues?.emails?.map(it => it.email).filter(it => it);
            const formData = {...{emails:recipientsList, campaign_id: props?.campaignID}, ...props?.emailTemplateProps};
            axios.post(SEND_EMAIL, formData)
            .then((res) => {
                props?.toggleLoader(false);
                if(res?.data?.response?.status){
                    alert("Test email sent successfully.");
                }else{
                    alert("Unable to send email");
                }
            }).catch((error) => {
                props?.toggleLoader(true);
                alert("Unable to send email");
            });
        }
    }

    // Append three default email fields on component mount
    // useEffect(() => {
    //     append([{ email: '' }, { email: '' }, { email: '' }]);
    // }, [append]);

    const validateRequiredEmail = (value, index) => {
        const some = watchFields?.emails?.some(it => it.email);
        if(!some){
            return 'Emil is required'
        }
        if (value && !value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
          return 'Invalid email format';
        }
        return true;
      };

    return (
        <div className="form-group">
            <label className="form-control-label">
                Email up to three recipients
            </label>
            <div>
                <p className='f-12-small mb-0 mt-2'>Test email will be sent to them</p>
                {fields.map((field, index) => (
                    <div key={field.id}>
                        <input
                            type="text"
                            className={`form-control-alternative form-control ${index > 0 ? 'mt-3' : ''}`}
                            placeholder="ex: ahmad@live.com"
                            {...register(`emails.${index}.email`, {
                                validate: (value) => validateRequiredEmail(value, index),
                            })}
                        />
                        {errors?.emails?.[index]?.email ? <FieldError message={errors?.emails?.[index]?.email?.message} /> : ''}
                    </div>
                ))}
            </div>
            {/* <button
                disabled={testEmailLoader}
                onClick={sendTestEmailToRecipient}
                type="button"
                className={`position-relative btn btn-ma-primary btn-md mt-3 ${testEmailLoader ? 'button--loading' : ''}`}>
                {testEmailLoader ? 'Sending Email...' : 'Send Test Email'}
            </button> */}
        </div>
    );
});

export default SendTestEmail;