const calculateCCAfterFee = (amount) => {
    // var FIXED_FEE = 0.30;
    // var PERCENTAGE_FEE = 0.029;
    var FIXED_FEE = window?.stripe_flat_fee;
    var PERCENTAGE_FEE = window?.stripe_percentage_fee;
    return Number(((amount) + FIXED_FEE)/(1 - PERCENTAGE_FEE)).toFixed(2);
}

const calculateFeePercentage = (amount) =>{
  let calculatedAmount = calculateCCAfterFee(amount);
  return (parseFloat(calculatedAmount) - amount).toFixed(2);
}


export default calculateCCAfterFee;
export {calculateFeePercentage}