import React from 'react';

function Unauthorized(props) {
    return (
        <div className='text-center'>
            <img src='./img/masjid/401.png' className='img-fluid w-30' />
            <h3>401 Unauthorized</h3>
            <p className='mb-0 text-danger text-bold'>Access is denied, you don't have the access.</p>
            <p>If you continue to see this error kindly contact our support team.</p>
        </div>
    );
}

export default Unauthorized;