import React from 'react';
import EmailReceiptForm from './email-receipt-form.component';
import SharedFormPreviewComponent from '../../../shared/shared-form-preview/shared-form-preview.component';

function EmailReceiptComponent(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <EmailReceiptForm />
                {/* <SharedFormPreviewComponent 
                image={'../../../img/masjid/email-receipt.png'}
                title={'Email Receipt'} /> */}
            </div>
        </div>
    );
}

export default EmailReceiptComponent;