import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { GET_SQUARE_DETAIL, REVOKE_SQUARE_ACCOUNT, SQUARE_ONBOARD_URL } from "../../../../constants/square-endpoints.constant";
import ConfirmationDialog from "../../../../shared/confirmation-dialog/confirmation-dialog";

function SquarePaymentProcessor(props) {
  const [squareDetail, setSquareDetail] = useState({});
  const [paymentToggle, setPaymentToggle] = useState(true);
  const [loader, setLoader] = useState(false);
  const confirmationDialogRef = useRef(null);

  const getSquareAccountDetail = () => {
    setLoader(true);
    axios.get(GET_SQUARE_DETAIL)
      .then((res) => {
        setLoader(false);
        if (res?.data?.status) {
          setSquareDetail(res?.data?.response);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const revokeSquareAccount = () => {
    setLoader(true);
    axios.delete(REVOKE_SQUARE_ACCOUNT)
      .then((res) => {
        setLoader(false);
        if (res?.data?.status) {
          setSquareDetail({});
        }else{
          props?.alertDialogRef.current.openDialog(res?.data?.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        props?.alertDialogRef.current.openDialog('Unable to remove square account');
      });
  }

  useEffect(() => {
    getSquareAccountDetail();
  }, []);

  const togglePayment = () => {
    setPaymentToggle(!paymentToggle);
  };

  return (
    <>
      <div className="col-xl-6 col-xl-6 col-xl-6 col-sm-12"> 
        {squareDetail?.id ? (
          <div className="card shadow" style={{
            minHeight: '315px'
          }}>
            {loader ? props?.loaderTemplate() : ""}
            <div className="stripe card-header bg-white border-0 pb-0">
              <div className="stripe--connect connect d-flex justify-content-start align-items-center">
                <div className="w-15">
                  <img
                    className="w-85"
                    src="../img/masjid/square-logo.png"
                    alt="Square Logo"
                  />
                </div>
                <div>
                  <div>
                    <span className="btn btn-sm btn-success mb-0 pointer-event-none">
                      Connected
                    </span>
                  </div>
                </div>
              </div>
              <p className="text-ma-primary">
                Your{" "}
                <span className="font-weight-bold">
                  campaigns can now accept donations
                </span>{" "}
                from credit cards, Apple Pay, and Google Pay.
              </p>
            </div>
            <div className="card-body">
              <div>
                <h6>Square Settings</h6>
                <div className="stripe-settings"></div>
                <div
                  className="d-flex hoverLink align-items-center mt-2 cursor-pointer"
                  onClick={togglePayment}
                >
                  <span className="font-weight-bold">
                    Connected Account{" "}
                    <span className="font-weight-normal">
                      ({squareDetail?.merchant_id})
                    </span>
                  </span>
                </div>
                <div className={paymentToggle ? "d-block" : "d-none"}>
                  <p className="text-black">
                    Merchant Account: Masjidal Donation ({props?.user?.email})
                  </p>
                  <div className="d-flex justify-content-start align-items-center mt-3">
                    <button
                      onClick={() => {
                        confirmationDialogRef.current.setDialogTitle(
                          "Confirm Removal"
                        );
                        confirmationDialogRef.current.setMessage(
                          "Are you sure you want to remove square account?"
                        );
                        confirmationDialogRef.current.setButtonLabel(
                          "Remove Account"
                        );
                        confirmationDialogRef.current.openDialog();
                      }}
                      type="button"
                      className="btn btn-danger btn-md"
                    >
                      Disconnect Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card shadow" style={{
            minHeight: '315px'
          }}>  
          {loader ? props?.loaderTemplate() : ""}
            <div className="stripe card-header bg-white border-0">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="mb-0">Connect Square</h3>
                </div>
                <div>
                  <span className="btn btn-sm btn-danger mb-0 pointer-event-none">
                    Not Connected
                  </span>
                </div>
              </div>
              <div className="stripe--connect connect d-flex justify-content-start align-items-center">
                <div className="w-15">
                  <img
                    className="w-85"
                    src="../img/masjid/square-logo.png"
                    alt="Stripe Logo"
                  />
                </div>
              </div>
              <p className="text-ma-primary mb-0">
                Trusted by millions of companies around the world, Square allows
                you to accept cards, low-fee bank payments, Apple Pay, and
                Google Pay.
              </p>
            </div>
            <div className="card-body">
              <div className="stripe-connect-body">
                <p className="text-ma-primary font-weight-bold">
                  Creating a Square account is simple and just takes a few
                  minutes.
                </p>

                <div className="d-flex justify-content-start align-items-center">
                  <div>
                    <a href={SQUARE_ONBOARD_URL} target="_blank" className="btn btn-ma-primary btn-md">
                      Connect Square Account
                    </a>
                  </div>
                </div>
                <div className="general-info">
                  <p className="text-ma-primary">
                    You can use Square as a nonprofit organization to process
                    donation payments and collect donor information.
                    <br />
                    <a className="text-warning" href="tel:nonprofit@stripe.com">
                      {" "}
                      Customer support: 1 (855) 700-6000
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* confirmation dialog start */}
      <ConfirmationDialog
          ref={confirmationDialogRef}
          executeAction={revokeSquareAccount}
        />
    </>
  );
}

export default SquarePaymentProcessor;
