import React, { useEffect } from 'react';
import FieldError from '../../../shared/fields-errors/field-error';
import { useFormContext } from 'react-hook-form';

function DonorCommentsTemplate(props) {
    const { register, clearErrors, formState: { errors } } = useFormContext();

    useEffect(() => {
        clearErrors();
    }, []);

    return (
        <div className='col-lg-12 mt-3 d-flex justify-content-center'>
            <div className={`form-group ${props?.descriptiveAmountView ? 'w-100' : 'w-75'}`}>
                <label className="form-control-label">
                    {
                        (props?.settings?.is_donor_comments && 
                        props?.settings?.is_comment_required) ?
                        <span className='text-danger me-1'>*</span> : ''
                    }
                     {props?.settings?.comments_label}</label>
                <textarea
                    placeholder={props?.settings?.comments_label}
                    {...register("donor_comments", {
                        validate: {
                            required: (value) => {
                                if (!value && props?.settings?.is_comment_required) return "This field is required";
                                return true
                            }
                        }
                    })}
                    type="text"
                    className="form-control-alternative form-control" ></textarea>
                {errors?.donor_comments ?
                    <FieldError message={errors?.donor_comments?.message} /> : ''}
            </div>

        </div>
    );
}

export default DonorCommentsTemplate;