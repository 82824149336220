import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { dateFilterList, intervalsList, recurringStatusList } from '../../../../data-list/data-list';
import axios from 'axios';
import { CAMPAIGNS_NAMES } from '../../../../constants/endpoints';
import { getTimeFilterValue } from '../../../../utils/utils';

const RecurringFilters = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return{
            setPageIndex: setPageIndex,
            pageIndex: pageIndex,
            getValues: getValues
        }
    });

    const [pageIndex, setPageIndex] = useState(0);

    const methods = useForm();
    const { register, unregister, getValues } = methods;

    const [campaignsList, setCampaignsList] = useState([]);

    const getCampaignsName = () => {
        axios.get(CAMPAIGNS_NAMES + 'both')
            .then((res) => {
                if (res?.data?.status) {
                    setCampaignsList(res?.data?.response);
                }
            }).catch((error) => {

            });
    };

    useEffect(() => {
        getCampaignsName();
    }, []);

    // const settingUpDateValue = (formValues) => {
    //     const startDate = formValues?.start_date ? getTimeFilterValue(formValues?.start_date) : '';
    //     const last_donated_date = formValues?.last_donated_date ? 
    //     getTimeFilterValue(formValues?.last_donated_date) : '';
    //     const next_donation_date = formValues?.next_donation_date ?
    //     getTimeFilterValue(formValues?.next_donation_date) : '';

    //     formValues.start_date = 

    // }
    return (
        <div className='row'>
            <div className="col-md-3 mt-2">
                <div className="form-group">
                    <Form.Select {...register('interval', {
                        value: '',
                        onChange: () => {
                            console.log(getValues())
                            props?.getAllRecurringPlans(getValues(), 0);
                            setPageIndex(0)
                            props?.setFiltersValue(getValues());
                            // props?.triggerFilter(getValues())
                        }
                    })}>
                        {
                            intervalsList.map((item, index) => {
                                return <option key={item.value + ' ' + index} value={item.value}>{item.label}</option>
                            })
                        }

                    </Form.Select>
                </div>
            </div>
            <div className="col-md-3 mt-2">
                <div className="form-group">
                    <Form.Select {...register('status', {
                        value: '',
                        onChange: () => {
                            console.log(getValues())
                            props?.getAllRecurringPlans(getValues(), 0);
                            setPageIndex(0)
                            props?.setFiltersValue(getValues());
                            // props?.triggerFilter(getValues())
                        }
                    })}>
                        {
                            recurringStatusList.map((item, index) => {
                                return <option key={item.value + ' ' + index} value={item.value}>{item.label}</option>
                            })
                        }

                    </Form.Select>
                </div>
            </div>
            <div className="col-md-3 mt-2">
                <div className="form-group">
                    <Form.Select {...register('campaign', {
                        value: '',
                        onChange: () => {
                            console.log(getValues())
                            props?.getAllRecurringPlans(getValues(), 0)
                            setPageIndex(0);
                            props?.setFiltersValue(getValues());
                            // props?.triggerFilter(getValues())
                        }
                    })}>
                        <option value={""}>All Campaigns</option>
                        {
                            campaignsList.map((item, index) => {
                                return <option key={item.id + ' ' + index} value={item.id}>{item.campaign_title}</option>
                            })
                        }

                    </Form.Select>
                </div>
            </div>
            <div className="col-md-3 mt-2">
                <div className="form-group">
                    <Form.Select {...register('ui_start_date', {
                        value: '',
                        onChange: () => {
                            unregister('start_date');
                            unregister('end_start_date');
                            if(getValues()?.ui_start_date){
                                const start = getTimeFilterValue(getValues()?.ui_start_date);
                                register('end_start_date',{
                                    value: start.endDate
                                });
                                register('start_date',{
                                    value: start.startDate
                                })
                                console.log(getValues())
                            }
                            props?.getAllRecurringPlans(getValues(), 0);
                            setPageIndex(0);
                            props?.setFiltersValue(getValues());
                            // props?.triggerFilter(getValues())
                        }
                    })}>
                        <option value={""}>Start Date</option>
                        {
                            dateFilterList.map((item, index) => {
                                return <option key={item.value + ' ' + index} value={item.value}>{item.label}</option>
                            })
                        }

                    </Form.Select>
                </div>
            </div>
            <div className="col-md-3 mt-2">
                <div className="form-group">
                    <Form.Select {...register('ui_last_donated_date', {
                        value: '',
                        onChange: () => {
                            console.log(getValues())
                            unregister('last_donated_date');
                            unregister('end_last_donated_date');
                            if(getValues()?.ui_last_donated_date){
                                const start = getTimeFilterValue(getValues()?.ui_last_donated_date);
                                register('end_last_donated_date',{
                                    value: start.endDate
                                });
                                register('last_donated_date',{
                                    value: start.startDate
                                })
                                console.log(getValues())
                            }
                            props?.getAllRecurringPlans(getValues(), 0);
                            setPageIndex(0);
                            props?.setFiltersValue(getValues());
                        }
                    })}>
                        <option value={""}>Last Donation Date</option>
                        {
                            dateFilterList.map((item, index) => {
                                return <option key={item.value + ' ' + index} value={item.value}>{item.label}</option>
                            })
                        }

                    </Form.Select>
                </div>
            </div>
            <div className="col-md-3 mt-2">
                <div className="form-group">
                    <Form.Select {...register('ui_next_donation_date', {
                        value: '',
                        onChange: () => {
                            unregister('next_donation_date');
                            unregister('end_next_donation_date');
                            if(getValues()?.ui_next_donation_date){
                                const start = getTimeFilterValue(getValues()?.ui_next_donation_date);
                                register('end_next_donation_date',{
                                    value: start.endDate
                                });
                                register('next_donation_date',{
                                    value: start.startDate
                                })
                                console.log(getValues())
                            }
                            props?.getAllRecurringPlans(getValues(), 0);
                            setPageIndex(0);
                            props?.setFiltersValue(getValues());
                        }
                    })}>
                        <option value={""}>Next Donation Date</option>
                        {
                            dateFilterList.map((item, index) => {
                                return <option key={item.value + ' ' + index} value={item.value}>{item.label}</option>
                            })
                        }

                    </Form.Select>
                </div>
            </div>

            {/* text filter */}
            <div className="col-md-4 mt-2">
                <div className="form-group">
                    <div className="input-group">
                        <input
                        {...register('text_value', {
                            value: ''
                        })}
                            id="txtDonationSearch"
                            onKeyDown={(e) => {
                                    if(e.key === 'Enter'){
                                        console.log(getValues())
                                        props?.getAllRecurringPlans(getValues(), 0);
                                        setPageIndex(0);
                                        props?.setFiltersValue(getValues());
                                    }
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Search by name or email"
                        />
                        <button onClick={(e) => {
                            console.log(getValues())
                            props?.getAllRecurringPlans(getValues(), 0);
                            setPageIndex(0);
                            props?.setFiltersValue(getValues());
                            // const value = document.getElementById('txtDonationSearch')?.value;
                            // setSearchDonationModel(value);
                        }} type="button" className="btn btn-outline-secondary mb-0">Search</button>
                        <Dropdown>
                            <Dropdown.Toggle
                                disabled={props?.fileLoader}
                                variant="default" id="dropdown-basic"
                                className={`btn btn-outline-secondary mb-0 border-radius-none 
                ${props?.fileLoader ? 'position-relative button--loading' : ''}`}>
                                Export
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={props?.downloadCSV}>Export All Columns</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    );
}) 


export default RecurringFilters;