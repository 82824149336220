// LoginGuard.js
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import Cookies from 'js-cookie';
import axios from 'axios';
import { REFRESH_TOKEN } from '../constants/endpoints';
import { ENVIRONMENT_CONSTANT } from '../constants/env.constant';
import { getUserInfo } from '../utils/user-info';
import Dashboard from '../pages/components/dashboard';
import Unauthorized from '../Unauthorized/Unauthorized';

const AuthGuard = ({ children, role }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const cookie = Cookies.get('token_validity');
  const userInfo = getUserInfo();
  // useEffect(() => {
  const refreshToken = async () => {
    const refreshTokenResponse = await axios.get(REFRESH_TOKEN);
    if (refreshTokenResponse.data?.status) {
      console.log("From guard")
      setIsAuthenticated(true);
      // refreshTokenResponse.data.response.masjid_id = refreshTokenResponse.data.response.uuid;
      localStorage.setItem(ENVIRONMENT_CONSTANT.USER_INFO, JSON.stringify(refreshTokenResponse?.data?.response));
      localStorage.setItem(ENVIRONMENT_CONSTANT.ORGANIZATION_LOGO, refreshTokenResponse?.data?.response?.logo_url);
      console.log(refreshTokenResponse.data?.message);
      // return children;
      navigate(window.location.pathname)
    } else {
      setIsAuthenticated(false);
      // Redirect unauthenticated users to the login page
      localStorage.clear();
      // return <Navigate to="/login" />;
      navigate('/login')
    }
  }

  if (!cookie) {
    localStorage.clear();
    return <Navigate to="/login" />
    // refreshToken();
  } else if(userInfo.role === role) {
    return children;
  }else{
    return <Unauthorized />
  }

  // }, [])

  // if (!cookie) {
  //   // Redirect unauthenticated users to the login page
  //   localStorage.clear();
  //   return <Navigate to="/login" />;
  // }


};

export default AuthGuard;