import axios from 'axios';
import { useEffect, useState } from 'react';
import { CAMPAIGNS_NAMES } from '../constants/endpoints';

export function useGetCampaigns(props) {
    const [campaignList, setCampaignList] = useState([]);

    useEffect(() => {
        getCampaigns();
    },[]);

    const getCampaigns = () => {
        axios.get(CAMPAIGNS_NAMES + 'campaign')
          .then((res) => {
            if (res?.data?.status) {
                setCampaignList(res?.data?.response);
            }
          }).catch((error) => {
    
          });
    };

    return {campaignList, getCampaigns};
};