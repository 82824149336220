import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSetOrgInfo } from '../../../utils/user-info';
import LinkQRCode from '../../../shared/qr-code/qr-code';
import AssignCampaign from './dialogs/assign-campaign';
import axios from 'axios';
import { GET_ALL_FUNDRAISING } from '../../../constants/endpoints';

function FundraisingSettings(props) {
    const orgInfo = getSetOrgInfo();
    const [qrCode,  setQrCode] = useState("");
    const assignCampaignDialogRef = useRef(null);
    const [pages, setPages] = useState([
        {
            type: 'campaign'
        },
        {
            type: 'product'
        }
    ]);

    const getFundraising = () => {
        axios.get(GET_ALL_FUNDRAISING)
        .then((res) => {
            if(res?.data?.response?.length > 0){
                const clone = structuredClone(pages);
                for (let index = 0; index < res?.data?.response.length; index++) {
                    const i = clone.findIndex(it => it.type === res?.data?.response[index]?.campaign?.type);
                    if(i !== -1){
                        clone[i].page_link = `/fundraising/${res?.data?.response[index]?.organization?.organization_short_name}/${res?.data?.response[index]?.campaign?.donation_page_link}`
                        clone[i].id = res?.data?.response[index]?.id;
                        clone[i].campaign_id = res?.data?.response[index]?.campaign?.id;
                    }
                }

                setPages(clone);
            }
        }).catch((error) => {

        })
    }

    useEffect(() => {
        getFundraising()
    }, [])
    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                        <h5 className="text-black">Fundraising Setup</h5>
                    </div>
                </div>

                <div className="row">
                    {pages.map((item, index) => {
                        return (
                            <div key={index} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                <div className="campaign-card">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <p className="mb-0">
                                                <a
                                                    className="mb-0 f-16 text-bold"
                                                >
                                                    {item?.type === 'campaign' ? 'Campaign' : 'Product'} Fundraising
                                                </a>
                                            </p>
                                        </div>

                                        <div className="d-flex justify-content-end align-items-baseline">
                                            <button
                                            onClick={() => assignCampaignDialogRef?.current?.handleShow(item,item?.type)}
                                                className="me-2 cursor-pointer camp-actions"
                                            >
                                                <i className="fa-regular fa-pen-to-square f-14"></i>{" "}
                                                <span className="text-capitalize d-none d-md-none d-lg-inline-block f-14">
                                                    Assign {item?.type}
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-2 campaign-title">
                                        <a
                                            target='_blank'
                                            className="text-light-blue"
                                            href={item?.page_link ? item?.page_link : '/fundraising/TEST/my-campaign'}
                                        >
                                            Preview Page {">"}{" "}
                                        </a>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-xl-12 mt-2 ">
                                            <a onClick={(e) => {
                                                e.stopPropagation();
                                                item?.type !== qrCode ?
                                                    setQrCode(item?.type) : setQrCode('')
                                            }} className="btn-action w-100 d-flex justify-content-start align-items-center cursor-pointer">
                                                <span className="me-2">
                                                    <i className="fa-solid fa-qrcode"></i>
                                                </span>
                                                <span>QR Code</span>
                                            </a>
                                            <div className='position-relative'>
                                                <LinkQRCode
                                                    url={`${window.location.origin}${item?.page_link ? item?.page_link : 'fundraising/TEST/my-campaign'}`}
                                                    name={item?.type}
                                                    qrCode={qrCode}
                                                    id={item?.type} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* assign campaign dialog */}
            <AssignCampaign ref={assignCampaignDialogRef} callback={getFundraising} />
        </div>
    );
}

export default FundraisingSettings;