import { Outlet } from "react-router-dom";
import CampaignSidebar from "./Common/Campaign-Sidebar";

function CampaignLayout(props) {
    return (
        <div >
            {/* sidebar start */}
            {/* <CampaignSidebar /> */}
            {/* sidebar end */}

            {/* campaign main content start */}
            <Outlet />
            {/* campaign main content end */}

        </div>
    );
}

export default CampaignLayout;