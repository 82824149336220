import React from 'react';
import getFormattedDate from '../../utils/date-format';

function PledgeConfirmation(props) {
    return (
        <div className={`row ${props?.formStep !== 'pledge-confirmation' ? 'd-none' : ''}`}>
            <div className="col-lg-12 text-center">
                <i className="fa-regular fa-circle-check" style={{ fontSize: '5rem', color: '#52a355' }}></i>
                <h5 className='mt-3 mb-0'>You Have Pledged ${props?.returnSelectedAmount()}</h5>
                <span>{new Date().toDateString().split(" ")[0] + ' ' + getFormattedDate(new Date())}</span>
                <p className='mt-4 mb-1 text-black'>
                    Thank you for your pledge, please complete your donation as soon as you can.
                </p>
                <button
                    style={{
                        backgroundColor: props?.campaignDetail?.primary_color ? (props?.campaignDetail?.primary_color) : '#005B97',
                        borderBottom: props?.campaignDetail?.primary_color ? ('2px solid ' + props?.campaignDetail?.primary_color) : ''
                    }}
                    onClick={(() => {
                        window.open(props?.campaignDetail?.website ? props?.campaignDetail?.website : 'https://mymasjidal.com/', '_self', '');
                    })}
                    type="button" className={`btn text-white btn-md w-70 `}>
                    Close
                </button>
            </div>
        </div>
    );
}

export default PledgeConfirmation;