import React, { useEffect, useState } from 'react';
import { GET_DAILY_PLAN_STATUS } from '../../../constants/admin-endpoints';
import axios from 'axios';
import Loader from '../../../shared/loader/loader';
import NoDataComponent from '../../../shared/no-data/no-data';
import getFormattedDate from '../../../utils/date-format';

function DailyRecurringPlansStatus(props) {
    const [requestLoader, setRequestLoader] = useState(false);
    const columns = ["ID","Organization", "Donor", "Payment Method ID", "Stripe Customer ID", "Stripe Account ID","amount","Start Date","Next Donation Date", "Payment Method Attached"]
    const [plans, setPlans] = useState([]);

    const getAllPlans = () => {
        setRequestLoader(true);
        axios.get(GET_DAILY_PLAN_STATUS)
            .then((res) => {
                setRequestLoader(false);
                if (res?.data?.status) {
                    setPlans(res?.data?.response);
                } else {
                    setPlans([])
                }

            }).catch((error) => {
                setRequestLoader(false);
            })
    }

    useEffect(() => {
        getAllPlans();
    }, [])

    return (
        <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2'>
                            <h5 className='font-weight-semibold text-black'>Plans ({plans.length})</h5>
                        </div>
                    </div>
                </div>

                <div className='row mt-3 position-relative'>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className="table-responsive">
                                {
                                    !requestLoader ?
                                        <table className="table align-items-center ">
                                            <thead className='text-center prayers-table-header white'>
                                                <tr>
                                                    {
                                                        columns.map((item, index) => {
                                                            return <th key={index}>{item}</th>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody className='prayer-table-body text-center'>
                                                {
                                                    plans.map((item, index) => {
                                                        return (
                                                            <tr
                                                            style={{
                                                                backgroundColor: item?.is_payment_method_exists === false ? '#f5365c' : ''
                                                            }} 
                                                            key={'plan_' + index} className={`${item?.is_payment_method_exists === false ? 'text-white' : ''}`}>
                                                                <td>{item?.id}</td>
                                                                <td>{item?.organization_name}</td>
                                                                <td>{item?.first_name + ' ' + item?.last_name}</td>
                                                                <td>{item?.payment_method_id}</td>
                                                                <td>{item?.stripe_customer_id}</td>
                                                                <td>{item?.stripe_account_id}</td>
                                                                <td>{(item?.amount/100)}</td>
                                                                <td>{getFormattedDate(item?.created_at)}</td>
                                                                <td>{getFormattedDate(item?.next_donation_date)}</td>
                                                                <td className={`text-uppercase ${item?.is_payment_method_exists ? 'text-success' : 'text-white'} text-bold`}>{item?.is_payment_method_exists?.toString()}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>

                                        </table> : ''
                                }
                                {/* no data message */}
                                {

                                    !requestLoader && plans?.length === 0 ?
                                        <NoDataComponent message={'No Plans Found'} /> : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        requestLoader ?
                            <Loader /> : ''
                    }
                </div>
            </div>
    );
}

export default DailyRecurringPlansStatus;