function Dashboard() {
    return (
        <>
          {/* main container start */}
          <div >
          {/* prayer time alert start */}
          <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-6 m-auto'>
            <div className='row time-alert pt-2 pb-2'>
              <div className='col-10 text-center'>
                <span className='mb-0'>Please Save the Eid Prayer Timings</span>
              </div>

              <div className='col-2 text-right'>
                <span className='fa-regular fa-circle-xmark fa-fw'></span>
              </div>
            </div>
          </div>
          </div>
          
          {/* prayer time alert end */}

          {/* timing and donation view start */}
          <div className='row mt-3'>
            <div className='col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3'>
              <div className='timing text-black text-center border-radius-lg p-3'>
                <span className='f-20 fa-regular fa-clock fa-fw'></span>
                <div className='row '>
                  <div className='col-3 mb-0 f-24 font-weight-bold'>06:</div>
                  <div className='col-3 mb-0 f-24 font-weight-bold'>35:</div>
                  <div className='col-3 mb-0 f-24 font-weight-bold'>33</div>
                  <div className='col-3 mb-0 f-24 font-weight-bold'>AM</div>


                  <div className='col-3 font-weight-normal f-14'>Hr</div>
                  <div className='col-3 font-weight-normal f-14'>Min</div>
                  <div className='col-3 font-weight-normal f-14'>Sec</div>

                  {/* <p className='mb-0 f-18 font-weight-bold '>06:35:33 AM</p>
                  <div className='font-weight-normal f-14'>
                    <span>Hr</span>
                    <span>Min</span>
                    <span>Sec</span>
                  </div> */}
                </div>

              </div>
            </div>

            <div className='mt-md-3 mt-xs-3 mt-sm-3 mt-lg-0 mt-xl-0 mt-xxl-0 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9  text-white'>
              <div className='stats-banner border-radius-lg'>
                <div className='p-3 layer border-radius-lg'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-4'>
                      <div className='d-flex justify-content-center align-items-center'>
                        <div className='stats-shadow me-2 p-3'>
                          <span className='fa-regular fa-rectangle-list fa-fw f-20'></span>
                        </div>
                        <div className='d-flex flex-column'>
                          <span className='font-weight-bolder f-45'>257</span>
                          <span className='f-14 text-nowrap'>Signage Devices</span>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-12 col-md-12 col-lg-4'>
                      <div className='d-flex justify-content-center align-items-center'>
                        <div className='stats-shadow me-2 p-3'>
                          <span className='fa-solid fa-mosque fa-fw f-20'></span>
                        </div>
                        <div className='d-flex flex-column'>
                          <span className='font-weight-bolder f-45'>100</span>
                          <span className='f-14 text-nowrap'>Athan Frames</span>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-12 col-md-12 col-lg-4'>
                      <div className='d-flex justify-content-center align-items-center'>
                        <div className='stats-shadow me-2 p-3'>
                          <span className='fa-solid fa-hand-holding-heart fa-fw f-20'></span>
                        </div>
                        <div className='d-flex flex-column'>
                          <span className='font-weight-bolder f-45'>90</span>
                          <span className='f-14 text-nowrap'>Donation Kiosks</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* timing and donation view end */}

          {/* prayer and donation stats start */}
          <div className='row '>
            <div className='col-sm-12 col-md-12 col-lg-6 mt-5'>
              <p className=' text-black font-weight-bold'>Todays Prayer & Iqamah</p>
              <div className="card ">
                {/* <div className="card-header pb-0 p-3">
                  <div className="d-flex justify-content-between">
                    <h6 className="mb-2">Sales by Country</h6>
                  </div>
                </div> */}
                <div className="table-responsive">
                  <table className="table align-items-center ">
                    <thead className='text-center prayers-table-header text-white '>
                      <tr>
                        <th>Prayers</th>
                        <th>Athan Timing</th>
                        <th>Iqamah Timing</th>
                      </tr>
                    </thead>
                    <tbody className='prayer-table-body'>
                      <tr>
                        <td className="w-30">
                          <div className="d-flex px-2 py-1 align-items-center">
                            <div>
                              <img src="./img/masjid/fajr.png" alt="Fajr Image" />
                            </div>
                            <div className="ms-4">
                              <h6 className="text-sm mb-0">Fajr</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">5:51 am</h6>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">$230,900</h6>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-30">
                          <div className="d-flex px-2 py-1 align-items-center">
                            <div>
                              <img src="./img/masjid/dhuhur.png" alt="Dhuhur Image" />
                            </div>
                            <div className="ms-4">
                              <h6 className="text-sm mb-0">Dhuhur</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">5:51 am</h6>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">$230,900</h6>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="w-30">
                          <div className="d-flex px-2 py-1 align-items-center">
                            <div>
                              <img src="./img/masjid/asr.png" alt="Asar Image" />
                            </div>
                            <div className="ms-4">
                              <h6 className="text-sm mb-0">Asar</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">5:51 am</h6>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">$230,900</h6>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="w-30">
                          <div className="d-flex px-2 py-1 align-items-center">
                            <div>
                              <img src="./img/masjid/maghrib.png" alt="Maghrib Image" />
                            </div>
                            <div className="ms-4">
                              <h6 className="text-sm mb-0">Maghrib</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">5:51 am</h6>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">$230,900</h6>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="w-30">
                          <div className="d-flex px-2 py-1 align-items-center">
                            <div>
                              <img src="./img/masjid/isha.png" alt="Isha Image" />
                            </div>
                            <div className="ms-4">
                              <h6 className="text-sm mb-0">Isha</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">5:51 am</h6>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">

                            <h6 className="text-sm mb-0">$230,900</h6>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='col-sm-12 col-md-12 col-lg-6 mt-5'>
              <p className=' text-black font-weight-bold'>Categories of donation</p>
              <div className='card p-3'>
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-6 text-center category-right-border'>
                    <img className='w-100' src="./img/masjid/donut.png" alt="Country flag" />
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <ul className="list-group">
                      <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                        <div className="d-flex align-items-center">
                          <div className="category-point-outer-circle me-3">
                            <div className='blue'></div>
                          </div>
                          <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark text-sm ">Zakaat</h6>
                          </div>
                        </div>
                      </li>

                      <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                        <div className="d-flex align-items-center">
                          <div className="category-point-outer-circle me-3">
                          <div className='light-blue'></div>
                          </div>
                          <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark text-sm ">Sadaqah</h6>
                          </div>
                        </div>
                      </li>

                      <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                        <div className="d-flex align-items-center">
                          <div className="category-point-outer-circle me-3">
                            <div className='green'></div>
                          </div>
                          <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark text-sm ">Masjid Expansion</h6>
                          </div>
                        </div>
                      </li>

                      <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                        <div className="d-flex align-items-center">
                          <div className="category-point-outer-circle me-3">
                            <div className='yellow'></div>
                          </div>
                          <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark text-sm ">Maintenance</h6>
                          </div>
                        </div>
                      </li>

                      <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                        <div className="d-flex align-items-center">
                          <div className="category-point-outer-circle me-3">
                            <div className='red'></div>
                          </div>
                          <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark text-sm ">Parking Lot</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* prayer and donation stats end */}

          {/* main graph start */}

          <div className='row mt-5'>
            <div className='card p-5 text-center'>
              <p>Main graph goes here</p>
            </div>
          </div>
          {/* main graph end */}
        </div>
        {/* main container end */}
        </>
    )

}

export default Dashboard