import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GET_ACTIVE_PLANS_COUNT } from '../../constants/endpoints';

function ActivePlans(props) {

    const [activePlansCount, setActivePlansCount] = useState(0);
    const getActivePlans = () => {
        axios.get(GET_ACTIVE_PLANS_COUNT)
        .then((res) => {
            res?.data?.status ? setActivePlansCount(res?.data?.response) : setActivePlansCount(0);
        }).catch((error) => {

        });
    }

    useEffect(() => {
        getActivePlans();
    }, [])

    return (
        <>
                <div className='col-xl-6 col-lg-6 col-md-6 text-center mt-3 mt-xl-0 mt-lg-0 mt-md-0 sm-mt-0'>
                    <div className='card'>
                        <p className='mt-3'>Active Plans</p>
                        <span className='mb-3 text-bold f-22 text-black'>{activePlansCount}</span>
                    </div>
                </div>
        </>
    );
}

export default ActivePlans;