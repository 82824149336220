import React, { useEffect, useRef, useState } from 'react';
import NoDataComponent from '../../../shared/no-data/no-data';
import Loader from '../../../shared/loader/loader';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { CAMPAIGNS_NAMES, DELETE_FORM_MAPPING, GET_ALL_MAPPINGS } from '../../../constants/endpoints';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AddEditFormIntegration from './dialogs/form-mapping.dialog';
import { useGetIntegration } from './hooks/get-integrations.hook';
import { useGetCampaigns } from '../../../hooks/get-campaign';
import getFormattedDate from '../../../utils/date-format';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';

function FormIntegrations(props) {
    const formMappingDialogRef = useRef(null);
    const confirmationDialogRef = useRef(null);
    // const {integrationsList, getSelectedIntegration} = useGetIntegration();
    const columns = ["Campaign", "Created At", "Actions"];
    const [formMappingList, setFormMappingList] = useState([]);
    const [requestLoader, setRequestLoader] = useState(false);
    const {register, getValues} = useForm();
    const {campaignList} = useGetCampaigns();
    const [searchModel, setSearchModel] = useState("");
    const [campaignModel, setCampaignModel] = useState("");


    const getAllFormMappings = () => {
        setRequestLoader(true);
        axios.get(`${GET_ALL_MAPPINGS}?name=${searchModel}&campaign=${campaignModel}`)
        .then((res) => {
            setRequestLoader(false);
            setFormMappingList(res?.data?.response?.length > 0 ? res?.data?.response : []);
        }).catch((error) => {
            setRequestLoader(false);
        });
    }

    const deleteMapping = (id) => {
        axios.delete(DELETE_FORM_MAPPING + id)
        .then((res) => {
            if(res?.data?.response){
                getAllFormMappings();
            }else{
                alert('Unable to delete form mapping');
            }
        }).catch((error) => {
            alert('Unable to delete form mapping');
        })
    }

    useEffect(() => {
        getAllFormMappings();
    },[searchModel, campaignModel])

    return (
        <>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='row'>
                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2'>
                            <h5 className='font-weight-semibold text-black'>Jotform Integration</h5>
                        </div>

                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 text-right f-14'>
                            <a onClick={() => {
                                formMappingDialogRef.current?.handleShow()
                            }}
                                className={`add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3`}>
                                <span>
                                    <i className="fa-solid fa-file-signature mr-5"></i>
                                </span>
                                <span className='ms-2 text-white'>Create Form Mapping</span>
                            </a>
                            {/* <Link to={`/integrations`}
                                className="mx-3 add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3">
                                <span>
                                <i className="fa-solid fa-puzzle-piece"></i>
                                </span>
                                <span className='ms-2 text-white'>Add New Integration</span>
                            </Link> */}
                        </div>
                    </div>
                </div>

                {/* search start */}
                <div className='row'>
                    <div className="col-md-2 mt-2">
                        <div className="form-group">
                            <Form.Select onChange={(e) => {
                                setCampaignModel(e.target.value);
                            }}>
                                <option value={""}>All Campaigns</option>
                                {
                                    campaignList.map((item, index) => {
                                        return <option
                                            key={'campaigns ' + index}
                                            value={item.id}>{item.campaign_title}</option>
                                    })
                                }
                            </Form.Select>
                        </div>
                    </div>
                    {/* text filter */}
                    {/* <div className="col-md-4 mt-2">
                        <div className="form-group">
                            <div className="input-group">
                                <input
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setSearchModel(e.target.value)
                                        }
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by name"
                                />
                                <button onClick={(e) => {
                                    // getDonors();
                                }} type="button" className="btn btn-outline-secondary mb-0">Search</button>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* search end */}

                {/* {
                    (integrationsList?.length === 0) ? <p className='mb-0 mt-2 text-bold text-danger text-center'>
                    No integrations found, kindly enable integration to create form mappings.
                </p> : ""
                } */}
                <div className='row mt-3 position-relative'>
                    <div className='col-xl-12'>
                        <div className='card'>
                            <div className="table-responsive">
                                {
                                    !requestLoader ?
                                        <table className="table align-items-center ">
                                            <thead className='text-center prayers-table-header white'>
                                                <tr>
                                                    {
                                                        columns.map((item, index) => {
                                                            return <th key={index}>{item}</th>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody className='prayer-table-body text-center'>
                                                {
                                                    formMappingList.map((item, index) => {
                                                        return <tr key={'mapping_' + index}>
                                                            {/* <td>{item?.name}</td> */}
                                                            <td>{item?.campaign?.campaign_title}</td>
                                                            <td>{getFormattedDate(item?.created_at)}</td>
                                                            <td>
                                                                <i onClick={() => {
                                                                    confirmationDialogRef.current.setDialogTitle('Confirm Deletion');
                                                                    confirmationDialogRef.current.setMessage(`Are you sure you want to delete "${item?.campaign?.campaign_title}" mapping ?`);
                                                                    confirmationDialogRef.current.setButtonLabel("Delete");
                                                                    confirmationDialogRef.current.openDialog(item?.id);
                                                                }}
                                                                    className="cursor-pointer fa-solid fa-trash me-2"></i>
                                                                <i
                                                                    onClick={() => {
                                                                            formMappingDialogRef.current?.handleShow(item);
                                                                        }}
                                                                    title='Edit' className="fa-solid fa-pen-to-square me-2 cursor-pointer"></i>

                                                            </td>
                                                        </tr>
                                                    })
                                                }

                                            </tbody>

                                        </table> : ''
                                }
                                {/* no data message */}
                                {

                                    !requestLoader && formMappingList?.length === 0 ?
                                        <NoDataComponent message={'No Integrations Found'} /> : ''
                                }

                            </div>
                        </div>
                    </div>
                    {
                        requestLoader ?
                            <Loader /> : ''
                    }
                </div>
            </div>

            {/* dialog for adding and updating integrations */}
            <AddEditFormIntegration 
            ref={formMappingDialogRef}
            formMappingList={formMappingList}
            // getSelectedIntegration={getSelectedIntegration}
            getAllFormMappings={getAllFormMappings}
            campaignList={campaignList}
             />

             {/* confirmation dialog */}
             <ConfirmationDialog ref={confirmationDialogRef} executeAction={deleteMapping} />
        </>
    );
}

export default FormIntegrations;