import { ENVIRONMENT_CONSTANT } from "./env.constant";

//auth endpoints start
export const AUTHENTICATE_MASJID = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}authentication/verify`;
export const LOGIN = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}authentication/login`;
export const REFRESH_TOKEN = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}authentication/token/refresh`;
export const GET_MASJID_INFO = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}authentication/info`;
export const LOGOUT_USER = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}authentication/logout`;
//auth endpoints end


//campaigns endpoints start
export const CREATE_CAMPAIGN = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/create`;
export const GET_CAMPAIGN_BY_ID = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/get/`;
export const GET_CAMPAIGN_BY_LINK = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/get-by-link/`;
export const UPDATE_CAMPAIGN = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/update/`;
export const GET_ALL_CAMPAIGNS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/getAll`;
export const DELETE_CAMPAIGN = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/delete/`;
export const GET_CAMPAIGN_ICONS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/getAllIcons/`;
export const COPY_CAMPAIGN = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/copy/`;
export const UPDATE_CAMPAIGNS_ORDER = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/bulk-update-order`;
//campaigns endpoints end

//donation endpoints start
export const GET_PAYMENT_INTENT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}stripe/intent`;
export const INITIATE_PAYMENT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/intent/card`;
export const INITIATE_QUICK_PAYMENT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/intent/quick-pay`;
export const UPDATE_PAYMENT_INTENT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}stripe/intent/update`;
export const SAVE_PAYMENT_INFO = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/save`;
export const GET_ALL_DONATIONS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/getAll`;
export const GET_DONATION_BY_ID = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/get/`;
export const GET_RAISED_DONATIONS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/getRaisedStats`;
export const GET_DONATION_STATS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/stats`;
export const DOWNLOAD_FILE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/generate`;
export const UPDATE_DONATION_STATUS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/update-status/`;
export const DELETE_OFFLINE_DONATION = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/offline/delete/`;
export const GET_TOP_THREE_DONORS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/top-three-donors/`;
//donation endpoints end

//donation pledge endpoint start
export const SAVE_DONATION_PLEDGE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-donations/save`;
export const GET_ALL_PLEDGES = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-donations/getAll`;
export const GET_PLEDGE_STATS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-donations/getStats`;
export const GET_PLEDGE_DETAIL = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-donations/get/`;
export const DOWNLOAD_PLEDGE_CSV = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-donations/generate`;
export const UPDATE_PLEDGE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-donations/update/`;
export const ADD_DONATION_FOR_PLEDGE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-donations/add-donation/`;
export const RESEND__PLEDGE_EMAIL = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-donations/resend-email/`;
//donation pledge endpoint end

//pledge email template endpoints start
export const GET_PLEDGE_EMAIL_TEMPLATE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-email-receipt/get/`;
export const SAVE_PLEDGE_TEMPLATE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pledge-email-receipt/upsert`;
//pledge email template endpoints end

//organization endpoints start
export const GET_ORGANIZATION_BY_ID = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}organization/get`;
export const ADD_UPDATE_ORGANIZATION_BY_MASJID_ID = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}organization/update/info`;
export const FIND_ORG_SHORT_NAME = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}organization/find/short-name/`;
//organization endpoints end

//email receipt endpoints start
export const SAVE_EMAIL_RECEIPT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation-email-receipt/save`;
export const GET_EMAIL_RECEIPT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation-email-receipt/get/`;
export const SEND_EMAIL = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation-email-receipt/send-email`;
export const RESEND_EMAIL = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation-email-receipt/resend-receipt`;
//email receipt endpoints end

//devices endpoints start
export const GET_REGISTERED_DEVICES = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}devices/get-all-devices`;
export const REGISTER_DEVICE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}devices/register`;
export const UPDATE_DEVICE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}devices/update/`;
export const DELETE_DEVICE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}devices/delete/`;
export const GET_DEVICE_BY_ID = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}devices/get/`;
//devices endpoints end

//tiles endpoints start
export const GET_TILES = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}tiles/get-all-tiles`;
export const DELETE_TILE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}tiles/delete/`;
export const ADD_TILE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}tiles/add`;
export const UPDATE_TILE = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}tiles/update/`;
//tiles endpoints end

//device media assigning apis start
// export const GET_ASSIGNED_TILES = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}devices/get-assigned-tiles/`;
export const GET_ASSIGNED_TILES = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}tiles/get-assigned-tiles/`;
export const GET_UNASSIGNED_TILES = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}tiles/get-unassigned-tiles/`;
export const ASSIGN_TILES = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}tiles/assign-tiles/`;
//device media assigning apis end

//endpoints for recurring plans start
export const GET_ALL_RECURRING_PLANS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}recurring-donations/getAll`;
export const GET_ACTIVE_PLANS_COUNT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}recurring-donations/active-plans`;
export const DOWNLOAD_RECURRING_PLANS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}recurring-donations/generate`;
export const UPDATE_RECURRING_OPTION = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}recurring-donations/update-recurring-option/`;

export const GET_PLAN_DETAIL = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}recurring-donations/detail/`;
export const GET_PLAN_DONATIONS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}recurring-donations/donations/`;
export const CHANGE_PLAN_STATUS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}recurring-donations/change-status`;
//endpoints for recurring plans end

//endpoints for subscribers start
export const GET_ALL_SUBSCRIBERS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}subscribers/getAll`;
export const DOWNLOAD_SUBSCRIBERS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}subscribers/generate`;
export const DELETE_SUBSCRIBER = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}subscribers/delete/`;
export const ADD_SUBSCRIBER = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}subscribers/add`;
//endpoints for subscribers end

//endpoints for supporters start
export const GET_ALL_SUPPORTERS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}supporters/getAll`;
export const DOWNLOAD_SUPPORTERS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}supporters/generate`;
export const FIND_DONOR_BY_EMAIL = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}supporters/find-donor`;
export const FIND_DONOR_BY_ID = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}supporters/find/`;
export const UPDATE_DONOR = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}supporters/update/`;
export const ADD_OFFLINE_DONATION = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}donation/offline`;
//endpoints for supporters end

//endpoints for products start
export const ADD_EDIT_PRODUCT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}products/upsert`;
export const GET_ALL_PRODUCTS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}products/getAll/`;
export const GET_PRODUCT_BY_ID = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}products/get/`;
export const GET_PRODUCT_BY_LINK = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}products/get-by-link/`;
export const DELETE_PRODUCT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}products/delete/`;
export const COPY_PRODUCT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}products/copy/`;
//endpoints for products end

//endpoints for store page settings start
export const SAVE_STORE_SETTINGS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}store/save`;
export const GET_SETTINGS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}store/settings/`;
export const GET_SETTINGS_BY_ID = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}store/settings/edit/`;
export const GET_ALL_PAGES = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}store/get/pages`;
//endpoints for store page settings end

//endpoints for yearly receipts start
export const SAVE_YEARLY_RECEIPT_SETTINGS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}yearly-receipts/save`;
export const GET_YEARLY_RECEIPT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}yearly-receipts/get`;
export const GET_DONORS_RECEIPTS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}supporters/receipts`;
export const GENERATE_PDF = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}yearly-receipts/download`;
export const SEND_YEARLY_RECEIPT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}yearly-receipts/send`;
//endpoints for yearly receipts end


//endpoints for integrations start
export const ADD_INTEGRATION = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}integrations/add`;
export const DELETE_INTEGRATION = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}integrations/delete/`;
export const GET_ALL_INTEGRATIONS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}integrations/getAll`;
//endpoints for integrations end

//endpoints for jotforms mapping start
// export const GET_USER_FORMS  = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}jotforms/get-user-forms/`;
// export const GET_FORM_FIELDS  = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}jotforms/get-form-fields/`;

export const SAVE_FORM_MAPPING = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}jotforms/save`;
export const GET_ALL_MAPPINGS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}jotforms/getAll`;
export const DELETE_FORM_MAPPING = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}jotforms/delete/`;
export const GET_FORM_SUBMISSION = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}jotforms/get/submission/`;
//endpoints for jotforms mapping end

//endpoints for announcements start
export const SAVE_ANNOUNCEMENT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}announcements/save`;
export const GET_ALL_ANNOUNCEMENTS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}announcements/getAll`;
export const DELETE_ANNOUNCEMENT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}announcements/delete/`;
export const GET_LATEST_ANNOUNCEMENT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}announcements/getLatestAnnouncement`;
export const MARK_ANNOUNCEMENT_AS_READ = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}announcements/markasread/`;
//endpoints for announcements end

//uploading file endpoints start
export const UPLOAD_FILE_URL = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}aws-s3/get-presigned-url`;
//uploading file endpoints end

//filters data endpoints for campaigns start
export const CAMPAIGNS_NAMES = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/names/`;
//filters data endpoints for campaigns end

//pusher event apis
export const SEND_PUSHER_EVENT = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pusher/send`;
export const AUTHENTICATE_PUSHER = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}pusher/authorize/channel/`;

//public endpoints start
export const GET_STORE_ITEMS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/public/`;
export const GET_COMPLETED_ITEMS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}campaigns/public/completed/`;
//public endpoints end

//endpoint for fundraising start
export const SAVE_FUNDRAISING_SETTINGS = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}fundraising/save`;
export const GET_ALL_FUNDRAISING = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}fundraising/get`;
//endpoint for fundraising end

//server events start
export const FUNDRAISING_STREAM = `${ENVIRONMENT_CONSTANT.API_ENDPOINT}events/stream/`;
//server events end