import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

function DonorAddressInfo(props) {
    
    const {register, formState: {errors}} = useFormContext();

    return (
        <Accordion alwaysOpen defaultActiveKey={['0']}>
            <div className='row'>
                <div className='col-12'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <span className='text-bold text-black'>Address & Contact Detail</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='row'>
                                <div className='col-xxl-4 col-12 mt-2'>
                                    <div className="form-group"><label className="form-control-label text-black " >
                                        Address
                                    </label>
                                        <input
                                          {...register('donor_info.address')}
                                            placeholder="Enter address" type="text"
                                            className="form-control-alternative form-control " />
                                    </div>
                                </div>

                                <div className='col-xxl-4 col-12 mt-2'>
                                    <div className="form-group"><label className="form-control-label text-black " >
                                        Address 2
                                    </label>
                                        <input
                                        {...register('donor_info.address_2')}
                                            placeholder="Enter address 2" type="text"
                                            className="form-control-alternative form-control " />
                                    </div>
                                </div>

                                <div className='col-xxl-4 col-12 mt-2'>
                                    <div className="form-group"><label className="form-control-label text-black " >
                                        City
                                    </label>
                                        <input
                                        {...register('donor_info.city')}
                                            placeholder="Enter city" type="text"
                                            className="form-control-alternative form-control " />
                                    </div>
                                </div>

                                <div className='col-xxl-4 col-12 mt-2'>
                                    <div className="form-group"><label className="form-control-label text-black " >
                                        State
                                    </label>
                                        <input
                                        {...register('donor_info.state_province')}
                                            placeholder="Enter state" type="text"
                                            className="form-control-alternative form-control " />
                                    </div>
                                </div>

                                <div className='col-xxl-4 col-12 mt-2'>
                                    <div className="form-group"><label className="form-control-label text-black " >
                                        Zip Code
                                    </label>
                                        <input
                                        {...register('donor_info.postal_code')}
                                            placeholder="Enter zip code" type="text"
                                            className="form-control-alternative form-control " />
                                    </div>
                                </div>

                                <div className='col-xxl-4 col-12 mt-2'>
                                    <div className="form-group"><label className="form-control-label text-black " >
                                        Country
                                    </label>
                                        <input
                                        {...register('donor_info.country')}
                                            placeholder="Enter country" type="text"
                                            className="form-control-alternative form-control " />
                                    </div>
                                </div>

                                <div className='col-xxl-4 col-12 mt-2'>
                                    <div className="form-group"><label className="form-control-label text-black " >
                                        Phone Number
                                    </label>
                                        <input
                                        {...register('donor_info.phone')}
                                            placeholder="Enter phone number" type="text"
                                            className="form-control-alternative form-control " />
                                    </div>
                                </div>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </div>
            </div>
        </Accordion>
    );
}

export default DonorAddressInfo;