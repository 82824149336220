import React, { useState } from "react";
import { useSquarePayment } from "./hooks/useSquarePayment";
import { PAYMENT_METHOD_TYPE } from "../../../../constants/constant";
import SquareCard from "./card/square-card";

const SquarePaymentForm = ({
  applicationId,
  locationId,
  onPaymentComplete,
}) => {
  const { tokenizeCard, errorMessage, ach, googlePay } = useSquarePayment(applicationId,locationId, PAYMENT_METHOD_TYPE.GOOGLE_PAY);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handlePayment = async (event) => {
    debugger;
    event.preventDefault();
    setError("");
    setIsLoading(true);

    const token = await tokenizeCard();

    if (token.status === "ERROR") {
      setError(token.message);
      setIsLoading(false);
    } else {
      // Call backend API to process the payment with the token
      try {
        alert(`Card detail captured your payment token is: ${token}`)
        // const response = await fetch('/process-payment', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ token }),
        // });
        // const data = await response.json();
        // if (data.errors) {
        //   setError('Payment failed');
        // } else {
        //   onPaymentComplete(data);
        // }
      } catch (error) {
        setError("Payment processing error");
      }
      setIsLoading(false);
    }
  };

  const tokenize = async (paymentMethod, options = {}) => {
    debugger
    paymentMethod.addEventListener(
      'ontokenization',
      async function (event) {
        const { tokenResult, error } = event.detail;
        if (error !== undefined) {
          let errorMessage = `Tokenization failed with error: ${error}`;
          throw new Error(errorMessage);
        }
        if (tokenResult.status === 'OK') {
        //   const paymentResults = await createPayment(tokenResult.token);
        //   displayPaymentResults('SUCCESS');

        //   console.debug('Payment Success', paymentResults);
        } else {
          let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
          if (tokenResult.errors) {
            errorMessage += ` and errors: ${JSON.stringify(
              tokenResult.errors,
            )}`;
          }
          throw new Error(errorMessage);
        }
      },
    );
    await paymentMethod.tokenize(options);
  }

  async function handlePaymentMethodSubmission(
    event,
    paymentMethod,
    options,
  ) {
    debugger
    event.preventDefault();

    try {
    //   achButton.disabled = true;
      await tokenize(paymentMethod, options);
    } catch (e) {
    //   achButton.disabled = false;
    //   displayPaymentResults('FAILURE');
      console.error(e.message);
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-6 offset-3">
          {errorMessage && <p className="mb-3 text-danger">{errorMessage}</p>}
          <div id="square-payment-container"></div>
          {/* <button className="btn btn-primary" onClick={handlePayment} disabled={isLoading}>
            {isLoading ? "Processing..." : "Pay"}
          </button> */}
          {/* <button onClick={(event) => {
            const paymentForm = document.getElementById('payment-form');
            handlePaymentMethodSubmission(event, ach, {
                accountHolderName: 'Wasim Essani',
                intent: 'CHARGE',
                total: { amount: 100, currencyCode: 'USD' },
              });
          }} className="btn btn-primary">Direct Bank Transfer</button> */}
          {error && <p className="error text-error">{error}</p>}
        </div>
      </div>
      <div className="row">
      <div onClick={(event) => {
         handlePaymentMethodSubmission(event, googlePay);
      }} id="google-pay-button"></div>
      <div onClick={(event) => {
         handlePaymentMethodSubmission(event, googlePay);
      }} id="apple-pay-button"></div>
      {/* <div id="apple-pay-button"></div> */}
      </div>
    </div>

    // <div className="row">
    //     <div className="col-6 offset-3">
    //         <SquareCard />
    //     </div>
    // </div>
  );
};

export default SquarePaymentForm;
