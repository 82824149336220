import axios from 'axios';
import {useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import '../campaigns/campaigns.component.scss'
import { Dropdown} from 'react-bootstrap';
import getFormattedDate from '../../../utils/date-format';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';
import Loader from "../../../shared/loader/loader";
import LinkQRCode from '../../../shared/qr-code/qr-code';
import useRemoveQRCode from '../../../hooks/useRemoveQRCodeHook';
import { GET_ALL_FUNDRAISING, GET_ALL_PAGES } from '../../../constants/endpoints';
import { getSetOrgInfo, getUserInfo } from '../../../utils/user-info';
import AssignCampaign from '../fundraising/dialogs/assign-campaign';

const Pages = () => {
    
    //props
    // const userInfo = getUserInfo();
    const orgInfo = getSetOrgInfo();
    const assignCampaignDialogRef = useRef(null);
    const [fundraisingSettings, setFundraisingSettings] = useState({});
    const [pages, setPages] = useState([
        {
            "title": "Campaign Page",
            edit_form_link: '/layout/edit-store-page/campaign',
            type:'campaign'
        },
        {
            "title": "Product Page",
            edit_form_link: '/layout/edit-store-page/product',
            type:'product'
        },
        {
            "title": "Donation Page",
            edit_form_link: '/layout/edit-store-page/both',
            type:'both'
        },
        {
            "title": "Live Fundraising Page",
            edit_form_link: '',
            type:'fundraising'
        },
    ]);
    const [qrCode,  setQrCode] = useState("");
    const removeQRCode = useRemoveQRCode(setQrCode);

    //methods
    const getFundraising = () => {
      axios.get(GET_ALL_FUNDRAISING)
      .then((res) => {
          if(res?.data?.status){
            setFundraisingSettings(res?.data?.response);
          }
      }).catch((error) => {

      })
  }

  useEffect(() => {
      getFundraising()
  }, []);
    //html side start
    return (
      <>
        <div className="row">
          {/* <ModalComponent /> */}
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                <h5 className="text-black">Links</h5>
              </div>
            </div>

            <div className="row position-relative">
              {pages.map((item, index) => {
                return (
                  <div key={index} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                    <div className="campaign-card">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className="mb-0">
                            <a
                              href={item?.edit_form_link}
                              target="_self"
                              className="mb-0 f-16 text-bold"
                            >
                              {item.title}
                            </a>
                          </p>
                        </div>

                        <div className="d-flex justify-content-end align-items-baseline">
                          <Link
                            to={item?.type !== 'fundraising' ? item?.edit_form_link : null}
                            onClick={() => item?.type === 'fundraising' ? assignCampaignDialogRef?.current?.handleShow(fundraisingSettings,'Campaign') : ''}
                            className="me-2 cursor-pointer camp-actions"
                          >
                            <i className="fa-regular fa-pen-to-square f-14"></i>{" "}
                            <span className="d-none d-md-none d-lg-inline-block f-14">
                              Edit Page
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="mt-2 campaign-title">
                        <a
                        target='_blank'
                          className="text-light-blue"
                          href={
                            item?.type !== 'fundraising' ? 
                            `${orgInfo?.organization_short_name}${item?.type !== 'both' ? ('/'+item?.type) : ''}` : 
                            fundraisingSettings?.id ? `/fundraising/${fundraisingSettings?.organization?.organization_short_name}/${fundraisingSettings?.campaign?.donation_page_link}`
                             : '/fundraising/test/test'
                          }
                        >
                          Preview Page {">"}{" "}
                        </a>
                      </div>

                      {/* <div className="mt-3">
                        <div>
                          <span className="f-14">Amount Raised</span>
                        </div>
                        <div>
                          <a href={null} className="f-16">
                            ${item?.total_amount ? item?.total_amount : 0}
                            <span className="text-light-blue mx-1">
                              ({item?.total_donations} donation)
                            </span>
                          </a>
                        </div>
                      </div> */}

                      <div className="row mt-3">
                        {/* <div className="col-xl-4 mt-2">
                          <a
                            href={`/layout/email-receipt/${item?.product_id}`}
                            className="btn-action w-100 d-flex justify-content-start align-items-center cursor-pointer"
                          >
                            <span className="me-2">
                              <i className="fa-solid fa-envelope-open-text"></i>
                            </span>
                            <span>Receipt Emails</span>
                          </a>
                        </div> */}
                        {
                          item?.type !== 'fundraising' ?
                        
                        <div className="col-xl-12 mt-2 ">
                          <a onClick={(e) => {
                          e.stopPropagation();
                          item?.type !== qrCode ? 
                          setQrCode(item?.type) : setQrCode('')
                        }} className="btn-action w-100 d-flex justify-content-start align-items-center cursor-pointer">
                            <span className="me-2">
                              <i className="fa-solid fa-qrcode"></i>
                            </span>
                            <span>QR Code</span>
                          </a>
                          <div className='position-relative'>
                          <LinkQRCode
                          url={`${window.location.origin}/${orgInfo?.organization_short_name}${item?.type !== 'both' ? ('/'+item?.type) : ''}`}
                          name={item?.product_campaign_title}
                          qrCode={qrCode}
                          id={item?.type}/>
                          </div>
                        </div> : ''}
    
                        {/* <div className="col-xl-4 mt-2">
                          <Link
                            to={`/offline-donation/${item?.product_id}/${item.product_campaign_title}/product`}
                            className="btn-action w-100 d-flex justify-content-start align-items-center cursor-pointer"
                          >
                            <span className="me-2">
                              <i className="fa-solid fa-money-bill"></i>
                            </span>
                            <span>Add Offline Donation</span>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
              
              {/* {
                requestLoader ? 
                <Loader /> : 
              !requestLoader && pages?.length === 0 ? (
                <div className="text-center mt-3">
                  <i
                    style={{ fontSize: "4rem" }}
                    className="fa-solid fa-list-ul"
                  ></i>
                  <p>
                    No pages available!
                  </p>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </div>

        {/* assign campaign dialog */}
        <AssignCampaign ref={assignCampaignDialogRef} callback={getFundraising} />
      </>
    );
    //html side end
}

export default Pages