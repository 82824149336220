import axios from 'axios';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { GET_CAMPAIGN_BY_ID, UPDATE_CAMPAIGN } from '../../../../../constants/endpoints';
import { boolean, string, z, ZodObject } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import FieldError from '../../../../../shared/fields-errors/field-error';
import AmountForm from './amount-form';
import DonorInfoSettings from './donor-info-settings';
import PaymentSuccessSettings from './payment-success-settings';
import DonationDesignation from './donation-designation';
import CampaignOptions from './campaign-options';
import MailingList from './mailing-list';
import { getSetOrgInfo } from '../../../../../utils/user-info';
import UploadImageDialog from '../../../../../shared/upload-image-dialog/upload-image-dialog';
import useUploadFile from '../../../../../hooks/upload-file.hook';
import DonorCommentsForm from './donor-comments-form';
import RecurringOptions from './recurring-options';

const CampaignForm = React.forwardRef((props, ref) => {

    //getting query param
    const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT;
    const search = window.location.search;
    const queryParam = new URLSearchParams(search);
    const formType = queryParam?.get('form_type') ? queryParam?.get('form_type') : 'campaign';

    const {uploadImage} = useUploadFile();
    const uploadImageDialogRef = useRef(null);
    const [fileName, setFileName] = useState("");
    const [currentFile, setCurrentFile] = useState(null);

    const navigate = useNavigate();
    const [updateCampaignLoader, setUpdateCampaignLoader] = useState(false);
    const params = useParams();
    var campaignID = params?.id;
    var title = params?.title;
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const organizationInfo = getSetOrgInfo();

    useImperativeHandle(ref, () => {
        return {
            previewChanges: previewChanges
        };
      });

    const schema = z.object(
        {
            "campaign_title": string().min(1, 'Campaign title is required.'),
            // "goal_amount": string().min(1, 'Goal amount is required.'),
            "goal_amount": string(),
            "primary_color": string().default("#015B97"),
            "google_apple_pay": boolean(),
            "one_time_interval": boolean(),
            "daily_interval": boolean(),
            "weekly_interval": boolean(),
            "biweekly_interval": boolean(),
            "monthly_interval": boolean(),
            "quarterly_interval": boolean(),
            "annaully_interval": boolean(),
            "default_interval":string(),
            "is_call_to_action": boolean(),
            // "call_to_action_message": string(),
            "is_donation_designation": boolean(),
            "designation_label": string(),
            "require_to_select": boolean(),
            "no_designation_label": string(),
            // "designation_dropdown_values": string(),
            // "quick_donate": boolean(),
            "donor_comments": boolean()
        }
    );

    //old logic
    // const methods = useForm({
    //     defaultValues:{one_time_interval:true,designation_dropdown_value:[{value:''}]},
    //     resolver: zodResolver(schema)
    // });

    const methods = useForm();
    const { register,control, trigger,setValue, handleSubmit,unregister, reset, formState: {isValid, errors }, getValues,watch } = methods;


    const { fields, remove, append } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "designation_dropdown_value", // unique name for your Field Array
      });

    // const isDonationDesignation = register("is_donation_designation")
    const requireToSelect = register("require_to_select",{value:false}); 


    //props for donation amount form start
     const amountArray = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "donation_amount.amount_intervals", // unique name for your Field Array
      });

    const intervalAmountList = useFieldArray({
        control,
        name:'amount_list'
    })
    //props for donation amount form end
    //watching all fields
    const watchAllFields = watch();

    //registering controls that required custom handling
    const colorControl = register("primary_color");
    // const callToActionMessage = register("call_to_action_message",{value:''});
    const isCallToAction = register("is_call_to_action");
    
    // const designationLabel = register("designation_label",{value:''}); 
    
    // const noDesignationLabel = register("no_designation_label",{value:''});

            const one_time_interval = register("one_time_interval");
            const daily_interval = register("daily_interval");
            const weekly_interval = register("weekly_interval");
            const weekly_jumuah_only = register("weekly_jumuah_only");
            const biweekly_interval = register("biweekly_interval");
            const monthly_interval = register("monthly_interval");
            const quarterly_interval = register("quarterly_interval");
            const annaully_interval = register("annaully_interval");
    // const designationDropdown = register("designation_dropdown_values",[{value:''}]); 

    const getCampaignByID = () => {

        axios.get((`${GET_CAMPAIGN_BY_ID}${campaignID}/?form_type=${formType}`))
            .then((res) => {

                if (res?.data?.response?.id) {
                    setSelectedCampaign(res?.data?.response);
                    setFileName(res?.data?.response?.image_alt_name);
                    props?.setCampaignTitle(res?.data?.response?.campaign_title);
                    document.getElementById('txtColor').value = (res?.data?.response?.primary_color ? 
                        res?.data?.response?.primary_color : '');
                    // createFormSchema()
                }
            }).catch((error) => {
                // alert('Failed to get campaign details')
            });
    }

    const cancelCreateCampaign = () => {
        navigate(`/${formType}s`);
        // let text = "Changes you have so far will not be saved.";
        // if (window.confirm(text) === true) {
        //     text = "Canceled.";
        //     navigate(`/${formType}s`);
        // }

    }

    const setColor = (event) => {
        document.getElementById('txtColor').value = event.target.value;
        // reset({
        //     primary_color: event.target.value
        // });
    }

    const addRemoveField = (index) => {
        if(index >= 0){
            remove(index);
        }else{
            append({value:''});
        }
    }

    useEffect(() => {
        getCampaignByID();
    }, [])

    useEffect(() => {
        // reset({campaign_title:title});
        if (selectedCampaign?.id ) {
            if(typeof(selectedCampaign?.designation_dropdown_value) === 'string' && selectedCampaign?.designation_dropdown_value?.split(',')?.length > 0){
                selectedCampaign.designation_dropdown_value = 
                selectedCampaign.designation_dropdown_value.split(',').map(it => Object.assign({},{value:it}))    
            }

            //if header background color is empty then set default value
            selectedCampaign.header_background_color = selectedCampaign?.header_background_color ? selectedCampaign?.header_background_color : '#015B97'

            //setting up intervals
            let list = [];
            if(selectedCampaign?.one_time_interval){
                list.push('One-Time')
            }
            if(selectedCampaign?.weekly_jumuah_only){
                setDisableIntervals(true);
                list.push('Every-Jumuah')
            }
            if(selectedCampaign?.daily_interval){
                list.push('Daily')
            }
            if(selectedCampaign?.weekly_interval){
                list.push('Weekly')
            }
            if(selectedCampaign?.monthly_interval){
                list.push('Monthly')
            }
            if(selectedCampaign?.quarterly_interval){
                list.push('Quarterly')
            }
            if(selectedCampaign?.annaully_interval){
                list.push('Annually')
            }

            setDefaultIntervalList(list);

            for (let index = 0; index < list.length; index++) {
                let currentIndex = selectedCampaign.donation_amount.amount_intervals.findIndex(it => it.type === list[index])
                if(currentIndex === -1){
                    selectedCampaign.donation_amount.amount_intervals.push({
                        type:list[index],
                        amount_list:[]
                    });   
                }
            }

            for (let index = 0; index < selectedCampaign?.donation_amount?.amount_intervals.length; index++) {
                selectedCampaign?.donation_amount?.amount_intervals[index]?.amount_list.sort((a,b) => {
                    return a.amount - b.amount
                });
            }

            // selectedCampaign.active_campaign_for_kiosk = true;
            
            if(!selectedCampaign?.recurring_option){
                selectedCampaign.recurring_option = 'none'
            }
            reset(selectedCampaign);
            // let formArray = [];
            // for (let index = 0; index < list.length; index++) {
            //     let item = {
            //         type: list[index]
            //     }

            //     formArray.push(item);
                
            // }
            // amountArray.append(formArray);
        }
    }, [selectedCampaign]);


    const updateCampaign = async (formValues, isPreview) => {
        setUpdateCampaignLoader(true);
        if(formValues?.feature_campaign){
            if(currentFile){
                const fileUploadResponse = await uploadImage(currentFile);
                if(fileUploadResponse?.image_alt_name){
                    formValues.image_location = fileUploadResponse?.image_location;
                    formValues.image_alt_name = fileUploadResponse?.image_alt_name;
                }else{
                    alert("Failed to upload file");
                    setUpdateCampaignLoader(false);
                    return;
                }
            }else if(!selectedCampaign?.image_location){
                alert("Please select image for featured product.");
                setUpdateCampaignLoader(false);
                return
            }
        }

        formValues.campaign_icon = childRef.current.selectedSource;
        if(!formValues.campaign_icon){
            formValues.is_campaign_icon = false;
        }
        
        axios.put((`${UPDATE_CAMPAIGN}${campaignID}/?form_type=${formType}`), formValues)
        .then((res) => {
            if(res?.data?.status){
                if(isPreview === true){
                    previewChanges()
                }else{
                    navigate(`/${formType}s`);    
                }
                
            }else{
                alert(res?.data?.response);
            }
        }).catch((error) => {
            alert('Unable to update campaign information.');
            // navigate(`/${formType}s`);
        }).finally((res) => {
            setUpdateCampaignLoader(false);
        })
    }

    const previewChanges = () => {
        window.open(`${
            organizationInfo?.organization_short_name ?
            organizationInfo?.organization_short_name: 'masjid'
        }/campaign/${selectedCampaign?.donation_page_link}`, '_blank').focus();
    }

    const updateAndPreview = () => {
        if(!isValid){
            trigger();
        }else{
            updateCampaign(getValues(),true)
        }
        
    }


    const onErrors = (error) => {
        console.log(error)
    }

    const [defaultIntervalList, setDefaultIntervalList] = useState([]);
    const [disableIntervals, setDisableIntervals] = useState(false);
    const pushIntervals = (e, value) => {
        if(e?.target?.checked){
            if(value === 'Every-Jumuah'){
                setDisableIntervals(true);
                if(watchAllFields?.one_time_interval){
                    setDefaultIntervalList([value, 'One-Time']);
                }else{
                    setDefaultIntervalList([value]);
                }
                const timer = setTimeout(() => {
                    setValue("default_interval", value);
                    clearTimeout(timer);
                }, 100);
                // setValue("donation_amount.amount_intervals", []);
                // first checking if interval is already exists
                let index = watchAllFields.donation_amount.amount_intervals.findIndex(it => it.type === value);
                if(index === -1){
                    let item = {
                        type: value
                    }
                    amountArray.append(item);
                }
                // setValue("one_time_interval", false);
                setValue("daily_interval", false);
                setValue("weekly_interval", false);
                setValue("biweekly_interval", false);
                setValue("monthly_interval", false);
                setValue("quarterly_interval", false);
                setValue("annaully_interval", false);
            }else{
                if(defaultIntervalList.length === 4){
                    e.target.checked = false;
                    e.preventDefault();
                    alert('You can only select a maximum of four intervals.');
                    return
                }else{
                    setDefaultIntervalList([...defaultIntervalList,value]);
                    const timer = setTimeout(() => {
                        setValue("default_interval", value);
                        clearTimeout(timer);
                    }, 100);
                    // first checking if interval is already exists
                    let index = watchAllFields.donation_amount.amount_intervals.findIndex(it => it.type === value);
                    if(index === -1){
                        let item = {
                            type: value
                        }
                        amountArray.append(item);
                    }
                }
            }
            
        }else{
            if(value === 'Every-Jumuah'){
                setValue("weekly_jumuah_only", false);
                setDisableIntervals(false);
            }
            let index = defaultIntervalList.indexOf(value);
            if(index !== -1){
                defaultIntervalList.splice(index,1);
                if(value === watchAllFields?.default_interval){
                    setValue('default_interval', defaultIntervalList[0] ? defaultIntervalList[0] : "")
                }
            }
            setDefaultIntervalList(defaultIntervalList);
            //removing interval from amount form array
            let formIndex = amountArray.fields.findIndex(it => it.type === value);
            let removedRes = formIndex !== -1 ? amountArray.remove(formIndex) : '';
        }
    }

    const toggleDynamicValidation = (e, field) => {
        
        if(!e?.target?.checked){
            // reset({...getValues(),call_to_action_message:undefined});
            unregister([field]);
        }else{
            register(field,{value:'', required:true});
        }

        // console.log(getValues())
    }


    const childRef = useRef(null);
    return (
        <>
        <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(updateCampaign,onErrors)} 
        className='col-xl-5 col-xl-55 col-md-12 col-sm-12 col-xs-12 left-form-panel p-0'>
            <div className='form-title text-white d-flex justify-content-start align-items-center bg-blue p-3'>
                <a onClick={cancelCreateCampaign}
                    className='text-white cursor-pointer'><i className="fa-solid fa-chevron-left me-2"></i></a>
                <h5 className='text-white mb-0 text-capitalize'>{formType}</h5>
            </div>

            <div className='campaign-form-body p-4'>
                <div className='col-12 card'>
                    <div className='card-body'>
                        <div className="form-group"><label className="form-control-label text-black text-bold text-capitalize" >
                        <span className='text-danger'>*</span> {formType} Title 
                        </label>
                        <input
                                {...register("campaign_title",{required:true})}
                                placeholder={`${formType === 'membership' ? 'Membership' : 'Campaign'} Title`}
                                type="text" className="form-control-alternative form-control" />
                                </div>
                        {errors?.campaign_title && errors?.campaign_title?.type === 'required' ? <FieldError message={'Please fill out this field'} /> : ''}
                        {
                            formType === 'campaign' ?
                            <div className='form-group mt-2 d-flex justify-content-between align-items-center'>
                                <label className="form-control-label d-flex align-items-center">
                                    <input
                                        {...register(`feature_campaign`)}
                                        className="me-1" type="checkbox" /> Featured Campaign
                                </label>
                                <div>
                                {
                                    fileName ? 
                                    <>
                                    <span className='f-16 text-ma-primary mt-2'>{fileName}</span> 
                                    <a 
                                    onClick={() => {
                                        if(currentFile){
                                            uploadImageDialogRef?.current?.setImageSrcByFile(currentFile);
                                        }else{
                                            uploadImageDialogRef?.current?.setImageSrcByField(selectedCampaign);
                                        }
                                    }}
                                    className='mx-2 anchor cursor-pointer'>Change Image</a>
                                    </>
                                     : watchAllFields?.feature_campaign ? 
                                     <button type='button' onClick={uploadImageDialogRef?.current?.handleShow}
                                    className='btn btn-primary mb-0'>
                                       {fileName ? 'Change Image' : 'Upload Image'}
                                    </button> : ''
                                }
                                
                                </div>
                            </div> : ''
                        }

                            {
                                watchAllFields?.feature_campaign ? 
                                <>
                                <div className='form-group mt-3'>
                                <label className="form-control-label d-flex align-items-center">
                                    Featured Campaign Description
                                </label>
                                <textarea
                        {...register("featured_campaign_desc", {
                            validate: {
                                required: (value) => {
                                    if(!value)return "This field is required";
                                    return true
                                }
                            }
                        })}
                            rows={5} placeholder="Enter Description" className="form-control-alternative form-control" ></textarea>
                            </div>
                            {errors?.featured_campaign_desc ?
                                <FieldError message={errors?.featured_campaign_desc?.message} /> : ''}
                                </> : ''
                            }
                        {/* <div className='mt-2'>
                            <input {...register("is_display_campaign_title",{
                                value: false
                            })} className='me-1' type="checkbox" />
                            <label>
                                Hide Campaign Title
                            </label>
                        </div>
                        <div className='mt-1'>
                            <input {...register("active_campaign_for_kiosk",{
                                value: true
                            })} className='me-1' type="checkbox" />
                            <label>
                                Active Campaign for Kiosk
                            </label>
                        </div> */}
                        
                    </div>
                </div>

                {/* campaign options view start */}
                <CampaignOptions  ref={childRef} selectedCampaign={selectedCampaign} />
                {/* campaign options view end */}

                {/* donor comments start */}
                <DonorCommentsForm />
                {/* donor comments end */}
                {
                    formType === 'campaign' ?
                    <div className='col-12 card mt-3'>
                    <div className='card-body'>
                        <div className="form-group"><label className="form-control-label text-black text-bold" >
                            Goal Amount (Optional)
                        </label><input
                                {...register("goal_amount")}
                                placeholder="Goal Amount"
                                type="number" className="form-control-alternative form-control" /></div>
                    </div>
                </div> : ''
                }
                <div className='col-12 card mt-3'>
                    <div className='card-body'>
                        <div className="form-group"><label className="form-control-label text-black text-bold" >
                            Primary Color
                            <p className='f-14 mb-0 text-secondary'>Pick a color or change the hexadecimal code</p>
                        </label>

                            <div className='d-flex justify-content-start align-items-center'>
                                <div className='w-20 me-2'>
                                    <input type="color" 
                                    {...colorControl}
                                    onChange={e => {
                                        colorControl.onChange(e);
                                        setColor(e);
                                    }} className='form-control w-100' style={{ height: '2.5rem' }} />
                                </div>

                                <div className='w-80'>
                                    <input id='txtColor'
                                     onKeyUp={(e) => {
                                        if(e.target.value){
                                            setValue('primary_color',e.target.value)
                                        }
                                    }}
                                     placeholder="Primary Color"
                                        type="text" className="form-control-alternative form-control" /></div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className='col-12 card mt-3'>
                    <div className='card-body'>
                        {/* <span className='text-black text-bold'>UltraSwift<sup>TM</sup> Checkout</span> */}
                        <span className='text-black text-bold'>Apple & Google Pay</span>
                        <p>
                            Allow donors to speed through checkout in just a few clicks using Apple Pay, Google Pay.
                        </p>
                        <div>
                            <input {...register("google_apple_pay")} className='me-1' type="checkbox" />
                            <label>
                                {/* Apple & Google<sup>pay </sup> Enabled */}
                                Apple & Google Pay
                            </label>
                        </div>
                    </div>
                </div>
                <div className='col-12 card mt-3'>
                    <div className='card-body'>
                        <span className='text-black text-bold'>Donation Intervals</span>
                        {
                            formType === 'campaign' ?
                            <div>
                            <input
                            {...one_time_interval}
                            onChange={e => {
                                one_time_interval.onChange(e)
                                pushIntervals(e,'One-Time')
                            }}
                             className="me-1" type="checkbox" />
                            <label>
                                One-time
                            </label>
                        </div> : ''
                        }
                        { 
                            <div className='mt-1'>
                            <input disabled={disableIntervals}
                             {...daily_interval}
                             onChange={e => {
                                 daily_interval.onChange(e)
                                 pushIntervals(e,'Daily')
                             }}
                             className="me-1" type="checkbox" />
                            <label>
                                Daily
                            </label>
                        </div>
                        }
                        {
                            formType === 'campaign' ? 
                            <div className='mt-1'>
                            <input disabled={disableIntervals}
                            {...weekly_interval}
                            onChange={e => {
                                weekly_interval.onChange(e)
                                pushIntervals(e,'Weekly')
                            }}
                            className="me-1" type="checkbox" />
                            <label>
                               Weekly
                            </label>
                        </div> : ''
                        }
                        {
                            formType === 'campaign' ? 
                            <div className='mt-1'>
                            <input
                            {...weekly_jumuah_only}
                            onChange={e => {
                                weekly_jumuah_only.onChange(e)
                                pushIntervals(e,'Every-Jumuah')
                            }}
                            className="me-1" type="checkbox" />
                            <label>
                               Every Jumuah
                            </label>
                        </div> : ''
                        }
                        {/* <div className='mt-1'>
                            <input {...register("biweekly_interval")} className="me-1" type="checkbox" />
                            <label>
                                Biweekly
                            </label>
                        </div> */}
                        <div className='mt-1'>
                            <input disabled={disableIntervals}
                            {...monthly_interval}
                            onChange={e => {
                                monthly_interval.onChange(e)
                                pushIntervals(e,'Monthly')
                            }}
                            className="me-1" type="checkbox" />
                            <label>
                                Monthly
                            </label>
                        </div>
                        <div className='mt-1'>
                            <input disabled={disableIntervals}
                             {...quarterly_interval}
                             onChange={e => {
                                 quarterly_interval.onChange(e)
                                 pushIntervals(e,'Quarterly')
                             }}
                            className="me-1" type="checkbox" />
                            <label>
                            Quarterly
                            </label>
                        </div>
                        <div className='mt-1'>
                            <input disabled={disableIntervals}
                             {...annaully_interval}
                             onChange={e => {
                                 annaully_interval.onChange(e)
                                 pushIntervals(e,'Annually')
                             }}
                            className="me-1" type="checkbox" />
                            <label>
                                Annually
                            </label>
                        </div>

                        <div className="form-group mt-3"><label className="" >
                            <span className='form-control-label text-black'>Set as Default</span>
                            <p className='mb-0'>The default interval will be pre-selected</p>
                        </label>

                        <select {...register("default_interval",{
                            validate: {
                                required:(value) => {
                                    if(!value) return "The field is required";
                                    return true
                                }
                            }
                        })} className="form-control" placeholder='Set as Default'>
                                    {
                                        defaultIntervalList.map((item,index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                    
                                </select>
                                {errors?.default_interval ? 
                                <FieldError message={errors?.default_interval?.message} /> : ''}
                            </div>
                                
                                {/* recurring options view start */}
                                <RecurringOptions />
                                {/* recurring options view end */}


                                <div className="form-group mt-3"><label className="form-control-label text-black d-flex align-items-center" >
                                <input
                                 {...isCallToAction}
                                 onChange={e => {
                                    isCallToAction.onChange(e)
                                    toggleDynamicValidation(e, 'call_to_action_message')
                                 }}
                                
                                 className="me-1" type="checkbox" /> Call to Action Message
                                </label>
                                {
                                    watchAllFields.is_call_to_action === true ?
                                    <input
                                        {...register('call_to_action_message')}
                                        placeholder="Call to Action Message"
                                        type="text" className="form-control-alternative form-control" /> :
                                        ''
                                }
                                {errors?.call_to_action_message &&
                                errors?.call_to_action_message?.type === 'required' ? 
                                <FieldError message={'Please fill out this field'} /> : ''}
                                
                                        </div>
                                {errors?.campaign_title?.message ? <FieldError message={errors?.campaign_title?.message} /> : ''}
                        {/* <div className="form-group mt-3"><label className="form-control-label text-black" >
                            Recommended Interval
                            <p className='f-12 mb-0'>Interval will be indicated with <i className="fa-solid fa-hand-holding-heart"></i> icon</p>
                        </label><input placeholder="Recommended Interval"
                            type="text" className="form-control-alternative form-control" /></div> */}
                    </div>
                </div>


                {/* donation designation start */}
                <DonationDesignation 
                // isDonationDesignation={isDonationDesignation}
                addRemoveField={addRemoveField} 
                errors={errors}
                requireToSelect={requireToSelect}
                toggleDynamicValidation={toggleDynamicValidation}
                fields={fields}
                watchAllFields={watchAllFields}/>
                {/* donation designation end */}

                <AmountForm
                control={control}
                amountArray={amountArray}
                intervalAmountList={intervalAmountList}
                watchAllFields={watchAllFields}
                errors={errors}
                defaultIntervalList={defaultIntervalList} />

                {/* donor info view start */}
                <DonorInfoSettings/>
                {/* donor info view end */}

                {/* mailing list start */}
                <MailingList />
                {/* mailing list end */}

                {/* payment success message view start */}
                <PaymentSuccessSettings watchAllFields={watchAllFields}/>
                {/* payment success message view end */}


                {/* <div className='col-12 card mt-3'>
                    <div className='card-body'>
                        <span className='text-black text-bold'>Quick Donate</span>
                        <div className='d-flex align-items-start justify-content-start'>
                            <input {...register("quick_donate")} className="me-1 mt-1" type="checkbox" />
                            <label >
                                Allow one-time donors to save their payment info and enjoy one-click payments in the future.
                            </label>
                        </div>
                    </div>
                </div> */}
                {/* <div className='col-12 card mt-3'>
                    <div className='card-body'>
                        <span className='text-black text-bold'>Donor Comments</span>
                        <div className='d-flex align-items-start justify-content-start'>
                            <input {...register("donor_comments")} className="me-1 mt-1" type="checkbox" />
                            <label>
                                Give donors the option to leave comment.
                            </label>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className='campaign-form-footer'>
                <button onClick={cancelCreateCampaign} disabled={updateCampaignLoader} type="button" 
                className="btn btn-outline-secondary mx-3">Cancel</button>
                <div>
                <button disabled={updateCampaignLoader} type="submit" 
                className={`btn btn-ma-primary btn-md me-3 ${updateCampaignLoader ? 'button--loading position-relative ' : ''}`}>
                    Update
                </button>
                <button disabled={updateCampaignLoader} type="button" 
                onClick={updateAndPreview}
                className={`btn btn-ma-primary btn-md me-3 ${updateCampaignLoader ? 'button--loading position-relative ' : ''}`}>
                    Update & Preview
                </button>
                </div>
                
            </div>
        </form>
        </FormProvider>

        {/* upload image dialog start */}
            <UploadImageDialog
                ref={uploadImageDialogRef}
                setFileName={setFileName}
                currentValue={selectedCampaign}
                setCurrentFile={setCurrentFile}
            />
        {/* upload image dialog end */}
        </>
    );
});

export default CampaignForm;