import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import DraggableItem from './draggable-item';
import { GET_ASSIGNED_TILES } from '../../../../constants/endpoints';
import axios from 'axios';

const DefaultContainer = ({ items, setItem, title,deviceID, assignedTiles, setAssignedTiles }) => {
    const TYPE = 'card';

    const [{ isOver }, dropRef] = useDrop({
        accept: TYPE,
        // drop: (droppedItem, monitor) => {
        //     const { index: originalIndex } = monitor.getItem();
        //     const updatedItems = [...items];
        //     updatedItems.splice(originalIndex, 1); // Remove the item from its original position
        //     setItem([...updatedItems, droppedItem.item]); // Add the item at the new position
        // },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const handleDrop = (originalIndex, newIndex) => {
        if (originalIndex !== newIndex) {
            console.log('originalIndex ' + originalIndex);
            console.log('newIndex ' + newIndex);
            moveItem(originalIndex, newIndex);
        }
    };

    const moveItem = (originalIndex, newIndex) => {
        //     setAssignedTiles((prevCards) =>
        //     update(prevCards, {
        //       $splice: [
        //         [originalIndex, 1],
        //         [newIndex, 0, prevCards[originalIndex]],
        //       ],
        //     }),
        //   )
            const updatedItems = [...assignedTiles];
            const [newItem] = updatedItems.splice(originalIndex, 1);
            updatedItems.splice(newIndex, 0, newItem);
            updatedItems[newIndex].tilesJunctions = [{
                tile_order: newIndex
            }]
            updatedItems[originalIndex].tilesJunctions = [
                {
                    tile_order: originalIndex
                }
            ]
            setAssignedTiles(updatedItems);
    };

    const removeItem = (item) => {
        //removing item from device list
        const index = assignedTiles.findIndex(it => it.id === item.id);
        if (index >= 0) {
            const cloneItems = [...assignedTiles];
            cloneItems.splice(index, 1);
            setAssignedTiles(cloneItems);
        }

        //pushing item back to the original list
        const newItems = [...items];
        newItems.push(item);
        setItem(newItems);
    }
    const getAssignedTiles = () => {
        axios.get(GET_ASSIGNED_TILES + deviceID + '/portal')
        .then((res) => {
            if(res?.data?.status){
                setAssignedTiles(res?.data?.response);
            }else{
                setAssignedTiles([]);
            }
        }).catch((error) => {

        });
    }

    useEffect(() => {
        getAssignedTiles();
    }, []);

    return (
        <div ref={dropRef}>
            {assignedTiles.map((item, index) => (
                <DraggableItem
                componentType={'tiles'}
                moveItem={handleDrop}
                removeItem={removeItem}
                containerID={'device'}
                id={item.id}
                item={item}
                index={index}
                key={item.id} />
            ))}

            {assignedTiles?.length === 0 ? <p className='mb-0 text-center text-black'>No Tiles Are Assigned Yet!</p> : ''}
        </div>
    );
}

export default DefaultContainer;