export const ENVIRONMENT_CONSTANT = {
    API_ENDPOINT:process.env.REACT_APP_API_ENDPOINT,
    REFRESH_URL:process.env.REACT_APP_REFRESH_URL,
    RETURN_URL:process.env.REACT_APP_RETURN_URL,
    ICON_URL:process.env.REACT_APP_ICON_URL,
    USER_INFO: 'userInfo',
    ORGANIZATION_LOGO: 'organization_logo',
    ORGANIZATION_INFO: 'orgInfo',
    PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY,
    PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER,
    PUSHER_DEVICE_STATUS_EVENT: '_device_status',
    PUSHER_DEVICE_PING_EVENT: '_device_ping',
    LOGO_BASE_URL: process.env.REACT_APP_LOGO_BASE_URL,
    CAMPAIGN_ICON_URL: process.env.REACT_APP_CAMPAIGN_ICON_URL
}