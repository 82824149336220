import React, { useRef } from 'react';
import CampaignForm from './shared/campaign-form';
import "./campaign-form.scss"
// import CampaignFormView from './shared/campaign-form-view';
import { useState } from 'react';
import SharedFormPreviewComponent from '../../../../shared/shared-form-preview/shared-form-preview.component';

function Main(props) {
    const [campaignTitle, setCampaignTitle] = useState('');

    const campaignFormRef = useRef(null);

    const previewCampaign = () =>{
        if(campaignFormRef?.current?.previewChanges){
            campaignFormRef.current.previewChanges();
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row'>
            <CampaignForm ref={campaignFormRef} setCampaignTitle={setCampaignTitle} />
            <SharedFormPreviewComponent
            triggerEvent={previewCampaign}
            isSubtitle={true}
            image={'../../../img/masjid/campaign.png'}
             title={campaignTitle ? campaignTitle : 'Campaign Title'} />
            </div>
            
        </div>
    );
}

export default Main;