import React, { useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { getSetOrgInfo } from '../../../../utils/user-info';

const PreviewEmailDialog = React.forwardRef((props, ref) => {

    const [subject, setSubject] = useState("");
    const [emailBody, setEmailBody] = useState("");
    const [to, setTo] = useState("");
    const [show, setShow] = useState(false);
    const [currentDonorInfo, setCurrentDonorInfo] = useState({})
    const handleClose = () => setShow(false);
    const handleShow = (formValues, donorInfo) => {
        setCurrentDonorInfo(donorInfo);
        setSubject(formValues?.email_subject);
        setEmailBody(formValues?.email_body);
        // setTo(to ? to : "");
        setShow(true);
    }
    const orgInfo = getSetOrgInfo();
    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow
        };
    });

    return (
        <Modal size='lg' backdrop="static"
            keyboard={false} show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title><i className="f-24 me-2 fa-solid fa-envelope-open-text"></i>Email Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-12'>
                        <p>From: <b>{orgInfo?.organization_name}</b></p>
                        <p>To: <b>{currentDonorInfo?.email ? currentDonorInfo?.email : 'ahmad@example.com'}</b></p>
                        <p>Reply To: <b>{'{organization_email}'}</b></p>
                        <p>Subject: <b dangerouslySetInnerHTML={{
                            __html: subject
                        }}></b></p>
                        <p>Attachment: <a onClick={() => props?.generatePdf(currentDonorInfo)}
                         className='text-ma-primary text-underline cursor-pointer'>Annual Summary - 2023</a></p>
                    </div>

                    <hr />
                    <div className='col-12'>
                        <div className='emailReceiptTemplate' dangerouslySetInnerHTML={{
                            __html: emailBody
                        }}>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default PreviewEmailDialog;