import React, { useEffect, useRef, useState } from 'react';
import "./update-device.scss";
import { Accordion } from 'react-bootstrap';
import DefaultContainer from './default-container';
import DeviceContainer from './device-container';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ASSIGN_TILES, GET_DEVICE_BY_ID, GET_UNASSIGNED_TILES, UPDATE_DEVICE } from '../../../../constants/endpoints';
import { getUserInfo } from '../../../../utils/user-info';
import { useForm } from 'react-hook-form';
import AttachTilesDialog from './sub-components/attach-tiles-dialog';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';
import FieldError from '../../../../shared/fields-errors/field-error';
import AddDeviceModal from '../devices/add-device-dialog';
import { DEVICE_LAYOUT_MAPPER } from '../../../../constants/constant';

function UpdateDevice(props) {

    const childRef = useRef(null);
    const deviceDialogRef = useRef(null);
    const attachTileDialog = useRef(null);
    const confirmationDialogRef = useRef(null);

    const params = useParams();
    const deviceID = params?.device_id;
    // const deviceCode = params?.device_code;

    const {register, getValues, formState: { isValid, errors }, handleSubmit, reset, watch} = useForm();
    const watchField = watch();
    // const deviceName = params?.device_name;
    // const [deviceName, setDeviceName] = useState(params?.device_name);
    const [deviceInfo, setDeviceInfo] = useState({});
    const [togglePasswordField, setTogglePasswordField] = useState(true);
    const navigate = useNavigate();

    const [assignTilesLoader, setAssignTilesLoader] = useState(false);

    const [tiles, setTiles] = useState([]);
    const [assignedTiles, setAssignedTiles] = useState([]);

    const getUnAssignedTiles = () => {
        axios.get(GET_UNASSIGNED_TILES + deviceID)
            .then((res) => {
                if (res?.data?.status) {
                    setTiles(res?.data?.response)
                } else {
                    setTiles([]);
                }
            }).catch((error) => {

            });
    }

    const getDeviceByID = () => {
        axios.get(GET_DEVICE_BY_ID + deviceID)
        .then((res) => {
            if(res?.data?.status){
                reset({
                    device_name: res?.data?.response?.device_name,
                    device_password: res?.data?.response?.device_password,
                    device_layout: DEVICE_LAYOUT_MAPPER[res?.data?.response?.device_layout]
                })
                setDeviceInfo(res?.data?.response)
            }
        }).catch((error) => {

        })
    }

    useEffect(() => {
        getUnAssignedTiles();
        getDeviceByID();
    }, []);

    const assignTiles = () => {
        setAssignTilesLoader(true);
        const masjid_id = getUserInfo()?.masjid_id || "";
        let index = 0;
        const formData = assignedTiles.map(it => {
            index += 1;
            return {
                device_id: deviceID,
                masjid_id: masjid_id,
                tile_id: it.id,
                tile_order: it.tilesJunctions?.length > 0 ? 
                it.tilesJunctions[0]?.tile_order : index
            }
        });

        axios.post((ASSIGN_TILES + deviceID), formData)
            .then((res) => {
                if (res?.data?.status) {
                    navigate("/devices");
                    // alert("Successfully updated the records.");
                    // updateDevice();
                } else {
                    setAssignTilesLoader(false);
                    confirmationDialogRef.current.setDialogTitle('Alert');
                    confirmationDialogRef.current.setMessage("Failed to update.");
                    confirmationDialogRef.current.openDialog()
                    // alert('Failed to assign tiles.');
                }
            }).catch((error) => {
                // alert('Failed to assign tiles.');
                setAssignTilesLoader(false);
                confirmationDialogRef.current.setDialogTitle('Alert');
                confirmationDialogRef.current.setMessage("Failed to update.");
                confirmationDialogRef.current.openDialog();
            });
    }

    const addTiles = (items) => {
        const newTiles = tiles.filter(it => items.includes(it.id));
        setAssignedTiles(newTiles)
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className='container-fluid'>
                <div className='row'>
                    <form onSubmit={handleSubmit(assignTiles)} className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 left-form-panel p-0'>
                    <div >
                        <div className='form-title text-white d-flex justify-content-start align-items-center bg-blue p-3'>
                            <a onClick={() => navigate("/devices")}
                                className='text-white cursor-pointer'><i className="fa-solid fa-chevron-left me-2"></i></a>
                            <h5 className='text-white mb-0'>Update Device</h5>
                        </div>
                        <div className='p-4'>
                        <div className='col-12 card'>
                                <div className='card-body'>
                                    <div className="form-group"><label className="form-control-label text-black text-bold" >
                                    Device Name
                                    </label>
                                        <input
                                        {...register('device_name',{
                                            disabled: true
                                            // validate:{
                                            //     required: (value) => {
                                            //         if(!value) return "This field is required";
                                            //         return true;
                                            //     } 
                                            // }
                                        })}
                                            placeholder="Device Name"
                                            type="text" className="form-control-alternative form-control" />
                                    </div>
                                    {/* {errors?.device_name ? <FieldError message={errors?.device_name?.message} /> : ''} */}
                                    <p className='text-black f-xsmal mt-1 mb-0'>Code: {deviceInfo?.device_code?.toUpperCase()}</p>

                                    <div className="form-group mt-2">
                                        <label className="form-control-label text-black text-bold" >
                                        Device Password
                                    </label>
                                        <div className='input-group'>
                                        <input
                                        {...register('device_password',{
                                            disabled: true
                                            // validate:{
                                            //     required: (value) => {
                                            //         if(!value) return "This field is required";
                                            //         return true
                                            //     },
                                            //     minLength: (value) => {
                                            //         if(value?.length <= 3) return "Minimum 4 characters is allowed."
                                            //     },
                                            //     maxLength: (value) => {
                                            //         if(value?.length > 4) return "Maximum 4 characters is allowed."
                                            //     }
                                            // }
                                        })}
                                            placeholder="Device Code"
                                            type={togglePasswordField ? 'password' : 'text'} 
                                            className="form-control-alternative form-control" />
                                             <i
                                             title={togglePasswordField ? 'Show' : 'Hide'} 
                                             onClick={() => {
                                                if(togglePasswordField){
                                                    setTogglePasswordField(false);
                                                }else{
                                                    setTogglePasswordField(true);
                                                }
                                             }} className={`input-group-text fa-solid ${togglePasswordField ? 'fa-lock' : 'fa-unlock'} cursor-pointer`}></i>
                                        </div>
                                    </div>
                                    <div className="form-group mt-2"><label className="form-control-label text-black text-bold" >
                                    Device Layout
                                    </label>
                                        <input
                                        {...register('device_layout',{
                                            disabled: true
                                        })}
                                            placeholder="Device Layout"
                                            type="text" className="form-control-alternative form-control" />
                                    </div>
                                    {/* {errors?.device_password ? <FieldError message={errors?.device_password?.message} /> : ''} */}
                                    <div className='text-right mt-3'>
                                    <button type='button'
                                    onClick={() => {
                                        deviceDialogRef?.current?.openEditView({
                                            id:deviceInfo?.id,
                                            device_code: deviceInfo?.device_code,
                                            device_name: deviceInfo.device_name,
                                            device_password: deviceInfo.device_password,
                                            device_layout: deviceInfo.device_layout,
                                            campaign_id: deviceInfo.campaign_id,
                                        })
                                    }}
                                     className='w-100 mb-0 btn btn-outline-primary'>Edit Device</button>
                                    </div>
                                </div>
                            </div>
                            <Accordion className='mt-3' defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className='mb-3'>
                                    <Accordion.Header>
                                        <span className='text-bold text-black'>Tiles</span>
                                        
                                    </Accordion.Header>
                                    <Accordion.Body className='device-media-accordion-body'>
                                        {
                                            deviceInfo?.device_layout ? 
                                            (deviceInfo?.device_layout !== 'layout_3' && deviceInfo?.device_layout !== 'layout_4') ? 
                                            <>
                                            <DefaultContainer 
                                        assignedTiles={assignedTiles}
                                        setAssignedTiles={setAssignedTiles}
                                        items={tiles} 
                                        setItem={setTiles}
                                        deviceID={deviceID}
                                         />
                                         
                                         <div className='attach-tile-sticky'>
                                         <span onClick={(e) => {
                                            e.stopPropagation();
                                            attachTileDialog.current.handleShow(assignedTiles)
                                        }} className='px-3 btn btn-link mb-0 mx-2'>
                                        <i className="fa-solid fa-plus"></i> Add/Remove Tiles
                                        </span>
                                         </div>
                                            </> : <h6 className="text-warning text-center">Tiles are not available for Layout 3 and Layout 4</h6> : ''
                                        }
                                        
                                    </Accordion.Body>
                                    
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="1" className='mb-3'>
                                <Accordion.Header>
                                    <span className='text-bold text-black'>Sliders</span>
                                </Accordion.Header>
                                <Accordion.Body className='device-media-accordion-body'>
                                    <div className='tile'>
                                        <div className='tile__content d-flex align-items-center justify-content-between'>
                                            <div className='d-flex justify-content-start align-items-center'>
                                                <img
                                                    src={`earthquake_3.jpg`} />
                                                <p className='mb-0 f-18 text-black'>Wasim Slider</p>
                                            </div>
                                            <div>
                                                <i className="fa-solid fa-grip-vertical f-18 text-black"></i>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item> */}
                            </Accordion>

                                {
                                    deviceInfo?.device_layout === 'layout_1' ? 
                                    <div className='card'>
                                <div className='card-body p-3 text-center'>
                                    <div className="d-flex justify-content-center">
                                    <button type='button' className='mb-0 btn btn-primary me-4'>Add/Edit Slides</button>
                                    <button type='button' className='mb-0 btn btn-primary'>Add/Edit Announcements</button>
                                    </div>
                                </div>
                                </div> : ''
                                }
                            {/* <div className='text-right'>
                                <button onClick={() => childRef.current.handleShow()} className='btn btn-primary'>
                                    Add Tile
                                </button>
                            </div> */}
                        
                        </div>
                        
                    </div>
                    <div className='campaign-form-footer'>
                            <button disabled={assignTilesLoader}
                                onClick={() => navigate("/devices")} type="button"
                                className={`btn btn-outline-secondary mx-3 ${assignTilesLoader ? 'position-relative button--loading' : ''}`}

                            >{(deviceInfo?.device_layout !== 'layout_3' && deviceInfo?.device_layout !== 'layout_4') ? 'Cancel' : 'Close'}</button>
                            {
                                (deviceInfo?.device_layout && deviceInfo?.device_layout !== 'layout_3' && deviceInfo?.device_layout !== 'layout_4') ? 
                            <div className='position-relative'>
                                <button
                                    disabled={assignTilesLoader}
                                     type="submit"
                                    className={`btn btn-ma-primary btn-md me-3 ${assignTilesLoader ? 'position-relative button--loading' : ''}`}>
                                    Save
                                </button>
                            </div> : ''
                            }

                        </div>
                    </form>
                    <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 form-view'>
                        <div>
                            <div className='mt-2'>
                                <h4>Example</h4>
                                <p>This is a sample, your changes will not be reflected here.</p>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-xl-12 text-center'>
                                {/* <h4>{props?.title ? props?.title : 'Title'}</h4> */}
                                <img src="../../../img/masjid/edit_device.png" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* injecting add tile dialog */}
            {/* <AddEditTiles getTiles={getUnAssignedTiles} ref={childRef} /> */}
            <AddDeviceModal ref={deviceDialogRef} getRegisteredDevices={(value) => {
                let device = Object.assign({}, deviceInfo);
                device.device_name = value.device_name;
                device.device_password = value.device_password;
                device.device_layout = value.device_layout;
                device.campaign_id = value.campaign_id;
                setDeviceInfo(device);
                reset({
                    device_name: value.device_name,
                    device_password: value.device_password,
                    device_layout: DEVICE_LAYOUT_MAPPER[value.device_layout],
                    campaign_id: value.campaign_id
                });
            }}/>

            <AttachTilesDialog
            getUnAssignedTiles={getUnAssignedTiles}
            addTiles={addTiles}
            tiles={tiles}
            ref={attachTileDialog} />

            <ConfirmationDialog
            isAlert={true}
            ref={confirmationDialogRef}
             />
        </DndProvider>
    );
}

export default UpdateDevice;