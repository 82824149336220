import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import AddEditTiles from './add-tiles.dialog';
import axios from 'axios';
import { DELETE_TILE, GET_TILES } from '../../../../constants/endpoints';
import getFormattedDate from '../../../../utils/date-format';
import { activeTilesBasedOnDate, getImageUrl } from '../../../../utils/utils';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';
import Loader from '../../../../shared/loader/loader';

const Tiles = React.forwardRef((props, ref) => {

    const childRef = useRef(null);
    const confirmationDialogRef = useRef(null);

    const [tilesList, setTilesList] = useState([]);

    const [dataLoader, setDataLoader] = useState(false);
    const getTiles = () => {
        setDataLoader(true);
        axios.get(GET_TILES)
            .then((res) => {
                setDataLoader(false);
                setTilesList(res?.data?.status ? res?.data?.response : []);
            }).catch((error) => {
                setDataLoader(false);
                setTilesList([]);
            });
    }

    const deleteTile = (id) => {
        axios.delete(DELETE_TILE + id)
            .then((res) => {
                if (res?.data?.status) {
                    getTiles();
                } else {
                    alert('Unable to delete tile');
                }
            }).catch((error) => {
                alert('Unable to delete tile');
            })
    }

    useEffect(() => {
        getTiles();
    }, [])

    return (
        <div>
            <div className="col-xl-12">
                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2'>
                        <h5 className="text-black">Tiles</h5>
                    </div>
                    <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 text-right f-14'>
                        <a onClick={(() => childRef.current.handleShow())} className="add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3">
                            <span>
                                <i className="fa-solid fa-circle-plus mr-5"></i>
                            </span>
                            <span className='ms-2 text-white'>Create Tile</span>
                        </a>
                    </div>
                </div>
            </div>

            <div className='row mt-2 position-relative'>
                {
                    tilesList.map((item, index) => {
                        return <div key={'tile' + index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-2">
                            <div className="card">
                                <div className="card-header p-3 d-flex justify-content-center">
                                <img className='image-icon' src={getImageUrl(item?.icon_image)} />
                                    {/* <div className="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-lg">
                                        <i className="fas fa-landmark opacity-10" aria-hidden="true"></i>                                    
                                        <i className="fa-solid fa-sliders opacity-10"></i>
                                    </div> */}
                                </div>
                                <div className="card-body pt-0 p-3 text-center">
                                    <p onClick={(() => childRef.current.openEditView(item))}
                                     className="cursor-pointer text-center mb-0 f-16 text-bold text-black">{item.tile_name}</p>
                                    <span className={`badge badge-sm mx-3 
                                    ${(item?.tile_status === 'active' && activeTilesBasedOnDate(item) === true) ? 'bg-gradient-success' : 'bg-gradient-danger'
                                                            }`}>
                                                                {
                                                                    (item?.tile_status === 'active' && activeTilesBasedOnDate(item) === true) ? 'Active' : 'In-Active'
                                                                }
                                                            </span>
                                    {
                                        item?.active_from && item?.active_to ?
                                            <div className='mt-2'>
                                                {/* <p className="mb-0 f-xsmal">From {dateTimeFormat(item?.active_from)}</p> */}
                                                <p className="mb-0 f-xsmal text-bold">{getFormattedDate(item?.active_from)} - {getFormattedDate(item?.active_to)}</p>
                                            </div> : ''
                                    }
                                    {/* <span className="text-xs">Belong Interactive</span> */}
                                    <hr className="horizontal dark my-3" />
                                    <div>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <Link onClick={(() => childRef.current.openEditView(item))} className='me-2 cursor-pointer'>
                                                <i className="fa-regular fa-pen-to-square f-16 card-actions"></i>
                                            </Link>

                                            {
                                                !item?.is_default_tile ?
                                                    <div onClick={() => {
                                                        confirmationDialogRef.current.setDialogTitle('Confirm Deletion');
                                                        confirmationDialogRef.current.setMessage(`Are you sure you want to delete "${item?.tile_name}" tile.`);
                                                        confirmationDialogRef.current.setButtonLabel("Delete Tile");
                                                        confirmationDialogRef.current.openDialog(item?.id);
                                                    }} className='cursor-pointer delete-icon'>
                                                        <i className="fa-solid fa-trash f-16 card-actions"></i>
                                                    </div> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
                {
                    dataLoader ? 
                    <Loader /> : 
                !dataLoader && tilesList?.length === 0 ?
                    <div className='text-center mt-3'>
                        <i style={{ fontSize: '4rem' }} className="fa-solid fa-list-ul"></i>
                        <p>No Tiles Created Yet!</p>
                    </div>
                    : ''}
            </div>

            {/* injecting dialog for add editing tiles */}
            <AddEditTiles 
            getTiles={getTiles}
            ref={childRef} />

            {/* confirmation dialog start */}
            <ConfirmationDialog
                ref={confirmationDialogRef}
                executeAction={deleteTile}
            />
            {/* confirmation dialog end */}
        </div>
    );
});

export default Tiles;