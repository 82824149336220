import axios from "axios";
import { LOGOUT_USER, REFRESH_TOKEN } from "../constants/endpoints";
import Cookies from "js-cookie";

const interceptor = () => {
    
    axios.interceptors.request.use((req) => {
        if(req.headers?.exclude_auth === "yes"){
          req.withCredentials = false;
        }else{
          req.withCredentials = true;
        }
        
        // let user = localStorage.getItem('userInfo')
        // if(user){
        //     let masjidID = JSON.parse(user)?.masjid_id;
        //     req.headers.masjidid = masjidID
        // }
        return req;
    });

    axios.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          if (
            !window.location.href?.includes('login') &&
            error?.response?.status === 401 && !error?.config?.url?.includes('token/refresh')) {
            const prevRequest = error?.config;
            if (error?.response?.status === 401 && !prevRequest?.sent) {
              prevRequest.sent = true;
              const refreshTokenRes = await axios.get(REFRESH_TOKEN, {
                withCredentials: true,
              });
              //commenting out this since we are using cookie for authentication
              // prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
              return axios(prevRequest);
            }
            //clearing up local storage
            // localStorage.clear();
            // window.location.href = LOGOUT_USER+`?time=${new Date().getTime()}`;
            // const authData = JSON.parse(localStorage.getItem("tokens"));
            // const payload = {
            //   access_token: authData.access_token,
            //   refresh_token: authData.refreshToken,
            // };
      
            // let apiResponse = await axios.post(
            //   "http://localhost:4000/auth/refreshtoken",
            //   payload
            // );
            // localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
            // error.config.headers[
            //   "Authorization"
            // ] = `bearer ${apiResponse.data.access_token}`;
            // return axios(error.config);
            return Promise.reject(error?.response);
          } else if(error.config.url?.includes('token/refresh')){
            Cookies.remove('token_validity',{
              path:'/',
              domain:`.${window.location.hostname}`
            });
            localStorage.clear();
            window.location.href = "/login"
            return Promise.reject(error);
          }else{
            return Promise.reject(error);
          }
        }
      );
};

export default interceptor;

