import React, { useRef } from 'react';
// import CampaignForm from './shared/campaign-form';
import "../../campaigns/campaign-form/campaign-form.scss"
// import CampaignFormView from './shared/campaign-form-view';
import { useState } from 'react';
import SharedFormPreviewComponent from '../../../../shared/shared-form-preview/shared-form-preview.component';
import CampaignForm from '../../campaigns/campaign-form/shared/campaign-form';
import ProductForm from './product-form';
// import SharedFormPreviewComponent from '../../../../shared/shared-form-preview/shared-form-preview.component';

function ProductMain(props) {
    const [campaignTitle, setCampaignTitle] = useState('');

    const productFormRef = useRef(null);

    const previewProduct = () =>{
        if(productFormRef?.current?.previewChanges){
            productFormRef.current.previewChanges();
        }
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
            <ProductForm ref={productFormRef} setCampaignTitle={setCampaignTitle} />
            <SharedFormPreviewComponent
            triggerEvent={previewProduct}
            isSubtitle={true}
            image={'../../../img/masjid/product_form.png'}
             title={campaignTitle ? campaignTitle : 'Product Title'} />
            </div>
            
        </div>
    );
}

export default ProductMain;