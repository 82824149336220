import { ENVIRONMENT_CONSTANT } from "../constants/env.constant"

const getUserInfo = () => {
    const user = localStorage.getItem(ENVIRONMENT_CONSTANT.USER_INFO);
    if(user){
        const parsedJson = JSON.parse(user);
        return parsedJson
    }else{
        return {};
    }
}

const getOrgLogo = () => {
    const orgLogo = localStorage.getItem(ENVIRONMENT_CONSTANT.ORGANIZATION_LOGO);
    if(orgLogo){
        return orgLogo
    }else{
        return '';
    }
}

//getting or setting org info
export const getSetOrgInfo = (orgInfo) => {
  if (orgInfo) {
    localStorage.setItem(
      ENVIRONMENT_CONSTANT.ORGANIZATION_INFO,
      JSON.stringify(orgInfo)
    );
  } else {
    const organization = localStorage.getItem(ENVIRONMENT_CONSTANT.ORGANIZATION_INFO);
    if (organization) {
      const parsedJson = JSON.parse(organization);
      return parsedJson;
    } else {
      return {};
    }
  }
};

export {getUserInfo, getOrgLogo};