import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { GET_COMPLETED_ITEMS, GET_SETTINGS, GET_STORE_ITEMS } from '../../../constants/endpoints';
import { useParams } from 'react-router-dom';
import Loader from '../../../shared/loader/loader';
import FeatureBadge from '../../../shared/badge/badge';
import { ENVIRONMENT_CONSTANT } from '../../../constants/env.constant';
import "./store.scss";
import { getTextColorByBG } from '../../../utils/utils';
import SmallCampaignTiles from './small-campaign-tiles';

function MasjidStoreCopy(props) {

    const logoBaseUrl = ENVIRONMENT_CONSTANT.LOGO_BASE_URL;
    const campaignIconUrl = ENVIRONMENT_CONSTANT.CAMPAIGN_ICON_URL;
    const param = useParams();
    const shortCode = param?.short_code;
    const type = param?.type;

    const itemTypes = [
        { label: 'All', value: '' },
        { label: 'Campaigns', value: 'campaign' },
        { label: 'Products', value: 'product' }
    ];

    // const [type, setType] = useState('product');
    const [searchText, setSearchText] = useState('');
    const [requestLoader, setRequestLoader] = useState(false);
    const [storeItems, setStoreItems] = useState([]);
    const [organizationInfo, setOrganizationInfo] = useState({});
    const [featuredItems, setFeaturedItems] = useState([]);
    const [completedItems, setCompletedItems] = useState([]);
    const [pageSettings, setPageSettings] = useState({});

    const getStoreItems = (masjidID) => {
        setRequestLoader(true);
        const body = {
            type: type ? type : 'both'
        }
        axios.post((GET_STORE_ITEMS + masjidID), body)
            .then((res) => {
                setRequestLoader(false);
                if (res?.data?.status) {
                    const items = res?.data?.response?.items;
                    let featuredItems = [];
                    let allItems = []
                    for (let index = 0; index < items.length; index++) {
                        if(items[index].feature_campaign){
                            featuredItems.push(items[index])
                        }else{
                            allItems.push(items[index])
                        }
                    }

                    setFeaturedItems(featuredItems);
                    setStoreItems(allItems);
                    setOrganizationInfo(res?.data?.response?.organization);
                }
            }).catch((error) => {
                setRequestLoader(false);
            })
    }

    const getCompletedItems = (masjidID) => {
        const body = {
            type: type ? type : 'both'
        }
        axios.post((GET_COMPLETED_ITEMS + masjidID), body)
            .then((res) => {
                setRequestLoader(false);
                if (res?.data?.status) {
                    setCompletedItems(res?.data?.response)
                }
            }).catch((error) => {
                setRequestLoader(false);
            })
    }

    const getPageSettings = () => {
        axios.get(`${GET_SETTINGS}${shortCode}/${type ? type : 'both'}`)
        .then((res) => {
            if(res?.data?.status){
                setPageSettings(res?.data?.response);
                getStoreItems(res?.data?.response?.masjid_id);
                getCompletedItems(res?.data?.response?.masjid_id);
            }
        }).catch((error) => {

        })
    }

    useEffect(() => {
        getPageSettings();
    }, [])

    //return footer
    const footer = () => {
        return <div className={`d-flex align-items-end justify-content-between p-3 ${completedItems?.length > 0 ? 'pt-8' : ''}`}>
            <div>
                <p className='text-black mb-0'>Powered by <span className='text-bold text-underline'>Masjidal</span></p>
            </div>
            <div>
                <img style={{ width: '100px' }} src='/img/masjid/public-pages/masjidal-icon.png' />
            </div>
        </div>
    }
    
    return (
        <>
            {/* main banner content start */}
            <div  className='masjid-banner-image position-relative d-sm-none d-none d-md-block' 
            style={{
                background:`url(${pageSettings?.image_location ? (logoBaseUrl + pageSettings?.image_location) : ''}) no-repeat top center`
            }}
            >
                <div className='container'>

                    <div>
                        <div className='position-relative'>
                        {
                            organizationInfo?.website && !pageSettings?.hide_back_link ?
                                <a style={{
                                    color: pageSettings?.main_text_color ? pageSettings?.main_text_color : 'white',
                                    top:'10%'
                                }} href={organizationInfo?.website} target='_self' 
                                className='d-md-block d-none text-xl-start position-absolute'>
                                    <i className="fa-solid fa-arrow-left"></i> Back to {organizationInfo?.organization_name ? organizationInfo?.organization_name : 'Masjid - Site'}
                                </a> : ''
                        }

                        {
                            pageSettings?.is_display_logo && organizationInfo?.image_location ? 
                            <div className='text-center mt-1'>
                            <img
                                style={{
                                    maxHeight: '120px',
                                    maxWidth: '100%'
                                }}
                                src={`${logoBaseUrl}${organizationInfo?.image_location}`} />
                        </div> : ''
                        }
                        </div>
                      

                        {/* {src={`${logoBaseUrl}${organizationInfo?.image_location}`}
                            pageSettings?.is_display_logo && organizationInfo?.image_location ? 
                            <hr className='mt-5' /> : ''
                        } */}
                        
                        <div className='pt-5 banner-description w-100'>
                            <h1 style={{
                                color: pageSettings?.main_text_color ? pageSettings?.main_text_color : 'white'
                            }}>{pageSettings?.title ? pageSettings?.title : 'Make A Donation'}</h1>

                            <p
                            style={{
                                color: pageSettings?.main_text_color ? pageSettings?.main_text_color : 'white'
                            }}
                             className='mb-0 px-md-2 px-sm-3 px-3'>{pageSettings?.description ? pageSettings?.description : 'The Prophet ﷺ said: "Whoever builds a masjid for Allah, Allah will build for him a similar house in Jannah” (Sahih Muslim).'}</p>
                        </div>
                    </div>

                </div>
            </div>
            {/* main banner content end */}

            <div className='masjid-banner-image position-relative d-sm-block d-block d-md-none' 
            style={{
                backgroundColor: pageSettings?.secondary_color ? pageSettings?.secondary_color : '#daeffffa',
                minHeight:pageSettings?.hide_subtitle_on_mobile ? '150px' : ''
            }}
            >
                <div className='container'>

                    <div>
                        {
                            organizationInfo?.website && !pageSettings?.hide_back_link ?
                                <a style={{
                                    color: pageSettings?.main_text_color ? pageSettings?.main_text_color : 'white',
                                }} href={organizationInfo?.website} target='_self' 
                                className='d-md-block d-none text-white pt-3 text-xl-start'>
                                    <i className="fa-solid fa-arrow-left"></i> Back to {organizationInfo?.organization_name ? organizationInfo?.organization_name : 'Masjid - Site'}
                                </a> : ''
                        }
                            <div className='text-center mt-1'>
                            <img
                                style={{
                                    maxHeight: '120px',
                                    maxWidth: '100%'
                                }}
                                src={`${logoBaseUrl}${organizationInfo?.image_location}`} />
                        </div>
                        
                        <div className='pt-9 banner-description w-100'>
                            <h1 style={{
                                color:  pageSettings?.secondary_color ? getTextColorByBG(pageSettings?.secondary_color) : '#004475'
                            }}>{pageSettings?.title ? pageSettings?.title : 'Make A Donation'}</h1>
                            <p
                            style={{
                                color:  pageSettings?.secondary_color ? getTextColorByBG(pageSettings?.secondary_color) : '#004475'
                            }}
                             className={`${pageSettings?.hide_subtitle_on_mobile ? 'd-none d-md-block' : ''} mb-0 px-md-2 px-sm-3 px-3`}>{pageSettings?.description ? pageSettings?.description : 'The Prophet ﷺ said: "Whoever builds a masjid for Allah, Allah will build for him a similar house in Jannah” (Sahih Muslim).'}</p>
                        </div>
                    </div>

                </div>
            </div>

            {/* featured campaign/products start */}
           {
                featuredItems?.length > 0 ?
                <div className='container mb-6'>
                <div>
                    {
                        featuredItems?.map((item, index) => {
                            return  <div key={`featured_item_${index}`}>
                            {index > 0 ? <hr className='mt-5' /> : ''}
                            <div className={`row featured-campaign ${index === 0 ? 'mt-6' : ''}`}>
                            <div className={`col-xxl-5 col-xl-5 col-lg-5 col-12 offset-xxl-1 offset-xl-1 offset-lg-1 my-auto ${(index%2) ? 'order-xxl-2 order-lg-2 order-md-2 order-sm-2 order-2' : 'order-md-1 order-sm-2 order-2'}`}>
                                <h2 className='text-black'>{item.campaign_title}</h2>
                                <span className='d-block pb-2'>{item.featured_campaign_desc}</span>
                                <div className='d-flex justify-content-between mb-3'>
                                            <div className='d-flex align-items-center'>
                                                {item?.type === 'product' ? <h4 className='text-black mb-0'>${item?.price_per_unit / 100}</h4> : ''}
                                                <a style={{
                                    backgroundColor: pageSettings?.button_color ? pageSettings?.button_color : '#004475',
                                    color: getTextColorByBG(pageSettings?.button_color ? pageSettings?.button_color : '#004475')
                                }} target='_blank'
                                                    href={
                                                        item?.type === 'product' ?
                                                            `
                                                                ${organizationInfo?.organization_short_name ?
                                                                organizationInfo?.organization_short_name : 'masjid'
                                                            }/product/${item.donation_page_link}` : `${organizationInfo?.organization_short_name ?
                                                                organizationInfo?.organization_short_name : 'masjid'
                                                            }/campaign/${item.donation_page_link}`
                                                    }
                                                    className={`btn btn-primary ${item.type === 'product' ? 'mx-4' : ''} m-0 donate-now`}>{item?.change_label_to_pay ? 'Pay' : 'Donate Now'}</a>
                                            </div>
                                    {item?.type === 'product' && item?.reset_count?.toLowerCase() !== 'yearly' && item?.reset_count?.toLowerCase() !== 'monthly' ? <span style={{
                                    color: pageSettings?.secondary_color ? getTextColorByBG(pageSettings?.secondary_color) : '#004475',
                                    backgroundColor: pageSettings?.secondary_color ? pageSettings?.secondary_color : '#DAEFFF'
                                }} className='remaining-quantity'>{item?.remainingQuantity} Remaining</span> : ''}
                                </div>

                            </div>
                            <div className={`text-center col-xxl-5 col-xl-5 col-lg-5 col-12 mb-sm-3 mb-3 mb-md-3 mb-lg-0 ${(index%2) ? 'order-xxl-1 order-lg-1 order-md-1 order-sm-1 order-1' : ' order-md-2 order-sm-1 order-1'} 
                            ${pageSettings?.not_display_feature_campaign_image ? 'd-none d-sm-none d-md-block' : ''}
                            `}>
                                <img src={logoBaseUrl + item?.image_location} />
                            </div>
                        </div>
                            </div>
                        })
                    }

                </div>

            </div> : ''
           }
            {/* featured campaign/products end */}

            {/* campaign or products start */}
            <div
            style={{
                backgroundColor: pageSettings?.secondary_color ? pageSettings?.secondary_color : '#daeffffa'
            }}
             className={`campaign-main-section d-sm-none d-none d-md-block pt-5 ${completedItems?.length > 0 ? 'pb-7' : ''}`}>
                <div className='container'>
                    <div className='row justify-content-center'>
                        {
                            storeItems?.map((item, index) => {
                                return <div key={`item_${index}`} className='col-xxl-3 col-lg-4 col-md-6 col-sm-12 col-12 mt-5'>
                                <div className='product' style={{
                                    backgroundColor: pageSettings?.primary_color ? pageSettings?.primary_color : '#004475'
                                }}>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                            <img className='image-icon store-img' src={item?.campaign_icon ? (campaignIconUrl + item.campaign_icon) : '/img/masjid/mosque.png'} />
                                            </div>
                                            <div>
                                            {
                                            item?.reset_count?.toLowerCase() !== 'yearly' && item?.reset_count?.toLowerCase() !== 'monthly' &&
                                            item?.remainingQuantity && item.type === 'product' ? <span style={{
                                    color: pageSettings?.secondary_color ? getTextColorByBG(pageSettings?.secondary_color) : '#004475',
                                    backgroundColor: pageSettings?.secondary_color ? pageSettings?.secondary_color : '#DAEFFF'
                                }} className='remaining-quantity'>{item?.remainingQuantity} Remaining</span> : ''}
                                            </div>
                                        </div>
                                        <div>
                                            <h5
                                            style={{
                                                minHeight:'50px',
                                                maxHeight:'50px',
                                                color: pageSettings?.primary_color ? getTextColorByBG(pageSettings?.primary_color) : 'white'
                                            }}
                                            className='mt-3'>{item?.campaign_title}</h5>
                                            <p
                                            style={{
                                                minHeight:'70px',
                                                maxHeight:'70px',
                                                overflow:'hidden',
                                                color: pageSettings?.primary_color ? getTextColorByBG(pageSettings?.primary_color) : 'white'
                                            }}
                                             className='mb-0 mt-1'>{item?.subtitle}</p>
                                            <p style={{
                                                minHeight:'60px',
                                                color: pageSettings?.primary_color ? getTextColorByBG(pageSettings?.primary_color) : 'white'
                                            }} className='text-bold f-25 mt-2 mb-0'>{item?.type === 'product' ? '$'+(item?.price_per_unit / 100) : ''}</p>
                                        </div>
                                    </div>
                                    <div className='donate'>
                                        <a style={{
                                    backgroundColor: pageSettings?.button_color ? pageSettings?.button_color : '#004475',
                                    color: getTextColorByBG(pageSettings?.button_color ? pageSettings?.button_color : '#004475')
                                }} target='_blank'
                                                    href={
                                                        item?.type === 'product' ?
                                                            `
                                                                ${organizationInfo?.organization_short_name ?
                                                                organizationInfo?.organization_short_name : 'masjid'
                                                            }/product/${item.donation_page_link}` : `${organizationInfo?.organization_short_name ?
                                                                organizationInfo?.organization_short_name : 'masjid'
                                                            }/campaign/${item.donation_page_link}`
                                                    }
                                                    className={`btn btn-primary w-100 m-0 donate-now`}>{item?.change_label_to_pay ? 'Pay' : 'Donate Now'}</a>
                                    </div>
                                </div>
                            </div>
                            })
                        }

                    </div>
                </div>

                {
                    completedItems?.length === 0 && storeItems?.length > 0 ?
                    footer() : ''
                }
            </div>
            {/* campaign or products end */}
            {/* campaign tile section for small screens */}
           <SmallCampaignTiles
           campaignIconUrl={campaignIconUrl}
           logoBaseUrl={logoBaseUrl}
           completedItems={completedItems}
           footer={footer}
           storeItems={storeItems}
           featuredItems={featuredItems} 
           pageSettings={pageSettings}
           organizationInfo={organizationInfo} />
           {/* campaign tile section for small screens end */}

            {/* completed products/campaigns start */}
                <div className='masjid-bottom-banner position-relative'>
            {/* <div>
            <img className='position-absolute' src='/img/masjid/public-pages/frame-left.png' />
            <img className='position-absolute img-hh' src='/img/masjid/public-pages/frame-right.png' />
            </div> */}
                {
                    completedItems?.length > 0 ?
                    <>
                    <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='text-center pt-5'>
                            <h4 style={{
                                    color: pageSettings?.primary_color ? pageSettings?.primary_color : '#004475'
                                }} className='text-capitalize'>Completed {!type ? 'Campaigns / Products' : type+'s'}</h4>
                        </div>

                        {/* tiles start */}
                        {
                            completedItems.map((item, index) => {
                                return  <div key={`completed_item_${index}`} className='d-sm-none d-none d-md-block col-xxl-3 col-lg-6 col-md-6 col-sm-12 col-12 mt-5'>
                                <div className='product completed' style={{
                                    backgroundColor: pageSettings?.primary_color ? pageSettings?.primary_color : '#004475'
                                }}>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                        <div>
                                        <img className='image-icon store-img' src={item?.campaign_icon ? (campaignIconUrl + item.campaign_icon) : '/img/masjid/mosque.png'} />
                                        </div>
                                            <div>
                                            </div>
                                        </div>
                                        <div>
                                            <h5
                                            style={{
                                                minHeight:'50px',
                                                maxHeight:'50px',
                                                color: pageSettings?.primary_color ? getTextColorByBG(pageSettings?.primary_color) : 'white'
                                            }}
                                             className='mt-3'>{item?.campaign_title}</h5>
                                            <p
                                            style={{
                                                minHeight:'70px',
                                                maxHeight:'70px',
                                                overflow:'hidden',
                                                color: pageSettings?.primary_color ? getTextColorByBG(pageSettings?.primary_color) : 'white'
                                            }}
                                             className='mb-0 mt-1'>{item?.subtitle}</p>
                                        </div>
                                    </div>
                                    <div style={{
                                         color: pageSettings?.primary_color ? getTextColorByBG(pageSettings?.primary_color) : 'white'
                                    }} className='f-18 mt-5 completed-campaign text-center pt-2 mb-0'>
                                        Completed
                                    </div>
                                </div>
                            </div>
                            })
                        }

                        {/* tiles end */}
                        <SmallCampaignTiles
                completedCampaigns={true}
                campaignIconUrl={campaignIconUrl}
                completedItems={completedItems}
                footer={footer}
                storeItems={completedItems}
                featuredItems={featuredItems} 
                pageSettings={pageSettings}
                organizationInfo={organizationInfo} />
                    </div>
                </div>
                    </> : ''
                }

            {
                completedItems?.length > 0 ?
                footer() : ''
            }
            </div> 
            
            {/* completed products/campaigns end */}
        </>
    );
}

export default MasjidStoreCopy;