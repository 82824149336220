import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Accordion, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FieldError from '../../../../shared/fields-errors/field-error';
import "../integrations.scss"
import { campaignFormMappingList } from '../../../../data-list/data-list';
import axios from 'axios';
import { getSetOrgInfo, getUserInfo } from '../../../../utils/user-info';
import { SAVE_FORM_MAPPING } from '../../../../constants/endpoints';

const AddEditFormIntegration = React.forwardRef((props, ref) => {
    const orgInfo = getSetOrgInfo();
    const userInfo = getUserInfo();
    const [isEdit, setIsEdit] = useState(false);
    const { register, handleSubmit, control, formState: { errors, isValid, isDirty }, getValues, watch, setValue, reset } = useForm();
    const fieldsWatcher = watch();
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);
    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow
        };
    });

    const handleClose = () => {
        setIsEdit(false);
        reset({
            // name: "",
            campaign_id: "",
            jotform_form_id:"",
            integration_api_key:"",
            campaign_url_path: "",
            system_mapping: campaignFormMappingList.map(it => Object.assign({}, {mapping_value: it.value, field_name: "", order_value: it.order_value})),
            outbound_mapping: [{
                mapping_value: "amount", 
                field_name: ""
            }]
        });
        setShow(false);
    }

    const handleShow = (formValues) => {
        if(formValues?.id){
            const copyFormValues = structuredClone(formValues);
            copyFormValues.jotform_form_id = `https://form.jotform.com/${copyFormValues.jotform_form_id}`;
            setIsEdit(true);
            reset(copyFormValues)
        }
        setShow(true);
    }

    // const setMapValue = (index, value) => {
    //     setValue(`system_mapping.${index}.field_name`, value);
    // }

    const saveMappings = (formValues) => {
        console.log(formValues)
        setLoader(true);
        axios.post(SAVE_FORM_MAPPING, formValues)
        .then((res) => {
            setLoader(false);
            if(res?.data?.status){
                handleClose();
                props?.getAllFormMappings();
            }else{
                alert(res?.data?.message);
            }
        }).catch((error) => {
            setLoader(false);
            alert(error?.response?.data?.message ? error?.response?.data?.message : "Failed to save form mapping");
        });
    }

    useEffect(() => {
        if(props?.formMappingList?.length > 0){
            const listOfIds = props?.formMappingList?.map(it => it.campaign.id);
            if(fieldsWatcher?.campaign_id){
                const data = props?.campaignList.filter(it => !listOfIds.includes(it?.id) || fieldsWatcher?.campaign_id === it?.id);
                setFilteredCampaigns(data);
                const valueChangeTime = setTimeout(() => {
                    setValue("campaign_id", fieldsWatcher?.campaign_id);
                }, 200);

                return () => {
                    clearTimeout(valueChangeTime);
                }
            }else{
                const data = props?.campaignList.filter(it => !listOfIds.includes(it?.id));
                setFilteredCampaigns(data);
            }   
        }else{
            setFilteredCampaigns(props?.campaignList);
        }

        

    }, [props?.formMappingList, props?.campaignList, fieldsWatcher?.campaign_id])


    return (
        <Modal
            size={'lg'}
            keyboard={false}
            backdrop="static" show={show} onHide={handleClose} animation={true}>
            <Form onSubmit={handleSubmit(saveMappings)}>
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex align-items-center justify-content-center'>
                        {isEdit ? `Edit` : 'Create'} Form Mapping
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='position-relative' >
                    <div className='row p-2'>
                        <div className='col-xl-12'>
                            <Row>
                                {/* <Col xs={12} lg={3} className='mb-2'>
                                    <Form.Group >
                                        <Form.Label><span className='text-danger'>*</span> Mapping Name</Form.Label>
                                        <Form.Control
                                            {...register('name', {
                                                validate: {
                                                    required: value => {
                                                        if (!value) return 'Field is required.';
                                                        return true;
                                                    },
                                                }
                                            })}
                                            type="text"
                                            placeholder="Enter Mapping Name" />
                                    </Form.Group>
                                    {errors?.name ? <FieldError message={errors?.name?.message} /> : ''}
                                </Col> */}
                                <Col xs={12} lg={4} className='mb-2'>
                                    <Form.Group >
                                        <Form.Label><span className='text-danger'>*</span> Select Campaign</Form.Label>
                                        <Form.Select {...register("campaign_id", {
                                            onChange: (e) => {
                                                if(e.currentTarget.value){
                                                    const campaign = filteredCampaigns.find(it => it.id === e.currentTarget.value);
                                                    if(campaign){
                                                        setValue("campaign_url_path", `${orgInfo?.organization_short_name}/campaign/${campaign?.donation_page_link}`)
                                                    }else{
                                                        setValue("campaign_url_path", "")
                                                    }
                                                }else{
                                                    setValue("campaign_url_path", "")
                                                }
                                            },
                                            validate: {
                                                required: value => {
                                                    if (!value) return 'Field is required.';
                                                    return true;
                                                },
                                            }
                                        })}>
                                            <option value={""}>
                                                        Select Campaign
                                                    </option>
                                            {
                                                filteredCampaigns.map((value, index) => {
                                                    return <option key={`campaign_${index}`} value={value.id}>
                                                        {value.campaign_title}
                                                    </option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                    {errors?.campaign_id ? <FieldError message={errors?.campaign_id?.message} /> : ''}
                                </Col>
                                <Col xs={12} lg={4} className='mb-2'>
                                    <Form.Group >
                                        <Form.Label><span className='text-danger'>*</span> Jotform Url</Form.Label>
                                        <Form.Control
                                            {...register('jotform_form_id', {
                                                validate: {
                                                    required: value => {
                                                        if (!value) return 'Field is required.';
                                                        return true;
                                                    },
                                                },
                                                pattern:{
                                                    value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                                                    message: "Enter a valid url"
                                                }
                                            })}
                                            type="text"
                                            placeholder="Enter Jotform Url" />
                                    </Form.Group>
                                    {errors?.jotform_form_id ? <FieldError message={errors?.jotform_form_id?.message} /> : ''}
                                </Col>
                                <Col xs={12} lg={4} className='mb-2'>
                                    <Form.Group >
                                        <Form.Label><span className='text-danger'>*</span> Jotform API Key</Form.Label>
                                        <Form.Control
                                            {...register('integration_api_key', {
                                                validate: {
                                                    required: value => {
                                                        if (!value) return 'Field is required.';
                                                        return true;
                                                    },
                                                }
                                            })}
                                            type="text"
                                            placeholder="Enter Jotform ID" />
                                    </Form.Group>
                                    {errors?.integration_api_key ? <FieldError message={errors?.integration_api_key?.message} /> : ''}
                                </Col>
                            </Row>
                        </div>
                        <div className='col-xl-12 mt-2'>
                            <div className='row' style={{
                                maxHeight: '350px',
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            }}>
                                <div className='col-12'>
                                    <span className='text-bold text-black f-15'>Jotform Inbound Mapping</span>
                                    <hr className='m-2' />
                                </div>
                                <div className='col-6 mb-3 d-none'>
                                    {
                                        campaignFormMappingList.map((item, index) => {
                                            return (<Col key={`mapped_value_${index}`} xs={12} className='mt-2'>
                                                <Form.Group >
                                                    {
                                                        index === 0 ? <Form.Label>Donation Form Fields</Form.Label> : ''
                                                    }
                                                    <Form.Control
                                                        disabled
                                                        defaultValue={item.value}
                                                        {...register(`system_mapping.${index}.mapping_value`, {
                                                            value: item?.value,
                                                            validate: {
                                                                required: value => {
                                                                    if (!value) return 'Field is required.';
                                                                    return true;
                                                                },
                                                            }
                                                        })}
                                                        type="text" />
                                                    {/* <input type='hidden' {...register(`system_mapping.${index}.field_name`, {
                                                        validate: {
                                                            required: value => {
                                                                if (!value) return 'Field is required.';
                                                                return true;
                                                            },
                                                        }
                                                    })} /> */}
                                                </Form.Group>
                                                <input type='hidden' {...register(`system_mapping.${index}.masjid_id`, {
                                                    value: userInfo?.masjid_id
                                                })} />
                                                <input type='hidden' {...register(`system_mapping.${index}.order_value`, {
                                                    value: item.order_value
                                                })} />
                                            </Col>)
                                        })
                                    }
                                </div>
                                <div className='col-12 mb-3'>
                                    <div className='row'>
                                    {
                                        campaignFormMappingList.map((item, index) => {
                                            return (<Col key={`form_field_${index}`} xs={4} className='mt-2'>
                                                <Form.Group className='row align-items-end'>
                                                    <div className={`${(item.value === 'address' ||
                                                            item.value === 'city' ||
                                                            item.value === 'phone' ||
                                                            item.value === 'state_province' ||
                                                            item.value === 'postal_code') ? 'col-md-12 col-12 col-lg-12' : 'col-12'}`}>
                                                    <Form.Label>{item?.label}</Form.Label>
                                                    <div style={{
                                                        border:  "1px solid lightgray",
                                                        padding: "0.5rem 0.75rem"
                                                    }} className={`${errors?.system_mapping?.[index]?.field_name ?
                                                        'error-field' : ''
                                                        } d-flex align-items-center justify-content-between`}>
                                                    <Form.Control {...register(`system_mapping.${index}.field_name`, {
                                                        validate: {
                                                            required: value => {
                                                                if (!value && 
                                                                    (item?.value === "first_name" ||
                                                                    item?.value === "last_name" ||
                                                                    item?.value === "email" ||
                                                                    item?.value === "amount") 
                                                                    ){
                                                                        return 'Field is required.';
                                                                    }else if(!value && (item.value === 'address' ||
                                                                    item.value === 'city' ||
                                                                    item.value === 'phone' ||
                                                                    item.value === 'state_province' ||
                                                                    item.value === 'postal_code')){
                                                                        if(fieldsWatcher?.system_mapping[index]?.is_required){
                                                                            return 'Field is required.';
                                                                        }else{
                                                                            return true;            
                                                                        }
                                                                    }else{
                                                                        return true;
                                                                    }
                                                                
                                                            },
                                                        }
                                                    })}
                                                        placeholder='Enter your form value'
                                                        type='text' className={`required_field `} 
                                                            // onBlur={(e) => {
                                                            //     setMapValue(index, e?.target?.value);
                                                            // }}
                                                            >
                                                    </Form.Control>
                                                    <label className={`${(item.value === 'address' ||
                                                            item.value === 'city' ||
                                                            item.value === 'phone' ||
                                                            item.value === 'state_province' ||
                                                            item.value === 'postal_code') ? 'd-block' : 'd-none'} mx-3 form-control-label d-flex align-items-center justify-content-center`}>
                                                            <input {...register(`system_mapping.${index}.is_required`,{
                                                                value: (item.value === 'address' ||
                                                                item.value === 'city' ||
                                                                item.value === 'phone' ||
                                                                item.value === 'state_province' ||
                                                                item.value === 'postal_code') ? false: true
                                                            })}
                                                                className="me-1"
                                                                type="checkbox" />
                                                            Required
                                                        </label>
                                                    </div>
                                                    </div>
                                                </Form.Group>
                                                
                                            </Col>)
                                        })
                                    }
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className='col-xl-12 mt-2'>
                            <Accordion defaultActiveKey={"0"}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <span className='text-bold text-black f-15'>Jotform Outbound Mapping</span>
                                        </Accordion.Header>
                                    <Accordion.Body>
                                        <Col xs={12} className='mt-2'>
                                            <Form.Group className='row align-items-center'>
                                                <div className="col-md-6 d-none">
                                                    <Form.Control disabled {...register(`outbound_mapping.${0}.mapping_value`,{
                                                        value: 'amount'
                                                    })}
                                                        placeholder='Enter your field name'
                                                        type='text'
                                                    >
                                                    </Form.Control>
                                                </div>
                                                <div className="col-md-3">
                                                <Form.Label className='mb-0'>Amount</Form.Label>
                                                    <Form.Control className={`${errors?.outbound_mapping?.[0]?.field_name ?
                                                            'error-field' : ''
                                                            }`}  {...register(`outbound_mapping.${0}.field_name`,{
                                                        validate: {
                                                            required: value => {
                                                                if (!value) return 'Field is required.';
                                                                return true;
                                                            },
                                                        }
                                                    })}
                                                        placeholder='Enter your field name'
                                                        type='text'
                                                    >
                                                    </Form.Control>
                                                </div>
                                                <input type='hidden' {...register(`outbound_mapping.${0}.masjid_id`, {
                                                    value: userInfo?.masjid_id
                                                })} />
                                            </Form.Group>
                                        </Col>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleClose}>
                        Cancel
                    </Button>

                    <Button type='submit'
                    disabled={loader}
                        className={`${loader ? 'position-relative button--loading' : ''}`}
                        variant="primary">
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export default AddEditFormIntegration;