import React from 'react';
import { DONATION_COLUMNS } from '../../../../constants/table-columns.constant';
import NoDataComponent from '../../../../shared/no-data/no-data';
import StripeAccountNotActive from '../../../../shared/stripe-connected/stripe-not-connected';
import "./campaign-detail.scss"
import { Link } from 'react-router-dom'
import DonationInfo from '../../../../shared/donation-info/donation-info';

function CampaignDetail(props) {
    const columns = [...DONATION_COLUMNS];
    columns.splice(1,1)
    return (
        <div>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <h5 className="font-weight-semibold text-black">Test Campaign</h5>
                        </div>
                    </div>
                </div>

                <div className='col-xl-12 mt-3 campaign-widget position-relative'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <h6 className="d-flex justify-content-center align-items-center">
                                        <i className="fa-solid fa-circle-check text-success me-1"></i> Campaign Created!</h6>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-outline-secondary text-no-wrap f-xsmal">Edit Campaign</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* stripe connected widget start */}
                <StripeAccountNotActive />
                {/* stripe connected widget end */}
            </div>

            <div className='row mt-5'>
            <div className='col-xl-12'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <h5 className="font-weight-semibold text-black">Overview</h5>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='border-bottom'>
                            <h6>Info</h6>
                            </div>
                            <div>
                                <span className='f-14'>Campaign URL</span>
                                <div className='d-flex justify-content-between align-items-center donation-preview-link'>
                                    <div className='f-14'>
                                        <i className="fa-solid fa-link-slash"></i> <a href='' target='_blank'>
                                            https://masjidal-donation.org/test-donation-48
                                        </a>
                                    </div>
                                    <div className='d-flex'>
                                    <i title='Copy Link' className="fa-regular fa-copy icon-button me-1"></i>
                                    <Link to="/layout/create-campaign" target={'_blank'}>
                                    <i title='Preview' className="fa-regular fa-eye icon-button cursor-pointer"></i>
                                    </Link>
                                    
                                    
                                        {/* <button type="button" className="btn btn-outline-secondary me-1 mb-0">Preview</button>
                                        <button type="button" className="btn btn-outline-secondary mb-0">Copy</button> */}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <span className='f-14'>Gross Donations</span>
                                <p>$0</p>
                            </div>
                            <div>
                                <span className='f-14'>Average Donation</span>
                                <p>$0</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-xl-0 mt-lg-0 mt-md-0 mt-3'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='border-bottom'>
                            <h6>0 Donations</h6>
                            </div>
                            <div className="table-responsive height-240">
                                <table className="table align-items-center ">
                                    <thead className='text-center prayers-table-header white'>
                                        <tr>
                                            <th>Currency</th>
                                            <th>Total</th>
                                            <th>Count</th>
                                        </tr>
                                    </thead>
                                    <tbody className='prayer-table-body'>
                                        {/* data goes here */}
                                    </tbody>
                                    
                                </table>
                               
                               {/* no data message */}
                               <NoDataComponent message={'No Donations Available'} />
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            {/* donations view start */}
            <DonationInfo columns={columns}/>
            {/* donations view end */}
        </div>

    );
}

export default CampaignDetail;