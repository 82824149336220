import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { COPY_CAMPAIGN, COPY_PRODUCT } from '../../constants/endpoints';
import axios from 'axios';
import FieldError from '../fields-errors/field-error';

const CopyCampaignDialog = React.forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const [requestLoader, setRequestLoader] = useState(false);
    const [currentCampaign, setCurrentCampaign] = useState({});
    const { register, setFocus, setValue, handleSubmit, reset, formState: { errors } } = useForm();

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow
        }
    });

    const handleClose = () => {
        reset({
            campaign_title: ""
        })
        setShow(false);
    };

    const handleShow = (campaign) => {
        setCurrentCampaign(campaign);
        setShow(true);
    }

    useEffect(() => {
        setFocus("campaign_title");
      }, [setFocus, show]);

    const copyCampaign = (formValues) => {
        setRequestLoader(true);
        const requestBody = {
            form_type: currentCampaign?.type,
            campaign_title: formValues.campaign_title
        }
        const endpoint = (currentCampaign?.type  ? COPY_CAMPAIGN : COPY_PRODUCT) + 
        (currentCampaign?.type ? currentCampaign?.id : currentCampaign?.product_id);
        axios
          .post(endpoint, requestBody)
          .then((res) => {
            setRequestLoader(false);
            if (res?.data?.status) {
              props?.callback();
              handleClose();
            } else {
              alert(`Unable to copy ${currentCampaign.type ? currentCampaign.type : currentCampaign.product_type}.`);
            }
          })
          .catch((error) => {
            setRequestLoader(false);
            alert(`Unable to copy ${currentCampaign.type ? currentCampaign.type : currentCampaign.product_type}.`);
          });
      };

    return (
        <Modal
            keyboard={false}
            backdrop="static" show={show} onHide={handleClose} animation={true}>
            <Form onSubmit={handleSubmit(copyCampaign)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize'>Copy {currentCampaign.type ? currentCampaign.type : currentCampaign.product_type}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label className='d-flex justify-content-between align-items-center'><span>
                        <span className='text-danger me-1'>*</span>Title</span> <span className='text-bold'>Current Title: {currentCampaign?.campaign_title ? currentCampaign?.campaign_title : currentCampaign?.product_campaign_title}</span></Form.Label>
                        <Form.Control
                            {...register('campaign_title', {
                                validate: {
                                    required: value => {
                                        if (!value) return 'Field is required.';
                                        return true;
                                    }
                                }
                            })} 
                            type="text" placeholder={`Enter ${currentCampaign.type ? currentCampaign.type : currentCampaign.product_type} title`} />
                    </Form.Group>
                    {errors?.campaign_title ? <FieldError message={errors?.campaign_title?.message} /> : ''}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={requestLoader} variant="default" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button disabled={requestLoader} type='submit' variant="primary"
                        className={`${requestLoader ? 'position-relative button--loading' : ''}`}>
                        Copy
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export default CopyCampaignDialog;