import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_PLEDGE_DETAIL, GET_PLEDGE_EMAIL_TEMPLATE, RESEND__PLEDGE_EMAIL, UPDATE_PLEDGE } from '../../../constants/endpoints';
import { pledgeStatusList } from '../../../data-list/data-list';
import { useForm } from 'react-hook-form';
import getFormattedDate, { dateTimeFormat } from '../../../utils/date-format';
import { getSetOrgInfo } from '../../../utils/user-info';
import { DEFAULT_PLEDGE_EMAIL_BODY, DEFAULT_PLEDGE_EMAIL_SUBJECT, PREFERRED_PAYMENT_METHOD_MAPPER } from '../../../constants/constant';
import DonationsByPledge from './components/donations-by-pledge';
import AddOfflineDonationDialog from './components/add-offline-donation-dialog';

function PledgeDetail(props) {
    const addDonationDialogRef = useRef(null);
    const [resendEmailLoader, setResendEmailLoader] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState({
        email_subject: DEFAULT_PLEDGE_EMAIL_SUBJECT,
        email_body: DEFAULT_PLEDGE_EMAIL_BODY
    });
    const orgInfo = getSetOrgInfo();
    const navigate = useNavigate();
    const params = useParams();
    const [requestLoader, setRequestLoader] = useState(false);
    const pledgeID = params?.pledge_id;
    const [pledgeDetail, setPledgeDetail] = useState({});
    const form = useForm();
    const { register, setValue,unregister, reset, formState: { errors }, watch } = form;
    const currentFields = watch();
    const [errorMessage, setErrorMessage] = useState("");

    //methods 
    const getPledgeDetail = () => {
        axios.get(`${GET_PLEDGE_DETAIL}${pledgeID}`)
            .then((res) => {
                if (res?.data.status) {
                    setPledgeDetail(res?.data?.response);
                    let status = "";
                    //checking if amount is greater then the pledge amount setting status paid else partial paid
                    if(res?.data?.response?.donation?.length > 0){
                        const totalDonationAmount = res?.data?.response?.donation?.reduce((previousValue, currentValue) => {
                            previousValue += (parseFloat(currentValue?.amount) / 100);
                            return previousValue;
                        }, 0);
                        
                        const pledgeAmount = (res?.data?.response?.amount / 100);
                        if(totalDonationAmount >= pledgeAmount){
                            status = 'Paid'
                        }else{
                            status = 'Partial_Paid'
                        }
                    }else{
                        status = (res?.data?.response?.recurring_plan?.id ? 
                            res?.data?.response?.recurring_plan?.status?.toLowerCase() === 'active' ? 'Partial_Paid' : 
                            res?.data?.response?.recurring_plan?.status?.toLowerCase() === 'failed' ? 'Un_Paid' : 
                            res?.data?.response?.recurring_plan?.status?.toLowerCase() === 'paused' ? 'Pending' : 
                            res?.data?.response?.recurring_plan?.status?.toLowerCase() === 'completed' ? 'Paid' : 
                            res?.data?.response?.recurring_plan?.status?.toLowerCase() === 'pending' ? 'Partial_Paid' : 
                            res?.data?.response?.recurring_plan?.status?.toLowerCase() === 'canceled' ? 'Canceled' : res?.data?.response?.pledge_status : res?.data?.response?.pledge_status)
                    }

                    reset({
                        pledge_status: status,
                        follow_up_date: res?.data?.response?.follow_up_date,
                        comments: res?.data?.response?.comments,
                    });

                    // if(res?.data?.response?.donation){
                    //     setValue("donation_info", {
                    //         id: res?.data?.response?.donation?.id,
                    //         amount: res?.data?.response?.donation?.amount / 100,
                    //         created_at: getFormattedDate(res?.data?.response?.donation?.created_at, true),
                    //         payment_type: res?.data?.response?.donation?.payment_type
                    //     });
                    // }
                    
                }
            }).catch((error) => {

            });
    }

    const generateEmailSubject = () => {
        if(emailTemplate?.email_subject && pledgeDetail?.id){
            const subject = emailTemplate?.email_subject?.replaceAll('{organization_name}',orgInfo.organization_name)
            .replaceAll('{campaign_name}', pledgeDetail?.campaign?.campaign_title)
            .replaceAll('{donor_first_name}', pledgeDetail?.donor?.first_name)
            .replaceAll('{donor_last_name}', pledgeDetail?.donor?.last_name)
            .replaceAll('{amount}', '$'+(pledgeDetail?.amount/100))
            .replaceAll('{interval}', pledgeDetail?.donation_type)
            return subject;
        }else{
            return ""
        }   
    }

    const generatedEmailBody = () => {
        if(emailTemplate?.email_body && pledgeDetail?.id){
            let body = emailTemplate?.email_body?.replaceAll('{organization_name}',`<label class='text-black text-bold'> ${orgInfo?.organization_name}</label>`)
            .replaceAll('{campaign_name}', `Campaign: <label class='text-black text-bold'> ${pledgeDetail?.campaign?.campaign_title}</label>`)
            .replaceAll('{donor_first_name} {donor_last_name}', `<label class='text-black text-bold'> ${pledgeDetail?.donor?.first_name} ${pledgeDetail?.donor?.last_name}</label>`)
            .replaceAll('{amount}', `Amount: <label class='text-black text-bold'>$${(pledgeDetail?.amount/100)}</label>`)
            .replaceAll('{interval}', `Donation Type: <label class='text-black text-bold'> ${pledgeDetail?.donation_type}</label>`)
            .replaceAll('{donation_link}', `<a class="text-primary" href="/${orgInfo?.organization_short_name}/campaign/${pledgeDetail?.campaign.donation_page_link}/?pledge_token=${pledgeDetail?.id}" target="_blank">
            Donate
            </a>`)

            let pledgeDetailsTemplate = `
            <p>Organization: <b>${orgInfo?.organization_name}</b><p/>
            <p>Campaign: <b>${pledgeDetail?.campaign?.campaign_title}</b><p/>
            <p>Donor Name: <b>${pledgeDetail?.donor?.first_name +' '+pledgeDetail?.donor?.last_name}</b><p/>
            <p>Amount: <b>$${(pledgeDetail.amount/100)}</b><p/>
            <p>Donation Interval: <b>${pledgeDetail?.donation_type}</b><p/>
            <p>Donated At: <b>${dateTimeFormat(pledgeDetail?.created_at)}</b><p/>
            <p>Preferred Payment Method: <b>${
                 pledgeDetail?.preferred_payment_option ? 
                PREFERRED_PAYMENT_METHOD_MAPPER[pledgeDetail?.preferred_payment_option] : ''
            }</b><p/>
            `;

            body = body?.trim()?.replaceAll("<p>{donation_details}</p>", `<div>${pledgeDetailsTemplate}</div>`);

            body += '<p></p><p>Powered by <a class="text-ma-primary" href="https://mymasjidal.com/" target="_blank">Masjidal</a></p>'
            return body;
        }else{
            return ""
        }   
    }

    const getEmailTemplate = () => {
        axios.get(GET_PLEDGE_EMAIL_TEMPLATE+pledgeDetail?.campaign?.id)
        .then((res) => {
            if(res?.data?.status){
                setEmailTemplate(res?.data?.response)
            }
        }).catch((error) => {
            
        })
    }

    const resendEmail = () => {
        setResendEmailLoader(true);
        axios.get(`${RESEND__PLEDGE_EMAIL}${pledgeDetail?.campaign?.id}/${pledgeDetail?.donor?.id}/${pledgeDetail?.id}`)
        .then((res) => {
            setResendEmailLoader(false);
            if(res?.data?.status){
                alert("Email is sent successfully.");
            }else{
                alert(res?.data?.message);
            }
        }).catch((error) => {
            setResendEmailLoader(false);
            alert("Failed to send email.");
        })
    }

    useEffect(() => {
        getPledgeDetail();
    }, []);

    // useEffect(() => {
    //     if(currentFields?.pledge_status === "Paid"){
    //         const timer = setTimeout(() => {
    //             window.scrollTo(0, document.body.scrollHeight);
    //             clearTimeout(timer);
    //         }, 200);
    //     }else if(currentFields?.pledge_status !== "Paid"){
    //         unregister("donation_info");
    //     }
    // }, [currentFields?.pledge_status])

    useEffect(() => {
        if(pledgeDetail?.campaign?.id){
            getEmailTemplate();
        }
    }, [pledgeDetail?.campaign?.id])

    const updatePledgeInfo = (formValues) => {
        setRequestLoader(true);
        if(!formValues?.follow_up_date){
            formValues.follow_up_date = null;
        }
        axios.put(UPDATE_PLEDGE + pledgeDetail?.id, formValues)
        .then((res) => {
            setRequestLoader(false);
            if(res?.data?.status){
                window.location.reload();
            }else{
                setErrorMessage(
                    res?.data?.message ? res?.data?.message : 'Failed to update pledge information.'
                );
            }
        }).catch((error) => {
            setRequestLoader(false);
            setErrorMessage('Failed to update pledge information.');
        });
    }

    return (
        <div className='row'>
            <div className='col-xxl-12'>
                <h5 className='font-weight-semibold text-black'>Pledge Detail</h5>
            </div>
            <div className='col-xxl-12 mt-2'>
                <Tabs
                    defaultActiveKey="pledge_detail"
                >
                    <Tab eventKey="pledge_detail" title="Details">
                        <div className='card'>
                            <div className='card-body'>
                                {/* <FormProvider {...form}> */}
                                    <form onSubmit={form.handleSubmit(updatePledgeInfo)}>
                                        <div className='row'>

                                            <div className='col-6'>
                                                <h6 className='pb-2' style={{
                                                    borderBottom: '1px solid'
                                                }}>General Information</h6>
                                                <dl className='mb-0 text-black'>

                                                    <dt>Campaign</dt>
                                                    <dd>{pledgeDetail?.campaign?.campaign_title}</dd>
                                                    <dt>Pledge Link</dt>
                                                    <dd>
                                                        <a className='text-ma-primary' id='txtLink' target='_blank' 
                                                        href={`/${orgInfo?.organization_short_name}/campaign/${pledgeDetail?.campaign?.donation_page_link}/?pledge_token=${pledgeID}`}>
                                                            {`${window.location.origin}/${orgInfo?.organization_short_name}/campaign/${pledgeDetail?.campaign?.donation_page_link}/?pledge_token=${pledgeID}`}
                                                        </a>
                                                    </dd>
                                                    <dt>Amount Pledged</dt>
                                                    <dd>${pledgeDetail?.amount / 100}</dd>
                                                    <dt>Donation Interval</dt>
                                                    <dd className='text-capitalize'>{pledgeDetail?.donation_type} 
                                                        {
                                                            pledgeDetail?.recurring_plan?.id ? 
                                                            <a 
                                                            className='text-ma-primary text-bold mx-3'
                                                            href={`/recurring/detail/${pledgeDetail?.recurring_plan?.id}`} target='_blank'>(View Plan Details)</a> : ''
                                                        }

                                                    </dd>
                                                    <dt>Recurring Option</dt>
                                                    <dd className='text-warning'>{
                                                        pledgeDetail?.recurring_option_value ?
                                                            pledgeDetail?.recurring_option_value?.includes("-") ?
                                                                `Recurring donation will end on ${getFormattedDate(pledgeDetail?.recurring_option_value)}` :
                                                                pledgeDetail?.recurring_option_value?.toLowerCase() === 'none' ?
                                                                    'None' : `Recurring will end after ${pledgeDetail?.recurring_option_value} payments`
                                                            : 'None'
                                                    }</dd>
                                                    <dt>Preferred Payment Method</dt>
                                                    <dd className='text-capitalize'>
                                                        {
                                                            pledgeDetail?.preferred_payment_option ? 
                                                            PREFERRED_PAYMENT_METHOD_MAPPER[pledgeDetail?.preferred_payment_option] : ''
                                                        }
                                                    </dd>
                                                    <dt>Status</dt>
                                                    <dd>
                                                        <OverlayTrigger
                                                         placement="top"
                                                         delay={{ show: 250, hide: 100 }}
                                                         overlay={pledgeDetail?.recurring_plan?.id ? <Tooltip>
                                                            {
                                                                pledgeDetail?.recurring_plan?.status?.toLowerCase() !== 'completed' ?
                                                                `You can not change the status for this pledge because the pledge is currently in recurring state,
                                                            if you wish to stop donations or want to update the status of this pledge kindly cancel the plan by clicking 
                                                            on View Plan Details` : 'You can not change the status because this pledge is completed'
                                                            }
                                                         </Tooltip> : <></>}
                                                        >
                                                            <select {...register("pledge_status")}
                                                         className='form-control'>
                                                            {
                                                                pledgeStatusList.map((item, index) => {
                                                                    return (item?.value ? <option key={'pledge_' + index} value={item?.value}>{item?.label}</option> : '')
                                                                })
                                                            }
                                                        </select>
                                                        </OverlayTrigger>
                                                        
                                                    </dd>
                                                    <dt>Next Follow Up Date</dt>
                                                    <dd>
                                                        <input {...register("follow_up_date")} type='date' className='form-control' />
                                                    </dd>
                                                    <dt>Comments</dt>
                                                    <dd>
                                                        <textarea {...register("comments")} placeholder='Enter your comments' rows='5' className='form-control'></textarea>
                                                    </dd>
                                                    <dd className='d-flex justify-content-between'>
                                                        <p className='text-danger'>{errorMessage}</p>
                                                        <button disabled={requestLoader} type='submit' className='btn btn-primary'>Save</button>
                                                    </dd>
                                                </dl>
                                            </div>
                                            <div className='col-6'>
                                                <h6 className='pb-2' style={{
                                                    borderBottom: '1px solid'
                                                }}>Donor Information</h6>
                                                <dl className='mb-0 text-black'>
                                                    <dt>Name</dt>
                                                    <dd>{pledgeDetail?.donor?.first_name + ' ' + pledgeDetail?.donor?.last_name}</dd>
                                                    <dt>Email</dt>
                                                    <dd>{pledgeDetail?.donor?.email}</dd>
                                                    <dt>Phone</dt>
                                                    <dd>{pledgeDetail?.donor?.phone}</dd>
                                                </dl>
                                            </div>

                                            {/* {
                                                currentFields?.pledge_status === 'Paid' && !pledgeDetail?.recurring_plan?.id ?

                                                    <div className='col-12'>
                                                        <div className='mt-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                                            <div className='col-12 border-bottom mb-3'>
                                                                <h6 className='text-bold'>Donation Information</h6>
                                                            </div>

                                                            <OfflineDonationInfo donationType={null} />
                                                            <input type='hidden' {...register("donation_info.campaign_id",{
                                                                value: pledgeDetail?.campaign?.id
                                                            })}/>
                                                            <input type='hidden' {...register("donation_info.platform_used",{
                                                                value: "Offline"
                                                            })}/>
                                                            <input type='hidden' {...register("donation_info.email",{
                                                                value: pledgeDetail?.donor?.email
                                                            })}/>
                                                            <input type='hidden' {...register("donation_info.donation_type",{
                                                                value: pledgeDetail?.donation_type
                                                            })}/>
                                                            <input type='hidden' {...register("donation_info.stripe_processing_fee",{
                                                                value: 0
                                                            })}/>
                                                        </div>
                                                    </div> : ''
                                            } */}
                                        </div>
                                </form>
                                {/* </FormProvider>  */}
                            </div>
                        </div>
                        
                        <hr />
                        {/* donations */}
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12'>
                                    <div className='text-right'>
                                    <button 
                                    disabled={!pledgeDetail?.id || pledgeDetail?.recurring_plan?.id}
                                    onClick={addDonationDialogRef?.current?.handleShow}
                                    type='button' 
                                    className={`btn btn-primary mb-0 position-relative 
                                    `}>Add Donation</button>
                                </div>
                                    <DonationsByPledge pledge={pledgeDetail} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey="receipt" title="Pledge Email">
                        <div className='card'>
                            <div className='card-body'>
                                <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-black mb-0 text-bold'>Preview</p>
                                <button disabled={resendEmailLoader} onClick={resendEmail} type='button' className={`btn btn-ma-primary position-relative ${resendEmailLoader ? 'button--loading' : ''}`}>Resend Receipt to Donor</button>
                                </div>
                                <hr />
                                <div className='d-grid'>
                                    <p className='mb-1'>From <label className='text-black text-bold'>{orgInfo?.organization_email}</label></p>
                                    <p className='mb-1'>To <label className='text-black text-bold'>{pledgeDetail?.donor?.email}</label></p>
                                    <p className='mb-1'>Subject <label className='text-black text-bold' dangerouslySetInnerHTML={{__html:generateEmailSubject()}}></label></p>
                                </div>
                                <hr />
                                <div className='row'>
                                {/* style={{
                                        backgroundColor:'#005b9717'
                                    }} */}
                                    <div className='col-xxl-6 p-3' >
                                        {/* <div className='row mb-2'>
                                            <div className='text-center'>
                                            <img src='/img/masjid/masjid_noor.png'className='mt-1 w-10'/>
                                            </div>
                                        </div> */}
                                        {/* <p>Dear Ali,</p>
                                        <p>This is a receipt for your gracious donation to <b>{orgInfo?.masjid_name}</b>.</p> */}
                                        <div className='d-grid text-black' dangerouslySetInnerHTML={{__html:generatedEmailBody()}}>
                                            
                                        {/* <p className='mb-1'>Organization: <label className='text-black text-bold'>Test Organization</label></p>
                                        <p className='mb-1'>Campaign: <label className='text-black text-bold'> Masjid Construction Campaign</label></p>
                                        <p className='mb-1'>Donor Name: <label className='text-black text-bold'>Wasim Essani</label></p>
                                        <p className='mb-1'>Amount: <label className='text-black text-bold'>$52.20</label></p>
                                        <p className='mb-1'>Donation Interval: <label className='text-black text-bold'>One-time</label></p>
                                        <p className='mb-1'>Receipt # <label className='text-black text-bold'>Thanks for donation to Test Organization</label></p>
                                        <p className='mb-1'>Donated At: <label className='text-black text-bold'>05/16/2021 03:41:52</label></p>
                                        <p className='mb-1'>Payment Method: <label className='text-black text-bold'>American Express 1004</label></p>
                                        <p className='mb-1'>Donor Address: <label className='text-black text-bold'>114 hollow drive DANVILLE, PA 17821 United States</label></p>
                                        <p className='mb-1'>Phone: <label className='text-black text-bold'>0342-2996875</label></p> */}
                                        </div>
                                        <p>{orgInfo?.organization_name}</p>
                                        {/* <p className='f-14'>If you did not make this donation or have received this email by mistake, please click here. This link is valid for 24 hours.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Tab>
                </Tabs>
            </div>
            
            {/* add donation form */}
            <AddOfflineDonationDialog pledgeDetail={pledgeDetail} ref={addDonationDialogRef} />
        </div>
    );
}

export default PledgeDetail;