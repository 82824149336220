import React, { useEffect, useImperativeHandle, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FieldError from '../../../../shared/fields-errors/field-error';
import axios from 'axios';
import { ADD_TILE, CAMPAIGNS_NAMES, UPDATE_TILE } from '../../../../constants/endpoints';
import IconView from '../../../../shared/icons-view/icon-view';
import { getImageUrl, getTextColorByBG } from '../../../../utils/utils';

const AddEditTiles = React.forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const buttonActionOptions = [
        { label: 'Select Action', value: '' },
        { label: 'Url', value: 'url' },
        { label: 'Specific Campaign', value: 'specific campaign' },
        { label: 'All Campaigns', value: 'all campaigns' },
        { label: 'All Products', value: 'all products' },
        { label: 'Specific Product', value: 'specific product' },
        { label: 'All Products And Campaigns', value: 'all_products_campaigns' },
        { label: 'Subscribe to Mailing List', value: 'subscribe to mailing list' },
    ];

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow,
            handleClose: handleClose,
            openEditView: openEditView
        };
    });

    const handleClose = () => {
        setCurrentTile({});
        setSelectedIcon("");
        setIsEdit(false);
        setShowIconView(false);
        setShow(false);
        reset({
            "tile_name": "",
            "subtitle": "",
            "active_from": "",
            "active_to": "",
            "tile_status": "active",
            "is_display_button": false,
            "button_label": "",
            "button_color": "#168500",
            "button_text_color": "#168500",
            "button_action": "",
            "button_action_value": ""
        });

        if(props?.openPrevious) props?.openPrevious();
    };
    const handleShow = () => setShow(true);

    //implementing add edit tiles functionality start
    const [tileLoader, setTileLoader] = useState(false);
    const methods = useForm();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        trigger,
        formState: { isValid, errors },
        watch } = methods;

    const watchFields = watch();

    const addTile = (formValues) => {
        setTileLoader(true);
        const formData = Object.assign({ icon_image: selectedIcon }, formValues);
        formData.active_from = new Date(formData.active_from + ' 00:00:00').toISOString();
        formData.active_to = new Date(formData.active_to + ' 23:59:59').toISOString();
        formData.button_text_color = getTextColorByBG(formData.button_color);
        axios.post(ADD_TILE, formData)
            .then((res) => {
                setTileLoader(false);
                if (res?.data?.status) {
                    handleClose();
                    props?.getTiles();
                } else {
                    alert('Failed to create tile.')
                }
            }).catch((error) => {
                setTileLoader(false);
                alert('Failed to create tile.')
            })
    }

    const [currentTile, setCurrentTile] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const openEditView = (tile) => {
        filterCampaignList(tile?.button_action);
        let startDate = '';
        let endDate = '';
        if(tile?.active_from){
            startDate = new Date(tile?.active_from);
            startDate = startDate.getFullYear() + 
            ((startDate.getMonth() + 1)?.toString()?.length === 1 ? (`-0`+(startDate.getMonth() + 1)) : (`-`+(startDate.getMonth() + 1)))
            +'-'+(startDate.getDate()?.toString()?.length === 1 ? `0${startDate.getDate()}` : startDate.getDate());
        }
        if(tile?.active_to){
             endDate = new Date(tile?.active_to);
             endDate = endDate.getFullYear() + 
             ((endDate.getMonth() + 1)?.toString()?.length === 1 ? (`-0`+(endDate.getMonth() + 1)) : (`-`+(endDate.getMonth() + 1)))
             +'-'+(endDate.getDate()?.toString()?.length === 1 ? `0${endDate.getDate()}` : endDate.getDate());
        }

        setSelectedIcon(tile?.icon_image);
        setCurrentTile(tile);
        setIsEdit(true);
        reset({
            "tile_name": tile?.tile_name,
            "subtitle": tile?.subtitle,
            "active_from": (startDate),
            "active_to": (endDate),
            "tile_status": tile?.tile_status,
            "is_display_button": tile?.is_display_button,
            "button_label": tile?.button_label,
            "button_color": tile?.button_color ? tile?.button_color : '#168500',
            "button_text_color": tile?.button_text_color ? tile?.button_text_color : '#168500',
            "button_action": tile?.button_action,
            "button_action_value": tile?.button_action_value
        });
        handleShow()
    }

    const updateTile = (formValues) => {
        setTileLoader(true);
        const formData = Object.assign({ icon_image: selectedIcon }, formValues);
        formData.active_from = new Date(formData.active_from + ' 00:00:00').toISOString();
        formData.active_to = new Date(formData.active_to + ' 23:59:59').toISOString();
        formData.button_text_color = getTextColorByBG(formData.button_color);
        axios.put((UPDATE_TILE + currentTile?.id), formData)
            .then((res) => {
                setTileLoader(false);
                if (res.data?.status) {
                    handleClose();
                    props?.getTiles();
                } else {
                    alert('Unable to update record');
                }
            }).catch((error) => {
                setTileLoader(false);
                alert('Unable to update record');
            })
    }
    //implementing add edit tiles functionality end


    //implementing functionality to handle image icon view start
    const [showIconView, setShowIconView] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState('');


    const ReturnBackIcon = () => {
        return showIconView ? <i
            title='Back'
            onClick={() => setShowIconView(false)}
            className="cursor-pointer f-18 me-3 fa-solid fa-chevron-left"></i> : ''
    }
    //implementing functionality to handle image icon view end

    //button action value implementation start
    const [campaignList, setCampaignList] = useState([]);
    const [allCampaignList, setAllCampaignList] = useState([]);
    const [campaignLoader, setCampaignLoader] = useState([]);

    const getAllCampaigns = () => {
        setCampaignLoader(true);
        axios.get(CAMPAIGNS_NAMES + 'both')
            .then((res) => {
                setCampaignLoader(false);
                if (res?.data?.status) {
                    setCampaignList(res?.data?.response);
                    setAllCampaignList(res?.data?.response);
                }
            }).catch((error) => {
                setCampaignLoader(false);
            });
    }
    useEffect(() => {
        getAllCampaigns();
    }, [])
    //button action value implementation end

    //filtering campaigns based on selected action
    const filterCampaignList = (action) => {
        if(action === 'specific product' || action === 'specific campaign'){
            let type = action === 'specific product' ? 'product' : 'campaign';
            const result = allCampaignList.filter(it => it.type === type);
            setCampaignList(result);
        }
        
    }

    return (
        <Modal
            size={'lg'}
            keyboard={false}
            backdrop="static" show={show} onHide={handleClose} animation={true}>
            <Form onSubmit={handleSubmit(isEdit ? updateTile : addTile)}>
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex align-items-center justify-content-center'>
                        {/* <ReturnBackIcon />  */}
                        {isEdit ? `Edit` : 'Create'} Tile
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='position-relative' style={{
                    height: showIconView ? '500px' : '',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}>
                    {/* {
                        showIconView ?
                            <IconView setSelectedIcon={setSelectedIcon} selectedIcon={selectedIcon} />
                            :
                    } */}

                    <Row>
                        <Col xs={6}>
                            <Form.Group >
                                <Form.Label><span className='text-danger'>*</span> Name</Form.Label>
                                <Form.Control
                                    {...register('tile_name', {
                                        validate: {
                                            required: value => {
                                                if (!value) return 'Field is required.';
                                                return true;
                                            },
                                        }
                                    })}
                                    type="text"
                                    placeholder="Enter Title Name" />
                            </Form.Group>
                            {errors?.tile_name ? <FieldError message={errors?.tile_name?.message} /> : ''}
                        </Col>
                        <Col xs={6}>
                            <Form.Group >
                                <Form.Label>Subtitle</Form.Label>
                                <Form.Control
                                    {...register('subtitle')}
                                    type="text"
                                    placeholder="Enter Subtitle" />
                            </Form.Group>
                        </Col>
                        <Col xs={3} className='mt-2'>
                            <Form.Group >
                                <Form.Label><span className='text-danger'>*</span> From</Form.Label>
                                <Form.Control
                                    {...register('active_from', {
                                        validate: {
                                            required: value => {
                                                if (!value) return 'Field is required.';
                                                return true;
                                            },
                                        }
                                    })}
                                    type="date" placeholder="Select From" />
                            </Form.Group>
                            {errors?.active_from ? <FieldError message={errors?.active_from?.message} /> : ''}
                        </Col>
                        <Col xs={3} className='mt-2'>
                            <Form.Group >
                                <Form.Label><span className='text-danger'>*</span> To</Form.Label>
                                <Form.Control
                                    {...register('active_to', {
                                        validate: {
                                            required: value => {
                                                if (!value) return 'Field is required.';
                                                return true;
                                            },
                                        }
                                    })}
                                    type="date" placeholder="Select To" />
                            </Form.Group>
                            {errors?.active_to ? <FieldError message={errors?.active_to?.message} /> : ''}
                        </Col>
                        <Col xs={6} className='mt-2'>
                            <Form.Group >
                                <Form.Label>Active</Form.Label>
                                <Form.Select {...register('tile_status')}>
                                <option value='active'>Yes</option>
                                <option value='in-active'>No</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        {/* <Col xs={12} className='mt-2'>
                            <div className="form-group">
                                <label className="form-label">
                                    Select Icon Image
                                </label>
                                <input
                                    placeholder="Campaign Title"
                                    type="file" className="form-control-alternative form-control" />
                            </div>
                        </Col> */}
                        <Col xs={6} className='mt-2'>
                            <div className="mt-1">
                                <label className="form-control-label d-flex align-items-center">
                                    <input
                                        {...register('is_display_button')}
                                        className="me-1" type="checkbox" />
                                    Show Button
                                </label>
                            </div>
                        </Col>
                        <Col xs={6} className='mt-2'>
                            <div className='d-flex justify-content-between align-items-center'>
                                {selectedIcon ? <img src={getImageUrl(selectedIcon)} className='image-icon small' /> : ""}
                                <Button type='button'
                                title={showIconView ? ' Close' : ' Select Image'}
                                    onClick={() => showIconView ? setShowIconView(false) : setShowIconView(true)}
                                    className='mb-0' variant="primary">
                                    <span className='d-flex align-items-center'>
                                    <i className={`fa-solid ${showIconView ? 'fa-circle-xmark' : 'fa-images'} me-1`}></i>
                                        <span className='d-sm-none d-none d-xl-block d-xxl-block d-lg-block d-md-block'>{showIconView ? ' Close' : ' Select Image'}</span>
                                        </span>
                                    </Button>
                            </div>
                        </Col>
                        {/* <Col className='mt-3'>
                            <Button variant="ma-primary" className='mb-0'>
                                Select Icon Image
                            </Button>
                        </Col> */}

                        {
                            watchFields?.is_display_button ?
                                <>
                                    <hr className='mt-2 mb-2' />
                                    {/* fields for tiles button start */}
                                    <Row>


                                    <Col>
                                            <Form.Group >
                                                <Form.Label><span className='text-danger'>*</span> Button Action</Form.Label>
                                                <Form.Select {...register('button_action', {
                                                    onChange: ((e) => {
                                                        setValue('button_action_value', '');
                                                        console.log(e.target.value)
                                                        if(
                                                            e.target.value?.toLowerCase() === 'specific campaign'
                                                            || e.target.value?.toLowerCase() === 'all campaigns'
                                                            || e.target.value?.toLowerCase() === 'all products'
                                                            || e.target.value?.toLowerCase() === 'specific product'
                                                            ){
                                                            setValue('button_label','Donate');
                                                            filterCampaignList(e.target.value);
                                                        }else if(e.target.value?.toLowerCase() === 'subscribe to mailing list'){
                                                            setValue('button_label','Subscribe');
                                                        }else{
                                                            setValue('button_label','Button Label');
                                                        }
                                                    }),
                                                    validate: {
                                                        required: value => {
                                                            if (!value && watchFields?.is_display_button) return 'Field is required.';
                                                            return true;
                                                        },
                                                    }
                                                })}>
                                                    {
                                                        buttonActionOptions.map((item, index) => {
                                                            return <option key={item.value + ' ' + index} value={item.value}>{item.label}</option>
                                                        })
                                                    }

                                                </Form.Select>
                                                {/* <Form.Control
                                                            {...register('button_action', {
                                                                validate: {
                                                                    required: value => {
                                                                        if (!value && watchFields?.is_display_button) return 'Field is required.';
                                                                        return true;
                                                                    },
                                                                }
                                                            })}
                                                            type="text" placeholder="Enter Button Action" /> */}
                                            </Form.Group>
                                            {errors?.button_action ? <FieldError message={errors?.button_action?.message} /> : ''}
                                        </Col>

                                        {
                                            watchFields?.button_action === 'url' ?
                                                <Col>
                                                    <Form.Group >
                                                        <Form.Label><span className='text-danger'>*</span> Action Value</Form.Label>
                                                        <Form.Control
                                                            {...register('button_action_value', {
                                                                pattern: {
                                                                    value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                                                                    message: 'Invalid url entered.'
                                                                },
                                                                validate: {
                                                                    required: value => {
                                                                        if (!value) return 'Field is required.';
                                                                        return true;
                                                                    },
                                                                }
                                                            })}
                                                            type="text" placeholder="Enter Action Value" />
                                                    </Form.Group>
                                                    {errors?.button_action_value ? <FieldError message={errors?.button_action_value?.message} /> : ''}
                                                </Col> :
                                                (watchFields?.button_action === 'specific campaign' || watchFields?.button_action === 'specific product') ?
                                                    <Col >
                                                        <Form.Group >
                                                            <Form.Label>Action Value</Form.Label>
                                                            <Form.Select {...register('button_action_value', {
                                                                pattern: undefined,
                                                                validate: {
                                                                    required: value => {
                                                                        if (!value) return 'Field is required.';
                                                                        return true;
                                                                    },
                                                                }
                                                            })}>
                                                                <option value={''}>Select {watchFields?.button_action === 'specific campaign' ? 'Campaign' : 'Product'}</option>
                                                                {
                                                                    campaignList.map((item, index) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>{item.campaign_title}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Form.Select>
                                                        </Form.Group>
                                                        {errors?.button_action_value ? <FieldError message={errors?.button_action_value?.message} /> : ''}
                                                    </Col> : ''
                                        }
                                        
                                        <Col xs={6}>
                                            <Form.Group >
                                                <Form.Label><span className='text-danger'>*</span> Button Label</Form.Label>
                                                <Form.Control
                                                    {...register('button_label', {
                                                        validate: {
                                                            required: value => {
                                                                if (!value && watchFields?.is_display_button) return 'Field is required.';
                                                                return true;
                                                            },
                                                        }
                                                    })}
                                                    type="text" placeholder="Enter Button Label" />
                                            </Form.Group>
                                            {errors?.button_label ? <FieldError message={errors?.button_label?.message} /> : ''}
                                        </Col>

                                        <Col xs={6} className='mt-2'>
                                            <Form.Group >
                                                <Form.Label>Button Color</Form.Label>
                                                <Form.Control
                                                    {...register('button_color', {
                                                        value: '#168500'
                                                    })}
                                                    className='txt-color'
                                                    type="color" placeholder="Select Button Color" />
                                            </Form.Group>
                                        </Col>

                                        {/* <Col xs={3}>
                                            <Form.Group >
                                                <Form.Label>Button Text Color</Form.Label>
                                                <Form.Control
                                                    {...register('button_text_color', {
                                                        value: '#168500'
                                                    })}
                                                    type="color"
                                                    className='txt-color'
                                                    placeholder="Select Text Color" />
                                            </Form.Group>
                                        </Col> */}

                            
                                    </Row>
                                    {/* fields for tiles button end */}
                                </> : ''
                        }



                    </Row>

                    {
                        showIconView ?
                            <Row className='mt-3'>
                                <hr />
                                <Col>
                                    <p className='text-bold text-black'>Image Selection</p>
                                </Col>
                                <IconView setSelectedIcon={setSelectedIcon} selectedIcon={selectedIcon} />
                            </Row> : ''
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleClose}>
                        Cancel
                    </Button>
                    {/* {
                        !showIconView ?
                            <Button disabled={!selectedIcon || tileLoader} type='submit'
                                className={`${tileLoader ? 'position-relative button--loading' : ''}`}
                                variant="primary">
                                {isEdit ? 'Update' : 'Add'}
                            </Button> :
                            <Button onClick={(e) => {
                                e.preventDefault();
                                trigger();
                                if (isValid) {
                                    setShowIconView(true)
                                }
                            }} type='button' variant="primary">
                                Next
                            </Button>
                    } */}

                    <Button disabled={!selectedIcon || tileLoader} type='submit'
                        className={`${tileLoader ? 'position-relative button--loading' : ''}`}
                        variant="primary">
                        {isEdit ? 'Update' : 'Add'}
                    </Button>

                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export default AddEditTiles;