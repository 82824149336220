import React, { useEffect, useImperativeHandle, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ENVIRONMENT_CONSTANT } from '../../../../../constants/env.constant';
import { GET_CAMPAIGN_ICONS } from '../../../../../constants/endpoints';
import axios from 'axios';
import IconView from '../../../../../shared/icons-view/icon-view';

const CampaignIconSelection = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
          handleShow: handleShow,
          handleClose: handleClose
        };
      });

      const handleClose = () => {
        setShow(false);
        props?.toggleIconState("");
        setSelectedIcon("");
      };
      const handleShow = () => setShow(true);
      const [show, setShow] = useState(false);

      const [selectedIcon, setSelectedIcon] = useState('');



    return (
        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props?.heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '500px', overflowY: 'auto', overflowX: 'hidden' }}>
                {/* icon view  */}
                <IconView setSelectedIcon={setSelectedIcon}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="ma-primary" onClick={(() => {
                    props?.toggleIconState(selectedIcon);
                    setShow(false)
                })}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CampaignIconSelection;