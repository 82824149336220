import React, { useEffect, useState } from 'react';
import SquarePaymentForm from './payment-processors/square/square-payment-form';

function MyComponent(props) {

    return (
        <div className='text-center mt-10'>
            Pay Directly with
            <SquarePaymentForm 
            applicationId={'sandbox-sq0idb-Z4dtbHOtrZ12wMIHR_ItHw'}
            locationId={'LK6HBHT3T9XQ5'}
            onPaymentComplete={null}
            />
        </div>
    );
}

export default MyComponent;