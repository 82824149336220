import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CampaignOptions from '../../campaigns/campaign-form/shared/campaign-options';
import DonorInfoSettings from '../../campaigns/campaign-form/shared/donor-info-settings';
import MailingList from '../../campaigns/campaign-form/shared/mailing-list';
import PaymentSuccessSettings from '../../campaigns/campaign-form/shared/payment-success-settings';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FieldError from '../../../../shared/fields-errors/field-error';
import ProductOptions from './shared/product-options';
import axios from 'axios';
import { ADD_EDIT_PRODUCT, GET_PRODUCT_BY_ID } from '../../../../constants/endpoints';
import { Form } from 'react-bootstrap';
import { months } from '../../../../data-list/month.llist';
import { getSetOrgInfo } from '../../../../utils/user-info';
import UploadImageDialog from '../../../../shared/upload-image-dialog/upload-image-dialog';
import useUploadFile from '../../../../hooks/upload-file.hook';
import DonorCommentsForm from '../../campaigns/campaign-form/shared/donor-comments-form';

const ProductForm = React.forwardRef((props, ref) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const productID = searchParams.get('id');


    const {uploadImage} = useUploadFile();
    const childRef = useRef(null);
    const uploadImageDialogRef = useRef(null);
    const [fileName, setFileName] = useState("");
    const [currentFile, setCurrentFile] = useState(null);
    const [productLoader, setProductLoader] = useState(false);

    const [currentProduct, setCurrentProduct] = useState({});
    const methods = useForm();
    const { register, control, setValue, trigger, handleSubmit, unregister, reset, formState: { isValid, errors }, getValues, watch } = methods;
    const colorControl = register("primary_color");
    const watchAllFields = watch();
    const organizationInfo = getSetOrgInfo();

    const cancelCreateProduct = () => {
        navigate("/products");
    }

    const addUpdateProduct = async (formValues, isPreview) => {
        setProductLoader(true);
        const formData = Object.assign({}, formValues); 
        if(formData?.feature_campaign){
            if(currentFile){
                const fileUploadResponse = await uploadImage(currentFile);
                if(fileUploadResponse?.image_alt_name){
                    formData.image_location = fileUploadResponse?.image_location;
                    formData.image_alt_name = fileUploadResponse?.image_alt_name;
                }else{
                    alert("Failed to upload file");
                    setProductLoader(false);
                    return;
                }
            }else if(!currentProduct?.image_location){
                alert("Please select image for featured product.");
                setProductLoader(false);
                return
            }
        }
        
        if (childRef.current.selectedSource) {
            formData.campaign_icon = childRef.current.selectedSource
        }else{
            formData.is_campaign_icon = false;
            formData.campaign_icon = '';
        }
        if (currentProduct?.id) {
            formData.id = currentProduct.id;
        }

        console.log(formData)
        axios.post(ADD_EDIT_PRODUCT, formData)
            .then((res) => {
                if (res?.data?.status) {
                    if (isPreview === true) {
                        previewChanges();
                    } else {
                        navigate("/products");
                    }

                } else {
                    alert('Unable to save product info');
                }
            }).catch((error) => {
                alert('Unable to save product info');
            }).finally(() => {
                setProductLoader(false);
            })
    }

    const updateAndPreview = () => {
        if (!isValid) {
            trigger();
        } else {
            addUpdateProduct(getValues(), true)
        }
    }

    const previewChanges = () => {
        window.open(`
                ${organizationInfo?.organization_short_name ?
                organizationInfo?.organization_short_name : 'masjid'
            }/product/${currentProduct?.donation_page_link}`, '_blank').focus();
    }

    const getProductByID = () => {
        axios.get(GET_PRODUCT_BY_ID + searchParams.get('id'))
            .then((res) => {
                if (res?.data?.status) {
                    setCurrentProduct(res.data.response);
                    setFileName(res?.data?.response?.image_alt_name);
                    res.data.response.unit_label = res.data.response.unit_label ? res.data.response.unit_label : 'Unit';
                    reset(res.data.response);
                }
            }).catch((error) => {

            })
    }

    useImperativeHandle(ref, () => {
        return {
            previewChanges: previewChanges
        };
    });

    const setColor = (event) => {
        document.getElementById('txtColor').value = event.target.value;
    }

    useEffect(() => {
        if (searchParams.get('id')) {
            getProductByID();
        }else{
            setValue('google_apple_pay', true);
            setValue('unit_label', 'Unit');
        }
    }, [])

    return (
        <>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(addUpdateProduct)}
                className='col-xl-5 col-xl-55 col-md-12 col-sm-12 col-xs-12 left-form-panel p-0'>
                <div className='form-title text-white d-flex justify-content-start align-items-center bg-blue p-3'>
                    <a onClick={cancelCreateProduct}
                        className='text-white cursor-pointer'><i className="fa-solid fa-chevron-left me-2"></i></a>
                    <h5 className='text-white mb-0'>Product</h5>
                </div>

                <div className='campaign-form-body p-4'>
                    <div className='col-12 card'>
                        <div className='card-body'>
                            <div className="form-group"><label className="form-control-label text-black text-bold" >
                                Product Title <span className='text-danger'>*</span>
                            </label>
                                <input
                                    {...register("campaign_title", { required: true })}
                                    placeholder="Product Title"
                                    type="text" className="form-control-alternative form-control" />
                            </div>
                            {errors?.campaign_title && errors?.campaign_title?.type === 'required' ? <FieldError message={'Please fill out this field'} /> : ''}
                            <div className='form-group mt-3 d-flex justify-content-between align-items-center'>
                                <label className="form-control-label d-flex align-items-center">
                                    <input
                                        {...register(`feature_campaign`)}
                                        className="me-1" type="checkbox" /> Featured Product
                                </label>
                                <div>
                                {
                                    fileName ? 
                                    <>
                                    <span className='f-16 text-ma-primary mt-2'>{fileName}</span> 
                                    <a 
                                    onClick={() => {
                                        if(currentFile){
                                            uploadImageDialogRef?.current?.setImageSrcByFile(currentFile);
                                        }else{
                                            uploadImageDialogRef?.current?.setImageSrcByField(currentProduct);
                                        }
                                    }}
                                    className='mx-2 anchor cursor-pointer'>Change Image</a>
                                    </>
                                     : watchAllFields?.feature_campaign ? 
                                     <button type='button' onClick={uploadImageDialogRef?.current?.handleShow}
                                    className='btn btn-primary mb-0'>
                                       {fileName ? 'Change Image' : 'Upload Image'}
                                    </button> : ''
                                }
                                
                                </div>
                            </div>
                            {
                                watchAllFields?.feature_campaign ? 
                                <>
                                <div className='form-group mt-3'>
                                <label className="form-control-label d-flex align-items-center">
                                    Featured Product Description
                                </label>
                                <textarea
                        {...register("featured_campaign_desc", {
                            validate: {
                                required: (value) => {
                                    if(!value)return "This field is required";
                                    return true
                                }
                            }
                        })}
                            rows={5} placeholder="Enter description" className="form-control-alternative form-control" ></textarea>
                            </div>
                            {errors?.featured_campaign_desc ?
                                <FieldError message={errors?.featured_campaign_desc?.message} /> : ''}
                                </> : ''
                            }
                        </div>
                    </div>

                    {/* campaign options view start selectedCampaign={selectedCampaign}*/}
                    <ProductOptions ref={childRef} selectedProduct={currentProduct}/>
                    {/* campaign options view end */}
                    
                        {/* donor comments start */}
                        <DonorCommentsForm />
                        {/* donor comments end */}

                    <div className='col-12 card mt-3'>
                        <div className='card-body'>
                            <div className="form-group"><label className="form-control-label text-black text-bold" >
                                Unit Amount
                            </label><input
                                    {...register("price_per_unit", {
                                        validate:{
                                            required: value => {
                                                if (!value) return 'Amount is required';
                                               return true;            
                                           },
                                           min: value => {
                                                   if (value <= 0){
                                                       return `Amount cannot be less then or equal to 0`
                                                   };
                                               return true;            
                                           }
                                        }
                                    })}
                                    placeholder="Unit"
                                    type="number" className="form-control-alternative form-control" /></div>
                                    {errors?.price_per_unit ? <FieldError message={errors?.price_per_unit?.message} /> : ''}
                            <div className="form-group mt-3">
                                <label className="form-control-label text-black text-bold" >
                                    Unit Label
                                </label><input
                                    {...register("unit_label")}
                                    placeholder="Unit Label"
                                    type="text" className="form-control-alternative form-control" /></div>
                        </div>
                    </div>
                    <div className='col-12 card mt-3'>
                        <div className='card-body'>
                            <div className="form-group"><label className="form-control-label text-black text-bold" >
                                Available Quantity (Optional)
                            </label><input
                                    {...register("goal_amount")}
                                    placeholder="Goal Amount"
                                    type="number" className="form-control-alternative form-control" /></div>
                                {
                                    watchAllFields?.goal_amount > 0 ? 
                                    <div className='form-group mt-3'>
                                <label className="form-control-label d-flex align-items-center">
                                    <input
                                        {...register(`sell_after_out_of_stock`)}
                                        className="me-1" type="checkbox" /> Continue selling after out of stock
                                </label>
                            </div> : ''
                                }
                             
                        </div>
                    </div>
                    <div className='col-12 card mt-3'>
                        <div className='card-body'>
                            <div className="form-group"><label className="form-control-label text-black text-bold">
                                Reset Count
                            </label>
                                <Form.Select {...register(`reset_count`)}>
                                    <option value={"none"}>None</option>
                                    <option value={"monthly"}>Monthly</option>
                                    <option value={"yearly"}>Yearly</option>
                                </Form.Select>
                            </div>
                            {
                                watchAllFields?.reset_count === 'yearly' ?
                                    <div className="form-group mt-3"><label className="form-control-label text-black text-bold">
                                        Reset Count on the 1st of a Selected Month
                                    </label>
                                        <Form.Select {...register(`reset_count_month`,{
                                            required: true
                                        })}>
                                            {
                                                months.map((item, index) => {
                                                    return <option key={`'month-${item.value}`} 
                                                    value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </Form.Select>
                                        {errors?.reset_count_month ? <FieldError message={'This field is required'} /> : ''}
                                    </div> : ''
                            }
                        </div>
                    </div>
                    <div className='col-12 card mt-3'>
                        <div className='card-body'>
                            <div className="form-group"><label className="form-control-label text-black text-bold" >
                                Primary Color
                                <p className='f-14 mb-0 text-secondary'>Pick a color or change the hexadecimal code</p>
                            </label>

                                <div className='d-flex justify-content-start align-items-center'>
                                    <div className='w-20 me-2'>
                                        <input type="color"
                                            {...register('primary_color', {
                                                onChange: ((e) => {
                                                    setColor(e);
                                                })
                                            })}
                                            className='form-control w-100' style={{ height: '2.5rem' }} />
                                    </div>

                                    <div className='w-80'>
                                        <input id='txtColor'
                                         onKeyUp={(e) => {
                                            if(e.target.value){
                                                setValue('primary_color',e.target.value)
                                            }
                                        }}
                                            defaultValue={watchAllFields?.primary_color}
                                            placeholder="Primary Color"
                                            type="text" className="form-control-alternative form-control" /></div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className='col-12 card mt-3'>
                        <div className='card-body'>
                            {/* <span className='text-black text-bold'>UltraSwift<sup>TM</sup> Checkout</span> */}
                            <span className='text-black text-bold'>Apple & Google Pay</span>
                            <p>
                                Allow donors to speed through checkout in just a few clicks using Apple Pay, Google Pay.
                            </p>
                            <div>
                                <input {...register("google_apple_pay")} className='me-1' type="checkbox" />
                                <label>
                                    {/* Apple & Google<sup>pay </sup> Enabled */}
                                    Apple & Google Pay
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* processing fee start */}
                    <div className='col-12 card mt-3'>
                        <div className='card-body'>
                            <label className="form-control-label text-black text-bold">
                                Processing Fee
                            </label>

                            <div className='form-group mt-3'>
                                <label className="form-control-label d-flex align-items-center">
                                    <input
                                        {...register(`donation_amount.ask_donors_cover_fee`)}
                                        className="me-1" type="checkbox" /> Ask donors to cover fee
                                </label>
                            </div>
                            <div className='form-group mt-2'>
                                <label className="form-control-label d-flex align-items-center">
                                    <input
                                        {...register(`donation_amount.ask_donors_cover_fee_default`)}
                                        className="me-1" type="checkbox" /> Ask donors to cover fees by default
                                </label>
                            </div>

                        </div>
                    </div>
                    {/* processing fee end */}

                    {/* donate labels start */}
                    <div className='col-12 card mt-3'>
                        <div className='card-body'>
                            <label className="form-control-label text-black text-bold">
                                "Donate" Labels
                            </label>

                            <div className='form-group mt-3'>
                                <label className="form-control-label d-flex align-items-center">
                                    <input
                                        {...register(`donation_amount.change_label_to_pay`)}
                                        className="me-1" type="checkbox" /> Change "Donate" labels to "Pay"
                                </label>
                            </div>

                        </div>
                    </div>
                    {/* donate labels end */}

                    {/* compliance and disclaimer start  */}
                    <div className='col-12 card mt-3'>
                        <div className='card-body'>
                            <label className="form-control-label text-black text-bold">
                                Compliance and Disclaimer
                            </label>

                            <div className="form-group mt-3">
                                <textarea
                                    {...register(`donation_amount.compliance`)}
                                    rows={2} placeholder="Enter your tax status, if applicable" className="form-control-alternative form-control" ></textarea>
                            </div>

                        </div>
                    </div>
                    {/* compliance and disclaimer end  */}

                    {/* donor info view start */}
                    <DonorInfoSettings />
                    {/* donor info view end */}

                    {/* mailing list start */}
                    <MailingList />
                    {/* mailing list end */}

                    {/* payment success message view start */}
                    <PaymentSuccessSettings watchAllFields={watchAllFields} />
                    {/* payment success message view end */}
                </div>

                <div className='campaign-form-footer'>
                    <button onClick={cancelCreateProduct} disabled={productLoader} type="button"
                        className="btn btn-outline-secondary mx-3">Cancel</button>
                    <div>
                        <button disabled={productLoader} type="submit"
                            className={`btn btn-ma-primary btn-md me-3 ${productLoader ? 'button--loading position-relative ' : ''}`}>
                            {productID ? 'Update' : 'Add'}
                        </button>
                        {
                            productID ?
                                <button disabled={productLoader} type="button"
                                    onClick={updateAndPreview}
                                    className={`btn btn-ma-primary btn-md me-3 ${productLoader ? 'button--loading position-relative ' : ''}`}>
                                    Update & Preview
                                </button> : ''
                        }
                    </div>
                </div>
            </form>
        </FormProvider>
        {/* //upload image dialog start */}
        <UploadImageDialog 
        ref={uploadImageDialogRef} 
        setFileName={setFileName}
        currentValue={currentProduct}
        setCurrentFile={setCurrentFile} />
        {/* //upload image dialog end */}
        </>
    );
});

export default ProductForm;