import './payment-processors.scss'
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ENVIRONMENT_CONSTANT } from '../../../../constants/env.constant';
import { STRIPE_ONBOARDING_ENDPOINTS } from '../../../../constants/stripe-onboarding.endpoints';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';
import { useSearchParams } from 'react-router-dom';
import AlertBox from '../../../../shared/alert-box/alert-box';
import SquarePaymentProcessor from './square-payment-processor';

function PaymentProcessorsComponent() {
    
    const confirmationDialogRef = useRef(null);
    const alertDialogRef = useRef(null);
    const [params] = useSearchParams();

    // let url = ENVIRONMENT_CONSTANT.API_ENDPOINT;
    const [paymentToggle, setPaymentToggle] = useState(true);

    //user session
    var user = (localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {});

    const [connectAccountInfo,setConnectAccountIfo] = useState({});
    const [accountInfoLoader, setAccountInfoLoader] = useState(false);

    function togglePayment(){
        setPaymentToggle(!paymentToggle)
    }

    const getConnectAccountInfo = () =>{
        
        setAccountInfoLoader(true);
        let endpoint = `${STRIPE_ONBOARDING_ENDPOINTS.GET_ACCOUNT}${user?.masjid_id}`;
        axios.get(endpoint)
        .then((res) => {
            
            if(res?.data){
                setConnectAccountIfo(res?.data);
            }
            
            // if(res?.data?.response === 'User not found'){
                
            // }else if(res?.data?.response?.stripe_account_id){

            // }
            setAccountInfoLoader(false);
        }).catch((error) => {
            setAccountInfoLoader(false)
        })
    }

    const createConnectAccount = () => {
        // window.open("https://stackoverflow.com/questions/27397266/onclick-event-binding-in-react-js","_self")
        setAccountInfoLoader(true);
        let endpoint = STRIPE_ONBOARDING_ENDPOINTS.CREATE_ACCOUNT;
        axios.post(endpoint,{email:user.email,masjid_id:user.masjid_id})
        .then((res) => {
            if(res?.data?.response?.url){
                window.open(res?.data?.response?.url,"_self")
            }else{
                setAccountInfoLoader(false);
                alertDialogRef.current.openDialog('Failed to generate onboarding link');
            }
            
        }).catch((error) => {
            setAccountInfoLoader(false);
        })
    }

    const finishStripeOnBoarding  = () => {
        setAccountInfoLoader(true);
        // let endPoint = `https://api.stripe.com/v1/account_links`;
        let endPoint = STRIPE_ONBOARDING_ENDPOINTS.GET_ONBOARDING_LINKS;
        let formData = `account=${connectAccountInfo?.response?.stripe_account_id}&refresh_url=${ENVIRONMENT_CONSTANT.REFRESH_URL}payment-processors/&return_url=${ENVIRONMENT_CONSTANT.RETURN_URL}/&type=account_onboarding`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`
          }
        axios.post(endPoint,formData,{
            headers: headers
        })
        .then((res) => {
            if(res?.data?.url){
                window.open(res?.data?.url,"_self")
            }else{
                setAccountInfoLoader(false);
                alertDialogRef.current.openDialog('Failed to generate onboarding link');
            }
        }).catch((error) => {
            setAccountInfoLoader(false);
            alertDialogRef.current.openDialog(error?.response?.data?.error?.message ? error?.response?.data?.error?.message : 'Failed to generate onboarding link');
        })
    }

    const removeStripeAccount = () => {
        setAccountInfoLoader(true);
        // let endPoint = `${STRIPE_ONBOARDING_ENDPOINTS.DELETE_ACCOUNT}${connectAccountInfo?.response?.id}/${connectAccountInfo?.response?.stripe_account_id}`
        let endPoint = `${STRIPE_ONBOARDING_ENDPOINTS.DELETE_ACCOUNT}`;
        axios.post(endPoint,{id:connectAccountInfo?.response?.id,stripe_account_id:connectAccountInfo?.response?.stripe_account_id})
        .then((res) => {
            setAccountInfoLoader(false);
            if(res?.data?.status){
                setConnectAccountIfo({})
            }else{
                alertDialogRef.current.openDialog('Unable to remove stripe account');
            }
        }).catch((error) => {
            alertDialogRef.current.openDialog('Unable to remove stripe account');
            setAccountInfoLoader(false);
        })
    }

    useEffect(() => {
        const stripeAuthCode = params.get('code');
        if(stripeAuthCode){
          completeStripeOnboarding(stripeAuthCode);
        }else{
          getConnectAccountInfo();
        }
        
    },[])

    //getting Oauth onboard link
    const getOAuthLink = () => {
      setAccountInfoLoader(true);
      axios.get(STRIPE_ONBOARDING_ENDPOINTS.STRIPE_OAUTH_LINK)
      .then((res) => {
        if(res?.data?.status){
          window.location =  res?.data?.response?.onboard_link;
        }
        // setAccountInfoLoader(false);
      }).catch((error) => {
        setAccountInfoLoader(false);
      })
    }

    //completing a onboarding process for existing stripe account
    const completeStripeOnboarding = (authCode) => {
      setAccountInfoLoader(true);
      const formData = {
        code: authCode,
        email: user?.email ? user?.email : ''
      }
        axios.post(STRIPE_ONBOARDING_ENDPOINTS.ONBOARD_EXISTING_STRIPE_ACCOUNT, formData)
        .then((res) => {
          if(res?.data?.status){
            getConnectAccountInfo();
          }else{
            setAccountInfoLoader(false);  
            alertDialogRef.current.openDialog(res?.data?.message ? res?.data?.message : "Failed to connect your stripe account");
          }
        }).catch((error) => {
          setAccountInfoLoader(false);
          alertDialogRef.current.openDialog('Failed to connect your stripe account');
        })
    }

    const loaderTemplate = () => {
        return(
        <div className='loader-wrapper'>
            <div className="main-loader spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        )
    }
  
    return (
      <>
        <div className="row">
          <div className="col-xl-6 col-xl-6 col-xl-6 col-sm-12">
            
          {/* <button
                          onClick={getOAuthLink}
                          type="button"
                          className="btn btn-default btn-md"
                        >
                          Already have a Stripe account?
                        </button> */}
            {!connectAccountInfo?.response?.stripe_account_id ? (
              <div className="card shadow" style={{
                minHeight: '315px'
              }}>
                {accountInfoLoader ? loaderTemplate() : ""}
                <div className="stripe card-header bg-white border-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h3 className="mb-0">Connect Stripe</h3>
                    </div>
                    <div>
                      <span className="btn btn-sm btn-danger mb-0 pointer-event-none">
                        Not Connected
                      </span>
                    </div>
                  </div>
                  <div className="stripe--connect connect d-flex justify-content-start align-items-center">
                    <div className="w-15">
                      <img
                        className="w-100"
                        src="../img/masjid/stripe-logo.png"
                        alt="Stripe Logo"
                      />
                    </div>
                    <div>
                      <span className="text-ma-primary">
                        <i className="fa-sharp fa-solid fa-star"></i>
                        <span>Recommended</span>
                      </span>
                    </div>
                  </div>
                  {/* <p>Your current email address is <b className='font-weight-bold text-ma-primary'>harveyspectre090@gmail.com</b></p> */}
                  <p className="text-ma-primary mb-0">
                    Trusted by millions of companies around the world, Stripe
                    allows you to accept cards, low-fee bank payments, Apple
                    Pay, and Google Pay.
                  </p>
                </div>
                <div className="card-body">
                  <div className="stripe-connect-body">
                    <p className="text-ma-primary font-weight-bold">
                      Creating a Stripe account is simple and just takes a few
                      minutes.
                    </p>

                    <div className="d-flex justify-content-start align-items-center">
                      <div>
                        <button
                          onClick={createConnectAccount}
                          type="button"
                          className="btn btn-ma-primary btn-md"
                        >
                          Connect your Stripe account
                        </button>
                      </div>
                      <div className="ms-2">
                        <button
                          onClick={getOAuthLink}
                          type="button"
                          className="btn btn-default btn-md"
                        >
                          Already have a Stripe account?
                        </button>
                      </div>
                    </div>
                    <div className="general-info">
                      <p className="text-ma-primary">
                        See you Masjidal doing a great efforts to empower
                        masjids. Registered non-profits can contact Stripe for
                        discounted rates at
                        <a
                          className="text-warning"
                          href="mailto:nonprofit@stripe.com"
                        >
                          {" "}
                          nonprofit@stripe.com
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card shadow" style={{
                minHeight: '315px'
              }}>
                {accountInfoLoader ? loaderTemplate() : ""}
                <div className="stripe card-header bg-white border-0 pb-0">
                  <div className="stripe--connect connect d-flex justify-content-start align-items-center">
                    <div className="w-15">
                      <img
                        className="w-100"
                        src="../img/masjid/stripe-logo.png"
                        alt="Stripe Logo"
                      />
                    </div>
                    <div>
                      <div>
                        <span className="btn btn-sm btn-success mb-0 pointer-event-none">
                          Connected
                        </span>
                      </div>
                    </div>
                  </div>
                  <p className="text-ma-primary">
                    Your{" "}
                    <span className="font-weight-bold">
                      campaigns can now accept donations
                    </span>{" "}
                    from credit cards, Apple Pay, and Google Pay.
                  </p>
                </div>
                <div className="card-body">
                  <div>
                    <h6>Stripe Settings</h6>
                    <div className="stripe-settings"></div>
                    <div
                      className="d-flex hoverLink align-items-center mt-2 cursor-pointer"
                      onClick={togglePayment}
                    >
                      {/* <i className={'f-80 fa-solid fa-greater-than'+(paymentToggle ? ' icon-rotate' : '')}></i> */}
                      {/* <i className={'f-80 fa-sharp fa-solid fa-greater-than'+(paymentToggle ? ' icon-rotate' : '')}></i> */}
                      <span className="font-weight-bold">
                        Connected Account{" "}
                        <span className="font-weight-normal">
                          ({user?.email})
                        </span>
                      </span>
                    </div>
                    <div className={paymentToggle ? "d-block" : "d-none"}>
                      <p className="text-black">
                        Merchant Account: Masjidal Donation ({user?.email})
                      </p>
                      <div className="d-flex justify-content-start align-items-center mt-3">
                        <div>
                          {connectAccountInfo?.response?.charges_enabled &&
                          connectAccountInfo?.response?.details_submitted ? (
                            <button
                              type="button"
                              className="btn btn-ma-primary btn-md"
                            >
                              Update Account
                            </button>
                          ) : (
                            <button
                              onClick={finishStripeOnBoarding}
                              type="button"
                              className="btn btn-ma-primary btn-md"
                            >
                              Finish Stripe Onboarding
                            </button>
                          )}
                        </div>
                        <div className="ms-2">
                          <button
                            onClick={() => {
                                confirmationDialogRef.current.setDialogTitle('Confirm Removal');
                                confirmationDialogRef.current.setMessage("Are you sure you want to remove stripe account?");
                                confirmationDialogRef.current.setButtonLabel("Remove Account");
                                confirmationDialogRef.current.openDialog()
                            }}
                            type="button"
                            className="btn btn-danger btn-md"
                          >
                            Disconnect Account
                          </button>
                        </div>
                      </div>
                      {/* <div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1"/>
                                                <label className="custom-control-label" htmlFor="customCheck1">Enable U.S. Bank Transfers</label>
                                        </div>
                                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <SquarePaymentProcessor user={user} 
          alertDialogRef={alertDialogRef}
          loaderTemplate={loaderTemplate} />
        </div>
        {/* confirmation dialog start */}
        <ConfirmationDialog
          ref={confirmationDialogRef}
          executeAction={removeStripeAccount}
        />
        {/* confirmation dialog end */}

        {/* alert box */}
        <AlertBox ref={alertDialogRef} />
      </>
    );
}

export default PaymentProcessorsComponent