import axios from "axios";
import { useEffect, useState } from "react";
import { UPLOAD_FILE_URL } from "../constants/endpoints";

const useUploadFile = (initialValue, masjidInfo) => {
  const [file, setFile] = useState(initialValue);
  const [fileError, setFileError] = useState("");
  // const [fileName, setFileName] = useState("");
  const [fileProperties, setFileProperties] = useState({});
  const [fileUploadLoader, setFileUploadLoader] = useState("");
  const [imageBase64, setImageBase64] = useState("");

  const uploadFileToAWS = async (file, fileName) => {
    //first getting uploading url
    const request = await axios.post(UPLOAD_FILE_URL,{image_extension: getFileExtension(fileName)});
    if(request?.data?.status){
        var formData = new FormData();
        const fields = request.data?.response?.fields;
        for (var key in fields) {
          formData.append(key, fields[key]);
        }

        formData.append("file", file);
        axios.post(
            request.data?.response?.url,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data", exclude_auth: "yes" },
            }
          )
          .then((res) => {
            setFileUploadLoader(false);
            if(res?.status === 204){
              // setFileName(fileName);
              setFileProperties({...request.data?.response, ...{image_alt_name: fileName}});
            }else{
              setFileError("Failed to upload file");
            }
          })
          .catch((error) => {
            setFileError("Failed to upload file");
            setFileUploadLoader(false);
          });
    }else{
        setFile(null);
        // setFileName("");
        setFileProperties({});
        setFileError("Failed to upload file");
        setFileUploadLoader(false);
    }

  };

  const getImageSrc = (file) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      setImageBase64(reader.result);
      setFileProperties({image_alt_name: file?.name});
      setFile(file);
      console.log(reader.result);
    };
    reader.readAsDataURL(file);
  };

  //checking if file is valid or not
  const checkIsValidImage = (event, isDirectUpload) => {
    setFileProperties({});
    setFileError("");
    setFile(null);
    const file = event?.target?.files?.[0] ? event.target.files?.[0] : event;
    if (!file) {
      setFileError("Please select a file to upload");
      return;
    }
    const fileName = file?.name;
    const fileExtension = getFileExtension(fileName);
    if (!/png|jpg/.test(fileExtension)) {
      setFileError(
        "Invalid file selected, only images are allowed ex: image.png/image.jpg"
      );
      return;
    } else if (file.size > 2000000) {
      setFileError("File size exceeds, maximum 2mb is allowed to upload.");
      return;
    }else{
      getImageSrc(file);
      if(isDirectUpload){
        setFileUploadLoader(true);
        uploadFileToAWS(file, file?.name);
      }
    }
  };

  // clearing all the fields
  const clearProps = () => {
    setFileError("");
    setFile(null);
    setImageBase64("");
    setFileProperties({});
  }

  const uploadImage = async (file) => {
    setFileUploadLoader(true);
    const request = await axios.post(UPLOAD_FILE_URL,{image_extension: getFileExtension(file?.name)});
    if(request?.data?.status){
      var formData = new FormData();
        const fields = request.data?.response?.fields;
        for (var key in fields) {
          formData.append(key, fields[key]);
        }

        formData.append("file", file);

        const fileUploadResponse = await axios.post(
          request.data?.response?.url,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data", exclude_auth: "yes" },
          }
        )

        setFileUploadLoader(false);
        if(fileUploadResponse?.status === 204){
          setFileProperties({...request.data?.response, ...{image_alt_name: file?.name}});
          return {...request.data?.response, ...{image_alt_name: file?.name}};
        }else{
          setFileError("Failed to upload file");
          setFileProperties({});
          return 'Failed to upload file';
        }
    }else{
      setFileUploadLoader(false);
      setFileProperties({});
      setFileError("Failed to upload file");
      return 'Failed to upload file';
    }

  }

  const getFileExtension = (fileName) => {
    const fileExtension = fileName?.slice(
      (Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1
    );

    return fileExtension;
  }
  return {file, fileError, fileProperties,fileUploadLoader, checkIsValidImage, imageBase64,setImageBase64, clearProps, uploadImage};
};

export default useUploadFile;
