import { useState } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { LOGOUT_USER } from "../../../../constants/endpoints"
import { SidebarLinks } from '../../../../data-list/sidebar-links';
import { getUserInfo } from '../../../../utils/user-info';

function Sidebar() {

    const activeParentNavLink = window.location.pathname;
    const userInfo = getUserInfo();
    const [sidebarToggle, setSidebarToggle] = useState(true);
    const navigate = useNavigate();
    
    function toggleSidebar(){
        setSidebarToggle(!sidebarToggle)
    }

    const logout = () =>{
        localStorage.clear();
        window.location.href = LOGOUT_USER+`?time=${new Date().getTime()}`;
    }
    return (
      // <div>
      <>
        {/* <div onClick={toggleSidebar} style={sidebarToggle ? {left:'12%'} : {left:'-12px'}} className="position-absolute text-white cursor-pointer toggle-navbar-button">
                <i className="fa-solid fa-bars"></i>
            </div> */}

        <aside
          className={`sidenav bg-blue navbar navbar-vertical navbar-expand-xs border-0 fixed-start ${
            !sidebarToggle ? "nav-collapse" : ""
          }`}
          id="sidenav-main"
        >
          <div
            className="sidenav-header text-center"
            style={{ position: "relative" }}
          >
            {/* <div className="collapse-toggle">
        <div style={{ position: 'relative' }}>
            <span className={'fa-solid pt-2 fa-greater-than'}></span>
        </div>
    </div> */}
            <i
              className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
              aria-hidden="true"
              id="iconSidenav"
            ></i>
            <a
              className="navbar-brand m-0 text-white"
              href="https://masjidal.com/"
              target="_blank"
            >
              <img
                src="/img/masjid/masjid_logo.png"
                className="navbar-brand-img h-100"
                alt="main_logo"
              />
              {/* <span className="ms-1 font-weight-bold">Masjid AL</span> */}
            </a>
          </div>
          <hr className="horizontal dark mt-0" />
          <div style={{
            minHeight:'500px'
          }}
            className="collapse navbar-collapse p-3"
            id="sidenav-collapse-main"
          >
            <ul className="navbar-nav">
              {/* <li className="nav-item">
            <a className="nav-link">
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
            </a>
        </li> */}
              {SidebarLinks.map((navItem, index) => {
                return (
                    navItem.role === userInfo.role || navItem.role === -1 ?
                  <li key={`parent-link-`+index} className="nav-item">
                    <a
                      className={`nav-link`}
                      aria-expanded={(navItem?.label === "Kiosks" || navItem?.label === "Account") ? false : true}
                      href={`#${navItem.label?.toLowerCase()}`}
                      data-bs-toggle="collapse"
                    >
                      <i className={navItem.icon}></i>
                      <span className="nav-link-text ms-1 f-15 ">
                        {navItem?.label}
                      </span>
                    </a>
                    <ul
                      id={navItem.label?.toLowerCase()}
                      data-bs-parent="#menu"
                      className={`nested-nav
        ${
          navItem?.label === "Kiosks" || navItem?.label === "Account"
            ? "collapse"
            : "show"
        }
         ${
           (activeParentNavLink.includes("devices") ||
             activeParentNavLink.includes("sliders") ||
             activeParentNavLink.includes("tiles") ||
             activeParentNavLink.includes("payment-processors") ||
             activeParentNavLink.includes("organization")) &&
           (navItem?.label === "Kiosks" || navItem?.label === "Account")
             ? "show"
             : ""
         }`}
                    >
                      {navItem.links.map((item, linkIndex) => {
                        return (
                            item.role === userInfo?.role ? 
                          <li key={`child-nav-`+linkIndex} className="nav-item">
                            <NavLink
                              className={`nav-link 
                    ${
                      activeParentNavLink.includes(
                        "yearly-receipts-settings"
                      ) && navItem.label === "Yearly Receipts"
                        ? "active"
                        : ""
                    }`}
                              to={item.link}
                              activeclassname="active"
                            >
                              <span className="nav-link-text ms-1 f-12-small ">
                                {item.label}
                              </span>
                            </NavLink>
                          </li> : ''
                        );
                      })}
                    </ul>
                  </li> : ''
                );
              })}
            </ul>
          </div>
          <div className="sidenav-footer mx-3 ">
            <div className="card card-plain shadow-none" id="sidenavCard">
              <img
                className="w-50 mx-auto"
                src="/img/illustrations/icon-documentation.svg"
                alt="sidebar_illustration"
              />
              <div className="card-body text-center p-3 w-100 pt-0">
                <div className="docs-info text-white">
                  <h6 className="mb-0 text-white">Need help?</h6>
                  <a
                    href="https://mymasjidal.com/"
                    target="_blank"
                    className="text-white text-xs font-weight-bold mb-0"
                  >
                    Please visit Masjidal
                  </a>
                </div>
              </div>
            </div>
            {/* <a onClick={logout}  className="btn btn-dark btn-sm w-100 mb-3">Logout</a> */}
          </div>
        </aside>
      </>

      // </div>
    );
}

export default Sidebar;