import React from 'react';

function SharedFormPreviewComponent(props) {
    return (
        <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 form-view'>
            <div>
                <div className='mt-2'>
                    <h4>Example</h4>
                    {
                        props?.isSubtitle ? <p>This is a sample, your changes will not be reflected here. To view your changes, <a className='text-light-blue cursor-pointer' onClick={props?.triggerEvent}>click here.</a></p> : ''
                    }
                </div>
            </div>

            <div className='row'>
                <div className='col-xl-12 text-center'>
                    {/* <h4>{props?.title ? props?.title : 'Title'}</h4> */}
                    <img src={props?.image} className='img-fluid' />
                </div>
            </div>
        </div>
    );
}

export default SharedFormPreviewComponent;