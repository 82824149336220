import React from 'react';
import "./announcement.scss"
function AnnouncementBar(props) {
    return (
        <div className='d-flex justify-content-center align-items-center p-2 announcement-bar' >
            <div className='w-90 f-18'>

                <div className='d-flex justify-content-center'>
                    <b className='text-uppercase mx-1'>{props?.announcement?.title} : </b>
                    <p dangerouslySetInnerHTML={{
                        __html: props?.announcement?.content
                    }}>

                    </p>
                </div>
            </div>
            <div onClick={() => {
                props?.markAnnouncementAsRead(props?.announcement?.id);
            }} className='w-10 cursor-pointer text-right me-2'>
                <i className="fa-solid fa-xmark"></i>
            </div>
        </div>
    );
}

export default AnnouncementBar;