import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../shared/fields-errors/field-error';

function DonationDesignationTemplate(props) {
    
    const { register, setValue } = useFormContext();

    useEffect(() => {
        if(props?.campaignDetail){
            if(!props?.campaignDetail?.require_to_select){
                setValue('donation_designation',props?.campaignDetail?.designation_dropdown_value.split(',')[0]);
            }else{
                setValue('donation_designation','');
            }
            
        }
    },[props?.campaignDetail])
    return (
        <div className='col-12 mt-3 d-flex justify-content-center'>
            <div className={`form-group ${props?.descriptiveAmountView ? 'w-100' : 'w-75'}`}>
                <label>
                    <span className='form-control-label text-black'>
                        {props?.campaignDetail?.designation_label}
                    </span>
                </label>
                <select {...register('donation_designation', {
                    required: true
                })} className="form-control" placeholder={props?.campaignDetail?.no_designation_label}>
                    {
                        props?.campaignDetail?.require_to_select ? 
                        <option value={''}>{props?.campaignDetail?.no_designation_label ? props?.campaignDetail?.no_designation_label : 'Please Select'}</option> : ''
                    }
                    
                    {
                        props?.campaignDetail?.designation_dropdown_value?.split(',').map((item, index) => {
                            return (
                                <option key={item + index} value={item}>{item}</option>
                            )
                        })
                    }
                </select>
                {
                    props?.errors?.donation_designation && props?.errors?.donation_designation?.type === 'required' ?
                        <FieldError message={'Donation Designation is required'} /> : ''
                }
            </div>
        </div>
    );
}

export default DonationDesignationTemplate;