import React from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../shared/fields-errors/field-error';

function ChargeFixedNumberOfTime(props) {
    const { register, formState: { errors }, watch } = useFormContext();
    const fields = watch();

    return (
        <>
            <div className='col-lg-12 d-flex justify-content-center mt-3'>
                <div className={`form-group ${props?.descriptiveAmountView ? 'w-100' : 'w-75'}`}>
                    <label className="form-control-label d-flex align-items-center">
                        <input {...register('is_charge_number_of_times', {
                            value: false
                        })} className="me-1" type="checkbox" />
                        End recurring donations after
                    </label>
                    {
                        fields?.is_charge_number_of_times ?
                            <>
                                <div className='d-flex justify-content-start align-items-center'>
                                <input placeholder="Count"
                                    {...register('recurring_option_value', {
                                        validate: {
                                            required: value => {
                                                if (!value) return "This field is required";
                                                return true
                                            },
                                            min: value => {
                                                if (value <= 0) {
                                                    return `Value cannot be less then or equal to 0`
                                                };
                                                return true;
                                            }
                                        }
                                    })}
                                    type="number" className="mt-2 form-control-alternative form-control w-60" />
                                <span className='mx-2'>payments</span>
                                </div>
                            </> : ''
                    }

                </div>
            </div>
            <div className='text-center'>
            {errors?.recurring_option_value ? <FieldError message={errors?.recurring_option_value?.message} /> : ''}
            </div>
        </>
    );
}

export default ChargeFixedNumberOfTime;