import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import "./login.scss"
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { string, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FieldError from '../shared/fields-errors/field-error';
import { GET_ORGANIZATION_BY_ID, LOGIN } from '../constants/endpoints';
import axios from 'axios';
import { ENVIRONMENT_CONSTANT } from '../constants/env.constant';

function Login(props) {

    const [loginLoader, setLoginLoader] = useState(false);
    const navigate = useNavigate();

    // const email = 'donate@masjidal.com';
    // const password = 'd_#@q1ER';

    const listOfCredentials = [
        {email:"salman@gmail.com", password:'s*lm*n@123', masjid_id:'vbnbnm-ert-6456-fghgfh', image_url:'./img/masjid/test_masjid.png', masjid_name: 'Masjid Noor 1'},
        {email:"wasim@gmail.com", password:'m*sjid@123', masjid_id: 'mnmcv-fj545-vbvb-dffdg', image_url:'./img/masjid/test_masjid.png', masjid_name: 'Test Masjid 2'},
        {email:"donate@masjidal.com", password:'d_#@q1ER', masjid_id: 'dfsfd-3423-s556dfsd-fsdf', image_url:'./img/masjid/masjid_noor.png', masjid_name: 'Masjid Noor'},
    ];

    const schema = z.object({
        "email":string().min(1, 'Email is required.'),
        "password":string().min(1, 'Password is required.'),
    });
    const { register, handleSubmit, reset, clearErrors,setError, formState: { errors }, getValues } = useForm({
        defaultValues:{one_time_interval:true},
        resolver: zodResolver(schema)
    });

    const login = (formValues) => {
        setLoginLoader(true);
        axios.post(LOGIN, formValues)
        .then((res) => {
            setLoginLoader(false);
            if(res?.data?.status){
                localStorage.setItem(ENVIRONMENT_CONSTANT.USER_INFO, JSON.stringify(res?.data?.response));
                navigate("/admin/donations")
            }else{
                setError('password', { type: 'custom', message: res?.data?.message });
            }
        }).catch((error) => {
            setLoginLoader(false);
            console.log(error);
            setError('password', { type: 'custom', message: 'Invalid Credentials.' });
        })
        // const user = listOfCredentials.find(it => it.email === formData?.email && it.password === formData?.password);
        // if(user){
        //     setLoginLoader(true);
        //     const $timer = setTimeout(() => {
        //         setLoginLoader(false);
        //         clearInterval($timer);
        //         // localStorage.setItem('userInfo', JSON.stringify({ email: 'wasim@live.com', masjid_id: 'dfsfd-3423-s556dfsd-fsdf' }));
        //         getOrganization(user);
        //     }, 3000);
        // }else{
        //     setError('password', { type: 'custom', message: 'Invalid Credentials.' });
        // }
    }

    
    const getOrganization = (user) => {
        axios.get(GET_ORGANIZATION_BY_ID,{
            headers:{
                masjidid: user?.masjid_id
            }
        })
        .then((res) => {
            if(res?.data?.response?.id){
                localStorage.setItem('userInfo', JSON.stringify({ email: user.email, masjid_id: user.masjid_id,image_url: user.image_url, masjid_name: user.masjid_name  }));
                localStorage.setItem('organization_logo', (res?.data?.response?.logo ? res?.data?.response?.logo : './img/masjid/test_masjid.png'))
                navigate('/donations');
            }else{
                alert('Unable to login.')
            }
        }).catch((error) => {
            alert('Failed to login');
        })
    }

    return (
        <div className='bg-vector height-100-vh'>
            <div className='container'>
                <div className='row'>
                {/* <button onClick={() => {
                    const user = {};
                    user.info.name = "wasim essani";
                }}>Break the world</button>; */}
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-11 col-11 login-main'>
                        <div className='col-12 login-header text-center'>
                            <img src='/img/masjid/masjid_logo_auth.png' className="navbar-brand-img w-40" alt="main_logo" />
                            {/* <h4 className='mt-2'>LOGIN</h4> */}
                            <p className='mt-3 mb-0'>Don't have an account? <Link>
                                <span className='text-success'>Sign up</span>
                            </Link></p>
                        </div>

                        <div className='row'>
                            <div className='col-8 offset-2'>
                                <form onSubmit={handleSubmit(login)} className="pl-lg-4 login-form">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group"><label className="form-control-label" >
                                                Email
                                            </label>
                                                <input 
                                                {...register("email")}
                                                placeholder="Email" type="text" className="form-control-alternative form-control" />
                                            </div>
                                            {errors?.email?.message ? <FieldError message={errors?.email?.message} /> : ''}
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <div className="form-group">
                                                <label className="form-control-label">
                                                    Password
                                                </label>
                                                <input 
                                                {...register("password")}
                                                placeholder="Password" type="password" className="form-control-alternative form-control" />
                                            </div>
                                            {errors?.password?.message ? <FieldError message={errors?.password?.message} /> : ''}
                                        </div>
                                        <div className='col-12 mt-4 '>
                                            <div className='d-flex justify-content-start align-items-center'>
                                                <div>
                                                    <button disabled={loginLoader}
                                                        type="submit" className={`btn text-white btn-primary me-3 mb-0 position-relative 
                                                        ${loginLoader ? 'button--loading' : ''}`}>
                                                        <span className='button__text'>LOGIN</span>
                                                    </button>
                                                </div>
                                                <div>
                                                    <input className="me-1" type="checkbox" />
                                                    <label>
                                                        Remember
                                                    </label>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-lg-12 mt-3'>
                                            <p>Forgot Password ?</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className='row text-center mt-4'>
                            <div className='col-12'>
                                {/* <p>Don't Have An Account</p> */}
                                <p>&copy; Masjidal 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;