import React, { useEffect, useRef, useState } from 'react';
import { CAMPAIGNS_NAMES } from '../../../constants/endpoints';
import { DONATION_COLUMNS_ADMIN } from '../../../constants/table-columns.constant';
import DatePickerDialog from '../../../shared/date-picker-dialog/date-picker-dialog';
import DonationInfo from '../../../shared/donation-info/donation-info';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import getFormattedDate from '../../../utils/date-format';
import { dateFilterList, intervalsList, paymentStatusList } from '../../../data-list/data-list';
import { ADMIN_CAMPAIGNS, ADMIN_ORGANIZATIONS } from '../../../constants/admin-endpoints';

function AllDonations(props) {
    const datePickerDialogRef = useRef(null);
    const timeFilters = [...dateFilterList,...[{ label: 'Custom Date', value: 'custom_date' }]];
    const [searchDonationModel, setSearchDonationModel] = useState('');
    const [campaignSearchModel, setCampaignSearchModel] = useState('');
    const [organizationSearchModel, setOrganizationSearchModel] = useState('');
    const [intervalSearchModel, setIntervalSearchModel] = useState('');
    const [paymentStatusSearchModel, setPaymentStatusSearchModel] = useState('success');
    const [timeSearchModel, setTimeSearchModel] = useState('');
  
    const [campaignsList, setCampaignsList] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
  
    const getCampaigns = () => {
      axios.get(ADMIN_CAMPAIGNS)
        .then((res) => {
          if (res?.data?.status) {
            setCampaignsList(res?.data?.response);
          }
        }).catch((error) => {
  
        });
    };

    const getOrganizations = () => {
      axios.get(ADMIN_ORGANIZATIONS)
        .then((res) => {
          if (res?.data?.status) {
            setOrganizationList(res?.data?.response);
          }
        }).catch((error) => {
  
        });
    };
  
    useEffect(() => {
      getCampaigns();
      getOrganizations();
    }, []);
  
    return (
      <div className='row'>
        <div className='col-xl-12'>
          <div>
            <h5 className='font-weight-semibold text-black'>Donations</h5>
          </div>
        </div>
  
        <div className="col-md-2 mt-2">
          <div className="form-group">
            <Form.Select
              value={timeSearchModel?.includes('from') ? 'custom_date' : timeSearchModel}
              onChange={(event) => {
                if(event.target.value !== 'custom_date'){
                  datePickerDialogRef?.current?.reset({
                    from:"",
                    to: ""
                  })
                  setTimeSearchModel(event.target.value)
                }else{
                  datePickerDialogRef?.current?.handleShow();
                }
                
              }}
              aria-label="Default select example">
              {
                timeFilters.map((item, index) => {
                  return <option key={item.value + ' ' + index} value={item.value}>{item.label}</option>
                })
              }
  
            </Form.Select>
            {
              datePickerDialogRef?.current?.getValues()?.from ?
              <p className='f-12-small text-bold'>Custom Range: {getFormattedDate(datePickerDialogRef?.current?.getValues()?.from)} - {getFormattedDate(datePickerDialogRef?.current?.getValues()?.to)} <a onClick={datePickerDialogRef?.current?.handleShow} className='f-12-small cursor-pointer text-ma-primary'>Change Date</a></p> : '' 
            }
            
          </div>
        </div>
  
        <div className="col-md-2 mt-2">
          <div className="form-group">
            <Form.Select
              value={paymentStatusSearchModel}
              onChange={(event) => setPaymentStatusSearchModel(event.target.value)}>
              {
                paymentStatusList.map((item, index) => {
                  return <option key={item.value}
                    value={item.value}>{item.label}</option>
                })
              }
              <option value={""}>Pending</option>
            </Form.Select>
          </div>
        </div>
        <div className="col-md-2 mt-2">
          <div className="form-group">
            <Form.Select
              value={intervalSearchModel}
              onChange={(event) => setIntervalSearchModel(event.target.value)}>
              {
                intervalsList.map((item, index) => {
                  return <option key={item.value + ' ' + index}
                    value={item.value}>{item.label}</option>
                })
              }
            </Form.Select>
          </div>
        </div>
  
        <div className="col-md-2 mt-2">
          <div className="form-group">
            <Form.Select
              value={campaignSearchModel}
              onChange={(event) => setCampaignSearchModel(event.target.value)}>
              <option value={""}>All Campaigns</option>
              {
                campaignsList.map((item, index) => {
                  return <option
                    key={'campaigns ' + index}
                    value={item.id}>{item.campaign_title}</option>
                })
              }
            </Form.Select>
          </div>
        </div>
        <div className="col-md-4 mt-2">
          <div className="form-group">
            <Form.Select
              value={organizationSearchModel}
              onChange={(event) => setOrganizationSearchModel(event.target.value)}>
              <option value={""}>All Organizations</option>
              {
                organizationList.map((item, index) => {
                  return <option
                    key={'organization_' + index}
                    value={item.masjid_id}>{item.organization_name}</option>
                })
              }
            </Form.Select>
          </div>
        </div>

        {/* date picker dialog start */}
        <DatePickerDialog 
        setTimeSearchModel={setTimeSearchModel}
        ref={datePickerDialogRef} />
        {/* date picker dialog end */}
  
        <div className='col-xl-12'>
          <DonationInfo
            isSuperAdmin={true}
            datePickerRef={datePickerDialogRef}
            campaignSearchModel={campaignSearchModel}
            organizationSearchModel={organizationSearchModel}
            intervalSearchModel={intervalSearchModel}
            paymentStatusSearchModel={paymentStatusSearchModel}
            timeSearchModel={timeSearchModel}
            showTitle={false}
            searchDonationModel={searchDonationModel}
            columns={DONATION_COLUMNS_ADMIN} />
        </div>
      </div>
    );
}

export default AllDonations;