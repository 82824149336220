import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ENVIRONMENT_CONSTANT } from '../../../../constants/env.constant';
import { activeTilesBasedOnDate } from '../../../../utils/utils';

const DraggableItem = ({ componentType, item, index, isDelete, removeItem, moveItem, containerID }) => {
    const ItemTypes = {
        CARD: 'card',
      }
    const TYPE = 'ITEM_TYPE';
    const iconUrl = ENVIRONMENT_CONSTANT.ICON_URL;
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
      accept: ItemTypes.CARD,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item, monitor) {
        if (!ref.current || containerID === 'main') {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        if(containerID === 'device') moveItem(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
    const [{ isDragging }, drag] = useDrag({
      // canDrag: item?.tile_status === 'active' ? true : false,
      type: ItemTypes.CARD,
      item: () => {
        if(componentType === 'tiles'){
          const { id, tile_name, icon_image, active_to, active_from, tile_status } = item;
          return { id,tile_name,icon_image, index, containerID, active_to, active_from, tile_status }
        }else{
          const { id, campaign_title, campaign_icon } = item;
          return { id, campaign_title, index, campaign_icon }
        }
        
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    })
  
    const returnTemplate = () => {
      if(componentType === 'tiles'){
        return (
          <div ref={ref}
          
           data-handler-id={handlerId} className={`tile w-100 ${isDragging ? 'dragging' : ''}`}>
              <div className='tile__content d-flex align-items-center justify-content-between'>
                  <div className='d-flex justify-content-start align-items-center'>
                      <img src={`${ENVIRONMENT_CONSTANT.CAMPAIGN_ICON_URL}${(item.icon_image?.includes('carpeting') ? item.icon_image.replace('.jpg.jpg','.jpg') : item.icon_image)}`} />
                      <p className='mb-0 f-18 text-black'>{item.tile_name}</p>
                      <span className={`badge badge-sm mx-3 
                                      ${(item?.tile_status === 'active' && activeTilesBasedOnDate(item) === true) ? 'bg-gradient-success' : 'bg-gradient-danger'
                                                              }`}>
                                                                  {
                                                                      (item?.tile_status === 'active' && activeTilesBasedOnDate(item) === true) ? 'Active' : 'In-Active'
                                                                  }
                      </span>
                      {/* <p className='mb-0 f-18 text-black'>{item.id}</p> */}
                  </div>
                  <div 
                  title={isDelete ? 'Remove Item' : ''}
                  className='cursor-pointer' onClick={() => removeItem(item)}>
                      <i className={`fa-solid ${isDelete ? 'fa-trash' : 'fa-grip-vertical'} f-18 text-black`}></i>
                  </div>
              </div>
          </div>
      );
      }else{
        return (
          <div ref={ref}
           data-handler-id={handlerId} className={`tile w-100 ${isDragging ? 'dragging' : ''}`}>
              <div className='tile__content d-flex align-items-center justify-content-between'>
                  <div className='d-flex justify-content-start align-items-center'>
                      <img src={item?.campaign_icon ? (ENVIRONMENT_CONSTANT.CAMPAIGN_ICON_URL + item.campaign_icon) : '/img/masjid/mosque.png'} />
                      <p className='mb-0 f-18 text-black'>{item.campaign_title}</p>
                  </div>
                  <div>
                      <i className={`fa-solid fa-grip-vertical f-18 text-black`}></i>
                  </div>
              </div>
              
          </div>
      );
      }
    }

    drag(drop(ref))
    // ${item?.tile_status === 'in-active' ? 'in-active-tile' : ''}
    // title={item?.tile_status === 'in-active' ? 'This tile is not active' : ''}
    return(
      returnTemplate()
    );
}

export default DraggableItem;