import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FieldError from '../../../../shared/fields-errors/field-error';
import { getSetOrgInfo, getUserInfo } from '../../../../utils/user-info';
import axios from 'axios';
import { GET_SETTINGS_BY_ID, SAVE_STORE_SETTINGS, UPDATE_CAMPAIGNS_ORDER } from '../../../../constants/endpoints';
import UploadImageDialog from '../../../../shared/upload-image-dialog/upload-image-dialog';
import useUploadFile from '../../../../hooks/upload-file.hook';
import { Form } from 'react-bootstrap';
import CampaignOrder from '../../../../shared/campaign-order/campaign-order';

const StoreEditForm = React.forwardRef((props, ref) => {
    //properties start

    //upload file props
    const campaignOrderRef = useRef(null);
    const { uploadImage } = useUploadFile();
    const uploadImageDialogRef = useRef(null);
    const [fileName, setFileName] = useState("");
    const [currentFile, setCurrentFile] = useState(null);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const params = useParams();
    const id = searchParams.id;
    const [requestLoader, setRequestLoader] = useState(false);
    const [masjidSettings, setMasjidSettings] = useState({});
    const userInfo = getUserInfo();
    const orgInfo = getSetOrgInfo();
    // const type = window.location.pathname.includes('product') ? 'product' : 'campaign'
    const type = params.type;

    const methods = useForm();
    const { register, control, setValue, trigger,
        handleSubmit, unregister,
        reset, formState: { isValid, errors }, getValues, watch } = methods;

    const watchAllFields = watch();
    //properties end

    //methods section start
    const saveSettings = async (formValues, isPreview) => {
        setRequestLoader(true);
        if (currentFile) {
            const fileUploadResponse = await uploadImage(currentFile);
            if (fileUploadResponse?.image_alt_name) {
                formValues.image_location = fileUploadResponse?.image_location;
                formValues.image_alt_name = fileUploadResponse?.image_alt_name;
            } else {
                alert("Failed to upload file");
                setRequestLoader(false);
                return;
            }
        } else if (!masjidSettings?.image_location) {
            alert("Please select a image for banner.");
            setRequestLoader(false);
            return
        }

        axios.post(SAVE_STORE_SETTINGS, formValues)
            .then((res) => {
                setRequestLoader(false);
                if (res?.data?.status) {
                    if (isPreview === true) {
                        previewChanges();
                    } else {
                        navigate(-1);
                    }
                } else {
                    alert(res?.data?.message);
                }
            }).catch((error) => {
                alert('Failed to save settings');
                setRequestLoader(false);
            })
    }

    const updateCampaignOrder = (formValues, isPreview) => {
        setRequestLoader(true);
        const requestBody = campaignOrderRef?.current?.campaignList.map(it => Object.assign({}, {
            id: it.id,
            campaign_order: it.campaign_order
        }));
        axios.post(UPDATE_CAMPAIGNS_ORDER, requestBody)
        .then((res) => {
            if(res?.data?.status){
                saveSettings(formValues, isPreview);
            }else{
                setRequestLoader(false);
                alert(res?.data?.message);
            }
        }).catch((error) => {
            setRequestLoader(false);
            alert("Failed to save settings");
        });
    }
    
    const updateAndPreview = () => {
        if (!isValid) {
            trigger();
        } else {
            if(type === 'both'){
                updateCampaignOrder(getValues(), true)
            }else{
                saveSettings(getValues(), true)
            }
            
        }
    }

    const cancelForm = () => {
        // navigate(type === 'product' ? '/products' : '/campaigns');
        navigate(-1);
    }

    const setColor = (event, elementID) => {
        if (document.getElementById(elementID)) {
            document.getElementById(elementID).value = event.target.value;
        }

    }

    const previewChanges = () => {
        window.open(`/${orgInfo?.organization_short_name}${type !== 'both' ? ('/'+type) : ''}`, '_blank').focus();
    }

    useImperativeHandle(ref, () => {
        return {
            previewChanges: previewChanges
        }
    });

    const getSettings = () => {
        axios.get(`${GET_SETTINGS_BY_ID}${userInfo?.masjid_id}/${type}`)
            .then((res) => {
                if (res?.data?.status) {
                    setMasjidSettings(res?.data?.response);
                    setFileName(res?.data?.response?.image_alt_name);
                    reset(res?.data?.response);
                } else {
                    setValue('title', 'Make A Donation');
                    setValue('description', 'The Prophet ﷺ said: "Whoever builds a masjid for Allah, Allah will build for him a similar house in Jannah” (Sahih Muslim).');
                }
            }).catch((error) => {

            })
    }

    useEffect(() => {
        getSettings();
    }, []);

    useEffect(() => {
        if (type) {
            setValue('type', type)
        }
    }, [])
    //methods section end
    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(type === 'both' ? updateCampaignOrder : saveSettings)}
                    className='col-xl-5 col-xl-55 col-md-12 col-sm-12 col-xs-12 left-form-panel p-0'>
                    <div className='form-title text-white d-flex justify-content-start align-items-center bg-blue p-3'>
                        <a onClick={cancelForm}
                            className='text-white cursor-pointer'><i className="fa-solid fa-chevron-left me-2"></i></a>
                        <h5 className='text-white mb-0'>Edit Page Settings</h5>
                    </div>

                    <div className='campaign-form-body p-4'>
                        <div className='col-12 card'>
                            <div className='card-body'>
                                <div className="form-group"><label className="form-control-label text-black text-bold" >
                                    Title <span className='text-danger'>*</span>
                                </label>
                                    <input
                                        {...register("title", {
                                            validate: {
                                                required: (value) => {
                                                    if (!value) return "The field is required";
                                                    return true
                                                }
                                            }
                                        })}
                                        placeholder="Page Title"
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                                {errors?.title ? <FieldError message={errors?.title?.message} /> : ''}
                                <div className='mt-2'>
                                    <label className="form-control-label d-flex align-items-center" >
                                        <input {...register("not_display_feature_campaign_image", {
                                            value: false
                                        })} className='me-1' type="checkbox" /> Do not display featured {type === 'both' ? 'campaign/product' : type} image on mobile
                                    </label>
                                </div>
                                <div className='mt-2'>
                                    <label className="form-control-label d-flex align-items-center" >
                                        <input {...register("hide_subtitle_on_mobile", {
                                            value: false
                                        })} className='me-1' type="checkbox" /> Hide subtitle on mobile
                                    </label>
                                </div>

                                <div className="mt-3 form-group"><label className="form-control-label text-black text-bold" >
                                    Subtitle <span className='text-danger'>*</span>
                                </label>
                                    <textarea
                                        {...register("description", {
                                            validate: {
                                                required: (value) => {
                                                    if (!value) return "This field is required";
                                                    return true
                                                }
                                            }
                                        })}
                                        rows={4} placeholder="Enter Description" className="form-control-alternative form-control" ></textarea>
                                </div>
                                {errors?.description ? <FieldError message={errors?.description?.message} /> : ''}

                                <div className="form-group mt-3"><label className="form-control-label text-black text-bold" >
                                    Main Text Color
                                </label>

                                    <div className='d-flex justify-content-start align-items-center'>
                                        <Form.Select
                                        {...register('main_text_color')}
                                            value={watchAllFields?.main_text_color}>
                                            <option value={'white'}>White</option>
                                            <option value={'black'}>Black</option>
                                        </Form.Select>
                                    </div>
                                </div>
                                {
                                    type === 'both' ? 
                                    <CampaignOrder ref={campaignOrderRef} /> : ''
                                }
                                
                                {/* <div className='form-group mt-2'>
                                <label className="form-control-label d-flex align-items-center">
                                    <input
                                        {...register(`feature_campaign`)}
                                        className="me-1" type="checkbox" /> Featured Product
                                </label>
                            </div>
                            {
                                watchAllFields?.feature_campaign ? 
                                <>
                                <div className='form-group mt-3'>
                                <label className="form-control-label d-flex align-items-center">
                                    Featured Product Description
                                </label>
                                <textarea
                        {...register("featured_campaign_desc", {
                            validate: {
                                required: (value) => {
                                    if(!value)return "This field is required";
                                    return true
                                }
                            }
                        })}
                            rows={5} placeholder="Enter description" className="form-control-alternative form-control" ></textarea>
                            </div>
                            {errors?.featured_campaign_desc ?
                                <FieldError message={errors?.featured_campaign_desc?.message} /> : ''}
                                </> : ''
                            } */}
                            </div>
                        </div>

                        <div className='col-12 card mt-3'>
                            <div className='card-body'>
                                <label className="form-control-label text-black text-bold" >
                                    Options
                                </label>
                                <div className='mt-2'>
                                    <label className="form-control-label d-flex align-items-center" >
                                        <input {...register("is_display_logo", {
                                            value: false
                                        })} className='me-1' type="checkbox" /> Display Logo
                                    </label>
                                </div>
                                <div className='mt-2'>
                                    <label className="form-control-label d-flex align-items-center" >
                                        <input {...register("hide_back_link", {
                                            value: false
                                        })} className='me-1' type="checkbox" /> Hide back to Masjid website link
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 card mt-3'>
                            <div className='card-body'>
                                <label className="form-control-label text-black text-bold">
                                    Banner Image
                                </label>

                                <div>
                                    {
                                        fileName ?
                                            <>
                                                <span className='f-16 text-ma-primary mt-2'>{fileName}</span>
                                                <a
                                                    onClick={() => {
                                                        if (currentFile) {
                                                            uploadImageDialogRef?.current?.setImageSrcByFile(currentFile);
                                                        } else {
                                                            uploadImageDialogRef?.current?.setImageSrcByField(masjidSettings);
                                                        }
                                                    }}
                                                    className='mx-2 anchor cursor-pointer'>Change Image</a>
                                            </>
                                            : <button type='button' onClick={uploadImageDialogRef?.current?.handleShow}
                                                className='btn btn-primary mb-0'>
                                                {fileName ? 'Change Image' : 'Upload Image'}
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-12 card mt-3'>
                            <div className='card-body'>
                                <div className="form-group"><label className="form-control-label text-black text-bold" >
                                    Primary Color
                                    <p className='f-14 mb-0 text-secondary'>Pick a color or change the hexadecimal code</p>
                                </label>

                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div className='w-20 me-2'>
                                            <input type="color"
                                                {...register('primary_color', {
                                                    onChange: ((e) => {
                                                        setColor(e, 'txtEditPageColor');
                                                    })
                                                })}
                                                className='form-control w-100' style={{ height: '2.5rem' }} />
                                        </div>

                                        <div className='w-80'>
                                            <input id='txtEditPageColor'
                                            onKeyUp={(e) => {
                                                if(e.target.value){
                                                    setValue('primary_color',e.target.value)
                                                }
                                            }}
                                                defaultValue={watchAllFields?.primary_color}
                                                placeholder="Primary Color"
                                                type="text" className="form-control-alternative form-control" /></div>
                                    </div>
                                </div>
                                
                                <div className="form-group mt-3"><label className="form-control-label text-black text-bold" >
                                    Secondary Color
                                </label>

                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div className='w-20 me-2'>
                                            <input type="color"
                                                {...register('secondary_color', {
                                                    onChange: ((e) => {
                                                        setColor(e, 'txtSecondaryColor');
                                                    })
                                                })}
                                                className='form-control w-100' style={{ height: '2.5rem' }} />
                                        </div>

                                        <div className='w-80'>
                                            <input id='txtSecondaryColor'
                                            onKeyUp={(e) => {
                                                if(e.target.value){
                                                    setValue('secondary_color',e.target.value)
                                                }
                                            }}
                                                defaultValue={watchAllFields?.secondary_color}
                                                placeholder="Secondary Color"
                                                type="text" className="form-control-alternative form-control" /></div>
                                    </div>
                                </div>
                                <div className="form-group mt-3"><label className="form-control-label text-black text-bold" >
                                    Button Color
                                </label>

                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div className='w-20 me-2'>
                                            <input type="color"
                                                {...register('button_color', {
                                                    onChange: ((e) => {
                                                        setColor(e, 'txtButtonColor');
                                                    })
                                                })}
                                                className='form-control w-100' style={{ height: '2.5rem' }} />
                                        </div>

                                        <div className='w-80'>
                                            <input id='txtButtonColor'
                                            onKeyUp={(e) => {
                                                if(e.target.value){
                                                    setValue('button_color',e.target.value)
                                                }
                                            }}
                                                defaultValue={watchAllFields?.button_color}
                                                placeholder="Button Color"
                                                type="text" className="form-control-alternative form-control" /></div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className='campaign-form-footer'>
                        <button onClick={cancelForm} disabled={requestLoader} type="button"
                            className="btn btn-outline-secondary mx-3">Cancel</button>
                        <div className='position-relative '>
                            <button disabled={requestLoader} type="submit"
                                className={`btn btn-ma-primary btn-md me-3 ${requestLoader ? 'button--loading' : ''}`}>
                                Save
                            </button>
                            <button onClick={updateAndPreview} disabled={requestLoader} type="button"
                                className={`btn btn-ma-primary btn-md me-3 ${requestLoader ? 'button--loading' : ''}`}>
                                Update & Preview
                            </button>
                        </div>
                    </div>
                </form>
            </FormProvider>

            {/* upload image dialog start */}
            <UploadImageDialog
                ref={uploadImageDialogRef}
                setFileName={setFileName}
                currentValue={masjidSettings}
                setCurrentFile={setCurrentFile}
            />
            {/* upload image dialog end */}
        </>
    );
});

export default StoreEditForm;