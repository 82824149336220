import React, { useEffect, useState } from 'react';
import { GET_ALL_PLEDGES } from '../../constants/endpoints';
import axios from 'axios';
import { Link } from 'react-router-dom';
import getFormattedDate from '../../utils/date-format';
import NoDataComponent from '../no-data/no-data';
import { Button } from 'react-bootstrap';
import Loader from '../loader/loader';

const PledgeTable = React.forwardRef((props, ref) => {

    //props 
    const columns = ["ID & Date", "Campaign", "Donor", "Amount", "Preferred Method", "Follow-Up Date", "Pledge Status"];
    const [requestLoader, setRequestLoader] = useState(false);
    const [pledges, setPledges] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [sortOrder, setSortOrder] = useState('desc');

    //methods
    const getAllPledges = () => {
        setRequestLoader(true);
        axios.get(`${GET_ALL_PLEDGES}?skip=${pageIndex}&status=${props?.pledgeStatusModel?.map((it => it.value))?.toString()}&campaign=${props?.campaignSearchModel}&search_text=${props?.searchModel}&donor_id=${props?.donor_id}&sort_order=${sortOrder}`)
            .then((res) => {
                setRequestLoader(false);
                if (res?.data?.status) {
                    setPledges(res?.data?.response);
                }
            }).catch((error) => {
                setRequestLoader(false);
            });
    }

    //effects 
    useEffect(() => {
        getAllPledges();
    }, [
        props?.pledgeStatusModel,
        props?.campaignSearchModel,
        props?.searchModel,
        pageIndex,
        sortOrder
    ]);

    return (
      <div className="row mt-3 position-relative">
        <div className="col-xl-12">
          <div className="card">
            <div className="table-responsive">
              {!requestLoader ? (
                <table className="table align-items-center ">
                  <thead className="text-center prayers-table-header white">
                    <tr>
                      {columns.map((item, index) => {
                        return  <th
                        onClick={() =>
                          index === 0
                            ? setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                            : ""
                        }
                        className={`table-sort ${
                          index === 0 ? "cursor-pointer" : ""
                        }`}
                        key={index}
                      >
                        {index === 0 ? (
                          <i
                            className={`text-bold fa-solid fa-arrow-${
                              sortOrder === "asc" ? "up" : "down"
                            }-short-wide mx-2`}
                          ></i>
                        ) : (
                          ""
                        )}
                        {item}
                      </th>
                      })}
                    </tr>
                  </thead>
                  <tbody className="prayer-table-body text-center">
                    {pledges?.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              to={`/pledges/detail/${item?.id}`}
                              className="text-light-blue"
                            >
                              {item.id.split("-")[0]?.toUpperCase()}
                            </Link>
                            <p>{getFormattedDate(item?.created_at)}</p>
                          </td>
                          <td>{item?.campaign?.campaign_title}</td>
                          {/* <td>
                                                            <Link to={`/donor-detail/${item?.donor?.id}/${item?.donor?.first_name +' '+item?.donor?.last_name}`} 
                                                            className='text-light-blue'>{item?.donor?.email}</Link>
                                                            </td> */}
                          <td>
                            {props?.donor_id ? (
                              item?.donor?.first_name +
                              " " +
                              item?.donor?.last_name
                            ) : (
                              <Link
                                to={`/donor-detail/${item?.donor?.id}/${
                                  item?.donor?.first_name +
                                  " " +
                                  item?.donor?.last_name
                                }`}
                                className="text-light-blue"
                              >
                                {item?.donor?.first_name +
                                  " " +
                                  item?.donor?.last_name}
                              </Link>
                            )}
                          </td>
                          <td>${item?.amount / 100}</td>
                          <td
                            className={`${
                              item?.preferred_payment_option === "ach"
                                ? "text-uppercase"
                                : "text-capitalize"
                            }`}
                          >
                            {item?.preferred_payment_option === "credit_card"
                              ? "Credit Card"
                              : item?.preferred_payment_option}
                          </td>
                          <td>
                            {item?.follow_up_date
                              ? getFormattedDate(item?.follow_up_date)
                              : "-"}
                          </td>
                          <td>
                            <span
                              className={`text-bold text-capitalize ${
                                item?.pledge_status?.toLowerCase() === "pending"
                                  ? "text-warning"
                                  : item?.pledge_status?.toLowerCase() ===
                                    "un_paid"
                                  ? "text-danger"
                                  : item?.pledge_status?.toLowerCase() ===
                                    "paid"
                                  ? "text-success"
                                  : item?.pledge_status?.toLowerCase() ===
                                    "cancelled"
                                  ? "text-danger"
                                  : "text-primary"
                              }`}
                            >
                              {item?.pledge_status === "Un_Paid"
                                ? "Unpaid" : 
                                item?.pledge_status === "Partial_Paid"
                                ? 'Partially Paid'
                                : item?.pledge_status}
                            </span>
                            <p>
                              <Link
                                to={`/pledges/detail/${item?.id}`}
                                className="text-primary"
                              >
                                View Details
                              </Link>
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                ""
              )}

              {/* no data message */}
              {!requestLoader && pledges?.length === 0 ? (
                <NoDataComponent message={"There is no pledges right now"} />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="mt-2 text-right">
            {pageIndex ? (
              <Button
                onClick={() => {
                  const index = pageIndex - 1;
                  setPageIndex(index);
                }}
                className="me-2"
                type="button"
                variant="primary"
              >
                Previous
              </Button>
            ) : (
              ""
            )}

            <Button
              disabled={pledges?.length > 0 ? false : true}
              onClick={() => {
                const index = pageIndex + 1;
                setPageIndex(index);
                // changePage(index)
              }}
              type="button"
              variant="primary"
            >
              Next
            </Button>
          </div>
        </div>
        {requestLoader ? <Loader /> : ""}
      </div>
    );
});

export default PledgeTable;