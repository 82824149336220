import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

function PaymentSuccessSettings(props) {

    const { register, unregister } = useFormContext();

    const toggleRedirectionField = () => {

    }

    useEffect(() => {
        if (props?.watchAllFields?.is_redirection_url) {
            register('redirection_url');
        } else {
            unregister('redirection_url');
        }
    }, [props?.watchAllFields?.is_redirection_url])
    return (
        <div className='mt-3 '>
            <div className='card col-12'>
                <div className='card-body'>
                    <label className="form-control-label text-black text-bold">
                        Thank You Message
                    </label>

                    <div className='form-group mt-3'>
                        <label className="form-control-label d-flex align-items-center">
                            A short message to show after the user donates
                        </label>
                        <textarea
                            {...register("success_message")}
                            rows={2} placeholder="Message" className="form-control-alternative form-control" ></textarea>
                    </div>
                </div>
            </div>
            <div className='card col-12 mt-3'>
                <div className='card-body'>
                    <label className="form-control-label text-black text-bold">
                        Redirection
                    </label>

                    <div className='form-group mt-3'>
                        <label className="form-control-label d-flex align-items-center">
                            <input {...register("is_redirection_url")} className="me-1" type="checkbox" /> Redirect to your URL after donation?
                        </label>
                        <p className='f-12-small mb-0 mt-2'>Select this option to redirect the donor to another page instead of our standard thank you page.</p>
                    </div>
                    {
                        props?.watchAllFields?.is_redirection_url === true ?
                            <div className='form-group mt-3'>
                                <label className="form-control-label d-flex align-items-center">
                                    Redirection URL
                                </label>
                                <input {...register("redirection_url")} placeholder="URL" type="text" className="form-control-alternative form-control" />
                            </div> : ""
                    }

                </div>
            </div>
        </div>
    );
}

export default PaymentSuccessSettings;