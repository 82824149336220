import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FieldError from '../../../../shared/fields-errors/field-error';
import { CAMPAIGNS_NAMES, SAVE_FUNDRAISING_SETTINGS } from '../../../../constants/endpoints';
import axios from 'axios';

const AssignCampaign = React.forwardRef((props,ref) => {
    //props
    const [loader, setLoader] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [show, setShow] = useState(false);
    const [type, setType] = useState("");
    const [campaignList, setCampaignList] = useState([]);
    const [currentValue, setCurrentValue] = useState({});

    // methods
    const handleClose = () => {
        setCurrentValue({});
        setType("");
        reset({
            campaign_id:''
        })
        setShow(false);
    };

    const handleShow = (value, type) => {
        setType(type);
        if(value?.id){
            setCurrentValue(value);
        }
        
        setShow(true);
    }

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow
        };
    });

    const assignCampaign = (formValues) => {
        setLoader(true);
        axios.post(`${SAVE_FUNDRAISING_SETTINGS}`, formValues)
        .then((res) => {
            setLoader(false);
          if (res?.data?.status) {
                props?.callback();
                handleClose();
          }else{
            alert(res?.data?.message ? res?.data?.message : 'Failed to save settings');
          }
        }).catch((error) => {
            setLoader(false);
            alert('Failed to save settings');
        });
    }

    const getCampaigns = () => {
        setLoader(true);
        axios.get(`${CAMPAIGNS_NAMES}both`)
          .then((res) => {
            setLoader(false);
            if (res?.data?.status) {
                setCampaignList(res?.data?.response);
            }
          }).catch((error) => {
            setLoader(false);
          });
    };

    useEffect(() => {
        if(type){
            getCampaigns();
        }
    }, [type]);

    useEffect(() => {
        if(campaignList?.length > 0){
            if(currentValue?.id){
                reset({
                    campaign_id: currentValue?.campaign?.id,
                    id: currentValue?.id,
                    show_pledge_qr: currentValue?.show_pledge_qr
                });
            }
            
        }
    }, [campaignList])
    
    return (
        <>
            <Modal
                keyboard={false}
                backdrop="static" show={show} onHide={handleClose} animation={true}>
                <Form onSubmit={handleSubmit(assignCampaign)}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-capitalize'>Assign {type}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" >
                            <Form.Label className='text-capitalize'>Select {type}</Form.Label>
                            <Form.Select {...register("campaign_id", {
                                required: {
                                    value: true,
                                    message: "This field is required"
                                }
                            })}>
                            <option value="">--Select--</option>
                                {
                                    campaignList?.map((item, index) => {
                                        return <option key={`cam_${index}`} value={item?.id}>{item?.campaign_title}</option>
                                    })
                                }
                            </Form.Select>
                            {errors?.campaign_id ? <FieldError message={errors?.campaign_id?.message} /> : ''}
                        </Form.Group>
                        <div className='mt-2'>
                    <label className="form-control-label d-flex align-items-center" >
                        <input {...register("show_pledge_qr")} className='me-1' type="checkbox" /> <span>Show Pledge QR Code</span>
                    </label>
                </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button disabled={loader} type='submit' variant="primary"
                            className={`${loader ? 'position-relative button--loading' : ''}`}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
});

export default AssignCampaign;