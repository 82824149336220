import React from 'react';

const ReceiptTemplate = React.forwardRef((props, ref) => {
    return (
        <div style={{
            padding: '10px'
        }} className="receipt-preview">
            <div
                style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between"
                }}
            >
                <div>
                    <img
                        src="./img/masjid/masjid_noor.png"
                        style={{ height: 100, maxWidth: "100%" }}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: 10 }}>
                        <span style={{ color: "#6A717F", fontSize: "9.5pt" }}>
                            Receipt ID:{" "}
                        </span>
                        <p
                            style={{
                                margin: 0,
                                color: "#6A717F",
                                fontWeight: "bold",
                                fontSize: "9.5pt"
                            }}
                        >
                            2022475012000001234
                        </p>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <span style={{ color: "#6A717F", fontSize: "9.5pt" }}>
                            Organization Tax ID:{" "}
                        </span>
                        <p
                            style={{
                                margin: 0,
                                color: "#6A717F",
                                fontWeight: "bold",
                                fontSize: "9.5pt"
                            }}
                        >
                            81-4249229
                        </p>
                    </div>
                    <div>
                        <span style={{ color: "#6A717F", fontSize: "9.5pt" }}>
                            Date Issued:{" "}
                        </span>
                        <p
                            style={{
                                margin: 0,
                                color: "#6A717F",
                                fontWeight: "bold",
                                fontSize: "9.5pt"
                            }}
                        >
                            11/03/2023
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <h2 style={{ fontSize: "21.5pt" }}>Your Annual Giving Report</h2>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <div
                        style={{
                            border: "1px solid lightgray",
                            borderRadius: 10,
                            padding: 16,
                            width: "100%"
                        }}
                    >
                        <span
                            style={{ color: "#101827", fontWeight: "bold", fontSize: "14.5pt" }}
                        >
                            Helpers of the Ummah
                        </span>
                        <p
                            style={{
                                color: "#6A717F",
                                fontSize: "9.5pt",
                                margin: "0pt",
                                marginTop: 10
                            }}
                        >
                            donate@helpersoftheummah.com
                        </p>
                        <p
                            style={{
                                color: "#6A717F",
                                fontSize: "9.5pt",
                                margin: "0pt",
                                marginTop: 10
                            }}
                        >
                            318 VERONICA CIR, BARTLETT, AL, US,
                        </p>
                    </div>
                    <div
                        style={{
                            border: "1px solid lightgray",
                            borderRadius: 10,
                            marginLeft: 10,
                            padding: 16,
                            width: "100%"
                        }}
                    >
                        <span
                            style={{ color: "#101827", fontWeight: "bold", fontSize: "14.5pt" }}
                        >
                            Wasim Essani
                        </span>
                        <p
                            style={{
                                color: "#6A717F",
                                fontSize: "9.5pt",
                                margin: "0pt",
                                marginTop: 10
                            }}
                        >
                            wasim@live.com
                        </p>
                        <p
                            style={{
                                color: "#6A717F",
                                fontSize: "9.5pt",
                                margin: "0pt",
                                marginTop: 10
                            }}
                        >
                            St 1, New York, NY, USA, 12345 555 2121
                        </p>
                    </div>
                </div>
            </div>
            <div style={{
                marginTop:'20px'
            }}>
                <h4 style={{ color: "black", fontSize: "14.5pt" }}>Transactions</h4>
                <table
                    style={{ width: "100%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                    <thead>
                        <tr
                            style={{ backgroundColor: "rgb(249, 250, 251)", textAlign: "left" }}
                        >
                            <th style={{ padding: 15, color: "#6A717F", fontSize: "7pt" }}>
                                Date
                            </th>
                            <th style={{ padding: 15, color: "#6A717F", fontSize: "7pt" }}>
                                Amount
                            </th>
                            <th style={{ padding: 15, color: "#6A717F", fontSize: "7pt" }}>
                                Payment Method
                            </th>
                            <th style={{ padding: 15, color: "#6A717F", fontSize: "7pt" }}>
                                Name
                            </th>
                            <th style={{ padding: 15, color: "#6A717F", fontSize: "7pt" }}>
                                Type
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ borderBottom: "1px solid rgb(249, 250, 251)" }}>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                07/05/2023
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>$500</td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                <span
                                    style={{
                                        padding: "5px 15px",
                                        backgroundColor: "#edebfe",
                                        color: "#6043c8",
                                        borderRadius: 5
                                    }}
                                >
                                    {" "}
                                    Stripe
                                </span>
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                Masjid Support
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                Campaign
                            </td>
                        </tr>
                        <tr style={{ borderBottom: "1px solid rgb(249, 250, 251)" }}>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                08/21/2023
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>$100</td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                <span
                                    style={{
                                        padding: "5px 15px",
                                        backgroundColor: "#edebfe",
                                        color: "#6043c8",
                                        borderRadius: 5
                                    }}
                                >
                                    {" "}
                                    Stripe
                                </span>
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                Masjid Support
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                Campaign
                            </td>
                        </tr>
                        <tr style={{ borderBottom: "1px solid rgb(249, 250, 251)" }}>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                10/20/2023
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                $1000
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                <span
                                    style={{
                                        padding: "5px 15px",
                                        backgroundColor: "#edebfe",
                                        color: "#6043c8",
                                        borderRadius: 5
                                    }}
                                >
                                    {" "}
                                    Stripe
                                </span>
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                Masjid Support
                            </td>
                            <td style={{ padding: 15, color: "black", fontSize: "7pt" }}>
                                Campaign
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{
                marginTop:'20px'
            }}>
                <h4 style={{ color: "black", fontSize: "14.5pt", marginBottom: 2 }}>
                    Totals
                </h4>
                <span style={{ color: "#6A717F", fontSize: "9.5pt", margin: "0pt" }}>
                    01/01/2022 - 12/31/2022
                </span>
                <div
                    style={{
                        marginTop: 16,
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        width: "20%"
                    }}
                >
                    <div style={{ backgroundColor: "rgb(249, 250, 251)", padding: 16 }}>
                        <span style={{ color: "#6A717F", fontWeight: "bold", fontSize: "7pt" }}>
                            TOTAL GIVINGS
                        </span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", padding: 16 }}>
                        <span style={{ marginTop: 5, color: "black", fontSize: "7pt" }}>
                            $500
                        </span>
                        <span style={{ marginTop: 5, color: "black", fontSize: "7pt" }}>
                            $100
                        </span>
                        <span style={{ marginTop: 5, color: "black", fontSize: "7pt" }}>
                            $1000
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: 50 }}>
                <span>Sincerely,</span>
                <p>Helpers of the Ummah</p>
                <p>
                    Thanks so much for your generosity. Please keep this document for tax
                    purposes. No goods or services were provided in return for these
                    contributions.
                </p>
                <p>Thank you!</p>
            </div>
        </div>

    );
});
export default ReceiptTemplate;