import PaymentProcessorsComponent from "./pages/components/accounts/payment-process/payment-processors.component";
import Dashboard from "./pages/components/dashboard";
import AuthUser from "./pages/components/authentication/auth";
import CampaignsComponent from "./pages/components/campaigns/campaigns.compoenent";
// import Unauthorized from "./Unauthorized/Unauthorized";
import MyComponent from "./pages/components/my-component";
import CreateCampaign from "./pages/components/campaigns/create-campaign/create-campaign.component"
import Main from "./pages/components/campaigns/campaign-form/main";
import CampaignDetail from "./pages/components/campaigns/campaing-detail/campaign-detail";
import Donations from "./pages/components/donations/donations";
import DonationWidget from "./pages/components/donation-widget/donation-widget";
import Login from "./login/login";
import OrganizationInfoComponent from "./pages/components/accounts/organization-info/organization-info.component";
// import Devices from "./pages/components/kiosks/devices/devices";
import Sliders from "./pages/components/kiosks/sliders/sliders";
import Tiles from "./pages/components/kiosks/tiles/tiles";
import EmailReceiptComponent from "./pages/components/email-receipt/email-receipt-main.component";
import UpdateDevice from "./pages/components/kiosks/update-device/update-device";
import RecurringPlans from "./pages/components/recurring-plans/recurring-plans";
import Subscribers from "./pages/components/subscribers/subscribers";
import Donors from "./pages/components/donors/donors";
import OfflineDonations from "./pages/components/offline-donations/offline-donations";
import DonorDetail from "./pages/components/donors/donor-detail";
import DonationDetail from "./pages/components/donations/donation-detail";
import Products from "./pages/components/products/products";
import ProductMain from "./pages/components/products/add-edit-products/product-main";
import ProductDonationWidget from "./pages/components/product-donation-widget/product-donation.widget";
import CallBack from "./pages/components/authentication/callback";
import MasjidStore from "./pages/components/public/store";
import MasjidStoreCopy from "./pages/components/public/store copy";
import StoreEditMain from "./pages/components/public/store-edit/store-edit-main";
import Pages from "./pages/components/masjid-public-pages/pages";
import YearlyReceipt from "./pages/components/yearly-receipts/yearly-receipts-logs";
import YearlyReceiptSettings from "./pages/components/yearly-receipts/yearly-receipts-settings";
import ReceiptTemplate from "./templates/receipt-templates";
import Memberships from "./pages/components/memberships/memberships";
import RecurringPlanDetail from "./pages/components/recurring-plans/recurring-plan-detail";
import FormIntegrations from "./pages/components/integrations/form-integrations";
import Integrations from "./pages/components/integrations/integrations";
import Unauthorized from "./Unauthorized/Unauthorized";
import Announcements from "./pages/components/announcements/announcements";
import AllDonation from "./super-admin/components/all-donations/all-donations";
import DonationPledge from "./pages/components/donation-pledge/donation-pledge";
import Pledges from "./pages/components/pledges/pledges";
import PledgeDetail from "./pages/components/pledges/pledge-detail";
import DailyRecurringPlansStatus from "./pages/components/daily-recurring-plans/daily-recurring-plans";
// import Fundraising from "./pages/components/fundraising/fundraising";
import FundraisingSettings from "./pages/components/fundraising/fundraising-settings";
import { Suspense, lazy } from "react";
const Devices = lazy(() => import("./pages/components/kiosks/devices/devices"));
const Fundraising = lazy(() => import("./pages/components/fundraising/fundraising"));

const MainRouteList = [
    {
        path:'payment-processors',
        title:'Payment Processors',
        component:<PaymentProcessorsComponent/>,
        isAuth: true,role: 2
    },
    {
        path:'organization',
        title:'Organization Info',
        component:<OrganizationInfoComponent/>,
        isAuth: true,role: 2
    },
    {
        path:'campaigns',
        title:'Campaigns',
        component:<CampaignsComponent/>,
        isAuth: true,role: 2
    },
    {
        path:'products',
        title:'Products',
        component:<Products />,
        isAuth: true,role: 2
    },
    {
        path:'memberships',
        title:'Memberships',
        component:<Memberships />,
        isAuth: true,role: 2
    },
    {
        path:'dashboard',
        title:'Dashboard',
        component:<Dashboard/>,
        isAuth: true,
        role: 1
    },
    {
        path:'campaign/:id',
        title:'Campaign Detail',
        component:<CampaignDetail />,
        isAuth: true,role: 2
    },
    {
        path:'offline-donation/:id/:name/:type',
        title:'Offline Donation',
        component:<OfflineDonations />,
        isAuth: true,role: 2
    },
    {
        path:'offline-donation/:id/:name/:type/:donation_id',
        title:'Offline Donation',
        component:<OfflineDonations />,
        isAuth: true,role: 2
    },
    {
        path:'donations',
        title:'Donations',
        component:<Donations />,
        isAuth: true,role: 2
    },
    {
        path:'donations/detail/:donation_id/:campaign_id',
        title:'Donation Detail',
        component:<DonationDetail />,
        isAuth: true,role: 2
    },
    {
        path:'pledges',
        title:'Donation Pledges',
        component:<Pledges />,
        isAuth: true,role: 2
    },
    {
        path:'pledges/detail/:pledge_id',
        title:'Pledge Details',
        component:<PledgeDetail />,
        isAuth: true,role: 2
    },
    {
        path:'recurring',
        title:'Recurring Plans',
        component:<RecurringPlans />,
        isAuth: true,role: 2
    },
    {
        path:'recurring/detail/:id',
        title:'Plan Detail',
        component:<RecurringPlanDetail />,
        isAuth: true,role: 2
    },
    {
        path:'subscribers',
        title:'Subscribers',
        component:<Subscribers />,
        isAuth: true,role: 2
    },
    {
        path:'donors',
        title:'Donors',
        component:<Donors />,
        isAuth: true,role: 2
    },
    {
        path:'donor-detail/:id/:name',
        title:'Donor Detail',
        component:<DonorDetail />,
        isAuth: true,role: 2
    },
    {
        path:'devices',
        title:'Kiosk Devices',
        component:<Suspense fallback={<p><i>Loading...</i></p>}><Devices /></Suspense>,
        isAuth: true,role: 2
    },
    // {
    //     path:'sliders',
    //     title:'Sliders',
    //     component:<Sliders />
    // },
    {
        path:'tiles',
        title:'Tiles',
        component:<Tiles />,
        isAuth: true,role: 2
    },
    {
        path:'pages',
        title:'Pages',
        component:<Pages />,
        isAuth: true,role: 2
    },
    // {
    //     path:'fundraising/setup',
    //     title:'Fundraising Setup',
    //     component:<FundraisingSettings />,
    //     isAuth: true,role: 2
    // },
    {
        path:'yearly-receipts',
        title:'Yearly Receipts',
        component:<YearlyReceipt />,
        isAuth: true,role: 2
    },
    {
        path:'yearly-receipts-settings',
        title:'Yearly Receipts Settings',
        component:<YearlyReceiptSettings />,
        isAuth: true,role: 2
    },
    {
        path:'form-mapping',
        title:'Form Integrations',
        component:<FormIntegrations />,
        isAuth: true,role: 2
    },
    //admin routes start
    {
        path:'announcements',
        title:'Announcements',
        component:<Announcements />,
        isAuth: true,role: 1
    },
    {
        path:'daily-plans',
        title:'Daily Plans',
        component:<DailyRecurringPlansStatus />,
        isAuth: true,role: 1
    },
    {
        path:'admin/donations',
        title:'Donations',
        component:<AllDonation />,
        isAuth: true,role: 1
    },
    //admin routes end
    
    // {
    //     path:'integrations',
    //     title:'Form Integrations',
    //     component:<Integrations />,
    //     isAuth: true,role: 2
    // }
];

const CampaignRouteList = [
    // {
    //     path:'test',
    //     title:'Test',
    //     component:<MyComponent />
    // },
  
    //product routes start
    {
        path:'edit-store-page/:type',
        title:'Edit Masjid Store Page',
        component:<StoreEditMain />,
        isAuth: true,role: 2
    },
    {
        path:'product/edit',
        title:'Add/Edit Product',
        component:<ProductMain />,
        isAuth: true,role: 2
    },
    //product routes start

    {
        path:'create-campaign',
        title:'Create Campaign',
        component:<CreateCampaign />,
        isAuth: true,role: 2
    },
    {
        path:'new/:id',
        title:'Campaign Form',
        component:<Main />,
        isAuth: true,role: 2
    },
    //for copy campaign and membership
    // {
    //     path:'copy/:id',
    //     title:'Campaign Form',
    //     component:<Main />,
    //     isAuth: true,role: 2
    // },
    {
        path:'email-receipt/:campaign_id',
        title:'Email Receipt',
        component:<EmailReceiptComponent />,
        isAuth: true,role: 2
    },
    {
        path:'email-receipt/pledge/:campaign_id',
        title:'Pledge Email Receipt',
        component:<EmailReceiptComponent />,
        isAuth: true,role: 2
    },
    {
        path:'update-device/:device_id',
        title:'Update Device',
        component:<UpdateDevice />,
        isAuth: true,role: 2
    },
]

const DefaultRouteList = [
  
    // {
    //     path:'unauthenticated',
    //     title:'Un Authenticated',
    //     component:<Unauthorized />
    // },
    // {
    //     path:'authenticate/:token',
    //     title:'',
    //     component:<AuthUser/>
    // }
    {
        path:'authenticate',
        title:'Authenticate',
        component:<AuthUser/>,
        isAuth: false
    },
    {
        path:'callback',
        title:'Information',
        component:<CallBack/>,
        isAuth: false
    },
    {
        path:'login',
        title:'Login',
        component:<Login />,
        isAuth: false
    }
]

const openRoute = [
    {
        path:'/:short_code/campaign/:link',
        title:'Donate',
        component:<DonationWidget />
    },
    {
        path:'/fundraising/:short_code/:campaign_link',
        title:'Donation Fundraising',
        component:<Suspense fallback={<p><i>Loading...</i></p>}><Fundraising /></Suspense>
    },
    //link for donation pledge  
    {
        path:'/:short_code/campaign/pledge/:link',
        title:'Donate',
        component:<DonationPledge />
    },
    {
        path:'/:short_code/product/:link',
        title:'Donate',
        component:<ProductDonationWidget />
    },
    {
        path:'/:short_code/:type',
        title:'Campaigns & Products',
        component:<MasjidStoreCopy />
    },
    {
        path:'/:short_code',
        title:'Campaigns & Products',
        component:<MasjidStoreCopy />
    },
    {
        path:'direct/payment',
        title:'Pay',
        component:<MyComponent />
    },
    {
        path:'receipt/preview',
        title:'Pay',
        component:<ReceiptTemplate />
    }
]

const routes = {MainRouteList,DefaultRouteList,CampaignRouteList,openRoute};
export default routes;