import React, { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import axios from 'axios';
import { DOWNLOAD_PLEDGE_CSV, GET_PLEDGE_STATS } from '../../../constants/endpoints';
import { pledgeStatusList } from '../../../data-list/data-list';
import { useGetCampaigns } from '../../../hooks/get-campaign';
import { downloadBlob } from '../../../utils/utils';
import PledgeTable from '../../../shared/pledge-table/pledge-table';
import Select from 'react-select';

function Pledges(props) {

    const [searchModel, setSearchModel] = useState('');
    const [campaignSearchModel, setCampaignSearchModel] = useState('');
    const [pledgeStatusModel, setPledgeStatus] = useState([{label: "Unpaid", value:"Un_Paid"}]);
    const [downloadFileLoader, setDownloadFileLoader] = useState(false);
    const {campaignList} = useGetCampaigns();
    const [pledgeStats, setPledgeStats] = useState({
        total: 0,
        pending: 0,
        gross: 0
    });

    //methods 

    const getPledgeStats = () => {
        axios.get(`${GET_PLEDGE_STATS}?status=${pledgeStatusModel?.map((it => it.value)).toString()}&campaign=${campaignSearchModel}&search_text=${searchModel}`)
        .then((res) => {
            if(res?.data?.status){
                setPledgeStats({
                    total: res?.data?.response[0],
                    pending: res?.data?.response[1],
                    gross: res?.data?.response[2]
                })
            }
        }).catch((error) => {

        })
    }

    const downloadCSV = () => {
        setDownloadFileLoader(true);
        axios.get(
        `${DOWNLOAD_PLEDGE_CSV}?status=${pledgeStatusModel?.map((it => it.value)).toString()}&campaign=${campaignSearchModel}&search_text=${searchModel}`, 
        { responseType: 'blob' })
        .then((res) => {
          setDownloadFileLoader(false);
          if(res?.data instanceof Blob){
            downloadBlob(res?.data, 'pledge_records_');
          }else{
            alert(res?.data?.message);
          }
        }).catch((error) => {
          setDownloadFileLoader(false);
          alert('Failed to download file.');
        })
      }

    useEffect(() => {
        getPledgeStats();
    }, [
        pledgeStatusModel,
        campaignSearchModel,
        searchModel
    ]);

    return (
      <div className="row">
        <div className="col-xl-12">
          <div>
            <h5 className="font-weight-semibold text-black">Pledges</h5>
          </div>
        </div>

        <div className="col-md-3 mt-2">
          <div className="form-group">
                    {/* <Form.Select
                        value={pledgeStatusModel}
                        onChange={(event) => setPledgeStatus(event.target.value)}>
                        {
                            pledgeStatusList.map((item, index) => {
                                return <option key={item.value}
                                    value={item.value}>{item.label}</option>
                            })
                        }
                    </Form.Select> */}
                    <Select 
                    isMulti={true}
                    defaultValue={pledgeStatusModel}
                    onChange={setPledgeStatus} options={pledgeStatusList} />
                </div>
        </div>

        <div className="col-md-3 mt-2">
          <div className="form-group">
            <Form.Select
              value={campaignSearchModel}
              onChange={(event) => setCampaignSearchModel(event.target.value)}
              
            >
              <option value={""}>All Campaigns</option>
              {campaignList.map((item, index) => {
                return (
                  <option key={"campaigns " + index} value={item.id}>
                    {item.campaign_title}
                  </option>
                );
              })}
            </Form.Select>
          </div>
        </div>

        <div className="col-md-4 mt-2">
          <div className="form-group">
            <div className="input-group">
              {/* <span className="input-group-text text-body">
                      <i className="fas fa-search" aria-hidden="true"></i>
                    </span> */}
              <input
                id="txtDonationSearch"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchModel(e.target.value);
                  }
                }}
                type="text"
                className="form-control"
                placeholder="Search by donor email"
              />
              <button
                onClick={(e) => {
                  const value =
                    document.getElementById("txtDonationSearch")?.value;
                  setSearchModel(value);
                }}
                type="button"
                className="btn btn-outline-secondary mb-0"
              >
                Search
              </button>
              <Dropdown>
                <Dropdown.Toggle
                  disabled={downloadFileLoader}
                  variant="default"
                  id="dropdown-basic"
                  className={`btn btn-outline-secondary mb-0 border-radius-none 
                                ${
                                  downloadFileLoader
                                    ? "position-relative button--loading"
                                    : ""
                                }`}
                >
                  Export
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={downloadCSV}>
                    Export All Columns
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="row mt-3">
            <div className="col-xl-4 col-lg-4 col-md-4 text-center mt-3 mt-xl-0 mt-lg-0 mt-md-0 sm-mt-0">
              <div className="card">
                <p className="mt-3">Gross Pledge Donation</p>
                <span className="mb-3 text-bold f-22 text-black">
                  {pledgeStats?.gross}
                </span>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 text-center mt-3 mt-xl-0 mt-lg-0 mt-md-0 sm-mt-0">
              <div className="card">
                <p className="mt-3">Total Pledges</p>
                <span className="mb-3 text-bold f-22 text-black">
                  {pledgeStats?.total}
                </span>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 text-center mt-3 mt-xl-0 mt-lg-0 mt-md-0 sm-mt-0">
              <div className="card">
                <p className="mt-3">Pending Pledges</p>
                <span className="mb-3 text-bold f-22 text-black">
                  {pledgeStats?.pending}
                </span>
              </div>
            </div>
          </div>

          {/* pledge table start */}
          <PledgeTable
            pledgeStatusModel={pledgeStatusModel}
            campaignSearchModel={campaignSearchModel}
            searchModel={searchModel}
            donor_id={""}
          />
          {/* pledge table end */}
        </div>
      </div>
    );
}

export default Pledges;