import React, { useEffect, useRef, useState } from 'react';
import NoDataComponent from '../../../shared/no-data/no-data';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { DELETE_SUBSCRIBER, DOWNLOAD_SUBSCRIBERS, GET_ALL_SUBSCRIBERS } from '../../../constants/endpoints';
import { downloadBlob } from '../../../utils/utils';
import { Button, Dropdown } from 'react-bootstrap';
import AddEditSubscriber from './add-subscriber.dialog';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';
import Loader from "../../../shared/loader/loader"

function Subscribers(props) {

    const subscriberDialogRef = useRef(null);
    const confirmationDialogRef = useRef(null);

    const columns = ['Name', 'Email', 'Action'];
    const methods = useForm();
    const { register, getValues } = methods;

    const [subscriberList, setSubscriberList] = useState([]);
    const [fileLoader, setFileLoader] = useState(false);

    const [pageIndex, setPageIndex] = useState(0);
    const [dataLoader, setDataLoader] = useState(false);
    const getAllSubscribers = (pageIndex) => {
        const formData = {...getValues(), ...{page_index: (pageIndex ? pageIndex : 0)}}
        setDataLoader(true);
        axios.post(GET_ALL_SUBSCRIBERS, formData)
        .then((res) => {
            setDataLoader(false);
            res?.data?.status ? setSubscriberList(res?.data?.response) : setSubscriberList([]);
        }).catch((error) => {
            setDataLoader(false);
        })
    }

    const changePage = (index) => {
        setPageIndex(index);
        getAllSubscribers(index);
    }

    useEffect(() => {
        getAllSubscribers();
    },[])

    const downloadCSV = () => {
        setFileLoader(true);
        
        axios.post(DOWNLOAD_SUBSCRIBERS, getValues() ,{ responseType: 'blob' })
        .then((res) => {
          setFileLoader(false);
          
          if(res?.data instanceof Blob){
            downloadBlob(res?.data, 'subscribers_');
          }else{
            alert('Failed to download file.');
          }
        }).catch((error) => {
          setFileLoader(false);
          alert('Failed to download file.');
        })
    }

    const deleteSubscriber = (id) => {
        axios.delete(DELETE_SUBSCRIBER + id)
        .then((res) => {
            if(res?.data?.status){
                getAllSubscribers();
            }else{
                alert('Unable to delete subscriber');
            }
        }).catch((error) => {
            alert('Unable to delete subscriber');
        })
    }

    return (
        <>
        <div className='row'>
            <div className='col-xl-12'>
              <div className='row'>
              <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2'>
                    <h5 className='font-weight-semibold text-black'>Subscribers</h5>
                </div>
                <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 text-right f-14'>
                            <a onClick={(() => subscriberDialogRef.current.handleShow())} 
                            className="mx-3 add-campaign text-white cursor-pointer pt-2 pb-2 pe-3 px-3">
                                <span>
                                    <i className="fa-solid fa-circle-plus mr-5"></i>
                                </span>
                                <span className='ms-2 text-white'>Add Subscriber</span>
                            </a>
                        </div>
              </div>
            </div>

            {/* search start */}
            <div className='row'>

                {/* text filter */}
                <div className="col-md-4 mt-2">
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                {...register('text_value', {
                                    value: ''
                                })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        console.log(getValues())
                                        getAllSubscribers(0);
                                        setPageIndex(0);
                                    }
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Search by name or email"
                            />
                            <button onClick={(e) => {
                                console.log(getValues())
                                getAllSubscribers(0);
                                setPageIndex(0);
                            }} type="button" className="btn btn-outline-secondary mb-0">Search</button>
                            <Dropdown>
                                <Dropdown.Toggle
                                    disabled={fileLoader}
                                    variant="default" id="dropdown-basic"
                                    className={`btn btn-outline-secondary mb-0 border-radius-none 
    ${fileLoader ? 'position-relative button--loading' : ''}`}>
                                    Export
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={downloadCSV}>Export All Columns</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            {/* search end */}

            {/* subscribers listing start */}
            <div className='row mt-3 position-relative'>
                <div className='col-xl-12'>
                    <div className='card'>
                        <div className="table-responsive">
                            {
                                !dataLoader ? 
                                <table className="table align-items-center ">
                                <thead className='text-center prayers-table-header white'>
                                    <tr>
                                        {
                                            columns.map((item, index) => {
                                                return <th key={index}>{item}</th>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody className='prayer-table-body text-center'>
                                    {
                                        subscriberList.map((item, index) => {
                                            return <tr key={'plan_' + index}>
                                                <td>{item?.first_name + ' ' + item?.last_name}</td>
                                                <td>{item?.email}</td>
                                                <td className=''>
                                                <i onClick={() => {
                                                    confirmationDialogRef.current.setDialogTitle('Confirm Deletion');
                                                    confirmationDialogRef.current.setMessage(`Are you sure you want to delete "${item?.first_name +' '+item?.last_name}" subscriber?.`);
                                                    confirmationDialogRef.current.setButtonLabel("Delete Subscriber");
                                                    confirmationDialogRef.current.openDialog(item?.id);
                                                }} 
                                                className="cursor-pointer fa-solid fa-trash me-2"></i>
                                                <i onClick={() => subscriberDialogRef.current.openEditView(item)} 
                                                className="cursor-pointer fa-solid fa-pen-to-square"></i>
                                                    </td>
                                            </tr>
                                        })
                                    }

                                </tbody>

                            </table> : ''
                            }

                            {/* no data message */}
                            {
                                
                                !dataLoader && subscriberList?.length === 0 ?
                                    <NoDataComponent message={'No Subscribers Found'} /> : ''
                            }

                        </div>
                    </div>

                    <div className='mt-2 text-right'>
                        {
                            pageIndex ?
                                <Button
                                    onClick={() => {
                                        const index = pageIndex - 1;
                                        setPageIndex(index)
                                        changePage(index)
                                    }}
                                    className='me-2' type='button'
                                    variant="primary">
                                    Previous
                                </Button> : ''
                        }

                        <Button disabled={subscriberList?.length > 0 ? false : true} onClick={() => {
                            const index = pageIndex + 1;
                            setPageIndex(index)
                            changePage(index)
                        }} type='button'
                            variant="primary">
                            Next
                        </Button>
                    </div>
                </div>
                {
                    dataLoader ? 
                    <Loader /> : ''
                }
            </div>
            {/* subscribers listing end */}
        </div>

        {/* subscribers dialog */}
        <AddEditSubscriber
        getAllSubscribers={getAllSubscribers}
        ref={subscriberDialogRef} />

        {/* confirmation dialog start */}
        <ConfirmationDialog
        ref={confirmationDialogRef}
        executeAction={deleteSubscriber}
        />
        {/* confirmation dialog end */}
        </>
    );
}

export default Subscribers;