import React from 'react';
import Sidebar from './Common/Sidebar/Sidebar';
import { Outlet } from "react-router-dom";
import MainNavbar from './Common/navbar/navbar';
import AnnouncementView from '../../shared/announcement/announcement-view';
import { getUserInfo } from '../../utils/user-info';

function MainLayout(props) {
    const userInfo = getUserInfo();
    return (
        <div>
            {/* sidebar component start  */}
            <Sidebar />
            {/* sidebar component end  */}

            {/* main content start */}
            <main className="main-content position-relative border-radius-lg">

                {/*  announcement bar */}
               {userInfo?.role === 2 ? <AnnouncementView /> : ''} 
                {/* navbar start */}
                <MainNavbar />
                {/* navbar end */}

                {/* router start */}
                <div className='container-fluid mt-3 pb-5'>
                    <div className='bg-greyish px-3 py-4'>

                        {/* mein layout content start */}
                        <Outlet />
                        {/* mein layout content end */}

                    </div>

                </div>

                {/* router end */}

            </main>
            {/* main content end */}
        </div>
    );
}

export default MainLayout;