import { createContext, useState } from "react";
import Cookies from "js-cookie";
import { ENVIRONMENT_CONSTANT } from "../constants/env.constant";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const cookie = Cookies.get("token_validity");
    const masjidInfo = localStorage.getItem(ENVIRONMENT_CONSTANT.USER_INFO);
    if (masjidInfo && cookie) {
      let parsedInfo = JSON.parse(
        localStorage.getItem(ENVIRONMENT_CONSTANT.USER_INFO)
      );
      return parsedInfo;
    }
    return null;
  });
  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
