import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const AnnouncementModal = React.forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const {register, watch} = useForm();
    const watchFields = watch();

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        if(watchFields?.do_not_show_again) {
            props?.markAnnouncementAsRead(props?.announcement?.id);
        }
    }

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow
        }
    });

    useEffect(() => {
        if (props?.announcement) {
            handleShow();
        }
    }, [props?.announcement])
    return (
        <>
            <Modal
                keyboard={false} show={show} onHide={handleClose} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{props?.announcement?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-black announcement_dialog' dangerouslySetInnerHTML={{
                        __html: props?.announcement?.content
                    }}></div>
                </Modal.Body>
                <Modal.Footer className='justify-content-between align-items-center'>
                    <div>
                        <label className="form-control-label d-flex align-items-center" >
                            <input {...register("do_not_show_again")} className='me-1'
                                type="checkbox" /> Don't show again
                        </label>
                    </div>
                    <div>
                        <Button variant="default" onClick={handleClose}>
                            Close
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
});

export default AnnouncementModal;