import React, { useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const AlertBox = React.forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");
    useImperativeHandle(ref, () => {
        return ({
            openDialog: openDialog
        })
    })
    const openDialog = (message, isSuccess) => {
        if(isSuccess){
            setIsSuccess(isSuccess);
        }
        setMessage(message);
        setShow(true);
    }

    const closeDialog = () => {
        setShow(false)
    }

    return (
        <Modal
            dialogClassName="confirmation-dialog"
            keyboard={false}
            centered
            size="md"
            backdrop="static" show={show} onHide={closeDialog} animation={true}>
            <div className='d-flex justify-content-between p-3'>
                {
                    isSuccess ? 
                    <Modal.Title className='text-success'>Success</Modal.Title> : 
                    <Modal.Title className='text-danger'>Alert!</Modal.Title>
                }
                <i onClick={closeDialog} className="fa-solid fa-xmark f-16 cursor-pointer"></i>
            </div>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <div className='p-3 text-right'>
                <Button variant='primary' className='me-2' onClick={closeDialog}>
                    Close
                </Button>
            </div>
        </Modal>
    );
})

export default AlertBox;