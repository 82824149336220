import React from 'react';

function NotFound(props) {
    return (
        <div>
            <p>Sorry, Page not found</p>
            <p>The requested url is not found</p>
        </div>
    );
}

export default NotFound;