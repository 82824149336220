import React, { useEffect, useImperativeHandle, useState } from 'react';
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";

import { string, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFormContext } from "react-hook-form";
import calculateCCAfterFee, { calculateFeePercentage } from '../../../utils/stripe-payment-fee.util';
import axios from 'axios';
import { INITIATE_PAYMENT } from '../../../constants/endpoints';

const PaymentForm = React.forwardRef((props, ref) => {
    
    useImperativeHandle(ref, () => {
        return {
            setFinalAmount: setFinalAmount,
            removeFeeForACH: removeFeeForACH
        };
    });

    const stripe = useStripe();
    const elements = useElements();

    const paymentSchema = z.object({
        "postal_address": string().default('')
    });

    const { register: paymentRegister, handleSubmit: paymentHandleSubmit } = useForm({
        resolver: zodResolver(paymentSchema)
    });

    const { register, clearErrors, watch, reset, setValue, getValues, formState :{isDirty} } = useFormContext();
    
    const fieldWatcher = watch();
    const [removeFeeForACH, setRemoveFeeForACH] = useState(false);
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const paymentElementOptions = {
        layout: "tabs"
    }

    const handleServerResponse = async (result) => {
        
        if (result.status === false) {
            setMessage(result.message);
          // Show error from server on payment form
        } else if (result.response.status === "requires_action") {
          // Use Stripe.js to handle the required next action
          const {
            error,
            paymentIntent
          } = await stripe.handleNextAction({
            clientSecret: result?.response?.client_secret
          });
      
      
          if (error) {
            setMessage(error);
            // Show error from Stripe.js in payment form
          } else {
            // Actions handled, show success message
            props?.onPaymentSuccess("Payment successful thank you for your kind donations.");
          }
        } else {
            // No actions needed, show success message
            props?.onPaymentSuccess("Payment successful thank you for your kind donations.");
        }

        setIsProcessing(false);
    }

    const submitDonationData = async (event) => {
        setMessage('');
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
    
        if (!stripe) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        setIsProcessing(true);
    
        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            setIsProcessing(false);
          // handleError(submitError);
          setMessage(submitError.message);
          return;
        }
    
        // Create the PaymentMethod using the details collected by the Payment Element
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          elements,
          params: {
            billing_details: {
              name: props?.fieldsWatcher?.first_name + ' ' + props?.fieldsWatcher?.last_name,
              email: props?.fieldsWatcher?.email
            }
          }
        });
    
        if (error) {
            setIsProcessing(false);
          // This point is only reached if there's an immediate error when
          // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
          setMessage(error.message); 
          return;
        }
    
        //sending request to backend for generating and confirming payment intent for card
        const donationPayload = props?.returnDonationPayload();
        donationPayload.payment_method_id = paymentMethod.id;

        try {
            const res = await axios.post(INITIATE_PAYMENT,donationPayload);
            const data = res.data;
            setIsProcessing(false);
            // Handle any next actions or errors. See the Handle any next actions step for implementation.
            handleServerResponse(data);
        } catch (error) {
            setIsProcessing(false);
            setMessage('Unable to complete your request, try again later.');
        }
        // const res = await fetch("/create-confirm-intent", {
        //   method: "POST",
        //   headers: {"Content-Type": "application/json"},
        //   body: JSON.stringify({
        //     paymentMethodId: paymentMethod.id,
        //   }),
        // });
        
    };
    const [finalAmount, setFinalAmount] = useState(0);
    const [loader, setLoader] = useState(false);
    const calculateStripeFee = (event) => {
        const amount = props?.returnSelectedAmount();
        if(event.target.checked){
            setFinalAmount(calculateCCAfterFee(amount));
            if(props?.setAmount){
                setLoader(true);
                props?.setAmount(Math.round(parseFloat(calculateCCAfterFee((amount)) * 100)));
            }
            
        }else{
            setFinalAmount(amount);
            if(props?.setAmount){
                setLoader(true);
                props?.setAmount(Math.round(amount * 100));
            }
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoader(false);
            clearTimeout(timer);
        }, 500);
    }, [props?.amount])

    // useEffect(() => {
    //    if(finalAmount) {
    //         //old implementation start
    //         // updatePaymentIntent();
    //         //old implementation end
            
    //         //new implementation start
    //         const calculatedAmount = (Math.round(finalAmount * 100));
    //         props?.setAmount(calculatedAmount);
    //         //new implementation end
    //    }
    // },[finalAmount])

    // const updatePaymentIntent = async () => {
    //     setIsProcessing(true);
    //     const updatePaymentPayload = {
    //         amount:(Math.round(finalAmount * 100)),
    //         payment_intent_id: props?.cardPaymentID,
    //         stripe_account_id: props?.campaignDetail?.stripe_account_id
    //     }
    //     axios.post(UPDATE_PAYMENT_INTENT,updatePaymentPayload)
    //     .then((res) => {
    //         setIsProcessing(false);
    //         if(res?.data?.response?.success && res?.data?.response?.intent?.id){
    //             console.log('payment intent updated successfully');
    //         }
    //     }).catch((error) => {
    //         setIsProcessing(false);
    //     })
    //     // const result = await stripe?.updatePaymentIntent(props?.cardPaymentID,{
    //     //     amount:(props?.returnSelectedAmount() * 100)
    //     // });

    //     // console.log(result);
    // }

    const [paymentAfterFee, setPaymentAfterFee] = useState(0);
    useEffect(() => {
        if(props?.campaignDetail){
            const amount = props?.returnSelectedAmount();
            if(props?.campaignDetail?.donation_amount?.ask_donors_cover_fee_default){
                console.log('Is default processing fee true')
                // setValue('cover_processing_fee',true);
                setFinalAmount(calculateCCAfterFee(amount));
                // if(fieldWatcher?.cover_processing_fee){
                    
                    
                // }else{
                //     setFinalAmount(amount);
                // }
            }else{
                console.log('Is default processing fee false')
                // setValue('cover_processing_fee',false);
                setFinalAmount(amount);
            }
            
            setPaymentAfterFee(calculateFeePercentage(amount))
        }
        
    },[props?.campaignDetail, props?.formValues?.custom_amount, props?.formValues?.selected_amount, props?.paymentFormAmount])
    
    return (
        <>
            <div>
                {/* fix the issue if user selects the amount before the next button enable */}
                <PaymentElement onChangeCapture={(e) => {
                    console.log(e)
                }} onChange={(e) => {
                    console.log("change event", e)
                    if(e?.value?.type === "us_bank_account"){
                        const timer = setTimeout(() => {
                            setValue("cover_processing_fee", false);
                            clearTimeout(timer);
                        }, 50);
                        calculateStripeFee({target:{checked: false}});
                        setRemoveFeeForACH(true);
                    }else{
                        setRemoveFeeForACH(false);
                    }
                }} id="payment-element" options={paymentElementOptions} />
                <div className='col-lg-12 mt-2 d-none'>
                    <div className="form-group"><label className="form-control-label">Postal Address</label>
                        <input id='txtAddress' placeholder="Billing Postal Address"
                            autoComplete="nofill"
                            {...paymentRegister("postal_address")}
                            className="form-control-alternative form-control" />
                        {/* <textarea placeholder='Postal Address' rows="1" className="form-control-alternative form-control"></textarea> */}
                    </div>
                </div>
                {
                    (!removeFeeForACH && props?.campaignDetail?.donation_amount?.ask_donors_cover_fee && 
                        props?.formStep === 'payment'
                        ) ?
                        <div className='col-lg-12 mt-4'>
                            <div className='d-flex align-items-center justify-content-start'>
                                <input
                                {...register('cover_processing_fee',{
                                    value: (removeFeeForACH === false && props?.campaignDetail?.donation_amount?.ask_donors_cover_fee_default) ? true : false,
                                    onChange: ((e) => {
                                        calculateStripeFee(e);
                                    })
                                })} className="me-1" type="checkbox" />
                                <label className='f-14'>
                                    Optionally add ${paymentAfterFee} to cover processing fees
                                </label>
                            </div>
                        </div> : ''
                }


                {/* {
                    props?.campaignDetail?.donation_amount?.ask_donors_cover_fee ? 
                       <div className='col-lg-12 mt-4'>
                       <div className='d-flex align-items-center justify-content-start'>
                           <input className="me-1" type="checkbox" />
                           <label className='f-14'>
                               Save my information for future donations.
                           </label>
                       </div>
                   </div> : ""
                } */}
                <div className="col-lg-12 mt-3">
                    <div className="text-center position-relative">
                        <button onClick={submitDonationData} disabled={loader || isProcessing || !stripe || !elements} type="button"
                           style={{
                            backgroundColor: props?.campaignDetail?.primary_color ? (props?.campaignDetail?.primary_color) : '#005B97',
                            borderBottom: props?.campaignDetail?.primary_color ? ('2px solid ' + props?.campaignDetail?.primary_color) : ''
                        }} className={`btn text-white btn-step-one w-100 f-18`}>
                            <i className="cursor-pointer fa-solid fa-lock me-2"></i>

                            {(isProcessing) ? "Processing ... " : `${props?.campaignDetail?.donation_amount?.change_label_to_pay ? 'Pay' : 'Donate'} 
                            $${
                                // !fieldWatcher?.selected_amount && fieldWatcher?.custom_amount ? fieldWatcher?.custom_amount :
                                // fieldWatcher?.selected_amount === "custom_amount" ? fieldWatcher?.custom_amount : fieldWatcher?.selected_amount
                                Number.isInteger(finalAmount) ? finalAmount : parseFloat(finalAmount)?.toFixed(2)
                            }
                            ${props?.selectedDonationType}`}
                        </button>
                    </div>
                    {/* {message && <div className={`f-xsmall ${message.includes('Payment successful') ? 'text-success' : 'text-danger'}`} id="payment-message">{message}</div>} */}
                    {message && <div className={`f-xsmall text-danger text-center`} id="payment-message">{message}</div>}
                </div>
            </div>

        </>
    );
});

export default PaymentForm;