import './Style.scss';

import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap/dist/js/bootstrap.js';

// import Sidebar from './components/common/sidebar-component/Sidebar';
import { Routes, Route, Navigate } from "react-router-dom";
import MainLayout from './Layouts/Main/Main';
import RouteList from "./routes";
import Guards from './protected/protected-routes';
import CampaignLayout from './Layouts/Campaign/Campaign';
import AuthUser from './pages/components/authentication/auth';
import NotFound from './404/Not-Found';
import interceptor from './utils/interceptor';
import { AuthContextProvider } from './context/authContext';
import AuthGuard from './protected/auth.guard';
import UnaAuthGuard from './protected/unAuth.guard';
import { getUserInfo } from './utils/user-info';

function App() {

  const userInfo = getUserInfo()
  //executing interceptor
  interceptor();
  
  //creating routes data for main layout start
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          element={
            prop?.isAuth === true ? 
            <AuthGuard role={prop.role}>
              {prop.component}
            </AuthGuard>
            : prop?.isAuth === false ?
             <UnaAuthGuard>
              {prop.component}
            </UnaAuthGuard> :
            prop.component
          }
          key={key}
        />
      )
    })
  }

  //creating routes data for main layout end


  return (
    <div className='main-app'>
      <AuthContextProvider>
      <Routes>
        {/* <Route element={<Guards.UnProtectedRoutes />}> */}
          {getRoutes(RouteList.DefaultRouteList)}
        {/* </Route> */}
        {/* <Route element={<Guards.ProtectedRoutes />}> */}
          <Route path="/" element={<MainLayout />}>
            {getRoutes(RouteList.MainRouteList)}
            {
              userInfo?.role === 2 ? 
              <Route index element={<Navigate to="/donations" />} /> : 
              <Route index element={<Navigate to="/admin/donations" />} />
            }
            
          </Route>

          <Route path="/layout" element={<CampaignLayout />}>
            {getRoutes(RouteList.CampaignRouteList)}
            <Route index element={<Navigate to="/campaigns" />} />
          </Route>

          {/* <Route path="authenticate/:token" element={<AuthUser />} /> */}
        {/* </Route> */}

        {/* <Route element={<Guards.ProtectedRoutes />}>
          <Route path='/campaign' element={<CampaignLayout />}>
            {getRoutes(RouteList.CampaignRouteList)}
          </Route>
        </Route> */}

        <Route path="*" element={<NotFound />} />
        <Route>{getRoutes(RouteList.openRoute)}</Route>
      </Routes>
      </AuthContextProvider>
   
    </div>
  )
}

export default App;