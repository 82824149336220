import React, { useImperativeHandle, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import FieldError from '../../../../shared/fields-errors/field-error';
import axios from 'axios';
import { SAVE_ANNOUNCEMENT } from '../../../../constants/endpoints';
import ReactQuill from 'react-quill';

const AnnouncementDialog = React.forwardRef((props, ref) => {

    //props
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [announcementLoader, setAnnouncementLoader] = useState(false);

    const { register, handleSubmit, control, formState: { errors }, reset } = useForm();

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow
        }
    });

    const handleShow = (announcement) => {
        if(announcement?.id){
            setIsEdit(true);
            const {announcementStatuses, ...values} = announcement;
            reset(values);
        }
        setShow(true);
    }

    const handleClose = () => {
        reset({
            type: 'bar',
            title: "",
            content: ""
        })
        setShow(false);
        setIsEdit(false);
    }

    const saveAnnouncement = (formValues) => {
        setAnnouncementLoader(true);
        axios.post(SAVE_ANNOUNCEMENT, formValues)
        .then((res) => {
            setAnnouncementLoader(false);
            if(res?.data?.status){
                handleClose();
                props?.getAllAnnouncements();
            }else{
                alert(res?.data?.message);
            }
        }).catch((error) => {
            setAnnouncementLoader(false);
        })
    }

    return (
        <>
            <Modal
                keyboard={false}
                backdrop="static" show={show} onHide={handleClose} animation={true}>
                <Form onSubmit={handleSubmit(saveAnnouncement)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isEdit ? 'Edit' : 'Create'} Announcement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-6'>
                                <Form.Group className="mb-3" >
                                    <Form.Label><span className='text-danger'>*</span> Type</Form.Label>
                                    <Form.Select {...register("type", {
                                        validate: {
                                            required: value => {
                                                if (!value) return 'Field is required.';
                                                return true;
                                            }
                                        }
                                    })}>
                                        <option value={'bar'}>Bar</option>
                                        <option value={'dialog'}>Dialog</option>
                                    </Form.Select>
                                    {errors?.type ? <FieldError message={errors?.type?.message} /> : ''}
                                </Form.Group>

                            </div>
                            <div className='col-6'>
                                <Form.Group className="mb-3" >
                                    <Form.Label><span className='text-danger'>*</span> Title</Form.Label>
                                    <Form.Control
                                        {...register('title', {
                                            validate: {
                                                required: value => {
                                                    if (!value) return 'Field is required.';
                                                    return true;
                                                }
                                            }
                                        })}
                                        type="text"
                                        placeholder="Enter title" />
                                    {errors?.title ? <FieldError message={errors?.title?.message} /> : ''}
                                </Form.Group>
                            </div>
                        </div>

                        <Form.Group className="mb-3" data-text-editor="content">
                            <Form.Label><span className='text-danger'>*</span> Content</Form.Label>
                            <Controller
                                name="content"
                                control={control}
                                // rules={{ required: 'This field is required' }}
                                render={({ field }) => (
                                    <ReactQuill
                                        value={field.value}
                                        onChange={field.onChange}
                                        modules={AnnouncementDialog.modules}
                                        formats={AnnouncementDialog.formats}
                                        bounds={`[data-text-editor="content"]`}
                                    />
                                )}
                            />
                            {errors?.content ? <FieldError message={errors?.content?.message} /> : ''}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button disabled={announcementLoader} type='submit' variant="primary"
                            className={`${announcementLoader ? 'position-relative button--loading' : ''}`}>
                            {isEdit ? 'Update' : 'Create'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
});


/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
AnnouncementDialog.modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  /* 
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  AnnouncementDialog.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
  ]

export default AnnouncementDialog;