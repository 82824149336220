import React from 'react';
import FieldError from '../../../../shared/fields-errors/field-error';
import { useFormContext } from 'react-hook-form';

function DonorPersonalInfo(props) {
    const { register,watch, formState:{ errors } } = useFormContext();
    const fieldWatcher = watch();

    return (
        <div className='row'>
            <div className='col-xxl-4 col-12 mt-2'>
                <div className="form-group"><label className="form-control-label text-black " >
                    <span className='text-danger'>*</span> First Name
                </label>
                    <input
                    {...register('donor_info.first_name',{
                        validate:{
                            required: value => {
                                if (!value) return 'Field is required.';
                                return true;
                            }
                        }
                    })}
                        placeholder="Enter first name" type="text"
                        className="form-control-alternative form-control " />
                        {
                            errors?.donor_info?.first_name  ? 
                            <FieldError message={errors?.donor_info?.first_name?.message} /> : ''
                        }
                </div>
            </div>
            <div className='col-xxl-4 col-12 mt-2'>
                <div className="form-group"><label className="form-control-label text-black " >
                    <span className='text-danger'>*</span> Last Name
                </label>
                    <input
                    {...register('donor_info.last_name',{
                        validate:{
                            required: value => {
                                if (!value) return 'Field is required.';
                                return true;
                            }
                        }
                    })}
                        placeholder="Enter last name" type="text"
                        className="form-control-alternative form-control " />
                         {
                            errors?.donor_info?.last_name  ? 
                            <FieldError message={errors?.donor_info?.last_name?.message} /> : ''
                        }
                </div>
            </div>
            <div className='col-xxl-4 col-12 mb-2 mt-2'>
                <div className="form-group"><label className="form-control-label text-black " >
                    <span className='text-danger'>*</span> Email
                </label>
                    <input
                    {...register('donor_info.email',{
                        pattern: {
                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            message: "Invalid email."
                        },
                        validate:{
                            required: value => {
                                if (!value) return 'Field is required.';
                                return true;
                            }
                        }
                    })}
                        onBlur={(e) => e.target.value ? props?.findDonorByEmail ? props?.findDonorByEmail(e.target.value) : '' : ''}
                        placeholder="Enter email" type="text"
                        className="form-control-alternative form-control " />
                          {
                            errors?.donor_info?.email  ? 
                            <FieldError message={errors?.donor_info?.email?.message} /> : ''
                        }
                </div>
                {props?.isDonorExists === 'exists' ? <>
                    <FieldError message={'A donor with this email is already exits.'} />
                    <p className='mb-0'><a onClick={props?.loadExistingDonor}
                    className='text-light-blue mb-4 cursor-pointer'>Load existing donor</a></p>
                </> : ''}
                {/*  */}
            </div>
        </div>
    );
}

export default DonorPersonalInfo;