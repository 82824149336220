import React from 'react';
import { useFormContext } from 'react-hook-form';

function DonorInfoSettings(props) {

    const { register } = useFormContext();

    return (
        <div className='col-12 card mt-3'>
            <div className='card-body'>
                <label className="form-control-label text-black text-bold">
                    Donor Info
                </label>

                <div className='form-group mt-3'>
                    <label className="form-control-label d-flex align-items-center">
                        <input {...register("enable_anonymous")} className="me-1" type="checkbox" /> Enable anonymous donation
                    </label>
                    <label className="form-control-label d-flex align-items-center mt-3">
                        <input {...register("collect_address")} className="me-1" type="checkbox" /> Collect address
                    </label>
                    <label className="form-control-label d-flex align-items-center mt-3">
                        <input {...register("collect_phone")} className="me-1" type="checkbox" /> Collect phone
                    </label>
                </div>
            </div>
        </div>
    );
}

export default DonorInfoSettings;