import React from 'react';

function NoDataComponent(props) {
    return (
        <div className='text-center mt-5 mb-5'>
            <i className="fa-solid fa-list-ul f-20"></i>
            <p>{props?.message}</p>
        </div>
    );
}

export default NoDataComponent;