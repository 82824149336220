import React, { useImperativeHandle, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FieldError from '../../../shared/fields-errors/field-error';
import axios from 'axios';
import { ADD_SUBSCRIBER } from '../../../constants/endpoints';

const AddEditSubscriber = React.forwardRef((props, ref) => {

    const [subscriberLoader, setSubscriberLoader] = useState(false);

    const { register, handleSubmit, setFocus, reset, formState: { errors } } = useForm();

    const [show, setShow] = useState(false);

    const handleClose = () => {
        reset({
            first_name:'',
            last_name:'',
            email:''
        })
        setShow(false);
        setIsEdit(false);
    };

    const handleShow = () => {
        setShow(true);
    }

    useImperativeHandle(ref, () => {
        return {
            handleShow: handleShow,
            handleClose: handleClose,
            openEditView: openEditView
        };
    });

    const [isEdit, setIsEdit] = useState(false);

    const openEditView = (subscriber) => {
        setIsEdit(true);
        reset(subscriber);
        handleShow();
    }

    const addEditSubscriber = (formValues) => {
        console.log(formValues)
        setSubscriberLoader(true);
        axios.post(ADD_SUBSCRIBER, formValues)
        .then((res) => {
            setSubscriberLoader(false);
            if(res?.data?.status){
                props?.getAllSubscribers();
                handleClose();
            }else{
                alert(res?.data?.message ? res?.data?.message : `Unable to ${isEdit ? 'Update' : 'Add'} subscriber`)
            }
        }).catch((error) => {
            setSubscriberLoader(false);
            alert(`Unable to ${isEdit ? 'Update' : 'Add'} subscriber`)
        });
    }
    
    return (
        <>
            <Modal 
            keyboard={false} 
            backdrop="static" show={show} onHide={handleClose} animation={true}>
            <Form onSubmit={handleSubmit(addEditSubscriber)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Edit' : 'Add'} Subscriber</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Form.Group className="mb-3" >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control 
                            {...register('first_name',{
                                validate:{
                                    required: value => {
                                        if (!value) return 'Field is required.';
                                        return true;
                                    }
                                }
                            })}
                            type="text"
                             placeholder="Enter first name" />
                             {errors?.first_name ? <FieldError message={errors?.first_name?.message} /> : ''}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control 
                            {...register('last_name',{
                                validate:{
                                    required: value => {
                                        if (!value) return 'Field is required.';
                                        return true;
                                    }
                                }
                            })}
                            type="text"
                             placeholder="Enter last name" />
                             {errors?.last_name ? <FieldError message={errors?.last_name?.message} /> : ''}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                            {...register('email',{
                                pattern: {
                                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: "Entered value does not match email format"
                                },
                                validate:{
                                    required: value => {
                                        if (!value) return 'Field is required.';
                                        return true;
                                    },
                                    
                                }
                            })}
                            type="text"
                             placeholder="Enter email" />
                             {errors?.email ? <FieldError message={errors?.email?.message} /> : ''}
                        </Form.Group>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button disabled={subscriberLoader} type='submit' variant="primary" 
                    className={`${subscriberLoader ? 'position-relative button--loading' : ''}`}>
                        {isEdit ? 'Update' : 'Add'}
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
});

export default AddEditSubscriber;