import React from 'react';
import "./loader.scss";

function Loader(props) {
    return (
        <div className='loader-wrapper-main'>
            <div className="loader"></div>
        </div>
    );
}

export default Loader;