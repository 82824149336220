// LoginGuard.js
import React from 'react';
import { Navigate } from 'react-router-dom'; // Assuming you're using React Router
import Cookies from 'js-cookie';

const UnaAuthGuard = ({ children }) => {
  const cookie = Cookies.get('token_validity');
  if (cookie) {
    // Redirect authenticated users away from the login page
    return <Navigate to="/donations" />;
  }

  return children;
};

export default UnaAuthGuard;