import React from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../../../shared/fields-errors/field-error';

function MailingList(props) {

    const { register, watch, formState: { errors } } = useFormContext();

    const watchFields = watch();

    return (
        <div className='col-12 card mt-3'>
            <div className='card-body'>
                <label className="form-control-label text-black text-bold">
                    Mailing List
                </label>

                <div className='form-group mt-3'>
                    <label className="form-control-label d-flex align-items-center">
                        <input
                            {...register("ask_donor_to_subscribe")}
                            className="me-1" type="checkbox" />
                        Ask donors to subscribe to your mailing list
                    </label>
                </div>
                {
                    watchFields?.ask_donor_to_subscribe ?
                        <>
                            <div className="form-group mt-3"><label className="form-control-label text-black text-bold" >
                                Email Subscription Request Label
                            </label>
                                <textarea   {...register("email_request_label", {
                                    validate: {
                                        required: (value) => {
                                            if (watchFields?.ask_donor_to_subscribe && !value) {
                                                return "This field is required"
                                            } else {
                                                return true
                                            }
                                        }
                                    }
                                })} placeholder="Email Request Label" className="form-control-alternative form-control" ></textarea>
                                {errors?.email_request_label ? <FieldError message={errors?.email_request_label?.message} /> : ''}
                            </div>

                            <hr className='mt-4' />
                            <div className='form-group'>
                                <label className="form-control-label d-flex align-items-center">
                                    <input
                                        {...register("ask_donor_to_subscribe_default")}
                                        className="me-1" type="checkbox" /> Subscribe donors by default
                                </label>
                            </div>
                        </> : ''
                }

            </div>
        </div>
    );
}

export default MailingList;