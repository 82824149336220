import React from 'react';
import FieldError from '../../../../../shared/fields-errors/field-error';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { number, string, z } from "zod";
import axios from 'axios';
import { CREATE_CAMPAIGN } from '../../../../../constants/endpoints';
import { useNavigate } from "react-router-dom";

function GetStarted(props) {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const formType = params?.get('form_type') ? params?.get('form_type') : 'campaign';
    const navigate = useNavigate();

    //form handling start

    const [saveFormLoader, setSaveFormLoader] = useState(false);

    var user = (localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {});

    const schema = z.object({
        campaign_title: string().min(1,'Campaign title is required'),
        language: string().default('English'),
        goal_amount: string().optional()
    });

    const { register, handleSubmit, formState:{ errors }, getValues } = useForm({
        resolver: zodResolver(schema)
    });

    const saveForm = (formValues) => {
        
        setSaveFormLoader(true);
        let formData = {...formValues,...{masjid_id:user?.masjid_id}};
        axios.post((CREATE_CAMPAIGN+`/?form_type=${formType}`),formData)
        .then((res) => {
            if(res?.data?.status){
                if(formType === 'membership'){
                    navigate(`/layout/new/${res?.data?.response?.id}/?form_type=membership`);
                }else{
                    navigate(`/layout/new/${res?.data?.response?.id}/`);
                }
                
                // alert("Campaign created successfully");
            }else{
                alert(res?.data?.response)
            }
        }).catch((error) => {
            // navigate(`/layout/new/EREW-43SERR-34534/${formValues?.campaign_title}`);
            alert(error);
        }).finally((e) => {
            setSaveFormLoader(false);
        });
    }
    //form handling end

    // offset-md-1 offset-sm-1 offset-xs-1 
    return (
            <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 offset-lg-3 offset-xl-4'>
        <div className='d-flex justify-content-center align-items-center flex-column'>
                <span>1/1</span>
                <progress value={100} max="100"> 100% </progress>
            </div>
            <div className="card mt-3">
                <div className="bg-white border-0 card-header">
                    <div className="align-items-center row">
                        <div className="col-12 text-center">
                            <h4 className="mb-0">Get Started</h4>
                        </div>
                        {/* <div className="text-right col-4"><a href="#pablo" className="btn btn-primary btn-sm">Settings</a></div> */}
                    </div>
                </div>
                <div className="card-body">
                    {/* <h6 className="heading-small text-muted mb-4">User information</h6> */}
                    <form onSubmit={handleSubmit(saveForm)} className="pl-lg-4">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group"><label className="form-control-label text-capitalize" >
                                    {formType} Title
                                </label>
                                    <input {...register("campaign_title")} placeholder="Campaign Title"
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                               
                               {errors?.campaign_title?.message ? <FieldError message={errors?.campaign_title?.message}/> : ''}

                            </div>
                            {/* <div className="col-lg-12 mt-3">
                                <div className="form-group">
                                    <label className="form-control-label">
                                        Language
                                    </label>
                                    <input readOnly {...register("language")} placeholder="Language" defaultValue={'English'} type="text"
                                        className="form-control-alternative form-control" /></div>
                            </div> */}
                        </div>
                            <div className="row">
                            {
                                formType === 'campaign' ?
                                <div className="col-lg-12 mt-3">
                                <div className="form-group"><label className="form-control-label">
                                    Goal Amount (Optional)
                                </label><input {...register("goal_amount")} placeholder="Goal Amount" type="number"
                                    className="form-control-alternative form-control" /></div>
                            </div> : ''
                            }
                            <div className="col-lg-12 mt-3">
                                <div className="text-right position-relative">
                                    <button disabled={saveFormLoader}
                                     type="submit" className={`btn btn-ma-primary btn-md w-100 ${saveFormLoader ? 'button--loading' : ''}`}>
                                        Create Donation Form
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                    </form>

                </div>
            </div>
        </div>
        
    );
}

export default GetStarted;