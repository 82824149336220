import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FieldError from '../../../../../shared/fields-errors/field-error';

function IntervalAmount(props) {

    const { register, setValue, watch, formState: { errors } } = useFormContext();

    const fieldsWatcher = watch();
    const control = props?.control;
    const { fields, remove, append } = useFieldArray({
        control,
        name: `donation_amount.amount_intervals[${props?.parentIndex}].amount_list`
    });

    const watchFields = watch();
    const addRemoveAmount = (index) => {
        if (index >= 0) {
            setValue(`donation_amount.amount_intervals[${props?.parentIndex}].amount_list[${index}].isDelete`,true);
            // let intervalAmount = fields[index];
            // intervalAmount.isDelete = true;
            // setRemovedIntervalAmounts([...removedIntervalAmounts, intervalAmount]);
            // remove(index);
        } else {
            let listOfIntervalAmounts = watchFields?.donation_amount.amount_intervals[props?.parentIndex]?.amount_list.filter(it => it.isDelete);
            if(listOfIntervalAmounts?.length > 0){
                let lastAmountIndex = watchFields?.donation_amount.amount_intervals[props?.parentIndex]?.amount_list.findIndex(it => it.isDelete);
                setValue(`donation_amount.amount_intervals[${props?.parentIndex}].amount_list[${lastAmountIndex}].isDelete`, false);
            }else if(watchFields?.donation_amount.amount_intervals[props?.parentIndex]?.amount_list?.length < 4){
                append({ amount: 0, description: "" });
            }else{
                alert('You can only add four option for each interval.');
            }
            

            // if (removedIntervalAmounts.length > 0) {
                
            //     let lastIndex = removedIntervalAmounts.length - 1;

            //     let previousAmount = removedIntervalAmounts[lastIndex];
            //     previousAmount.isDelete = false;
            //     append(previousAmount);

            //     removedIntervalAmounts.splice(lastIndex, 1);
            //     setRemovedIntervalAmounts(removedIntervalAmounts);

            // } else if (fields.length < 4) {
            //     append({ amount: 0, description: "" });
            // } else {
            //     alert('You can only add four option for each interval.');
            // }
        }
    }

    return (
        <>
            {
                fields.map((item, index) => {
                    return (
                        !watchFields?.donation_amount?.amount_intervals?.[props?.parentIndex]?.amount_list[index]?.isDelete ?
                            <div key={item.id}
                                className='row align-items-center mt-2'>
                                <div className='col-4'>
                                    <input
                                        {...register(`donation_amount.amount_intervals[${props?.parentIndex}].amount_list[${index}].amount`, { 
                                            validate:{
                                                required: value => {
                                                     if (!value) return 'Amount is required';
                                                    return true;            
                                                },
                                                min: value => {
                                                        if (value <= 0){
                                                            return `Amount cannot be less then or equal to 0`
                                                        };
                                                    return true;            
                                                }
                                            }
                                        })}
                                        type="number" className="form-control-alternative form-control" placeholder='20.0' />
                                    {
                                        errors?.donation_amount?.amount_intervals ? 
                                        errors?.donation_amount?.amount_intervals[props?.parentIndex]?.amount_list[index]?.amount ?
                                        <FieldError message={
                                            errors?.donation_amount?.amount_intervals[props?.parentIndex]?.amount_list[index]?.amount?.message
                                        } /> : '' : ''
                                }
                                </div>
                                <div className='col-6'>
                                    <input
                                        {...register(`donation_amount.amount_intervals[${props?.parentIndex}].amount_list[${index}].description`)}
                                        type="text" className="form-control-alternative form-control" placeholder='Amount Description' />
                                </div>
                                <div className='col-2'>
                                    <i onClick={(() => addRemoveAmount(index))} className="fa-solid fa-square-minus me-2 f-12 cursor-pointer"></i>
                                </div>

                            </div>
                            : ''
                    )
                })
            }

            <div className='mt-3'>
                <button onClick={(() => addRemoveAmount())} type="button" className="form-control btn-default w-50">
                    <i className="fa-solid fa-square-minus"></i> Add Amount
                </button>
            </div>

        </>

    );
}

export default IntervalAmount;