import React, { useEffect, useImperativeHandle, useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { Accordion } from 'react-bootstrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import OrderContainer from './order-container';
import axios from 'axios';
import { GET_STORE_ITEMS } from '../../constants/endpoints';
import { getUserInfo } from '../../utils/user-info';

const CampaignOrder = React.forwardRef((props, ref) => {

    const userInfo = getUserInfo();
    const [campaignList, setCampaignList] = useState([]);

    const getCampaigns = () => {
        axios.post(GET_STORE_ITEMS + userInfo?.masjid_id, {
            type: "both"
        }).then((res) => {
            if(res?.data?.status){
                setCampaignList(res?.data?.response?.items);
            }
        }).catch((error) => {

        })
    }

    useEffect(() => {
        getCampaigns();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            campaignList: campaignList
        };
    });

    return (
        <DndProvider backend={HTML5Backend}>
            <Accordion className='mt-3' defaultActiveKey="0">
                <Accordion.Item eventKey="0" className='mb-3'>
                    <Accordion.Header>
                        <span className='text-bold text-black'>Arrange Campaigns Order</span>
                    </Accordion.Header>
                    <Accordion.Body className='device-media-accordion-body'>
                        <OrderContainer
                            campaignList={campaignList}
                            setCampaignList={setCampaignList} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </DndProvider>
    );
});

export default CampaignOrder;