import React, { useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmationDialog = React.forwardRef((props, ref) =>  {

    const [show, setShow] = useState(false);
    const [requestLoader, setRequestLoader] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    //content properties
    const [dialogTitle, setDialogTitle] = useState("");
    const [message, setMessage] = useState("");
    const [buttonLabel, setButtonLabel] = useState("");

    useImperativeHandle(ref, () => {
        return({
            openDialog: openDialog,
            setDialogTitle:setDialogTitle,
            setMessage:setMessage,
            setButtonLabel:setButtonLabel
        })
    })
    const openDialog = (item) => {
        setShow(true);
        if(item){
            setCurrentItem(item);
        }
    }

    const closeDialog = () => {
        setDialogTitle("Confirm");
        setMessage("Are you sure?");
        setButtonLabel("Action");
        setShow(false)
    }

    const executeAction = () => {
        closeDialog();
        props?.executeAction(currentItem);
    }

    return (
        <Modal 
        dialogClassName="confirmation-dialog"
            keyboard={false}
            centered
            size="md"
            backdrop="static" show={show} onHide={closeDialog} animation={true}>
                    <div className='d-flex justify-content-between p-3'>
                    <Modal.Title>{dialogTitle}</Modal.Title>
                    <i onClick={closeDialog} className="fa-solid fa-xmark f-16 cursor-pointer"></i>
                    </div>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <div className='p-3 text-right'>
                <Button variant={props?.isAlert ? 'primary' : 'link'} className='me-2' onClick={closeDialog}>
                        {props?.isAlert ? 'Close' : 'Cancel'}
                    </Button>
                    {
                        !props?.isAlert ? 
                        <Button
                    onClick={executeAction}
                     disabled={requestLoader} type='button' variant="primary"
                        className={`${requestLoader ? 'position-relative button--loading' : ''}`}>
                        {buttonLabel}
                    </Button> : ''
                    }
                </div>
        </Modal>
    );
})

export default ConfirmationDialog;