import React from 'react';
import SelectFormType from './shared/select-form.component';
import "./create-campaign.scss"
import { useState } from 'react';
import GetStarted from './shared/get-started.component';
import { useNavigate } from "react-router-dom";
function CreateCampaign(props) {

    const navigate = useNavigate();

    const [currentProcess, setCurrentProcess] = useState(100);
    const [currentStep, setCurrentStep] = useState('get-started');

    const toggleView = (value) => {
        setCurrentStep(value);
        value === 'get-started' ? setCurrentProcess(100) : setCurrentProcess(50);
    }

    return (
        <div className='container mt-5'>
            {/* <div>
                {currentStep === 'type' ? <a onClick={(() => navigate("/campaigns"))} className='cursor-pointer text-cobalt-light'>
                    <i className="fa-solid fa-circle-left"></i>  Campaigns
                </a> : <a onClick={(() => toggleView('type'))} className='cursor-pointer text-cobalt-light'>
                    <i className="fa-solid fa-circle-left"></i> Back
                </a>}


            </div> */}
            
            
            {
                currentStep === 'type' ? <SelectFormType toggleForm={toggleView} /> :
                    currentStep === 'get-started' ?
                        <GetStarted /> : ''
            }


        </div>
    );
}

export default CreateCampaign;