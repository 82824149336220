import { useState, useEffect } from 'react';

const useRemoveQRCode = (setQrCode) => {

   useEffect(() => {
    const hideQR = () => {
        setQrCode('')
    }

    document.addEventListener('click', hideQR);
    
    return () => {
      document.removeEventListener('click', hideQR);
    }
   }, []);
};

export default useRemoveQRCode;