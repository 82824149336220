import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { paymentTypeList } from '../../../../data-list/data-list';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../../shared/fields-errors/field-error';

function OfflineDonationInfo(props) {

    const { register, setValue, formState: { errors } } = useFormContext();
    const [donationDesignation, setDonationDesignation] = useState([]);
    const [pricePerUnit, setPricePerUnit] = useState(0);

    useEffect(() => {
        if (props?.currentCampaign?.designation_dropdown_value) {
            const values = props?.currentCampaign?.designation_dropdown_value?.split(',');
            setDonationDesignation(values);
        }

        if(props?.currentCampaign?.price_per_unit){
            setPricePerUnit('$'+props?.currentCampaign?.price_per_unit);
        }
    }, [props?.currentCampaign])
    return (
        <div className='row'>
            <div className='col-xxl-4 col-12 mt-2'>
                <div className="form-group"><label className="form-control-label text-black " >
                    Payment Type
                </label>
                    <Form.Select
                        {...register('donation_info.payment_type')}
                    >
                        {
                            paymentTypeList.map((item, index) => {
                                return <option key={`payment_type` + index} value={item.value}>
                                    {item.label}
                                </option>
                            })
                        }
                    </Form.Select>
                </div>
            </div>
            <div className='col-xxl-4 col-12 mt-2'>
                <div className="form-group"><label className="form-control-label text-black " >
                    <span className='text-danger'>*</span> Donation Date
                </label>
                    <input
                        {...register('donation_info.created_at', {
                            validate: {
                                required: value => {
                                    if (!value) return 'Field is required.';
                                    return true;
                                }
                            }
                        })}
                        placeholder="Enter date" type="date"
                        className="form-control-alternative form-control " />
                    {
                        errors?.donation_info?.created_at ?
                            <FieldError message={errors?.donation_info?.created_at?.message} /> : ''
                    }
                </div>
            </div>

            {
                props?.donationType === 'product' ?
                    <>
                        <div className='col-xxl-4 col-12 mt-2'>
                            <div className="form-group"><label className="form-control-label text-black " >
                            Amount Per {props?.currentCampaign?.unit_label ? props?.currentCampaign?.unit_label : 'Unit'}
                            </label>
                                <input disabled value={pricePerUnit}
                                     type="text"
                                    className="form-control-alternative form-control " />
                            </div>
                        </div>
                        <div className='col-xxl-4 col-12 mt-2'>
                            <div className="form-group"><label className="form-control-label text-black " >
                            <span className='text-danger'>*</span> Quantity 
                            <span className='text-ma-primary mx-2'>{!props?.currentCampaign?.sell_after_out_of_stock && props?.currentCampaign?.available_units ? `(${props?.currentCampaign?.available_units}) Available` : ''}</span>
                            </label>
                                <input
                                    {...register('donation_info.no_of_units', {
                                        onChange: ((e) => {
                                            if (e.target.value) {
                                                const convertedValue = parseFloat(e.target.value);
                                                if (convertedValue > 0) {
                                                    const totalAmount = (convertedValue * props?.currentCampaign?.price_per_unit);
                                                    setValue('donation_info.amount', totalAmount);
                                                } else {
                                                    setValue('donation_info.amount', 0);
                                                }
                                            } 
                                        }),
                                        valueAsNumber: true,
                                        validate: {
                                            required: value => {
                                                if (!value) return 'Field is required.';
                                                return true;
                                            },
                                            min: value => {
                                                if(value <= 0) return 'Invalid quantity.'
                                                return true
                                            },
                                            max: value => {
                                                if (!props?.currentCampaign?.sell_after_out_of_stock) {
                                                    if (props?.currentCampaign?.available_units === undefined) {
                                                        return true
                                                    } else {
                                                        const available_quantity = parseFloat(props?.currentCampaign?.available_units);
                                                        if (available_quantity > 0 && value <= available_quantity) {
                                                            return true
                                                        } else {
                                                            return 'Quantity exceeds'
                                                        }
                                                    }

                                                } else {
                                                    return true
                                                }
                                            }
                                        },
                                       
                                    })}
                                    placeholder="Enter amount" type="number"
                                    className="form-control-alternative form-control " />
                                {
                                    errors?.donation_info?.no_of_units ?
                                        <FieldError message={errors?.donation_info?.no_of_units?.message} /> : ''
                                }
                            </div>
                        </div>
                        <div className='col-xxl-4 col-12 mt-2'>
                            <div className="form-group"><label className="form-control-label text-black " >
                                Total Amount
                            </label>
                                <input disabled
                                    {...register('donation_info.amount', {
                                        validate: {
                                            required: value => {
                                                if (!value) return 'Field is required.';
                                                return true;
                                            }
                                        }
                                    })}
                                    placeholder="Enter amount" type="number"
                                    className="form-control-alternative form-control " />
                                {
                                    errors?.donation_info?.amount ?
                                        <FieldError message={errors?.donation_info?.amount?.message} /> : ''
                                }
                            </div>
                        </div>
                    </> : <div className='col-xxl-4 col-12 mt-2'>
                        <div className="form-group"><label className="form-control-label text-black " >
                            <span className='text-danger'>*</span> Amount (USD)
                        </label>
                            <input
                                {...register('donation_info.amount', {
                                    validate: {
                                        required: value => {
                                            if (!value) return 'Field is required.';
                                            return true;
                                        }
                                    }
                                })}
                                placeholder="Enter amount" type="number"
                                className="form-control-alternative form-control " />
                            {
                                errors?.donation_info?.amount ?
                                    <FieldError message={errors?.donation_info?.amount?.message} /> : ''
                            }
                        </div>
                    </div>
            }

            {
                donationDesignation?.length > 0 ?
                    <div className='col-xxl-4 col-12 mt-2'>
                        <div className="form-group"><label className="form-control-label text-black " >
                            Please Direct My Support
                        </label>
                            <Form.Select {...register('donation_info.donation_designation')}>
                                {
                                    donationDesignation.map((item, index) => {
                                        return <option key={`support_` + index} value={item}>
                                            {item}
                                        </option>
                                    })
                                }
                            </Form.Select>
                        </div>
                    </div> : ''
            }
        </div>
    );
}

export default OfflineDonationInfo;